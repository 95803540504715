<template>
    <div class="fb">
        <button class="btn fb-btn" @click="login">Sign In with Facebook</button>
    </div>
</template>

<script>
    export default {
        name: "vfacebook",
        props: ['facebookAppId'],
        created() {
            this.getFBsdk();
        },
        mounted() {
            this.initFB();
        },
        data() {
            return {
                FB: false,
                status: false,
                authResponse: null,
            }
        },
        methods: {
            getFBsdk() {
                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {
                        return;
                    }
                    js = d.createElement(s);
                    js.id = id;
                    js.src = "//connect.facebook.net/en_US/sdk.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));

            },
            initFB() {
                var self = this;
                window.fbAsyncInit = function () {
                    self.FB = FB;
                    self.FB.init({
                        appId: self.facebookAppId,
                        cookie: true,
                        xfbml: true,
                        version: 'v10.0',
                    });
                    self.FB.getLoginStatus(function (response) {
                        self.status = response.status
                    });
                    //self.FB.AppEvents.logPageView();
                }
            },
            login() {
                var self = this;
                var permission = 'email';
                // if facebook user already logged
                if (self.authResponse) {
                    self.checkPermission(permission,
                        function () {
                            self.validate(self.authResponse);
                        }, function () {
                            self.askPermission(permission,
                                function () {
                                    self.validate(self.authResponse);
                                },
                                self.getFailedPermissionCallBack(permission));
                        }
                    );

                    return;
                }

                FB.login(function (response) {

                    if (response.status !== "connected") {
                        self.showError();

                        return;
                    }
                    self.authResponse = response.authResponse;
                    self.checkPermission(permission,
                        function () {
                            self.validate(response.authResponse);
                        }, function () {
                            self.askPermission(permission,
                                function () {
                                    self.validate(self.authResponse)
                                },
                                self.getFailedPermissionCallBack(permission));
                        }
                    );

                }, {scope: permission});
            },

            checkPermission: function (permission, onSuccess, onFailure) {
                var self = this;
                self.FB.api("/me/permissions/" + permission, function (response) {
                    var receivedPermission;

                    if ("undefined" != typeof (response.error) || response.data.length == 0) {
                        return;
                    }

                    receivedPermission = response.data[0];

                    if (receivedPermission.permission != permission || receivedPermission.status != 'granted') {
                        if ("function" == typeof (onFailure)) {
                            onFailure();
                        }
                        return;
                    }
                    onSuccess();
                });
            },
            /**
             * Validate facebook user on the backend facebook SDK
             *
             * @param authResponse {object} - facebook auth response
             * @param onSuccess {function} - callback after successes validation
             * @param onError {function} - callback after error validation
             * @param params {object} - POST request params
             */
            validate: function (authResponse, onSuccess, onError, params) {
                var self = this;

                // default onSuccess function
                onSuccess = onSuccess ||
                    function (data) {
                        if (data.redirectUrl) {
                            window.location = data.redirectUrl;
                        }
                    };

                // default onError function
                onError = onError ||
                    function (data) {
                        var message = '';
                        var i;
                        var errorObj;

                        if (data.errors.length > 0) {
                            for (i = 0; i < data.errors.length; i++) {
                                errorObj = data.errors[i];

                                if (errorObj.field === 'userEmail') {
                                    message +=
                                        'Sorry, our site requires your email address to work correctly. ' +
                                        'Seems like your facebook account doesn\'t have one. Our apologizes, ' +
                                        'but we can\'t sign you in until your facebook account doesn\'t ' +
                                        'have attached email. \n' +
                                        'Remember that you can always use regular Sign In procedure.\n';
                                }
                                if (errorObj.field === 'facebookSession') {
                                    message +=
                                        'Seems like your facebook session has expired. \n' +
                                        'Try to refresh the page.\n';
                                }
                            }
                        }
                        self.showError(message);
                    };

                params = params || {};

                params.signedRequest = authResponse.signedRequest;

                $.ajax({
                    type: 'POST',
                    url: '/api/facebook/connect',
                    data: $.toJSON(params),
                    success: function (data) {
                        if (data.success) {
                            onSuccess(data);
                        } else {
                            onError(data);
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        var response = jqXHR.responseJSON || {};

                        self.showError(response.message);
                    },
                    contentType: 'application/json',
                    dataType: 'json'
                });
            },
            /**
             * Show authorization error
             */
            showError: function (error) {
                error = error || 'Facebook authorization error. Please try again.';
                //todo:showError
            },
            /**
             * getFailedPermissionCallBack
             * */
            getFailedPermissionCallBack: function (permission) {
                var self = this;
                return function () {
                    self.showError('You must allow ' + permission + ' to sign-in');
                };
            },
            /**
             * askPermission
             * */
            askPermission: function (permission, onSuccess, onFailure) {
                var self = this;

                onSuccess = onSuccess || function () {
                };
                onFailure = onFailure || function () {
                };

                /*console.log({permission, onSuccess, onFailure});*/

                /* UIKitDialog.confirm(
                     'Please allow ' + permission + ' to sign-in',
                     function () {
                         self.FB.login(
                             function (response) {
                                 if (response.status !== "connected") {
                                     onFailure();

                                     return;
                                 }

                                 self.authResponse = response.authResponse;
                                 self.checkPermission(permission,
                                     onSuccess,
                                     onFailure
                                 );
                             },
                             {
                                 scope: permission,
                                 auth_type: 'rerequest'
                             }
                         );
                     },
                     function () {
                         self.showError();
                     }
                 );*/

            },
        },
    }
</script>

<style lang="scss">
    .fb {
        &-btn {
            width: 100%;
            padding: torem(12px) torem(36px);
            background-color: $fbblue;
            vertical-align: middle;
            max-width: 100%;
            border-color: $fbblue;
            font-weight: bold;
            margin-top: torem(10px);
            &:hover {
                background-color: $fbblue;
                border-color: $fbblue;
            }
        }
    }
</style>
