<template>
    <ValidationObserver
        v-if="user"
        ref="observer"
        :class="['sform',{'hide':isShowing}]"
        tag="form"
        @keydown.enter = "onClick()"
        v-slot="{ invalid }">
        <div class="row">
            <div class="col-12">
                <h2 class="sform-title"  @click="toggleHider">
                    User Information
                </h2>
            </div>
        </div>
        <div class="sform-hider">
            <div class="row">
                <!--firstName-->
                <div class="col-12">
                    <vinput
                        ref="firstName"
                        class="sform-input"
                        key="firstName"
                        :value="firstName"
                        :ttInfoOptions="{contentAsHTML:true}"
                        title="First name"
                        VPRules="required"
                        inputType="text"
                        inputDisplayName="First Name"
                        inputName="firstName"
                        @input="onInput($event,$refs.firstName)"
                    />
                </div>
                <div class="col-12">
                    <vinput
                        ref="lastName"
                        class="sform-input"
                        key="lastName"
                        :value="lastName"
                        :ttInfoOptions="{contentAsHTML:true}"
                        title="Last name"
                        VPRules="required"
                        inputType="text"
                        inputDisplayName="Last Name"
                        inputName="lastName"
                        @input="onInput($event,$refs.lastName)"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button ref="btn" class="btn sform-btn" :disabled="isOldValues || invalid" @click="onClick()">
                        Save user info
                    </button>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
    import vinput from '@/components/input-tt';
    import {ValidationProvider, ValidationObserver} from 'vee-validate';
    import {toggle} from '../mixins/toggle';
    import {marge} from '../mixins/marge';
    import {onInput} from '../mixins/onInput';

    export default {
        name: "user-info-form",
        props: ['user'],
        mixins: [toggle, marge, onInput],
        data() {
            return {
                firstName: null,
                lastName: null,
                isOldValues: true,
            }
        },
        mounted() {
            this.firstName = this.user.get('firstName');
            this.lastName = this.user.get('lastName');

        },
        methods: {
            async onClick() {
                this.isOldValues = true;
                var self = this,
                    isValid = await this.$refs.observer.validate();
                if (isValid) {
                    this.user.saveInfo({
                        wait: true,
                        success(e) {
                            self.$root.popup.showMessage('info change success');
                        },
                        error(newUserInfo, response) {
                            if (response) {
                                self.$root.popup.showMessage(response);
                            }
                            self.isOldValues = false;
                        }
                    }, {
                        firstName: this.firstName,
                        lastName: this.lastName,
                    });
                }
            },
        },
        components: {
            vinput,
            ValidationProvider,
            ValidationObserver,
            //notif
        }
    }
</script>