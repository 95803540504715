<template>
    <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
        class="currentProject"
        tag="form"
        @submit.prevent=""
        v-if="load">
        <!--Project title*-->
        <div class="row">
            <div class="col-12">
                <inputTT
                    title="Project title"
                    :ttInfoOptions="{contentAsHTML:true}"
                    ttInfoLabel="Retailers restrict information in ALL CAPS.<br/>Retailers restrict EP or Album as part of the title."
                    VPtitle="title"
                    VPrules="required|validateTitle|noUrl"
                    inputName="title"
                    inputPlaceHolder="Project Title"
                    v-model="currentProject.attributes.title"
                    @input="ontitleInput"
                    @focus="titleFocus = true"
                    @blur="titleFocus=false"
                    @change="checkForm"
                />
            </div>
        </div>
        <!--Copyright year*-->
        <div class="row">
            <div class="col-12 col-md-6 ">
                <inputErr
                    class="formRow"
                    :VPRules="{required:true,between:{min:1900,max:yearNow}}"
                    VPtitle="Copyright year"
                    title="Copyright year*"
                    :ttInfoOptions="{contentAsHTML:true}"
                    ttInfoLabel="Retailers restrict information in ALL CAPS.<br/>Retailers restrict EP or Album as part of the title."
                >
                    <multiselect
                        ref="multiselect"
                        v-model="currentProject.attributes.copyrightYear"
                        @input="checkForm()"
                        :options="dateList"
                        :searchable="true"
                        :show-labels="false"
                        placeholder="Choose..."
                    />
                </inputErr>
            </div>
            <div class="col-12 col-md-6 ">
                <inputTT
                    title="Album copyright line*"
                    :ttInfoOptions="{contentAsHTML:true}"
                    ttInfoLabel='Must enter either artist, label or project name <br> For example: "Elvis Presley" or "Universal Music Group"'
                    VPtitle="copyright"
                    VPrules="required"
                    inputName="copyrightLine"
                    inputPlaceHolder="Copyright Line"
                    v-model="currentProject.attributes.copyrightLine"
                    @change="checkForm"
                />
            </div>
        </div>
        <!--Primary Genre*-->
        
        <div class="row">
            <div class="col-12 col-dynamic-sm-12 col-sm-6 ">
                <inputErr
                    class="formRow"
                    VPRules="required"
                    VPtitle="Primary Genre*"
                    title="Primary Genre*"
                >
                    <multiselect
                        :allow-empty="false"
                        :options="genre"
                        :showLabels="false"
                        @input="onChangeGenre('primaryGenre',$event)"
                        group-id="id"
                        group-label="category"
                        group-values="genrelist"
                        label="title"
                        placeholder="Type to search"
                        tag-placeholder="Choose ..."
                        track-by="id"
                        v-model="primaryGenre"
                    >
                        <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                </inputErr>
            
            </div>
            <div class="col-12 col-dynamic-sm-12 col-sm-6 ">
                <inputErr
                    class="formRow"
                    VPRules="required"
                    VPtitle="Secondary Genre*"
                    title="Secondary Genre*"
                >
                    <multiselect
                        ref="multiselect"
                        :allow-empty="false"
                        v-model="secondaryGenre"
                        :options="genre"
                        @input="onChangeGenre('secondaryGenre',$event)"
                        group-values="genrelist"
                        group-label="category"
                        group-id="id"
                        placeholder="Type to search"
                        track-by="id"
                        label="title"
                        tag-placeholder="Choose ..."
                        :showLabels="false"
                    >
                        <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </multiselect>
                </inputErr>
            </div>
        </div>
        <!--UPC-->
        <div class="row">
            <div class="col-12">
                <row :contClass="{'scrool':!model.upsRequired}">
                    <vue-tooltipster
                        slot="tooltip"
                        class="tooltipster--responsive-left"
                        :tooltipsterOptions="{contentAsHTML:true}"
                        label="Enter your UPC/ Barcode or leave it blank to have one assigned for you. This is usually found on the <br>
                   back of the CD jacket. This number is used for tracking sales. A UPC number is 12-14 numbers, <br>
                   please don't include any dashes or spaces and be sure to include all the small numbers.">
                        <i class="ficon icon-info"></i>
                    </vue-tooltipster>
                    
                    <div class="additem" :class="{'active':model.upsRequired}" slot="title">
                        <p @click="upcChange()">Add UPC*<i class="ficon icon-plus"></i></p>
                    </div>
                    <template slot="item" v-if="model.upsRequired">
                        <inputTT
                            :ttInfoOptions="{contentAsHTML:true}"
                            VPtitle="upc"
                            :VPRules="{required:model.upsRequired,min:12,max:14,digitsAndLetters:''}"
                            inputName="upc"
                            inputPlaceHolder="UPC number"
                            v-model="currentProject.attributes.upc"
                            @change="checkForm"
                        />
                    </template>
                </row>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <row :contClass="{'scrool':!showOriginalReleaseDate}">
                    <vue-tooltipster
                        slot="tooltip"
                        class="tooltipster--responsive-left"
                        :tooltipsterOptions="{contentAsHTML:true}"
                        label='If this project was previously released, retailers require the original release date'>
                        <i class="ficon icon-info"></i>
                    </vue-tooltipster>
                    
                    <div class="additem" :class="{'active':showOriginalReleaseDate}" slot="title">
                        <p @click="changeOriginalReleaseDate">
                            Has this project been previously released?<i class="ficon icon-plus"></i>
                        </p>
                    </div>
                    
                    <template slot="item" v-if="showOriginalReleaseDate">
                        <ValidationProvider
                            name="originalReleaseDate"
                            :rules="{required:!!currentProject.attributes.originalReleaseDate,isDateFormat:''}"
                            class="large"
                            v-slot="{ errors,classes}"
                        >
                            <vDatePicker
                                ref="originalReleaseDate"
                                :customClass="{'error':errors.length}"
                                :allow-empty="true"
                                :options="{
                                     dateFormat: 'mm/dd/yy',
                                     maxDate: '+0d',
                                     autoSize: true
                                }"
                                :nullValue="true"
                                @keyDown="onDateKyeDown($event)"
                                v-model="currentProject.attributes.originalReleaseDate"
                            />
                            <vue-tooltipster slot="tooltip"
                                             v-if="classes.invalid"
                                             class="vee-error"
                                             :themeClass="'tooltipster-octive-error'"
                                             :tooltipsterOptions="{contentAsHTML:true}"
                                             :label="errors[0]">
                                <i class="ficon icon-ic_error tooltipstered"></i>
                            </vue-tooltipster>
                        </ValidationProvider>
                    </template>
                </row>
            </div>
        </div>
        <!--Liner notes-->
        <div class="row">
            <div class="col-12">
                <row>
                    <p slot="title" class="project-form-title">
                        Liner notes
                    </p>
                    <vue-tooltipster
                        slot="tooltip"
                        :tooltipsterOptions="{contentAsHTML:true}"
                        label="This is where you'd provide credits and shout outs.<br>For example the names of guest musicians,<br>producer,  engineers, studio name, etc">
                        <i class="ficon icon-info project-form-info__top"></i>
                    </vue-tooltipster>
                    <textarea
                        slot="item"
                        class="project-form-tarea" name="notes" placeholder=""
                        v-model="currentProject.attributes.notes"></textarea>
                
                
                </row>
            </div>
        </div>
        <!--btnSave-->
        <div class="row">
            <div class="col-12">
                <row
                    cont-class="'mb-0'"
                    row-class="'mb-0'"
                    :topClass='false'
                >
                    <template slot="item">
                        <button ref="submitbtn" :disabled="!genre" class="btn project-form-btn"
                                @click="submit()">
                            Save
                        </button>
                    </template>
                </row>
            </div>
        </div>
        <popup ref="popup" v-model="showModal" :text="popupText" />
    </ValidationObserver>

</template>
<script>
    /*components*/
    import multiselect from 'vue-multiselect';
    import tswitch from './switch';
    import check from './check';
    import vueTooltipster from '../../../components/vue-tooltipster'
    import row from './row';
    import vDatePicker from './v-date-picker';
    import inputTT from '@/components/input-tt';
    import inputErr from '@/components/error-slot';
    import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';

    import * as rules from 'vee-validate/dist/rules';
    import en from 'vee-validate/dist/locale/en';

    import popup from '@/components/popup'

    for (let rule in rules) {
        // add the rule
        extend(rule, {
            ...rules[rule], // add the rule
            message: en.messages[rule] // add its message
        });
    }
    extend('isDateFormat', {
        validate(date) {
            return /(\d{2})+\/+(\d{2})+\/+(\d{4})\b/.test(date)
        },
        message: 'this is no data format'
    });
    extend('digitsAndLetters', {
        validate(e) {
            return e.length && /^[a-zA-Z0-9!)]+$/.test(e);
        },
        message: 'Type only digits and letters '
    });

    extend('isrcValidation', {
        validate(e) {
            return e.length && /^[A-Z]{2}[A-Z0-9]{3}[0-9]{7}$/.test(e);
        },
        message: 'Wrong isrc'
    });

    export default {
        name: "projectForm",
        props: {
            fitem: {
                type: Array,
                default: () => {
                }
            },
            currentProject: {
                type: Object,
                default: () => {
                }
            },
            genre: {},
            dateList: {},
            immediatelyHighlight: {}
        },
        data() {
            return {
                upc: '',
                Genre: false,
                load: false,
                titleFocus: false,
                popupText: false,
                showModal: false,
                showOriginalReleaseDate: false,
                oldOriginalReleaseDate: null,
                model: {
                    upsRequired: false,
                    preOrderDate: {
                        options: {
                            minDate: '+30',
                            autoSize: true
                        },
                        field: ''
                    },

                },
                primaryGenre: '',
                secondaryGenre: '',
                yearNow: '',
            }
        },

        async mounted() {
            this.model.upsRequired = !!this.currentProject.attributes.upc;
            this.showOriginalReleaseDate = !!this.currentProject.attributes.originalReleaseDate;
            this.load = true;
            this.$nextTick(() => {
                document.querySelectorAll('.multiselect').forEach(e => {
                    e.setAttribute('tabindex', 0);
                });
                if (this.immediatelyHighlight) {
                    this.$refs.observer.validate();
                }
            });
            let now = new Date();
            this.yearNow = now.getFullYear() + 1;
            this.parseGenreField('primaryGenre');
            this.parseGenreField('secondaryGenre');
        },

        methods: {

            ontitleInput() {
                let value = this.currentProject.get('title');
                value = !this.titleFocus ? value.replace(/\s+/g, ' ').trim() : value;
                this.currentProject.setTitle(value);
                /*if single - change name track*/
                let tracks = this.currentProject.getTracks();
                if (tracks.length === 1) {
                    this.currentProject.getTracks().models[0].set('title', this.currentProject.getTitle());
                }
            },

            changeOriginalReleaseDate() {
                this.showOriginalReleaseDate = !this.showOriginalReleaseDate;
                if (this.showOriginalReleaseDate) {
                    this.currentProject.set('originalReleaseDate', this.oldOriginalReleaseDate);
                }
                else {
                    this.oldOriginalReleaseDate = this.currentProject.get('originalReleaseDate');
                    this.currentProject.set('originalReleaseDate', null);
                }
            },

            parseGenreField(field) {
                if (this.currentProject.attributes[field]) {
                    this[field] = {};
                    this[field].id = this.currentProject.attributes[field].id || this.currentProject.attributes[field].attributes.id;
                    this[field].title = this.currentProject.attributes[field].title || this.currentProject.attributes[field].attributes.title;
                }
            },

            onChangeGenre(field, e) {
                this.currentProject.attributes[field] = e;
                this.checkForm();
            },
            onDateKyeDown(date) {
                this.currentProject.set('originalReleaseDate', date);
            },
            async submit() {
                this.ontitleInput();
                this.$refs.submitbtn.disabled = true;
                this.currentProject.set('desiredReleaseDate', null);
                this.currentProject.set('preReleaseDate', null);
                var self = this,
                    isValid = await this.$refs.observer.validate();

                this.currentProject.disableValidationOfTracks();
                this.currentProject.disableValidationOfCoverArt();
                const errors = this.currentProject.validate(this.currentProject.attributes);
                if (errors) {
                    isValid = false;
                    this.$refs.observer.setErrors(errors);
                }

                if (!isValid) {
                    this.$nextTick(() => this.$emit('formHasError'));
                    self.$refs.submitbtn.disabled = false;

                    return;
                }

                await this.currentProject.save(null, {
                    validate: false,
                    success(responseProject) {
                        if (self.$route.name == 'create-project') {
                            self.$router.replace("/project/" + self.currentProject.id + '/edit');
                        }
                        if (self.$refs.submitbtn) {
                            self.$refs.submitbtn.disabled = false;
                            return true
                        }
                    },
                    error(model, response) {
                        if (response.responseJSON && response.responseJSON.errors) {
                            self.showErrors(response.responseJSON.errors);
                        } else {
                            self.$root.popup.showMessage(response);
                        }
                        if (self.$refs.submitbtn) {
                            self.$refs.submitbtn.disabled = false;
                        }
                    }
                });
            },

            upcChange() {
                this.model.upsRequired = !this.model.upsRequired;
                if (!this.model.upsRequired) {
                    this.currentProject.attributes.upc = null;
                }
            },

            showErrors(errors) {
                let errorsForVeeValidate = {};
                for (let error of errors) {
                    if (!errorsForVeeValidate[error.field]) {
                        errorsForVeeValidate[error.field] = [];
                    }
                    errorsForVeeValidate[error.field].push(error.message);
                }
                this.$refs.observer.setErrors(errorsForVeeValidate);
            },

            async checkForm() {
                let
                    self = this,
                    errors = false;
                this.currentProject.disableValidationOfTracks();
                this.currentProject.disableValidationOfCoverArt();
                this.currentProject.attributes.albumType = this.currentProject.attributes.albumType ? this.currentProject.attributes.albumType : 1;
                errors = this.currentProject.validate(this.currentProject.attributes);
                if (errors) {
                    console.warn('project-form errors:', {errors});
                }
                self.$emit('validateFormChange', !!errors);
            },
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            row,
            multiselect,
            tswitch,
            check,
            vueTooltipster,
            popup,
            vDatePicker,
            inputTT,
            inputErr
        },
    }
</script>