<template>

    <ValidationObserver v-if="value.attributes.file"
                        ref="observer"
                        v-slot="{ invalid }"
                        class="trackForm"
                        @submit.prevent=""
                        tag="form">
        <div class="row no-gutters" v-if="isMobile">
            <div class="col-12">
                <track-tab
                    key="track-tab-pos"
                    :track="value"
                    :handle="false"
                    :audio="false"
                    :tools="false"
                    @playPause="value.set('isPlay',$event)"
                />
            </div>
        </div>
        <!--Name of the track*-->
        <div class="row no-gutters">
            <div class="col-12">
                <inputTT
                    title="Name of the track*"
                    :ttInfoOptions="{contentAsHTML:true}"
                    ttInfoLabel="Retailers restrict information in ALL CAPS. <br> Retailers restrict EP or Album as part of the title."
                    VPtitle="Track name"
                    VPRules="required|validateTitle|noUrl"
                    inputName="title"
                    inputPlaceHolder="name of the track"
                    :value="value.getTitle()"
                    @input="ontitleInput($event)"
                    @focus="titlefocus=true"
                    @blur="titlefocus=false"
                    @change="formChange()"
                    :disabled="isSingle"
                />
            </div>
        </div>
        <!--Language & Includes explicit content-->
        <div class="row no-gutters">
            <div class="col-12 col-md-6" v-if="lang">
                <row>
                    <vue-tooltipster
                        slot="tooltip"
                        :tooltipsterOptions="{contentAsHTML:true}"
                        label='Retailers require the language to be specified on <br/> each track. Please select the appropriate language. <br/> If the track has no vocals, please choose "No Linguistic Content". '>
                        <i class="ficon icon-info"></i>
                    </vue-tooltipster>
                    <p slot="title" class="project-form-title">
                        Language*
                    </p>
                    <template slot="item">
                        <ValidationProvider
                            v-slot="{errors, classes}"
                            rules="required"
                            vid="language"
                        >
                            <multiselect
                                ref="multiselect"
                                v-model="language"
                                :class="{'error':errors[0]}"
                                :options="lang"
                                @input="onChangeLang($event)"
                                track-by="languageId"
                                label="displayName"
                                :searchable="true"
                                :show-labels="false"
                                placeholder="Choose..."></multiselect>
                        </ValidationProvider>
                    </template>

                </row>
            </div>
            <div class="col-12 col-md-6">
                <row>
                    <vue-tooltipster slot="tooltip"
                                     :tooltipsterOptions="{contentAsHTML:true}"
                                     label="Be sure this is accurate otherwise your project will get rejected">
                        <i class="ficon icon-info tooltipstered"></i>
                    </vue-tooltipster>
                    <p slot="title" class="project-form-title">
                        Includes explicit content?*
                    </p>
                    <template slot="item">
                        <ValidationProvider
                            v-slot="{errors, classes}"
                            vid="isExplicit"
                            rules="required">
                            <multiselect
                                ref="multiselect"
                                v-model="isExplicit"
                                :class="{'error':errors[0]}"
                                :options="[{bool:true,title:'yes'},{bool:false,title:'no'}]"

                                :searchable="true"
                                :show-labels="false"
                                placeholder="Choose..."
                                label="title"
                                track-by="bool"
                                @input="explicitChange($event)"
                            ></multiselect>
                        </ValidationProvider>
                    </template>
                </row>
            </div>
        </div>
        <!--Song artists *-->
        <div class="row no-gutters">
            <div class="col-12">
                <row>
                    <vue-tooltipster slot="tooltip"
                                     :tooltipsterOptions="{contentAsHTML:true}"
                                     label='Identify the "Main" performers on this track. <br>
                                  This is typically the artist or group releasing the project. <br>
                                  Use the drop down menu and select "Featured" if a featured artist <br>
                                  is included on this track. <br>
                                  Please check your artist identifiers in list below.'>
                        <i class="ficon icon-info tooltipstered"></i>
                    </vue-tooltipster>
                    <p slot="title" class="project-form-title">
                        Add song artists *
                    </p>
                    <template slot="item">
                        <ValidationProvider
                            class="full-width"
                            tag="div"
                            :rules="{checkMain:{newArtistFlag}}"
                            vid="artists"
                            v-slot="{errors}">
                            <participator-list
                                v-if="value.attributes.participators.models"
                                key="mainartists"
                                track-by="typeLabel"
                                placeholder="Artist name"
                                :errors="errors"
                                :options="[1,2]"
                                :user-participators="userParticipators"
                                :value="value.attributes.participators"
                                @add="mainArtistsAdd($event)"
                                @remove="mainArtistRemove($event)"
                                @input="formChange()"
                                @toggleNewArtist ="toggleNewArtistFlag($event)"
                            />

                        </ValidationProvider>
                    </template>
                </row>
            </div>
        </div>
        <!--
          Copyright year*,
          Song copyright line*
        -->
        <div class="row no-gutters">
            <div class="col-12 col-md-6">
                <row>
                    <!--<vue-tooltipster slot="tooltip"
                                     :tooltipsterOptions="{contentAsHTML:true}"
                                     label=''>
                      <i class="ficon icon-info tooltipstered"></i>
                    </vue-tooltipster>-->
                    <p slot="title" class="project-form-title"> Copyright year*</p>
                    <template slot="item">
                        <ValidationProvider
                            vid="copyrightYear"
                            rules="required"
                            class="large"
                            v-slot="{validate, errors, classes}"
                        >
                            <multiselect
                                ref="multiselect"
                                v-model="value.attributes.copyrightYear"
                                :class="{'error':errors[0]}"
                                :options="dateList"
                                :searchable="true"
                                :show-labels="false"
                                @input="formChange()"
                                placeholder="Choose..."></multiselect>
                        </ValidationProvider>
                    </template>
                </row>
            </div>
            <div class="col-12 col-md-6">
                <inputTT
                    title="Song copyright line*"
                    :ttInfoOptions="{contentAsHTML:true}"
                    ttInfoLabel='Must enter either artist, label or project name <br>
                       For example: "Elvis Presley" or "Universal Music Group"'
                    VPtitle="copyrightLine"
                    VPRules="required|max:55"
                    inputName="copyrightLine"
                    inputPlaceHolder="Copyright line"
                    @change="formChange()"
                    v-model="value.attributes.copyrightLine"
                />
            </div>
        </div>
        <!--
          Genre*
        -->
        <div class="row no-gutters">
            <div class="col-12">
                <div class="formRow">
                    <inputErr
                        VPRules="required"
                        VPtitle="Genre"
                        title="Genre*"
                    >
                        <multiselect
                            ref="multiselect"
                            :allow-empty="false"
                            :options="genre"
                            :showLabels="false"
                            @input="onChangeGenre($event)"
                            group-id="id"
                            group-label="category"
                            group-values="genrelist"
                            label="title"
                            placeholder="Type to search"
                            tag-placeholder="Choose ..."
                            track-by="id"
                            v-model="trackGenre"
                        >
                            <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                        </multiselect>
                    </inputErr>
                </div>
            </div>
        </div>
        <!--
          Add Song Writer*, Composer, Publisher
        -->
        <div class="row no-gutters">
            <div class="col-12">
                <row topClass="align-items-start">
                    <vue-tooltipster slot="tooltip"
                                     class="align-items-start"
                                     :tooltipsterOptions="{contentAsHTML:true}"
                                     label='Please add the writer, composer or publisher name in each<br>respective field below. Leave blank if unknown. <br>Publisher formatting should be "Publishing Company (PRO)"<br>e.g. "Octiive Publishing (ASCAP)"'>
                        <i class="ficon icon-info tooltipstered"></i>
                    </vue-tooltipster>
                    <p slot="title" class="project-form-title">
                      Composition & Lyrics (required)<br>
                        <span>(Please use legal name or your project will not be accepted)</span>
                    </p>
                    <template slot="item">
                        <ValidationProvider
                            class="full-width"
                            vid="participators"
                            tag="div"
                            :rules="composerParticipatorValidationRules"
                            v-slot="{errors}">
                            <participator-list
                                key="artists"
                                :errors="errors"
                                :options="composerParticipatorRoles"
                                :user-participators="userParticipators"
                                placeholder="Artist name"
                                :value="value.attributes.participators"
                                @input="formChange()"
                                @add="value.attributes.participators.add($event)"
                                @remove="value.attributes.participators.remove($event)"
                            />
                        </ValidationProvider>
                    </template>
                </row>
            </div>
        </div>

      <!--
          Production & Engineering
        -->
      <div class="row no-gutters">
        <div class="col-12">
          <row topClass="align-items-start">
            <p slot="title" class="project-form-title">
              Production & Engineering* (required)<br>
              <span>(Please use legal name or your project will not be accepted)</span>
            </p>
            <template slot="item">
              <ValidationProvider
                class="full-width"
                vid="engineering_artists"
                tag="div"
                rules="checkEngineer"
                v-slot="{errors}">
                <participator-list
                  key="artists"
                  :errors="errors"
                  :options="[10,11,12,13,14,15]"
                  :user-participators="userParticipators"
                  placeholder="Artist name"
                  :value="value.attributes.participators"
                  @input="formChange()"
                  @add="value.attributes.participators.add($event)"
                  @remove="value.attributes.participators.remove($event)"
                />
              </ValidationProvider>
            </template>
          </row>
        </div>
      </div>

      <!--
          Performing Artists
        -->
      <div class="row no-gutters">
        <div class="col-12">
          <row topClass="align-items-start">
            <p slot="title" class="project-form-title">
              Performing Artists* (required)<br>
              <span>(Please use legal name or your project will not be accepted)</span>
            </p>
            <template slot="item">
              <ValidationProvider
                class="full-width"
                vid="performing_artists"
                tag="div"
                rules="checkPerformer"
                v-slot="{errors}">
                <participator-list
                  key="artists"
                  :errors="errors"
                  :options="[16,17,18,19,20]"
                  :user-participators="userParticipators"
                  placeholder="Artist name"
                  :value="value.attributes.participators"
                  @input="formChange()"
                  @add="value.attributes.participators.add($event)"
                  @remove="value.attributes.participators.remove($event)"
                />
              </ValidationProvider>
            </template>
          </row>
        </div>
      </div>

        <!--upc-->
        <div class="row no-gutters">
            <div class="col-12">
                <row :contClass="{'scrool':!isrcRequired}">
                    <vue-tooltipster
                        slot="tooltip"
                        :tooltipsterOptions="{contentAsHTML:true}"
                        class="tooltipster--responsive-left"
                        label="Enter your ISRC number or leave it blank to have one assign for you. <br>
            This number is used for tracking sales of individual songs. <br>
            An ISRC code is 12 characters, please don't include any dashes or spaces.">
                        <i class="ficon icon-info"></i>
                    </vue-tooltipster>
                    <div class="additem" :class="{'active':isrcRequired}" slot="title">
                        <p @click="toggler({isrcRequired});removeOrAddAttr('isrc',isrcRequired)">Add ISRC*<i
                            class="ficon icon-plus"></i></p>
                    </div>
                    <template slot="item" v-if="isrcRequired">
                        <inputTT
                            :ttInfoOptions="{contentAsHTML:true}"
                            VPtitle="isrc"
                            :VPRules="{required:isrcRequired,min:12,max:14,digitsAndLetters:'',isrcValidation:''}"
                            inputName="isrc"
                            inputPlaceHolder="isrc number"
                            v-model="value.attributes.isrc"
                            @change="formChange"
                        />

                    </template>
                </row>
            </div>

        </div>
        <!--Song Mix-->
        <div class="row no-gutters">
            <div class="col-12 col-md-12">
                <row :contClass="{'scrool':!showMix}" :rowClass="'min-width'">

                    <div class="additem tooltip-empty-response" :class="{'active':showMix}" slot="title">
                        <p @click="toggler({showMix});removeOrAddAttr('mixNote',showMix)">Song Mix*<i
                            class="ficon icon-plus"></i></p>
                    </div>
                    <template slot="item" v-if="showMix">
                        <inputTT
                            :ttInfoOptions="{contentAsHTML:true}"
                            VPtitle="upc"
                            :VPRules="{required:value.attributes.showMix}"
                            inputName="mix"
                            inputPlaceHolder="song mixer"
                            v-model="value.attributes.mixNote"
                            @change="formChange"
                        />
                    </template>
                </row>
            </div>
            <div class="col-12 col-md-12">
                <row :contClass="{'scrool':!showRemix}">
                    <vue-tooltipster slot="tooltip"
                                     :tooltipsterOptions="{contentAsHTML:true}"
                                     class="tooltipster--responsive-left"
                                     label='Please leave blank if none (Left blank is most common). <br>
                                  This space is for the remixers name. <br>
                                  For example: "Mike D" or "DJ Sonar"'>
                        <i class="ficon icon-info tooltipstered"></i>
                    </vue-tooltipster>
                    <template slot="title">
                        <div class="additem" :class="{'active':showRemix}" slot="title">
                            <p @click="toggler({showRemix});removeOrAddRemixer(showRemix)"> Song Remixers?<i
                                class="ficon icon-plus"></i></p>
                        </div>
                    </template>
                    <template slot="item">
                        <ValidationProvider
                            class="full-width"
                            vid="remixers"
                            tag="div"
                            :rules="{required:!!showRemix}"
                            v-slot="{errors}">
                            <participator-list
                                v-if="showRemix"
                                class="fullwidth"
                                :errors="errors"
                                :options="[3]"
                                :userParticipators="userParticipators"
                                :label="false"
                                :rowInputClass="'col-12'"
                                :placeholder="''"
                                @input="formChange()"
                                @add="value.attributes.participators.add($event)"
                                @remove="value.attributes.participators.remove($event)"
                                :value="value.attributes.participators" />
                        </ValidationProvider>
                    </template>
                </row>
            </div>
        </div>
        <!--btn-->
        <div class="row no-gutters">
            <div class="col-12">
                <row
                    :cont-class="'mb-0'"
                    :show-left="false"
                    :row-class="'mb-0'"
                >
                    <template slot="item">
                        <button ref="submitbtn"
                                :disabled="!genre && !value.attributes.slotId && !value.getProject().get('id')"
                                class="btn project-form-btn"
                                @click="onSave()">
                            Save
                        </button>
                    </template>
                </row>
            </div>
        </div>
        <popup ref="popup" v-model="showModal" :text="popupText"></popup>
    </ValidationObserver>
    <div v-else-if="!value.attributes.file">
        <empty-state v-if="!value.get('isNewTrack')"
                     h1text="Audio file not found"
                     text="Please upload your audio again"
        />
        <random-loader v-else class="loader--poss" :substrate="false" :randomText="true" />
    </div>
</template>

<script>
    import multiselect from 'vue-multiselect';
    import row from './row';
    import participatorList from './participator-list';
    import vueTooltipster from '../../../components/vue-tooltipster';
    import vueSwitch from './switch';
    import inputTT from '@/components/input-tt'
    import inputErr from '@/components/error-slot'
    import trackTab from './track-tab';
    import popup from '@/components/popup'
    import randomLoader from '@/components/random-loader';
    import emptyState from '../../../components/emptyState'
    import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
    import {mapGetters} from 'vuex';
    import {isMobile} from '../../../components/mixins/isMobile';
    import ParticipatorType from '../../../components/models/participator/type';

    extend('validateTitle', {
        validate(title) {
            var notAllowedWords = [
                'ep', 'album', 'featuring', 'featured', 'feat'
            ];

            var notAllowedWordsRegexp = new RegExp('(^|[^a-zа-я])(' + notAllowedWords.join('|') + ')([^a-zа-я]|$)', 'i');

            if (notAllowedWordsRegexp.test(title)) {
                return 'Words "' + notAllowedWords.join('", "') + '" are not allowed in a title';
            }
            return true
        },
    });


    extend('checkWriter', {
        validate(participators) {
            return !!participators.getWriterParticipators().length
        },
        message: 'Writer is required'
    });

    extend('checkLyricist', {
      validate(participators) {
        return !!participators.getLyricistParticipators().length
      },
      message: 'Lyricist is required for linguistic tracks.',
    });

    extend('checkEngineer', {
      validate(participators) {
        return !!participators.getEngineerParticipators().length
      },
      message: 'You need to select at least 1 item from the list'
    });

    extend('checkPerformer', {
      validate(participators) {
        return !!participators.getPerformerParticipators().length
      },
      message: 'You need to select at least 1 item from the list'
    });

    extend('checkMain', {
        params: ['objFlag'],
        validate(participators, {objFlag} ) {
          return !!participators.getMainParticipators().length
        },
        message: 'Main artist is required.'
    });


    export default {
        name: "track-form",
        props: ['value', 'genre', 'dateList', 'userParticipators', 'projectInfo', 'immediatelyHighlight'],
        data() {
            return {
                isrcRequired: false,
                showMix: false,
                showRemix: false,
                trackGenre: null,
                language: null,
                isExplicit: null,
                popupText: false,
                showModal: false,
                oldAttrStorage: {},
                newArtistFlag: false
            }
        },
        mixins: [isMobile],
        async mounted() {
            if (this.value.attributes.genre) {
                this.trackGenre = {
                    id: this.value.attributes.genre.id || this.value.attributes.genre.attributes.id,
                    title: this.value.attributes.genre.title || this.value.attributes.genre.attributes.title
                }
            }
            if (this.value.attributes.language) {
                let language = this.value.attributes.language,
                    languageId = language.languageId || language.attributes.languageId,
                    displayName = language.displayName || language.attributes.displayName;
                if (languageId && displayName) {
                    this.language = {
                        languageId: languageId,
                        displayName: displayName,
                    }
                }
            }
            this.isrcRequired = this.value.attributes.isrc ? true : false;
            if (this.value.attributes.isExplicit !== null) {
                this.isExplicit = {
                    bool: this.value.attributes.isExplicit,
                    title: this.value.attributes.isExplicit ? 'yes' : 'no'
                };
            }
            this.showRemix = this.value.attributes.participators.getParticipatorsByType(3).length;
            this.$nextTick(() => {
                document.querySelectorAll('.multiselect').forEach(e => {
                    e.setAttribute('tabindex', 0);
                });
                if (this.immediatelyHighlight && this.$refs.observer) {
                    this.$refs.observer.validate();
                }
            });


        },
        methods: {
            toggleNewArtistFlag(checked) {
                this.newArtistFlag = checked;
            },
            ontitleInput(value) {
                value = !this.titlefocus ? value.replace(/\s+/g, ' ').trim() : value;
                var lowerWords = [
                    'a', 'an', 'and', 'for', 'from',
                    'of', 'or', 'to', 'the', 'in', 'as',
                    'but', 'nor', 'so', 'yet', 'at',
                    'by', 'into', 'off', 'onto',
                    'over', 'up', 'with'
                ];

                var valueArray = value.split(' ');
                var valueArrayLength = valueArray.length;
                var newValue = '';
                var word;
                var lowerCase;
                var filter;

                for (var i in valueArray) {
                    filter = valueArray[i].match(/^([^a-zа-я]*)(.*?)([^a-zа-я]*)$/i);
                    word = filter[2];
                    lowerCase = word.toLowerCase();

                    if (i > 0 && i < valueArrayLength - 1
                        && lowerWords.indexOf(lowerCase) != -1
                    ) {
                        word = lowerCase;
                    } else {
                        word = word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
                    }
                    newValue += filter[1] + word + filter[3] + (i < valueArrayLength - 1 ? ' ' : '');
                }
                this.value.set('title', newValue);
            },

            onChangeGenre(newTrackGenre) {
                this.value.attributes.genre = newTrackGenre;
                this.formChange();
            },

            onChangeLang(newLang) {
                this.value.attributes.language = newLang;
                this.formChange();
            },
            mainArtistsAdd(id){
                this.value.attributes.participators.add(id);
                /*this.value.save(null, {validate: false});*/
            },
            mainArtistRemove(id){
                this.value.attributes.participators.remove(id);
                /*this.value.save(null, {validate: false});*/
            },

            toggler(layer) {
                this[Object.keys(layer)[0]] = !layer[Object.keys(layer)[0]];
            },
            removeOrAddAttr(attr, state) {
                let setter = null;
                if (state) {
                    setter = this.oldAttrStorage[attr];
                }
                else {
                    this.oldAttrStorage[attr] = this.value.get(attr);
                }
                this.value.set(attr, setter);
            },
            removeOrAddRemixer(state) {
                if (state) {
                    this.value.get('participators').add(this.oldAttrStorage.remixer);
                }
                else {
                    this.oldAttrStorage.remixer = this.value.attributes.participators.where({type: 3});
                    let participators = this.value.get('participators');
                    participators.remove(participators.where({type: 3}));
                }
            },
            async onSave() {
                this.ontitleInput(this.value.getTitle());
                this.$refs.submitbtn.disabled = true;
                let
                    isValid = await this.$refs.observer.validate(),
                    errors = this.value.validate(this.value.attributes),
                    self = this;
                this.value.changedIncrement();

                if (errors) {
                    this.$refs.observer.setErrors(errors);
                    isValid = false;
                }

                if (!isValid) {
                    this.$refs.submitbtn.disabled = false;
                    this.$emit('formHasError');
                    return false
                }

                if (this.projectInfo.isNew()) {
                    await this.projectInfo.save(null, {
                        validate: false,
                        success(e) {
                        },
                        error(project, response) {
                            self.$root.popup.showMessage(response);
                            errors = true;
                        }
                    });
                }
                if (errors) {
                    this.$refs.submitbtn.disabled = false;
                    return false;
                }
                if (!this.isrcRequired) {
                    this.value.set('isrc', null)
                }
                this.value.save(null, {
                    validate: false,
                    success(e) {
                        self.checkTrackFile();
                        if (self.$refs.submitbtn) {
                            self.$refs.submitbtn.disabled = false;
                        }
                    },
                    error(track, response) {
                        if (response.responseJSON && response.responseJSON.errors) {
                            self.showErrors(response.responseJSON.errors);
                        } else {
                            self.$root.popup.showMessage(response);
                        }
                        errors = true;
                        if (self.$refs.submitbtn) {
                            self.$refs.submitbtn.disabled = false;
                        }
                    }
                });
            },

            async checkTrackFile() {

                if (this.value.attributes.file) {
                    return;
                }
                let self = this;
                this.value.fetch({
                    error(collection, error) {
                        self.$root.popup.showMessage(error);
                    }
                }).then(this.checkTrackFile);
            },

            showErrors(errors) {
                let errorsForVeeValidate = {};
                for (let error of errors) {
                    if (!errorsForVeeValidate[error.field]) {
                        errorsForVeeValidate[error.field] = [];
                    }
                    errorsForVeeValidate[error.field].push(error.message);
                }
                this.$refs.observer.setErrors(errorsForVeeValidate);
            },

            async formChange() {
                let errors = this.value.validate(this.value.attributes);
                this.$emit('validateFormChange', !!errors);
            },

            explicitChange(selection) {
                this.isExplicit = selection;
                this.value.attributes.isExplicit = selection ?  selection.bool : null;
                this.formChange()
            },
        },

        components: {
            ValidationProvider,
            ValidationObserver,
            multiselect,
            row,
            participatorList: participatorList,
            vueTooltipster,
            vueSwitch,
            inputTT,
            inputErr,
            popup,
            randomLoader,
            emptyState,
            trackTab
        },
        computed: {
            ...mapGetters({
                lang: 'track/getLanguage',
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
            }),
            isSingle() {
              return this.value.getProject().getTracks().length === 1
            },
            composerParticipatorValidationRules () {
                let rules = ['checkWriter'];

                if (this.language && this.language.languageId !== 'zxx') {
                  rules.push('checkLyricist');
                }

                return rules.join('|');
            },
            composerParticipatorRoles () {
              let roles = [4,5,6];

              if (this.language && this.language.languageId !== 'zxx') {
                roles.push(9); // enable lyricist
              }

              return roles;
            },
        },
    }

</script>
