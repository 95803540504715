<template>
  <div class="col-12 d-flex flex-column">
    <random-loader class="loader--poss" v-if="models == null" :substrate="false" text="Your PR Blast list will be shown here"/>
    <div v-else-if="models.length > 0"  v-for="item in models" :key="item.id">
      <itemSlot v-if="!isMobile"
        
        :status="item.getStatusData()"
        :isEditable="item.isEditable()"
        :editUrl="item.getEditUrl()"
        :showUrl="item.getShowUrl()"
      >
        <p slot="head" class="d-none d-md-block">Created date</p>
        <p slot="head">Full name</p>
        <p slot="head"> <span class="d-none d-sm-inline">Release</span> Title</p>
        <p slot="head"> <span class="d-none d-sm-inline">Release</span> format</p>
        <p slot="head">Artist name</p>
        
        <p slot="date">
          {{item.getCreatedAtFormatted()}}
        </p>
        
        <p slot="content" class="d-none d-md-inline-block" :class="{'marketingItem-empty':!item.getCreatedAtFormatted()}">
          {{item.getCreatedAtFormatted() ||'no Date'}}
        </p>
        <p slot="content" :class="{'marketingItem-empty':!item.get('qaFullName')}">
          {{item.attributes.qaFullName || 'no Name'}}
        </p>
        <p slot="content" :class="{'marketingItem-empty':!item.get('qaReleaseTitle')}">
          {{item.attributes.qaReleaseTitle || 'no title'}}
        </p>
        <p slot="content" :class="{'marketingItem-empty':!item.get('qaReleaseFormat')}">
          {{item.getQaReleaseFormatText(item.get('qaReleaseFormat')) || 'no Format'}}
        </p>
        <p slot="content" :class="{'marketingItem-empty':!item.get('qaArtistBio')}">
          {{item.attributes.qaArtistBio || 'no Artist'}}
        </p>
      </itemSlot>
      <itemMobileSlot v-else
        :status="item.getStatusData()"
        :isEditable="item.isEditable()"
        :editUrl="item.getEditUrl()"
        :showUrl="item.getShowUrl()"
      >
        <p slot="item-title">
          <span :class="{'text-empty':!item.get('qaReleaseFormat')}">
          {{item.getQaReleaseFormatText(item.get("qaReleaseFormat")) || 'no Format'}}
          </span>
          <span class="split"></span>
          <span :class="{'text-empty':!item.get('qaArtistBio')}">
          {{item.get('qaArtistBio') || 'no Artist'}}
          </span>
        </p>
        
        <p slot="date" :class="{'text-empty':!item.getCreatedAtFormatted()}">
            {{item.getCreatedAtFormatted() ||'no Date'}}
        </p>
        
        <p slot="item-head-left">Full name</p>
        <p slot="item-cont-left" :class="{'text-empty':!item.get('qaFullName')}">
            {{item.get('qaFullName')|| 'no Name'}}
        </p>
        <p slot="item-head-right">Release title</p>
        <p slot="item-cont-right" :class="{'text-empty':!item.get('qaReleaseTitle')}">
            {{item.get('qaReleaseTitle')|| 'no Title'}}
        </p>
        
        
      </itemMobileSlot>
    </div>
    <empty-state v-else
                h1text="You do not have PR Blast"
                text="Your PR Blast list will be shown here"
    />
  </div>
</template>

<script>
    import itemSlot from './item-slot';
    import itemMobileSlot from './item-mobile-slot';
    import emptyState from '../../../components/emptyState';
    import randomLoader from '@/components/random-loader';
    import Mondoblast from '@/components/collections/marketing/mondoblast';
    import {mapGetters} from 'vuex';
    import {isMobile} from '../../../components/mixins/isMobile';
    export default {
        name: "mondoblast-item",
        mixins:[isMobile],
        data() {
            return {
                models: null,

            }
        },
        async mounted() {
            let self = this;
            let mondoblast = new Mondoblast();
            mondoblast.fetch({
                success() {
                    self.models = mondoblast.models;
                },
                error(collection,error) {
                    self.$root.popup.showMessage(error);
                }
            }).then(() => {

            });
        },
        computed: {
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
            }),
        },
        components: {
            itemSlot,
            emptyState,
            randomLoader,
            itemMobileSlot
        }
    }
</script>
