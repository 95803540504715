<template>
  <div class="container d-flex flex-column align-items-stretch">
    <template v-if="!!(currentProject && subscription !== false && load)">
      <div class="posr">
        <router-link class="distribute-close edit-project-close ficon icon-subtract"
                     :to="'/project/'+currentProject.getId()+'/edit'"></router-link>
        <project-item :item="currentProject" v-if="currentProject.attributes.title" :viewMode="true" />
      </div>
      <template
        v-if="load && subscription !== false && cart && projectCollection && currentProject.attributes.title ">
        <div class="feature">
          <div class="feature-obj">
            <h3 class="feature-h1 main-h1">
              Exclusive Offer: Your Music Now on SoundCloud!
            </h3>
            <p class="feature-subtext main-sub">
              You ready for this? Octiive is now the only indie distributor that can directly upload your music to SoundCloud. It’s incredibly simple. Just follow the steps below, and we’ll handle the rest:

              take care of the rest.
            </p>
            <div class="feature-vdate">
              <section class="step">
                <h3>1. Make sure you have a SoundCloud account</h3>
                <p>Don’t have one? <a href="https://soundcloud.com/" target="_blank">Register here</a></p>
              </section>

              <section class="step">
                <h3>2. Connect your SoundCloud account to Octiive</h3>
                <p>With just a few clicks, you’ll link your account, and we’ll take care of the rest.</p>
              </section>

              <section class="step">
                <h3>3. Ensure your project meets the required structure:</h3>
                <p>
                  <ul>
                    <li>You are distributing your project under your artist name and account.</li>
                    <li>Your project features only one primary artist.</li>
                    <li>Your project will be live on your connected SoundCloud account.</li>
                  </ul>
                </p>
              </section>

              <section class="step">
                <h3>Get Your Music Everywhere</h3>
                <p>With Octiive, it’s a one-stop solution to get your music on hundreds of retailers, and now, directly on SoundCloud.</p>
              </section>

              <hr class="feature-hr">

              <div class="connect">
                <sound-cloud @handler="soundCloudHandler" :user="soundCloudUser" />
              </div>

              <div class="feature-subtext main-sub" style="margin-top: 20px">
                <label for="termsAndConditionAgreement" style="cursor: pointer;" v-show="this.soundCloudUser">
                  <input type="checkbox" id="termsAndConditionAgreement" v-model="distributeToSoundCloud" /> Distribute to SoundCloud?
                </label>

                <div>

                </div>

                <div class="addition-btn">
                  <button class="btn btn--small ficon icon-plus" @click="nextStep">
                    {{ nextStepButtonLabel }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="feature-cart sc-image">
          </div>
        </div>
      </template>
    </template>
    <random-loader v-else class="loader--poss" :substrate="false" :randomText="true" />
  </div>
</template>

<script>
import DistibutiionModel from '@/components/models/distribution-project';
import Products from '@/components/collections/products'
import addonsForm from './components/addons-form'
import projectItem from './components/project-item';
import plansLoop from '../plans/components/plans-loop';
import { mapGetters } from 'vuex';
import randomLoader from '@/components/random-loader';
import ProjectCollection from '@/components/collections/distribution-projects';
import SoundCloud from "./components/soundcloud.vue";
import LabelHome from "../dashboard/components/label-home.vue";
import PlatformArtist from "../../components/models/platform/platform-artist";

export default {
  name: "distribute",
  data() {
    return {
      currentProject: false,
      load: false,
      subscription: false,
      cart: false,
      products: false,
      projectCollection: null,
      usedArtistCount: null,
      user: false,
      soundCloudUser: false,
      distributeToSoundCloud: false
    }
  },

  mounted: async function () {
    // TODO this module needs to be cleaned up

    this.disableCartCursorPointer();
    this.initCurrentProject();

    let self = this;
    this.$store.dispatch('cart/loadCart').then(cart => {
      this.cart = cart;
    });
    this.$store.dispatch('subscription/loadSubscription').then(subscription => {
      this.subscription = subscription;
    });
    await this.initProducts();
    this.projectCollection = new ProjectCollection();
    await this.projectCollection
      .loadUsedMainArtistCount(this.currentProject)
      .then((count) => {
        console.log(count);
        this.usedArtistCount = count;
      });

    // Load user information
    this.user = await this.$store.dispatch('user/loadUser');

    // Load SoundCloud profile
    const soundCloudLoaded = await this.user.loadSoundCloud();
    if (soundCloudLoaded.username) {
      this.soundCloudUser = soundCloudLoaded;
    }

    if (this.soundCloudUser) {
      this.distributeToSoundCloud = true;
    }

    this.load = true;
  },

  beforeRouteLeave(to, from, next) {
    this.enableCartCursorPointer();
    next();
  },

  methods: {
    soundCloudHandler(message) {
      switch (message) {
        case 'success':
          // TODO when success even received, reload the user data
          location.reload()
          break;
      }
    },

    async attachSoundCloudIdentifier()
    {
      const mainArtist = this.currentProject.getMainArtists() ? this.currentProject.getMainArtists()[0] : null;
      if (mainArtist) {
        const platformArtists = await mainArtist.get('platformArtists');

        const SoundCloudIdentifier = new PlatformArtist()
        SoundCloudIdentifier.set('platform', 'SoundCloud');
        SoundCloudIdentifier.set('platformId', this.soundCloudUser.permalink);
        platformArtists.add(SoundCloudIdentifier);

        await mainArtist.save()
      }
    },

    async nextStep() {
      // if user's SC account is connected and user want to distribute to soundcloud,
      if (this.soundCloudUser && this.distributeToSoundCloud) {
        // the project should have 1 main artist only
        if (this.currentProject.getMainArtists().length > 1) {
          this.$root.popup.showMessage('Ops, You can\'t submit a project to SoundCloud that has multiple main artists. If you don\'t want, you can uncheck and continue');
          this.distributeToSoundCloud = false;
          return;
        }

        await this.prepareForSoundCloudDistribution();
      }

      let id = this.currentProject.getId();
      this.$router.push('/project/' + id + '/distribute');
    },

    isAddons() {
      return this.currentProject.isPayPerProject() || this.currentProject.isFreeSingle() ||
        (
          this.subscription &&
          this.projectCollection &&
          this.subscription.get('maxArtistCount') >= this.usedArtistCount &&
          this.subscription.attributes.status === 'active' && !this.subscription.get('isRenewalAllowed')
        )
        || !!this.cart.getItemsByType([1, 13]).length;
    },
    disableCartCursorPointer() {
      let cartButton = document.querySelector(".header .cart-header-btn");
      let cartCounter = document.querySelector(".cart-header-counter");

      if (cartButton) {
        cartButton.style.cursor = 'default';
        cartButton.classList.add('disableCartButton');
      }

      if (cartCounter) {
        cartCounter.classList.add('disableCartCounter');
      }
    },
    enableCartCursorPointer() {
      let cartButton = document.querySelector(".header .cart-header-btn");
      let cartCounter = document.querySelector(".cart-header-counter");

      if (cartButton) {
        cartButton.style.cursor = "pointer";
        cartButton.classList.remove('disableCartButton');
      }

      if (cartCounter) {
        cartCounter.classList.remove('disableCartCounter');
      }
    },
    checkProjectForDistribute() {
      // todo: add check for subscription in cart and main artists in current project
      if (!this.currentProject.readyForDistribute()) {
        console.warn('this project doesn\'t ready for distribution');
        let id = this.currentProject.getId();
        this.$router.push('/project/' + id + '/edit');

        return;
      }

      if (!this.currentProject.isIncomplete()) {
        this.$router.push('/project/list');
      }
    },
    initCurrentProject() {
      let self = this;
      if (this.$route.params.id) {
        this.currentProject = Backbone.Relational.store.find(DistibutiionModel, this.$route.params.id);
        if (!this.currentProject) {
          this.currentProject = new DistibutiionModel();
          this.currentProject.set('id', this.$route.params.id);
          this.currentProject.fetch({
            error(collection, error) {
              self.$root.popup.showMessage(error);
            },
            success() {
              self.checkProjectForDistribute();
            }
          });
        } else {
          self.checkProjectForDistribute();
        }
      }
    },
    async initProducts() {
      if (!this.productList) {
        await this.$store.dispatch('product-list/load');
      }

      this.products = new Products(this.productList);
    },
    async prepareForSoundCloudDistribution() {
      try {
        // Attach SC identifier to main artist
        await this.attachSoundCloudIdentifier();

        // Mark the project as pending for QC
        this.currentProject.distributeToSoundCloud();
        this.currentProject.set('desiredReleaseDate',null);
        await this.currentProject.save();
      } catch (e) {
        this.$root.popup.showMessage(error);
      }
    }
  },
  computed: {
    ...mapGetters({
      productList: 'product-list/get'
    }),

    nextStepButtonLabel() {
      return this.distributeToSoundCloud ? 'Agree and Continue' : 'Continue';
    }
  },
  components: {
    LabelHome,
    SoundCloud,
    projectItem,
    plansLoop,
    addonsForm,
    randomLoader
  }
}
</script>

<style lang="scss">
.step {
  margin-top: 1rem;

  h3 {
    font-weight: bold;
    font-size: 1.5rem;
  }

  p {
    padding-left: 20px;
    color: #4c4c4c;
    font-size: 1.3rem;
  }

  ul {
    padding-left: 20px;
  }
}

hr.feature-hr {
  margin: 2rem 0;
}

.ready {
  p {
    color: #4c4c4c;
    font-size: 1.3rem;
    margin-bottom: 1em;
  }
}

.connect {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.sc-image {
  background-image: url('https://cdn.prod.website-files.com/5de692c71b5c23251d74e90c/666aefbb27ea49b9f4790481_rachit-tank-CDJa851MH0E-unsplash-p-3200.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.continue-btn {
  padding: .5rem 1rem;
  background-color: black;
  color: white;
}

</style>
