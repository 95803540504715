import Backbone from 'backbone';

export default Backbone.Model.extend({
    urlRoot: '/api/analytics/',
    defaults: {
        chart: null,
        list: null,
        totals: null,
    },

    fetchCurrent: function (type, dateFrom, dateTo, groups, projects) {
        let options = {};
        if (options.url === undefined) {
            options.url = this.urlRoot + type + "/from/" + this.dateFormat(dateFrom) + "/to/" + this.dateFormat(dateTo) + "/by/" + groups + '?';
        }
        if (projects) {
            let projectIds = '';

            projects.forEach(project => {
                projectIds += projectIds.length ? '&' : '';
                projectIds += '&project_ids[]=' + project.id;
            });
            options.url += projectIds;
        }
        return Backbone.Model.prototype.fetch.call(this, options);
    },
    dateFormat(date) {
        date = date.split('/');
        return date[2] + '-' + date[0] + '-' + date[1];
    },
    getChart() {
        return this.get('chart');
    },
    getList() {
        return this.get('list');
    },
    getTotals() {
        return this.get('totals');
    },
    isFill(){
        return !!( this.getChart() && this.getList() && this.getTotals() ) && !!( this.getChart().length && this.getList().length && Object.keys( this.getTotals()).length )
    }
});