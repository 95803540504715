<template>
    <div class="col-12">
      <div class="mondoblastShow">
        <router-link v-if="load" to="/marketing/prblast" class="mondoblastShow-close ficon icon-subtract"></router-link>
        <div class="row">
          <div class="col-12">
            <h1 class="mondoblastShow-main-h1 main-h1">
              PR Blast
            </h1>
          </div>
        </div>
        <template v-if="load">
          <div class="row">
            <div class="col-12">
              <div class="mondoblastShow-h1 mondoblastShow-h1--top">
                Main info
              </div>
            </div>
          </div>
          <div class="mondoblastShow-row">
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Full name'
                :text=" model.attributes.qaFullName"
              />
            </div>
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Release title'
                :text=" model.attributes.qaReleaseTitle"
              />
            </div>
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Release format'
                :text=" qaReleaseFormat"
              />
            </div>
          
          
          </div>
          <div class="mondoblastShow-row">
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Twitter handle'
                :text=" model.attributes.qaTwitter"
              />
            </div>
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Getting involved in music'
                :text=" model.attributes.qaHowGetInvolved"
              />
            </div>
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Artist name'
                :text=" model.attributes.qaReleaseArtistName"
              />
            </div>
          </div>
          <div class="mondoblastShow-row">
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Writers'
                :text=" model.attributes.qaWhoWritesSongs"
              />
            </div>
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='List of artistic influences'
                :text=" model.attributes.qaArtisticInfluences"
              />
            </div>
          </div>
          <hr class="mondoblastShow-hr">
          <div class="mondoblastShow-row">
            <div class="mondoblastShow-col">
              <itemShow
                strongText='Album message'
                :text=" model.attributes.qaAlbumMessage"
              />
              <itemShow
                strongText='Artist bio'
                :text=" model.attributes.qaArtistBio"
              />
            </div>
          </div>
          <hr class="mondoblastShow-hr">
          
          <div class="row">
            <div class="col-12">
              <div class="mondoblastShow-h1">
                Contact info
              </div>
            </div>
          </div>
          
          <div class="mondoblastShow-row">
            <div class="mondoblastShow-col">
              <itemShow
                strongText='Email'
                :text=" model.attributes.contactEmail"
              />
              <itemShow
                strongText='Adress'
                :text=" model.attributes.contactAddress"
              />
              <itemShow
                strongText='Phone number'
                :text=" model.attributes.contactPhone"
              />
              <itemShow
                strongText='Website'
                :text=" model.attributes.contactWebsite"
              />
            </div>
          </div>
        </template>
        <random-loader v-else class="loader--poss" :substrate="false" :randomText="true" />
      </div>
    </div>
</template>

<script>
  import Mondoblast from '@/components/models/marketing/mondoblast'
  import itemShow from './item-show'
  import randomLoader from '@/components/random-loader';

  export default {
    name: "mondoblast-show",
    data() {
      return {
        load: false,
        model: null,
        qaReleaseFormat: null
      }
    },
    async mounted() {
      let self = this;
      this.model = Backbone.Relational.store.find(Mondoblast, this.$route.params.id);
      if (!this.model) {
        this.model = new Mondoblast({id: this.$route.params.id});
        await this.model.fetch({
          error(collection, error) {
            self.$root.popup.showMessage(error);
          }
        });
      }
      if (this.model.attributes.qaReleaseFormat) {
        this.qaReleaseFormat = this.model.albumTypes[this.model.attributes.qaReleaseFormat]
      }
      this.load = true;
    },
    components: {
      itemShow,
      randomLoader
    }

  }
</script>

