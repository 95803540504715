<template>
    <div class="table" v-if="value">
        <div class="table-row table-header d-flex row">
            <div class="table-col col-6">
                <p>Title</p>
            </div>
            <div class="table-col col-6">
                <p>{{selection}}</p>
            </div>
        </div>
        <div class="table-row row" v-if="tableMass.length" v-for=" some in tableMass">
            <div class="table-col col-6">
                <p class="p title">
                    <i class="cercle"
                       :style="{
                        backgroundColor:getColorByName(some.name.replace(' sub-30s','_30'))
                       }"
                    ></i>
                    {{some.name}}
                </p>
            </div>
            <div class="total-col col-6">
                <div class="table-totals">
                    <p class="total-count">
                        {{some.totals.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}
                    </p>
                    <p class="total-percentage ficon"
                       :class="{
                        'up icon-select-up':some.totals.percentage>0,
                        'down icon-select-down':some.totals.percentage<0,
                       }"
                    >
                        {{some.totals.percentage.toFixed(2).replace(/\.0+$/, '').replace('.', ',')}} %
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {color} from '../mixins/color'

    export default {
        name: "v-table-analytic",
        props: ['value', 'selection', 'colorMap'],
        mixins: [color],
        data() {
            return {
                mass: []
            }
        },
        mounted() {
            this.mass = this.getMass(this.value);
        },
        methods: {
            getMass(value) {
                if (!value) {
                    return [];
                }
                return value.reduce((acc, cur) => {
                    let res = [{
                        name: cur.name,
                        totals: {
                            count: cur.totals.count,
                            percentage: cur.totals.percentage,
                        }
                    }];
                    if (cur.sub30Totals.count || cur.sub30Totals.percentage) {
                        let resSub = {
                            name: cur.name + ' sub-30s',
                            totals: {
                                count: cur.sub30Totals.count,
                                percentage: cur.sub30Totals.percentage,
                            }
                        };
                        res.push(resSub);
                    }
                    if (!acc) {
                        return acc;
                    }
                    return acc.concat(res);
                }, []);
            },

        },
        computed: {
            tableMass() {
                return this.value && this.getMass(this.value);
            }
        }
    }
</script>

<style lang="scss">
    .table {
        &-row {
            &:nth-child(2n) {
                background: $white-eb;
            }
        }
        & &-header {
            
            p {
                font-weight: $fw-800;
                font-size: torem(24px);
                line-height: torem(29px);
                color: $blue-1f;
            }
        }
        &-row {
            padding: torem(12px) 0;
        }
        &-totals {
            display: flex;
        }
        p {
            color: $mainfont;
            
            font-weight: $fw-500;
            font-size: torem(17px);
            line-height: torem(21px);
            
            display: flex;
            align-items: center;
            
            .cercle {
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                display: inline-block;
                background-color: transparent;
                margin-right: torem(10px);
                flex: 0 0 auto;
            }
            &.total {
                display: flex;
                &-percentage {
                    display: flex;
                    color: $grey-6c;
                    &:before {
                        order: 1;
                        justify-self: unset;
                        align-self: center;
                        font-size: 0.5rem;
                        margin-right: 0.6rem;
                        margin-left: .6rem;
                        font-weight: bold;
                    }
                    &.up {
                        color: $green-21;
                    }
                    &.down {
                        color: $red-f3;
                    }
                }
                
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .table {
            margin-left: torem(-28px);
            margin-right: torem(-28px);
            width: auto;
            p {
                font-size: torem(10px);
    
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                display: block;
                .cercle{
                    vertical-align: middle;
                }
            }
            &-row {
                align-items: center;
            }
            & &-header p {
                font-size: torem(10px);
            }
            &-col {
                padding: 0 torem(15px);
            }
            &-row {
                padding: torem(10px) 0;
            }
            &-totals{
                justify-content: flex-end;
            }
        }
    }
</style>