<template>
    <div>
        <div class="plan-item" :class="{'plan-item-disabled':!isEnabled}">
            <div class="plan-item-head" v-if="!!$slots['title']">
                <slot name="title"></slot>
            </div>
            <div class="plan-item-price" v-if="!!$slots['price']">
                <slot name="price"></slot>
            </div>
            <div class="plan-item-list" v-if="!!$slots['list']">
                <slot name="list"></slot>
            </div>
            <div class="plan-item-notif" v-if="!!$slots['notif']">
                <slot name="notif"></slot>
            </div>
            <div class="plan-item-bottom">
                <button
                    v-if="currentProject != null"
                    ref="buttonAddToCart"
                    class="btn plan-item-btn" @click="addToCart()"
                    :disabled="!isEnabled"
                >
                    Select
                </button>
                <router-link
                    v-else
                    to="/project/create"
                    class="btn plan-item-btn"
                >
                    Select
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "ppp-item",
        props: ['products', 'currentProject'],
        data() {
            return {
                isEnabled: false,
                rules: {min: Infinity, max: -1},
                cart: false,
                subscription: false,
            }
        },
        mounted() {

            this.getRules();
            this.$store.dispatch('subscription/loadSubscription').then(subscription => {
                this.subscription = subscription;
                this.isEnabled = this.getEnableStatus();
            });
            this.$store.dispatch('cart/loadCart').then(cart => {
                this.cart = cart;
            });
        },
        methods: {
            getRules() {
                for (let product of this.products) {
                    this.rules.min = this.rules.min > product.getParamData('min_tracks') ? product.getParamData('min_tracks') : this.rules.min;
                    this.rules.max = this.rules.max < product.getParamData('max_tracks') ? product.getParamData('max_tracks') : this.rules.max;
                }
            },
            addToCart() {
                this.$refs.buttonAddToCart.disabled = true;
                let
                    currentProjectId = this.currentProject.attributes.id,
                    trackCount = this.currentProject.getTracks().length;

                let product = this.products.find(p => this.canBeBought(p, trackCount));
                if (!product) {
                    console.error('Can\'t find suitable product for ' + trackCount + ' tracks');
                    console.log(this.products);

                    return;
                }
                this.cart.addItemToCart(product, {project_id: currentProjectId},
                    () => this.$emit('addToCartSuccess'),
                    (error) => {
                        this.$root.popup.showMessage(error);
                        this.$emit('addToCartError', error);
                        this.$refs.buttonAddToCart.disabled = false;
                        return;
                    }
                );
            },

            getEnableStatus() {
                if (!this.currentProject) {
                    return true;
                }
                let res = true, trackCount = this.currentProject.getTracks().length;
                if (trackCount >= this.rules.min && trackCount <= this.rules.max) {
                    res = true;
                }
                else {
                    res = false;
                }
                return res;
            },

            canBeBought(product, trackCount)
            {
                var min = product.getParamsData().min_tracks,
                    max = product.getParamsData().max_tracks;
                return min <= trackCount && trackCount <= max
                    ? product
                    : null;
            }
        }
    }
</script>