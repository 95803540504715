import Backbone from 'backbone';
import 'backbone-relational/backbone-relational';

    var Badge = Backbone.RelationalModel.extend({
        defaults: {
            title: '',
            buttonText: '',
            isButtonEnabled: false,
            buttonUrl: '',
            statusTitle: '',
            statusClass: '',
            isCancelButtonEnabled: false,
        },
        isNull: function () {
            return this.getTitle() === '';
        },
        hasButton: function () {
            return !!this.getButtonText();
        },
        hasStatus: function () {
            return !!this.getStatusTitle();
        },
        getStatusTitle: function () {
            return this.get('statusTitle');
        },
        getStatusClass: function () {
            return this.get('statusClass');
        },
        getTitle: function () {
            return this.get('title');
        },
        getButtonText: function () {
            return this.get('buttonText');
        },
        isButtonEnabled: function () {
            return this.get('isButtonEnabled');
        },
        getButtonUrl: function () {
            return this.get('buttonUrl');
        },
        hasCancelButton: function(){
            return this.get('isCancelButtonEnabled');
        },
        getCancelButtonUrl: function(){
            return '/api/subscription/cancel';
        },
        getCancelButtonText: function(){
            return 'Cancel';
        },
    });

    Badge.createFromSubscription = function (subscription, isCancelButtonEnabled) {
        var badgeTitle = subscription.getTitle();

        if (subscription.isNull()) {
            return new Badge();
        }

        if (subscription.getStatus() === 'suspended') {
            return new Badge({
                title: badgeTitle,
                statusTitle: 'Suspended',
                statusClass: 'gray',
                isButtonEnabled: true,
                buttonText: 'Choose one',
                buttonUrl: '/plans', // /dashboard/buy/music-distribution
                isCancelButtonEnabled: false
            });
        }

        if (subscription.getStatus() === 'cancelled') {
            return new Badge({
                title: badgeTitle,
                statusTitle: 'Cancelled',
                statusClass: 'rouge',
                isButtonEnabled: true,
                buttonText: 'Choose one',
                buttonUrl: '/plans', // /dashboard/buy/music-distribution
                isCancelButtonEnabled: false
            });
        }

        if (subscription.isWaiting()) {
            return new Badge({
                title: badgeTitle,
                statusTitle: 'Pending payment',
                statusClass: 'gray',
                isCancelButtonEnabled: isCancelButtonEnabled
            });
        }

        if (subscription.isPaid() && subscription.isBoundary()) {
            return new Badge({
                title: badgeTitle,
                statusTitle: 'Active',
                statusClass: 'active',
                isCancelButtonEnabled: isCancelButtonEnabled
            });
        }

        if (subscription.isPaid() && !subscription.isBoundary()) {
            return new Badge({
                title: badgeTitle,
                statusTitle: 'Active',
                statusClass: 'active',
                isButtonEnabled: true,
                buttonText: 'Upgrade',
                buttonUrl: '/plans', // /dashboard/buy/upgrade-music-distribution
                isCancelButtonEnabled: isCancelButtonEnabled
            });
        }

        if (subscription.get('isRenewalAllowed') && subscription.isCurrent()) {
            return new Badge({
                title: badgeTitle,
                statusTitle: 'Expired',
                statusClass: 'rouge',
                isButtonEnabled: true,
                buttonText: 'Renewal',
                buttonUrl: '/plans', ///dashboard/subscription/renewal-music-distribution
                isCancelButtonEnabled: false
            });
        }

        if (!subscription.isPaid()) {
            return new Badge({
                title: badgeTitle,
                statusTitle: 'Expired',
                statusClass: 'rouge',
                isButtonEnabled: true,
                buttonText: 'Choose one',
                buttonUrl: '/plans', // /dashboard/buy/music-distribution
                isCancelButtonEnabled: isCancelButtonEnabled
            });
        }

        return new Badge();
    };

    export default Badge;
