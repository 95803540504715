<template>
    <ValidationProvider
        :name="VPtitle"
        tag="div"
        v-slot="{errors, classes}"
        :class="['slotTT',VPClass]"
        :rules="VPRules">
        <div :class="{'slotTT-error':classes.invalid}">
            <div class="slotTT-top" v-if="title || ttInfoLabel">
                <vue-tooltipster
                    v-if="ttInfoLabel"
                    :tooltipsterOptions="ttInfoOptions"
                    class="slotTT-info"
                    :label="ttInfoLabel">
                    <i class="ficon icon-info"></i>
                </vue-tooltipster>
                <p class="slotTT-title" v-if="title" v-html="title"></p>
            </div>
            <div class="slotTT-bottom">
                <slot></slot>
                <vue-tooltipster
                    v-if="classes.invalid "
                    :class="['slotTT-ttError',ttErrorClass]"
                    themeClass="slotTT-ttError-tooltipster"
                    :tooltipsterOptions="ttErrorOptions"
                    :label="ttErrorLabel||errors[0]">
                    <i class="ficon icon-ic_error tooltipstered"></i>
                </vue-tooltipster>
            </div>
        </div>
    </ValidationProvider>
</template>

<script>
    import vueTooltipster from '@/components/vue-tooltipster';
    import {ValidationProvider} from 'vee-validate';

    export default {
        name: "input-tt",
        props: [

            'title',
            'ttInfoOptions',
            'ttInfoLabel',

            'VPtitle',
            'VPClass',
            'VPRules',

            'ttErrorClass',
            'ttErrorOptions',
            'ttErrorLabel',
        ],
        components: {
            vueTooltipster,
            ValidationProvider
        }
    }
</script>

<style lang="scss">
    .slotTT {
        display: flex;
        flex-direction: column;
        width: 100%;
        
        .multiselect {
            position: relative;
            input {
                border: none;
                padding-left: 0;
            }
            &--active + .slotTT-ttError{
                z-index: 100;
            }
        }
        &-top {
            flex: 1 1 auto;
            width: 100%;
            display: flex;
            padding-left: torem(26px);
            position: relative;
            margin-left: torem(-26px);
        }
        &-info {
            position: absolute;
            font-size: torem(18px);
            cursor: pointer;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
        }
        &-bottom {
            width: 100%;
            display: flex;
            position: relative;
            input, textarea {
                width: 100%;
                background: $white;
                border: torem(1px) solid $color-62;
                box-sizing: border-box;
                border-radius: torem(4px);
                min-width: 1px;
                flex: 1 1 auto;
                padding: 0 torem(12px);
                color: $blue-1f;
                font-size: torem(17px);
                line-height: torem(46px);
            }
        }
        &-ttError {
            position: absolute;
            top: 0;
            bottom: 0;
            right: torem(22px);
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: torem(18px);
            z-index: 2;
            &.tooltipster .ficon {
                color: $errofontColor;
            }
            &-tooltipster .tooltipster-content {
                font-weight: $fw-medium;
                color: $errofontColor
            }
            
        }
        &-title {
            font-weight: $fw-bold;
            font-size: torem(19px);
            line-height: torem(23px);
            display: flex;
            align-items: center;
            color: $mainfont;
            margin-bottom: torem(8px);
            
        }
        &-error {
            .multiselect__select {
                margin-right: torem(30px);
                &:before {
                    color: $errofontColor;
                }
            }
            .multiselect {
                border-color: $errofontColor;
                color: $errofontColor;
                .multiselect__current,
                .multiselect__input,
                .multiselect__single {
                    color: $errofontColor;
                    input {
                        border: none;
                    }
                }
            }
            .multiselect__tags {
                border-color: $errofontColor;
                color: $errofontColor;
            }
            input, textarea {
                padding-right: 0;
                border-color: $errofontColor;
                color: $errofontColor;
                &::placeholder {
                    color: $errofontColor;
                }
            }
        }
        &.textArea .slotTT-error .tooltipster.slotTT-ttError {
            align-items: end;
            margin-top: torem(15px);
        }
        
        &.textArea .slotTT-error textarea {
            padding-right: torem(50px);
        }
        
    }
    
    @include media-breakpoint-down(xs) {
        /*slotTT moobile herer*/
        .slotTT {
            &-bottom {
                input, textarea {
                    background: transparent;
                    line-height: torem(38px);
                }
            }
        }
    }
</style>