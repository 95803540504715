<template>
    <div class="col-12">
      <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
        class="mondoEdit"
        tag="form"
        @submit.prevent=""
      >
        <div class="mondoEdit-row row">
          <router-link v-if="load" to="/marketing/prblast" class="mondoblastShow-close ficon icon-subtract"></router-link>
          <div class="mondoEdit-col col-12 ">
            <p class="mondoEdit-h1">
              PR Blast
            </p>
          </div>
        </div>
        <template v-if="load">
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-md-6 ">
              <p class="mondoEdit-h2 mondoEdit-h2--scnd">
                1. Please answer the below questions so that we can get <br> started on your press release!
              </p>
            </div>
          </div>
          
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 ">
              <note>
                <p>
                  Please make sure all information is complete and accurate. Include information which may appear in
                  your press release only. <br> Any or all of the above may appear in your global press release.
                  <strong>no changes can be made after we publish your press release.</strong>
                </p>
              </note>
            </div>
          </div>
          
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                VPRules="required|max:255"
                VPtitle="FullName"
                title="What is your full name?*"
              >
                <input @change="checkForm()" v-model="model.attributes.qaFullName" type="text">
              </inputErr>
            </div>
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                
                VPRules="required|max:255"
                VPtitle="ReleaseTitle"
                title="What is the title of your release?*"
              >
                <input @change="checkForm()" v-model="model.attributes.qaReleaseTitle" type="text">
              </inputErr>
            </div>
          </div>
          <div class="mondoEdit-row row">
            
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                VPRules="required|max:255"
                VPtitle="ReleaseFormat"
                title="What format are you releasing?*"
              >
                <multiselect
                  v-model="qaReleaseFormat"
                  @input="onCnahgeReleaseFormat($event)"
                  :options="releaseFormatList"
                  :searchable="false"
                  :show-labels="false"
                  label="text"
                  track-by="id"
                  placeholder="Choose..."></multiselect>
              </inputErr>
            </div>
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                VPRules="required|max:255"
                VPtitle="ReleaseArtistName"
                title="What is the name you wish to appear as the artist of this release?* "
              >
                <input @change="checkForm()" v-model="model.attributes.qaReleaseArtistName" type="text">
              </inputErr>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col mondoEdit-crutch col-12 col-md-6 ">
              <inputErr
                VPRules="required|max:255"
                VPtitle="Twitter"
                title="We want to help you promote your project! What is your Twitter handle?*"
              >
                <input @change="checkForm()" v-model="model.attributes.qaTwitter" type="text">
              </inputErr>
            </div>
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                VPRules="required|max:255"
                VPtitle="HowGetInvolved"
                title="How did you start getting involved in music?*"
              >
                <input @change="checkForm()" v-model="model.attributes.qaHowGetInvolved" type="text">
              </inputErr>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                VPRules="required|max:255"
                title="Who writes your songs?*"
              >
                <input @change="checkForm()" v-model="model.attributes.qaWhoWritesSongs" type="text">
              </inputErr>
            </div>
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                VPRules="required|max:255"
                VPtitle="ArtisticInfluences"
                title="Please list some of your artistic influences*"
              >
                <input @change="checkForm()" v-model="model.attributes.qaArtisticInfluences" type="text">
              </inputErr>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                VPRules="required|max:255"
                VPtitle="AlbumMessage"
                VPClass="textArea"
                title="What message does your album share with its listeners? "
              >
                  
                  <textarea @change="checkForm()" class="mondoEdit-area" v-model="model.attributes.qaAlbumMessage">
                    </textarea>
              </inputErr>
            </div>
            <div class="mondoEdit-colcol-12 col-md-6 ">
              <inputErr
                VPRules="required|max:255"
                VPTtitle="ArtistBio"
                VPClass="textArea"
                title="Please copy and paste an existing artist bio you may have*"
              >
                  <textarea @change="checkForm()" class="mondoEdit-area" v-model="model.attributes.qaArtistBio">
                  </textarea>
              </inputErr>
            </div>
          </div>
          
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-md-6 ">
              <p class="mondoEdit-h2">
                2. Please list any of the following desired contact information.
              </p>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 ">
              <note>
                <p>
                  All information listed below will be broadcast to the web and listed on your press release. <br>
                  please only list public information! no changes can be made after we publish your press release.
                </p>
              </note>
            </div>
          </div>
          
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                :VPRules="{required:true,email:'',max:255}"
                VPtitle="Email"
                title="Email*"
              >
                <input @change="checkForm()" v-model="model.attributes.contactEmail" type="text">
              </inputErr>
            </div>
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                title="Address"
                VPRules="max:255"
              >
                <input @change="checkForm()" v-model="model.attributes.contactAddress" type="text">
              </inputErr>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                title="Phone number"
                VPRules="max:255"
                :VPRules="{phone:''}"
              >
                <input @change="checkForm()" placeholder="+1 _ _ _ -_ _ _ - _ _ _ _ "
                       v-model="model.attributes.contactPhone" type="text">
              </inputErr>
            </div>
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                title="Website"
                :VPRules="{website:'',max:255}"
              >
                <input @change="checkForm()" v-model="model.attributes.contactWebsite" type="text">
              </inputErr>
            </div>
          </div>
          
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 mondoEdit-buttons ">
              <button :disabled="!load && !isFormValid" ref="sandButton" class="mondoEdit-btn btn" @click="send()"> Send
              </button>
              <button :disabled="!load" ref="draftButton" class="mondoEdit-btn btn btn-blue"
                      @click="saveDraft()"> Save draft
              </button>
            
            </div>
          </div>
        </template>
        <random-loader v-else class="loader--poss" :substrate="false" :randomText="true" />
      </ValidationObserver>
    </div>
</template>

<script>
  import inputErr from '@/components/error-slot'
  import note from './note'
  import Mondoblast from '@/components/models/marketing/mondoblast'
  import randomLoader from '@/components/random-loader';
  import multiselect from 'vue-multiselect';
  import {ValidationObserver, extend} from 'vee-validate';

  extend('email', {
    validate(e) {
      let regExp = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]+)$/gm;
      return e.length && regExp.test(e);
    },
    message: 'john@example.com'
  });
  extend('website', {
    validate(e) {
      let regExp = /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
      return e.length && regExp.test(e);
    },
    message: 'Must be website link '
  });
  extend('phone', {
    validate(e) {
      let regExp = /^[+0-9]*$/gm;
      return e.length && regExp.test(e);
    },
    message: 'Example +15417543010'
  });


  export default {
    name: "mondoblast-edit",
    data() {
      return {
        load: false,
        model: false,
        qaReleaseFormat: null,
        isFormValid: false,
        releaseFormatList: [
          {id: 1, text: 'Single'},
          {id: 2, text: 'EP'},
          {id: 3, text: 'Album'},
        ]
      }
    },
    async mounted() {
      let self = this;
      this.model = Backbone.Relational.store.find(Mondoblast, this.$route.params.id);
      if (!this.model) {
        this.model = new Mondoblast({id: this.$route.params.id});
        await this.model.fetch({
          error(collection, error) {
            self.$root.popup.showMessage(error);
          }
        });
      }
      if (this.model.attributes.qaReleaseFormat) {
        this.qaReleaseFormat = this.releaseFormatList[this.model.attributes.qaReleaseFormat - 1]
      }
      this.load = true;
      this.checkForm();
    },
    methods: {
      async send() {
        let self = this;
        this.checkForm();
        if (this.isFormValid) {
          this.$refs.sandButton.disabled = true;
          let res = this.model.sendModel()
            .done(function () {
              self.$root.popup.showMessage('Your PR Blast was successfully submitted!');
              self.$router.push('/marketing');
            })
            .fail(function (xhr) {
              self.$refs.sandButton.disabled = false;
              var response = xhr.responseJSON || {},
                message = response.message || xhr.statusText;

              if (xhr.status !== 400) {
                self.$root.popup.showMessage(message);
                return;
              }
              self.$root.popup.showMessage(message || "Please check form information");

            })
        }

      },

      async saveDraft() {
        let self = this, draftButton = this.$refs.draftButton;
        draftButton.disabled = true;
        this.checkForm();
        await this.model.save(
          null, {
            success(e) {
              self.$root.popup.showMessage('Successfully saved as a draft');
              draftButton.disabled = false;
            },
            error(ret, error) {
              self.$root.popup.showMessage('Successfully saved as a draft but form has errors');
              draftButton.disabled = false;
            }
          }
        );
        draftButton.disabled = false;
      },


      onCnahgeReleaseFormat(selected) {

        this.qaReleaseFormat = selected || null;
        this.model.attributes.qaReleaseFormat = selected ? selected.id : null;
      },

      async checkForm() {
        this.isFormValid = await this.$refs.observer.validate();
      }

    },
    components: {
      ValidationObserver,
      multiselect,
      inputErr,
      note,
      randomLoader
    }
  }
</script>

