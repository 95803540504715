<template>
    <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="submit( )">
        <ValidationProvider
            tag="div"
            :name="item.displayName || item.name"
            :rules="item.rules"
            :key="item.name"
            v-slot="{ errors,classes}"
            v-for="(item,index) in formdata.inputs"
            class="signform-holder"
            :class="[item.class,{
                                    'signform-input':item.type!='checkbox' && item.type!='radio',
                                    'signform-checkbox':item.type=='checkbox'
                                    }]"
            :id="item.name"
        >
            <label v-if="item.placeholder" class="label">
                {{item.placeholder}}
            </label>
            <div class="signform-holder">
                <input
                    v-if="item.type!='checkbox' && item.type!='radio' && item.type!='captcha'"
                    :tabindex="index+1"
                    v-model="item.model"
                    :name="item.name"
                    :class="[item.inputclass,{'error':classes.invalid}]"

                    :type="item.dtype || item.type"
                    autocomplete="on"
                    @keydown.enter="keyEnter($event,item)"
                >
                <div class="signform-iconholder" v-if="item.type == 'password' || classes.invalid">
                    <button v-if="item.type == 'password'"
                            @click.stop="toggleType(item,$event)"
                            class="signform-showpassword ficon icon-eye"
                            :class="{'active':item.dtype === 'text'}"
                            title="Show password under asterisks (ctrl+enter)"></button>

                    <vue-tooltipster slot="tooltip"
                                     v-if="classes.invalid && item.type!='checkbox'"
                                     :tooltipsterOptions="{contentAsHTML:true}"
                                     themeClass="vee-error"
                                     :label="item.errormes || errors[0]">
                        <i class="signform-error--input ficon icon-ic_error"></i>
                    </vue-tooltipster>
                </div>
                <template v-if="item.type==='checkbox' || classes.invalid">
                    <vcheckBox v-model="item.model"
                               v-if="item.type==='checkbox'"
                               :name="item.name"
                               :adclass="{'error':classes.invalid}"
                               :tabindex="index+1"
                               :text="item.cbhtml" />
                </template>
                <div id="captcha" ref="captcha" v-if="item.type==='captcha'"></div>

            </div>
        </ValidationProvider>
        <button ref="submitButton" v-if="formdata.submittext" class="btn signform-btn" :class="formdata.submitClass">
            {{formdata.submittext}}
        </button>
    </ValidationObserver>
</template>

<script>


    import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
    import axios from 'axios';
    import * as rules from 'vee-validate/dist/rules';
    import en from 'vee-validate/dist/locale/en';
    import vueTooltipster from '@/components/vue-tooltipster';

    import vcheckBox from './vcheckbox';

    import Captcha from '@/components/captcha';
    import Config from '@/components/config';

    for (let rule in rules) {
        // add the rule
        extend(rule, {
            ...rules[rule], // add the rule
            message: en.messages[rule] // add its message
        });
    }
    extend('phone', {
        validate(e) {
            return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(e);
        },
        message: 'This looks like an incorrect phone number.'
    });


    export default {
        name: "vue-form",
        props: ['formdata', 'rememberme'],
        data() {
            return {
                token: false,
                config: false,
                captcha: false,
            }
        },
        mounted() {
            this.config = Config;
            console.log('mounted > ', this.config.get('requireCaptcha'));
            this.checkCaptcha(this.config.get('requireCaptcha'));
        },
        updated() {
            console.log('updated > ', this.config.get('requireCaptcha'));
            this.checkCaptcha(this.config.get('requireCaptcha'));
        },
        methods: {
            async submit() {
                this.$refs.submitButton.disabled = true;
                // wild workaround
                this.token = this.token ? this.token : window.phpSignFormLink.token || false;
                var isValid = false;
                isValid = await this.$refs.observer.validate();
                if (!isValid) {
                    this.$refs.submitButton.disabled = false;
                    return false
                }
                await axios.post(
                    this.formdata.request,
                    this.getFormData()
                ).then((response) => {
                    response = response.data;
                    if (response.success) {
                        if (response.hasOwnProperty('isEmailVerified')
                            && !response.isEmailVerified
                        ) {
                            this.$root.popup.showMessage(
                                'We are excited to get your music out to the world! Before we can distribute a project we will need to verify your email address. Please check your inbox and follow the instructions.'
                            );
                            this.$root.popup.setAddText('Go To Dashboard');
                            this.$root.popup.onConfirm = function () {
                                window.location.href = response.redirectUrl;
                            };
                            if (this.$refs.submitButton) {
                                this.$refs.submitButton.disabled = false;
                            }
                        } else if (response.redirectUrl) {
                            window.location.href = response.redirectUrl;
                        }
                        else {
                            this.$root.popup.showMessage('A new password was sent to your Email.');
                            this.$refs.submitButton.disabled = false;
                        }

                        return response;
                    }

                    this.token = response.csrfToken;
                    console.log('login > ', response.data && response.data.requireCaptcha, response);
                    this.checkCaptcha(response.data && response.data.requireCaptcha);
                    if (response.errors) {
                        this.$refs.observer.setErrors(response.errors);
                        if (response.errors.auth || response.errors.csrfToken) {
                            this.$root.popup.showMessage(response.errors.auth || response.errors.csrfToken);
                        }
                    }
                    if (this.$refs.submitButton) {
                        this.$refs.submitButton.disabled = false;
                    }

                    return response;
                }).catch(errors => {
                    this.$root.popup.showMessage(errors.message);
                })
            },

            checkCaptcha(requireCaptcha) {
                if (!requireCaptcha) {
                    if (this.captcha && this.captcha.isInitialized) {
                        this.captcha.destroy();
                    }

                    return;
                }

                if (!this.$refs.captcha) {
                    console.log('no captcha');
                    return;
                }

                if (!this.captcha) {
                    this.captcha = new Captcha();
                    console.log('create and init captcha');
                }

                if (!this.captcha.isInitialized) {
                    console.log('init captcha');
                    this.captcha.init();
                } else {
                    console.log('refresh captcha');
                    this.captcha.refresh();
                }
            },

            toggleType: function (item, e) {
                /*
                *check, if type has dtype area
                * if not - method create tag and change them
                * */
                e.preventDefault();
                if (!item.dtype) {
                    this.$set(item, 'dtype', item.type);
                }
                item.dtype = item.dtype === 'password' ? 'text' : 'password';

            },


            getFormData() {
                var res = {};
                this.$refs.observer.$el.querySelectorAll('input').forEach(function (e) {
                    res[e['name']] = e['value'] === 'on' ? '' + (+e.checked) : e['value'];
                });
                res['csrfToken'] = this.token;
                if (!(this.rememberme === undefined)) {
                    res['remember'] = this.rememberme;
                }
                if (this.captcha && this.$refs.captcha[0]) {
                    res['g-recaptcha-response'] = this.captcha.getResponse();
                }
                if (this.formdata.isFree) {
                    res['isFreeSingle'] = 1;
                }
                return res;
            },

            showpass(item) {
                if (item.type != 'password') return false;

                if (!item.dtype) {
                    this.$set(item, 'dtype', item.type);
                }
                item.dtype = item.dtype === 'password' ? 'text' : 'password';
            },

            keyEnter(e, item) {
                if (e.ctrlKey) {
                    this.showpass(item);
                } else {
                    e.stopPropagation();
                    e.preventDefault();
                    this.submit();
                }
                return false;
            },
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            vueTooltipster,
            vcheckBox
        },
    }
</script>
