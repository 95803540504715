import Backbone from 'backbone'
import ItemModel from '@/components/models/user/order/item'

export default Backbone.Collection.extend({
  model: ItemModel,

  toDTO: function () {
    return this.map(function (item) {
      return item.toDTO();
    });
  }
});
