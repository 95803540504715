var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',{staticClass:"role",class:{'role-error':_vm.errors.length}},[_c('div',{staticClass:"row flex-sm-nowrap"},[(_vm.label)?_c('div',{staticClass:"col-12 col-sm-6 role-multiselect",class:{'multiselect-error':_vm.errors.length}},[(_vm.selectOptions)?_c('multiselect',{ref:"selector",class:{'disabled':!_vm.canSelectType},attrs:{"options":_vm.selectOptions,"searchable":true,"show-labels":false,"label":"typeLabel","track-by":"type","disabled":!_vm.canSelectType,"allow-empty":false},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"col-12 col-sm-6 role-input",class:_vm.rowInputClass},[_c('inputBtn',{attrs:{"error":_vm.errors.length ? _vm.errors : null,"placeholder":_vm.placeholder,"value":_vm.name,"disabled":_vm.disabled,"textBtn":"Add","autoCompleteData":_vm.filterParticipator},on:{"click":function($event){return _vm.onAdd($event)}}})],1)]),(_vm.errors.length)?_c('div',{staticClass:"role-errors"},_vm._l((_vm.errors),function(error){return _c('p',[_vm._v("\n            "+_vm._s(error)+"\n        ")])}),0):_vm._e(),(_vm.listLength)?_c('div',{staticClass:"role-table"},[_vm._l((_vm.list),function(item){return [_c('div',{staticClass:"row",class:{
                'warning': _vm.hasWarning(item),
                'error': _vm.hasError(item),
            }},[_c('div',{staticClass:"col-8 col-sm-1 d-flex"},[_c('button',{staticClass:"mobile-tools-button ficonblack icon-dots",attrs:{"id":"toggleBtn"},on:{"click":function($event){return _vm.toggleDetails(item)}}}),_c('vue-tooltipster',{ref:"dsptooltip",refInFor:true,staticClass:"tooltipster--responsive-left",attrs:{"slot":"tooltip","tooltipsterOptions":{contentAsHTML:true},"label":'To show / hide DSP identificators click \'3 horizontal dots\' button'},slot:"tooltip"})],1),_c('div',{staticClass:"col-4 col-sm-5 d-flex"},[_c('p',[_vm._v(_vm._s(item.attributes.typeLabel))])]),_c('div',{staticClass:"col-8 col-sm-6 d-flex"},[_c('p',[_vm._v(_vm._s(item.attributes.name))]),_c('i',{staticClass:"ficon icon-subtract",on:{"click":function($event){return _vm.onRemove(item)}}})])]),(_vm.isEligibleToAttachDSPIdentifier(item) && _vm.areDetailsShown(item))?_c('div',{staticClass:"id-table"},[(item.attributes.usedInSubmittedProjects)?_c('div',{staticClass:"row info-box"},[_vm._v("\n                    Uh-oh! Can’t tweak artist info once it’s in. Need a change? "),_c('a',{attrs:{"href":"mailto:support@octiive.com"}},[_vm._v("Contact support")])]):_c('div',{staticClass:"row info-box"},[_vm._v("\n                    Does this artist already have a profile on streaming platforms? Input the identifiers as shown. Is this a new Artist? Simply leave the fields empty. Don't know how to find the artist identifier? Click on the information (\""),_c('strong',[_vm._v("i")]),_vm._v("\") marks.\n                ")]),_vm._l((_vm.getAllPlatformArtists(item)),function(platformArtist){return _c('div',{staticClass:"row participator-list-row"},[_c('div',{staticClass:"col-4 col-sm-6 d-flex"},[_c('vue-tooltipster',{staticClass:"tooltipster--responsive-left",attrs:{"slot":"tooltip","tooltipsterOptions":{contentAsHTML:true},"label":'Does the artist has a profile on <strong>'
                                            + platformArtist.get('platform') + '</strong>?<br>'
                                            + 'If yes, please add it here.<br>'
                                            + 'Don\'t you know how to find '
                                            + platformArtist.get('platform') + ' identifier? Click on this information mark'},slot:"tooltip"},[_c('a',{attrs:{"target":"_blank","href":_vm.getHelpLink(platformArtist.get('platform'))}},[_c('i',{staticClass:"ficon icon-info tooltipstered",staticStyle:{"margin-top":"10px"}})])]),_c('p',[_vm._v(_vm._s(platformArtist.get('platform')))])],1),(item.attributes.usedInSubmittedProjects)?_c('div',{staticClass:"col-8 col-sm-6 d-flex"},[_c('div',{staticClass:"inputTT",staticStyle:{"padding-top":"10px","color":"gray","font-style":"italic"}},[_vm._v("\n                            "+_vm._s(platformArtist.getPlatformId() ? platformArtist.getPlatformId() : 'Marked as new artist')+"\n                        ")])]):_c('div',{staticClass:"col-8 col-sm-6 d-flex"},[_c('div',{staticClass:"inputTT"},[_c('div',{staticClass:"inputTT-bottom",on:{"dblclick":function($event){return _vm.unlockPlatformIdentifier($event)}}},[_c('input',{staticClass:"inputTT-input",attrs:{"type":"text","placeholder":platformArtist.get('platform') + ' Identifier'},domProps:{"value":platformArtist.getPlatformId()},on:{"change":function($event){return _vm.savePlatformArtist(item, platformArtist, $event)}}})])])])])})],2):_vm._e()]})],2):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }