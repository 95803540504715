<template>
    <div class="feature">
        <div class="feature-obj">
            <h1 class="feature-h1 main-h1">
                A little more and we will <br> distribute your {{currentProject.getFullAlbumType().text}}!
            </h1>
            <p class="feature-subtext main-sub">
                To do this, add the release date and purchase <br>
                additional services at will.
            </p>
            <div class="feature-vdate">
                <ValidationObserver
                    tag="div" class="feature-row row"
                    ref="observer"
                    @submit.prevent=""
                >
                    <div class="feature-col col-12 col-md-6">
                        <p class="feature-label" :class="{'disabled':currentProject.attributes.expediteToITunes}">
                            <vue-tooltipster
                                slot="tooltip"
                                :tooltipsterOptions="{contentAsHTML:true}"
                                label="We are coordinating a worldwide release for this<br>
                       project by sending your music to <br>
                       600+ retailers in over 140 countries. In order to ensure<br>a smooth process, please plan on a release date<br>of at least 30 days in advance.">
                                <i class="ficon icon-info project-form-info__top"></i>
                            </vue-tooltipster>
                            Enter Release Date*
                        </p>
                        <ValidationProvider
                            name="desiredReleaseDate"
                            v-slot="{errors}"
                            :rules="{required:!currentProject.attributes.expediteToITunes,isDateFormat:''}">
                            <vDatePicker
                                ref="relisDate"
                                @keyDown="onDatekeyDown('desiredReleaseDate', $event)"
                                :customClass="{'error':errors.length}"
                                :isDisabled="currentProject.attributes.expediteToITunes"
                                :allow-empty="true"
                                :string="alternative"
                                :isShowString="!!alternative"
                                :options="{
                                    dateFormat: 'mm/dd/yy',
                                    minDate: '+30d',
                                    autoSize: true,
                                }"
                                v-model="currentProject.attributes.desiredReleaseDate"
                            />
                        </ValidationProvider>
                    </div>
                    <div class="feature-col col-12 col-md-6" v-if="currentProject.attributes.preRelease">
                        <p class="feature-label" :class="{'disabled':currentProject.attributes.expediteToITunes}">
                            <vue-tooltipster
                                slot="tooltip"
                                :tooltipsterOptions="{contentAsHTML:true}"
                                label="Pre Orders are projects that are made available<br>
                       for purchase prior to the release date and then<br>
                       fulfilled when the project is released. When offering<br>
                       a project as a Pre Order, the start date must be<br>at least 2 weeks prior to the release date of the project.
                       <br>NOTE: release date should be 6 weeks out.">
                                <i class="ficon icon-info project-form-info__top"></i>
                            </vue-tooltipster>
                            Pre Order Date
                        </p>
                        <ValidationProvider
                            name="preReleaseDate"
                            v-slot="{errors}"
                            :rules="{required:currentProject.attributes.preRelease && !currentProject.attributes.expediteToITunes,isDateFormat:''}">
                            <vDatePicker
                                ref="preRelisDate"
                                @keyDown="onDatekeyDown('preReleaseDate', $event)"
                                :customClass="{'error':errors.length}"
                                :isDisabled="currentProject.attributes.expediteToITunes"
                                :string="alternative"
                                :isShowString="!!alternative"
                                :options="{dateFormat: 'mm/dd/yy',
                                    minDate: '+30d',
                                    autoSize: true}"
                                @input="onPreReleaseDateChange()"
                                v-model="currentProject.attributes.preReleaseDate"
                            />
                        </ValidationProvider>
                    </div>
                </ValidationObserver>
            </div>
            <hr class="feature-hr">
            <div class="feature-additions" v-if="load">
                <div class="addition-item" v-if="!hasAddon(packages.PRE_RELEASE)">
                    <div class="addition-text">
                        <p>
                            <strong>
                                Pre Order date for
                            </strong>
                            <span>&nbsp;${{ this.getProductPrice(packages.PRE_RELEASE) }}</span>
                        </p>
                    </div>
                    <div class="addition-btn">
                        <button class="btn btn--small ficon icon-plus" :disabled="!load"
                                @click="addAddon(packages.PRE_RELEASE, 'preRelease', $event)">
                            Add
                        </button>
                    </div>
                </div>
                <div class="addition-item" v-if="!hasAddon(packages.EXPEDITE_TO_ITUNES)">
                    <div class="addition-text">
                        <p>
                            <vue-tooltipster
                                slot="tooltip"
                                :tooltipsterOptions="{contentAsHTML:true}"
                                label="We request your project to be released as quickly as possible<br>
                         but generally, expedites take 10-14 days to go live.">
                                <i class="ficon icon-info project-form-info__top"></i>
                            </vue-tooltipster>
                            <strong>
                                To expedite&nbsp;
                            </strong>
                            this release for
                            <span>&nbsp;${{ this.getProductPrice(packages.EXPEDITE_TO_ITUNES) }}</span>
                        </p>
                    </div>
                    <div class="addition-btn">
                        <button class="btn btn--small ficon icon-plus" :disabled="!load"
                                @click="addAddon(packages.EXPEDITE_TO_ITUNES, 'expediteToITunes', $event)">
                            Add
                        </button>
                    </div>
                </div>
                <div class="addition-item">
                    <div class="addition-text">
                        <p>
                            <vue-tooltipster
                                slot="tooltip"
                                :tooltipsterOptions="{contentAsHTML:true}"
                                label="Fans like to understand the background of where an artist is coming from.<br>
                         Got a tale to tell? You now have the opportunity to have a <b>professionally written Bio</b><br>
                         made with expert attention to detail by one of our world-class staff writers.">
                                <i class="ficon icon-info project-form-info__top"></i>
                            </vue-tooltipster>
                            <router-link class="main-link addition-link " to="/services/bio">Custom Artist Bio
                            </router-link>
                            &nbsp;
                            for
                            <span>&nbsp;${{ this.getProductPrice(packages.MONDO_BIO) }}</span>
                        </p>
                    </div>
                    <div class="addition-btn">
                        <button class="btn btn--small ficon icon-plus" :disabled="!load"
                                @click="addAddon(packages.MONDO_BIO, 'mondoBio', $event)">
                            Add
                        </button>
                    </div>
                </div>
                <div class="addition-item" v-if="!hasAddon(packages.MONDO_BLAST)">
                    <div class="addition-text">
                        <p>
                            <vue-tooltipster
                                slot="tooltip"
                                :tooltipsterOptions="{contentAsHTML:true}"
                                label="Press is key. Exposure is crucial.<br>
                         You've got great tunes, you’ve got global distribution,<br>
                         and now you just need to spread the word out on the streets.<br>
                         Our staff writers are ready to make sure the masses know about your project!">
                                <i class="ficon icon-info project-form-info__top"></i>
                            </vue-tooltipster>
                            <router-link class="main-link addition-link " to="/services/prblast">PR Blast</router-link>
                            &nbsp;
                            for
                            <span>&nbsp;${{ this.getProductPrice(packages.MONDO_BLAST) }}</span>
                        </p>
                    </div>
                    <div class="addition-btn">
                        <button class="btn btn--small ficon icon-plus" :disabled="!load"
                                @click="addAddon(packages.MONDO_BLAST, 'mondoBlast', $event)">
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <div class="addition-item" v-if="!hasAddon(packages.NEIGHBORING_RIGHTS)">
              <div class="addition-text">
                <p>
                  Neighboring Rights for ${{ this.getProductPrice(packages.NEIGHBORING_RIGHTS) }}
                </p>
              </div>
              <div class="addition-btn">
                <button class="btn btn--small ficon icon-plus orange" :disabled="!load"
                        @click="addAddon(packages.NEIGHBORING_RIGHTS, 'neighboringRights', $event)">
                  Add
                </button>
              </div>
            </div>
            <div class="addition-item" v-if="!hasAddon(packages.YOUTUBE_CONTENT_ID)">
              <div class="addition-text">
                <p>
                  Youtube Content ID for ${{ this.getProductPrice(packages.YOUTUBE_CONTENT_ID) }}
                </p>
              </div>
              <div class="addition-btn">
                <button class="btn btn--small ficon icon-plus orange" :disabled="!load"
                        @click="addAddon(packages.YOUTUBE_CONTENT_ID, 'youtubeContentID', $event)">
                  Add
                </button>
              </div>
            </div>
        </div>
        <div class="feature-cart">
            <h1 class="feature-cart-h1 main-h1">
                My order
            </h1>

            <cartList
                :remove="true"
                @click="onSubmit($event)"
                @remove="removeFromCart($event)"
                :buttonText="btnCheckoutText"
                :cart="cart"
                :requireTermsAndConditionAgreement="true" />
        </div>
    </div>
</template>

<script>
    import vDatePicker from './v-date-picker';
    import vueTooltipster from '@/components/vue-tooltipster'
    import Products from '@/components/collections/products'
    import cartList from './../../cart/components/cart-item-list'
    import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';

    import {mapGetters} from 'vuex';

    extend('isDateFormat', {
        validate(date) {
            return /(\d{2})+\/+(\d{2})+\/+(\d{4})\b/.test(date)
        },
        message: 'this is no data format'
    });

    export default {
        name: "addons-form",
        props: ['cart', 'currentProject', 'subscription'],
        data() {
            return {
                relisDate: '+30d',
                preRelisDate: '+30d',
                btnCheckoutText: 'Distribute',
                checkoutLink: '/project',
                isDisabled: true,
                products: false,
                load: false,
                addons: [
                    {
                        type: 13,
                        projectField: 'payPerProject'
                    },
                    {
                        type: 9,
                        projectField: 'preRelease'
                    },
                    {
                        type: 8,
                        projectField: 'expediteToITunes'
                    },
                    {
                        type: 12,
                        projectField: 'mondoBio'
                    },
                    {
                        type: 10,
                        projectField: 'mondoBlast'
                    },
                    {
                      type: 14,
                      projectField: 'neighboringRights'
                    },
                    {
                      type: 15,
                      projectField: 'youtubeContentID'
                    },
                ],
                packages: {
                    // packages ids
                    PRE_RELEASE: 16,
                    EXPEDITE_TO_ITUNES: 15,
                    MONDO_BIO: 19,
                    MONDO_BLAST: 10,
                    NEIGHBORING_RIGHTS: 30,
                    YOUTUBE_CONTENT_ID: 31,
                },
            }
        },

      async mounted() {
            let self = this;
            this.addons.forEach(addon => {
                var item = self.cart.getItems().find(
                    (cartItem) => cartItem.isAddonFor(self.currentProject, addon.type)
                );
                self.currentProject.set(addon.projectField, !!item);
            });
            for (let i = 0; i < this.cart.getItems().models.length; i++) {
                let cartItem = this.cart.getItems().models[i];
                // drop other project addons...
                if (cartItem
                    && cartItem.get('productAttributes').project_id
                    && !cartItem.isAddonFor(this.currentProject)
                ) {
                    i -= 1;
                    await cartItem.destroy();
                }
            }

            // fetch product details from the server if they are not already loaded
            await this.initProducts();

            this.checkTotal();

            this.load = true

            await this.promoteNeighboringRightAddon();
      },
        updated() {
            if (!this.currentProject.hasPreRelease()) {
                this.currentProject.set('preReleaseDate', null);
            }
        },
        methods: {
            changeProjectFieldForAddon(cartItem, value) {
                if (!cartItem.getProduct()) {
                    return;
                }
                this.addons.some(addon => {
                    if (cartItem.getProduct().getType() == addon.type) {
                        this.currentProject.attributes[addon.projectField] = value;

                        return true;
                    }
                })
            },

            checkTotal() {
                if (this.cart.getPrice() > 0) {
                    this.btnCheckoutText = 'Checkout';
                    this.checkoutLink = '/cart/checkout';
                } else {
                    this.btnCheckoutText = 'Distribute';
                    this.checkoutLink = '/project';
                }
            },

            async initProducts() {
                if (!this.productList) {
                    await this.$store.dispatch('product-list/load');
                }
                this.products = new Products(this.productList);
            },

            checkExpidateAndPreRelease() {
                let hasPreReleaseDate = this.cart.getItems().find(
                        cartItem => cartItem.isAddonFor(this.currentProject, 9)
                    ),
                    isExpedite = this.cart.getItems().find(
                        cartItem => cartItem.isAddonFor(this.currentProject, 8)
                    );
                return hasPreReleaseDate || isExpedite;
            },
            autoCorrectDate() {
                this.currentProject.autoCorrectDate = true;
                this.currentProject.correctionReleaseDates();

                this.currentProject.autoCorrectDate = false;
                this.onPreReleaseDateChange();
            },

            removeFromCart(cartItem) {
                this.changeProjectFieldForAddon(cartItem, false);
                this.checkTotal();

                let isPreReleaseDate = cartItem.isAddonFor(this.currentProject, 9);
                let isExpedite = cartItem.isAddonFor(this.currentProject, 8);
                if (isPreReleaseDate || isExpedite) {
                    this.autoCorrectDate();
                }
            },
            onDatekeyDown(field, value) {
                this.currentProject.set(field, value);
            },
            onPreReleaseDateChange() {
                if (!this.currentProject.attributes.preReleaseDate) {

                    this.$refs.relisDate.setMinDate('+30d');
                    return false
                }
                var time = new Date(new Date(this.currentProject.attributes.preReleaseDate).getTime() + (1000 * 60 * 60 * 24 * 14));
                this.$refs.relisDate.setMinDate(time);
            },

            hasAddon(id) {
                let cartItem = this.cart.getItemBy({id: id});

                return cartItem && cartItem.isAddonFor(this.currentProject);
            },

            getProductPrice(productId) {
                if(!this.products) {
                  return;
                }

                let product = this.products.findWhere({ id: productId });

                if (! product) {
                    console.log(`Invalid product id given: `, productId);
                    return 0;
                }

                return product.getPrice()
            },

            async addAddon(id, addonName, event) {
                // event.currentTarget.disabled = true;
                let self = this,
                    product = this.products.findWhere({id});

                this.cart.addItemToCart(product, {
                        project_id: this.currentProject.attributes.id,
                    }, null,
                    function (error) {
                        self.$root.popup.showMessage(error);
                        event.currentTarget.disabled = true;
                    }).then(async () => {
                        self.currentProject.attributes[addonName] = true;
                        self.checkTotal();
                        if (self.checkExpidateAndPreRelease()) {
                            self.autoCorrectDate();
                        }
                        // Wait for the cart to update
                        await self.cart.fetch();
                    }
                );
            },

            async onSubmit(checkoutBtn) {
              checkoutBtn.disabled = true;

              try {
                // First, promote missing addons
                await this.promoteMissingNewAddonsOnCheckout();

                // Validate the form
                if (!await this.$refs.observer.validate()) {
                  checkoutBtn.disabled = false;
                  return false;
                }

                // Save the current project
                await this.currentProject.save();

                // Fetch the cart to ensure it's up to date
                await this.cart.fetch();

                this.checkTotal();

                if (Number.parseFloat(this.cart.getPrice()) !== 0) {
                  // Redirect to checkout if cart has items
                  this.$router.push(this.checkoutLink);
                } else {
                  // If cart is empty, proceed to distribute the project
                  try {
                    await this.currentProject.distribute();
                    this.$router.push('/project');
                  } catch (error) {
                    this.$root.popup.showMessage(error);
                    checkoutBtn.disabled = false;
                  }
                }
              } catch (error) {
                this.$root.popup.showMessage(error);
                checkoutBtn.disabled = false;
              }


              return
                var self = this;

                checkoutBtn.disabled = true;
                if (!await this.$refs.observer.validate()) {
                    checkoutBtn.disabled = false;
                    return false;
                }

                await self.cart.fetch();

                this.currentProject.save().done(() => {
                  console.log(self.cart.getPrice(), self.cart.isEmpty())
                    if (Number.parseFloat(self.cart.getPrice()) !== 0) {
                        self.$router.push(self.checkoutLink);
                    } else {
                        self.currentProject.distribute().done(() => {
                            self.$router.push('/project');
                        }).fail((jqXHR) => {
                            self.$root.popup.showMessage(jqXHR);
                            checkoutBtn.disabled = false;
                        })
                    }
                }).catch((jqXHR, error) => {
                    if (typeof errors === 'object') {
                       self.$root.popup.showMessage(error);
                    } else {
                        self.$root.popup.showMessage(jqXHR);
                    }
                    checkoutBtn.disabled = false;
                });
            },

          async promoteNeighboringRightAddon() {
            // Return immediately if the addon is already present
            if (this.hasAddon(this.packages.NEIGHBORING_RIGHTS)) {
              return Promise.resolve();
            }

            // Delayed promotion for the Neighboring Rights addon
            await this.delay(async () => {
              const message = `
                <h3 class="prompt-heading">🌟 Neighboring Rights 💸</h3>
                Every year, $2.5 billion in royalties go unclaimed.
                Add it for just $${this.getProductPrice(this.packages.NEIGHBORING_RIGHTS)} and let
                Octiive scoop up your share!
            `;

              const response = await this.popupConfirmation(message, 'Add Now', 'Cancel');
              if (response.confirmed) {
                await this.addAddon(this.packages.NEIGHBORING_RIGHTS, 'neighboringRights');
              }
            }, 5000);
          },

          async promoteMissingNewAddonsOnCheckout() {
            let missingAddons = [];

            // Collect missing addons dynamically
            if (!this.hasAddon(this.packages.NEIGHBORING_RIGHTS)) {
              missingAddons.push({
                id: this.packages.NEIGHBORING_RIGHTS,
                addonName: 'neighboringRights',
                message: `<h3 class="prompt-heading">✨Still Thinking About Neighboring Rights? 💼</h3>
            We get it, pop-ups are annoying—but missing out on your share of $2 billion+
            in unclaimed royalties? That’s way worse. Add it now for just
            $${this.getProductPrice(this.packages.NEIGHBORING_RIGHTS)} and let Octiive do the collecting for you!`,
                confirmText: 'Add Now',
              });
            }

            if (!this.hasAddon(this.packages.YOUTUBE_CONTENT_ID)) {
              missingAddons.push({
                id: this.packages.YOUTUBE_CONTENT_ID,
                addonName: 'youtubeContentID',
                message: `<h3 class="prompt-heading">Alright, last nudge—promise! 🙃</h3>
            <h3 class="prompt-heading">🎥 Capture Your YouTube Earnings! 💰</h3>
            Your music deserves to get paid every time it plays on YouTube.
            Don’t let someone else cash in! Add Content ID for just $${this.getProductPrice(this.packages.YOUTUBE_CONTENT_ID)}
            and start collecting what’s yours today.`,
                confirmText: 'Add Now',
              });
            }

            // Handle case for both missing addons
            if (missingAddons.length === 2) {
              const combinedMessage = `
        <h3 class="prompt-heading">Last chance to secure your earnings! 💸</h3>
        <div class="missing-addons">
            <div>
                🔑 Neighboring Rights: These can make up 20-25% of your total royalties—don’t let them slip away for just
                $${this.getProductPrice(this.packages.NEIGHBORING_RIGHTS)}
                — <a href="#" class="addon-link" data-addon-id="${this.packages.NEIGHBORING_RIGHTS}" data-addon-name="neighboringRights">Add</a>
            </div>
            <div>
                🎥 YouTube Content ID: With 2 billion monthly users, every play could mean royalties.
                Add it now for only $${this.getProductPrice(this.packages.YOUTUBE_CONTENT_ID)}
                — <a href="#" class="addon-link" data-addon-id="${this.packages.YOUTUBE_CONTENT_ID}" data-addon-name="youtubeContentID">Add</a>
            </div>
        </div>`;

              // Dynamically register event listeners for add-on links
              this.$root.popup.$on('rendered', () => {
                const addonLinks = document.querySelectorAll('.addon-link');
                addonLinks.forEach(link => {
                  link.addEventListener('click', async (e) => {
                    e.preventDefault();
                    missingAddons = [];

                    const addonId = Number(link.getAttribute('data-addon-id'));
                    const addonName = String(link.getAttribute('data-addon-name'));

                    if (addonId && addonName) {
                      await this.addAddon(addonId, addonName);
                      this.$root.popup.close();

                      if (this.$root.popup._resolvePromise) {
                        this.$root.popup._resolvePromise({ confirmed: true });
                        delete this.$root.popup._resolvePromise;
                        delete this.$root.popup._rejectPromise;
                      }
                    }
                  });
                });
              });

              const response = await this.popupConfirmation(combinedMessage, 'Add Both', 'Cancel');
              if (response.confirmed) {
                for (const addon of missingAddons) {
                  await this.addAddon(addon.id, addon.addonName);
                }
              }
            } else {
              // Handle individual missing addons
              for (const addon of missingAddons) {
                const response = await this.popupConfirmation(addon.message, addon.confirmText, 'Cancel');
                if (response.confirmed) {
                  await this.addAddon(addon.id, addon.addonName);
                }
              }
            }
          },

          popupConfirmation($message, $confirmText = 'Add', $cancelText = 'Cancel') {
            const popup = this.$root.popup;

            return new Promise((resolve, reject) => {
              popup.showMessage($message);
              popup.setAddText($confirmText);
              popup.setCancleText($cancelText);

              // Store resolve and reject so they can be accessed later
              popup._resolvePromise = resolve;
              popup._rejectPromise = reject;

              // Assign handlers to resolve or reject the promise
              popup.onConfirm = () => {
                resolve({ confirmed: true }); // Returns a response indicating confirmation
              };

              popup.onCancel = () => {
                resolve({ confirmed: false }); // Returns a response indicating cancellation
              };
            });
          },

          delay(callback, delayInMilliseconds = 0) {
            return setTimeout(callback, delayInMilliseconds);
          },
        },
        computed: {
            alternative() {
                if (this.currentProject.attributes.expediteToITunes) {
                    /*setDates*/
                    this.currentProject.autoCorrectDate = true;
                    this.currentProject.correctionReleaseDates();
                    this.currentProject.autoCorrectDate = false;
                }
                return this.currentProject.attributes.expediteToITunes ? 'As soon as possible' : ''
            },
            ...mapGetters({
                productList: 'product-list/get'
            })
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            vDatePicker,
            vueTooltipster,
            cartList
        },
    }
</script>

<style lang="scss">
    .feature {
        display: flex;
        &-h1 {
            margin: 0;
        }
        &-obj {
            @include obj;
            @include coner;
            box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
            min-height: torem(752px);
            flex: 1 1 torem(874px);
            padding: torem(52px) torem(92px);
            padding-bottom: torem(71px);
            &--row {
                display: flex;
            }
        }
        &-cart {
            @include obj;
            min-height: torem(752px);
            box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
            margin-left: torem(22px);
            flex: 1 1 torem(742px);
            padding: torem(52px) torem(59px);
            padding-bottom: torem(71px);
            display: flex;
            flex-direction: column;
            &-h1 {
                margin: 0;
            }
            .feature-hr {
                margin: 0;
                margin-bottom: torem(44px);
            }
        }
        &-subtext {
            text-align: left;
            margin-top: torem(16px);
        }
        &-vdate {
            margin: torem(52px) 0;
        }
        &-label {
            font-weight: $fw-semibold;
            font-size: torem(22px);
            line-height: torem(27px);
            display: flex;
            align-items: center;
            color: $mainfont;
            margin-bottom: torem(10px);
            &.disabled {
                &, .tooltipster .ficon {
                    color: $color-ac;
                }
            }
        }
        &-col {
            padding: 0 torem(14px);
        }
        &-row {
            margin-left: torem(-14px);
            margin-right: torem(-14px);
        }
        &-hr {
            opacity: .8;
            border-top: torem(2px) solid $subline-color;
        }
        &-btn {
            font-size: torem(29px);
            line-height: torem(29px);
            width: 100%;
            text-align: center;
            margin-top: auto;
            height: torem(59px);
            .btn {
                &:before {
                    display: none;
                }
            }
        }
        &-total {
            display: flex;
            &-text {
                flex: 1 1 auto;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-weight: $fw-extrabold;
                font-size: torem(32px);
                line-height: torem(39px);
                display: flex;
                align-items: center;
                color: $mainfont;
            }
            &-price {
                flex: 0 0 auto;
                text-align: right;
                font-weight: $fw-semibold;
                font-size: torem(28px);
                line-height: torem(34px);
                display: flex;
                align-items: center;
                text-align: right;
                color: $mainfont;
                padding-right: torem(36px);
            }
        }
        .vdate {
            margin-left: torem(25px);
        }
        .addition {
            &-link {
                display: inline-block;
                font-weight: $fw-semibold;
            }
            &-item {
                display: flex;
                margin: torem(32px) 0;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &-text {
                flex: 1 1 auto;
                display: flex;
                p {
                    font-size: torem(22px);
                    line-height: torem(27px);
                    font-weight: $fw-semibold;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    strong {
                        font-weight: $fw-bold !important;
                    }
                }
            }
            &-btn {
                margin-left: auto;
                flex: 0 0 torem(120px);
                background-color: transparent;
                .btn {
                    font-size: torem(18px);
                    line-height: torem(22px);
                    &.ficon:before {
                        display: none;
                    }
                }
            }
        }
        .fcart {
            padding-top: torem(10px);
            padding-bottom: torem(24px);
            &-item {
                display: flex;
                width: 100%;
                margin: torem(28px) 0;
                margin-bottom: torem(38px);
                align-items: flex-end;
            }
            &-name, &-price {
                font-weight: $fw-extrabold;
                font-size: torem(22px);
                line-height: torem(27px);
                display: flex;
                align-items: center;
                &.blue {
                    color: $blue-18;
                }
            }
            &-name {
                flex: 1 1 auto;
                max-width: 50%;
            }
            &-price {
                min-width: torem(100px);
                flex: 0 0 torem(100px);
                text-align: right;
                margin-left: auto;
                display: block;
                &--free {
                    margin-right: torem(36px);
                }
            }
            &-remove {
                flex: 0 0 torem(18px);
                font-size: torem(18px);
                padding: 0;
                border: none;
                box-shadow: none;
                margin: 0 0 0 torem(18px);
                color: $red-f3;
                background-color: transparent;
                outline: none;
                &:before {
                    margin: 0;
                }
                &:disabled {
                    opacity: .3;
                }
            }
            &-h1 {
                margin: 0;
            }
            &-addons {
                &-item {
                    display: flex;
                    font-weight: $fw-semibold;
                    font-size: torem(22px);
                    line-height: torem(27px);
                    color: $mainfont;
                    margin: torem(28px) 0;
                    align-items: flex-end;
                }
                &-text {
                    flex: 1 1 auto;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                &-price {
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .feature {
            &-obj, &-cart {
                padding: torem(20px);
                flex: 1 1 50%;
                max-width: 50%;
                min-height: auto;
            }
            &-obj {
                min-width: torem(322px);
            }
            &-h1, &-cart-h1 {
                font-size: torem(20px);
                line-height: torem(24px);
            }
            &-subtext {
                font-size: torem(12px);
                line-height: torem(18px);
            }
            &-label {
                font-size: torem(18px);
                margin-bottom: torem(6px);
                .tooltipster {
                    order: 1;
                    margin-left: torem(10px);
                }
            }
            .vdate {
                margin-left: 0;
                margin-bottom: torem(18px);
            }
            &-vdate {
                margin: torem(15px) 0;
                margin-bottom: torem(4px);
            }
        }
        .feature .addition {
            &-btn {
                margin-left: auto;
                flex: 0 0 auto;
                .btn.ficon {
                    font-size: 0;
                    width: torem(31px);
                    height: torem(31px);
                    min-width: torem(1px);
                    line-height: 1;
                    &:before {
                        display: block;
                        font-size: torem(15px);
                    }
                }
            }
            &-text {
                p {
                    font-size: 0;
                    strong, span, a {
                        font-size: torem(16px);
                        font-weight: $fw-600;
                    }
                    .tooltipster {
                        display: none;
                    }
                }
            }
        }
    }
    a.distribute-close.edit-project-close.ficon.icon-subtract {
        top: torem(50px);
    }

    .orange {
      background-color: #FF6337;
      transition: all .3s;
      border: 0;
    }

    .orange:hover {
      background-color: #000;
      color: #FF6337;
    }

    .prompt-heading {
      font-weight: bold;
    }

    .missing-addons {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      div {
        flex: 1;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .btn {
        display: inline-block;
      }
    }

    /*media-breakpoint-down*/
    @include media-breakpoint-down(xs) {
        a.distribute-close.edit-project-close.ficon.icon-subtract {
            margin-bottom: torem(10px);
        }
        .feature {
            flex-wrap: wrap;
            &-h1 {
                justify-content: left;
            }
            &-subtext {
                margin-top: torem(10px);
            }
            &-obj, &-cart {
                width: 100%;
                max-width: 100%;
            }
            &-cart {
                margin: 0;
                width: 100%;
                max-width: 100%;
                margin-top: torem(20px);
                border-radius: torem(4px);
                .cartList-item {
                    padding: 0;
                    .price {
                        padding-right: torem(10px);
                    }
                    .remove {
                        padding: 0;
                        padding-left: torem(3px);
                        height: torem(21px);
                        width: torem(26px);
                        margin-right: torem(8px);
                    }
                }
                .cartList-promo, .cartList-total {
                    padding: 0;
                }
            }
            &-obj {
                width: 100%;
                max-width: 100%;
            }
        }
    }

</style>
