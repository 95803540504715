<template>
    <div class="c-tooltip" :class="{
    'c-tooltip-left': value.xAlign !== 'right',
    'c-tooltip-right':value.xAlign === 'right'
    }" v-if="value.body" :style="{
    left: getLeftPosition()+'px',
    top:value.y+'px',
    }">
        <div class="c-tooltip-header">
            {{value.title[0]}}
        </div>
        <p v-for="bodyItem in value.body">
            <i class="cercle"
               :style="{backgroundColor:getColorByName(bodyItem.lines[0].split(':')[0].replace(' sub-30s','_30'))}">
            </i>
            <b>{{bodyItem.lines[0].split(':')[0]}}</b>
            :
            <span :style="{color:getColorByName(bodyItem.lines[0].split(':')[0].replace(' sub-30s','_30'))}">
                {{bodyItem.lines[0].split(':')[1]}}
            </span>
        </p>
    </div>
</template>

<script>
    import {color} from '../mixins/color'

    export default {
        name: "v-tooltip",
        props: ['value', 'mouse', 'colorMap'],
        mixins: [color],
        methods: {
            getLeftPosition() {
                if (!this.$el) {
                    return false
                }
                let pos = this.mouse.x,
                    width = this.$el.offsetWidth;
                if (this.value.xAlign === 'right') {
                    pos = pos - width;
                }
                /* else{
                     pos = pos + width;
                 }*/
                return pos;
            }
        }
    }
</script>

<style lang="scss">
    .c-tooltip {
        
        position: absolute;
        transition: left .3s, top .3s;
        z-index: 2;
        max-height: 60vh;
        overflow: auto;
        @include obj;
        @include coner;
        box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
        padding: torem(22px) torem(22px) torem(22px) torem(58px);
        &-header{
            font-size: torem(20px);
            font-weight: $fw-bold;
            border-bottom: torem(1px) solid $grey-ba;
            margin-bottom: torem(10px);
            margin-left: torem(-60px);
            margin-right: torem(-22px);
            padding: torem(10px) 0 torem(10px) torem(60px);
        }
        p {
            font-size: torem(17px);
            color: $mainfont;
            span{
                font-weight: $fw-bold;
            }
        }
        margin-left: torem(-3px);
        &-left {
            margin-left: 1rem;
        }
        &-right {
            margin-left: -1rem;
        }
        .cercle {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            margin-right: torem(10px);
        }
    }
</style>