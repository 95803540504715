/*eslint-disable */

/**/
import Vue from 'vue';
import store from './Store/store';
import axios from 'axios';
import "./sass/style.scss";


/*
* import VueRouter*/
import VueRouter from 'vue-router';
import {routes} from './Views/routes/router';
import VueBackbone from 'vue-backbone'
import VueIntro from 'vue-introjs';

Vue.use(VueRouter);
Vue.use(VueBackbone);
Vue.use(VueIntro);
/*
* import VueRouter end*/
/*
* import pages*/
import dashboardPage from './Views/dashboard_page';
import signformPage from './Views/signform_page';
import emailConfirmationPage from './Views/email-confirmation-page'
/*
* import pages end*/


Vue.config.productionTip = false;

Vue.component('dashboardPage', dashboardPage);
Vue.component('signformPage', signformPage);
Vue.component('emailConfirmationPage', emailConfirmationPage);
import {mapGetters} from 'vuex';

document.addEventListener('DOMContentLoaded', function () {
    if (!document.getElementById('vue-app')) {
        console.warn('Vue root element not found on this page');
        return;
    }

    var router = new VueRouter({
        routes: routes,
        mode: 'history'
    });

    const vueApp = new Vue({
        el: '#vue-app',
        store,
        router,
        data: {
            user: false,
            popup: false,
            isIntro: false,
            orientation: null,
        },
        create() {
        },
        beforeMount() {
            this.orientation = window.orientation;
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        mounted: function () {
            /*Redirects*/
            if (/^\/dashboard$/.test(this.$route.path) || this.$route.path == '/') {
                this.$router.push('/home');
            }
        },
        methods: {
            onResize() {
                document.body.style.overflow = 'hidden'
                let
                    width = Math.max(
                        document.body.scrollWidth, document.documentElement.scrollWidth,
                        document.body.offsetWidth, document.documentElement.offsetWidth,
                        document.body.clientWidth, document.documentElement.clientWidth
                    ),
                    fontSize = 0;

                this.$store.commit("resolution/set", width);
                this.$store.commit("resolution/setInnerSize", {
                    width: document.documentElement.clientWidth,
                    height: document.documentElement.clientHeight
                });
                let html = document.querySelector('html');
                fontSize = (this.deviceState.fontSize || 14) * (width * 100 / this.deviceState.size) / 100;
                html.style.fontSize = fontSize + 'px';
                this.$store.commit("resolution/setFontSize", this.deviceState.fontSize);
                document.body.style.overflow = '';
            }
        },
        computed: {
            ...mapGetters({
                /*orientation: 'resolution/getOrientation',*/
                fontSize: 'resolution/getFontSize',
                deviceState: 'resolution/getCurrentState'
            }),
        },
    });


});
