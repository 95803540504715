<template>
    <div class="row" v-if="selectedRange">
        <div class="col-12 col-sm-6 col-md-3">
            <v-date-picker
                :isDisabled="selectedRange.val!==5 || disabled"
                v-model="min"
                :string="min"
                @input="updateDate()"
                :options="{
                         dateFormat: 'mm/dd/yy',
                         maxDate: max,
                         autoSize: true}"
            />
        </div>
        <div class="col-12 col-sm-6 col-md-3 reports-xs-margin">
            <v-date-picker
                :isDisabled="selectedRange.val!==5||disabled"
                @input="updateDate()"
                v-model="max"
                :string="max"
                :options="{
                         dateFormat: 'mm/dd/yy',
                         minDate:min,
                         maxDate:nowDate,
                         autoSize: true}"
            />
        </div>
        <div class="col-12 col-sm-12 col-md-3 reports-sm-margin">
            <v-multiselect
                :disabled="disabled"
                :allow-empty="false"
                :options="dateRangeOptions"
                :showLabels="false"
                @input="onDateRangeChange($event)"
                v-model="selectedRange"
                track-by="name"
                label="name"
            ></v-multiselect>
        </div>
    </div>
</template>

<script>
    import vDatePicker from '@/Views/projects/components/v-date-picker.vue';
    import vMultiselect from 'vue-multiselect';

    export default {
        name: "v-analytic-date-filter",
        props: ['minDate', 'maxDate','disabled'],
        data() {
            return {
                max: null,
                min: null,
                dateRangeOptions: [
                    {name: 'Last week', val: 1},
                    {name: 'Last month', val: 2},
                    {name: 'Trimester', val: 3},
                    {name: 'Year', val: 4},
                    {name: 'Custom', val: 5},
                ],
                selectedRange: null,
                nowDate:null,
            }
        },
        mounted() {
            this.max = this.maxDate;
            this.min = this.minDate;
            this.nowDate = this.formateDate(new Date());
            this.selectedRange = this.dateRangeOptions[0];
            this.onDateRangeChange(this.selectedRange);
        },
        methods: {
            onDateRangeChange(selectedRange) {
                this.selectedRange = selectedRange;
                switch (selectedRange.val) {
                    case 1:

                        this.setDateRangeLastWeek();
                        break;
                    case 2:

                        this.setDateRangeLastMonth();
                        break;
                    case 3:

                        this.setRangeTrimester();
                        break;
                    case 4:
                        this.setRangeYear();
                        break;

                }
            },
            setDateRangeLastWeek() {
                let nowDate = new Date(),
                    maxDate = null,
                    minDate = null;
                maxDate = new Date();
                nowDate.setDate(nowDate.getDate() - 7);
                minDate = nowDate;

                this.updateDate({
                    maxDate: this.formateDate(maxDate),
                    minDate: this.formateDate(minDate)
                });
            },
            setDateRangeLastMonth() {
                let nowDate = new Date(),
                    maxDate = null,
                    minDate = null;
                maxDate = new Date();
                nowDate.setMonth(nowDate.getMonth() - 1);
                minDate = nowDate;

                this.updateDate({
                    maxDate: this.formateDate(maxDate),
                    minDate: this.formateDate(minDate)
                });
            },
            setRangeTrimester() {
                let nowDate = new Date(),
                    maxDate = null,
                    minDate = null;
                maxDate = new Date();
                nowDate.setMonth(nowDate.getMonth() - 3);
                minDate = nowDate;

                this.updateDate({
                    maxDate: this.formateDate(maxDate),
                    minDate: this.formateDate(minDate)
                });
            },
            setRangeYear() {
                let nowDate = new Date(),
                    maxDate = null,
                    minDate = null;
                maxDate = new Date();
                nowDate.setFullYear(nowDate.getFullYear() - 1);
                minDate = nowDate;

                this.updateDate({
                    maxDate: this.formateDate(maxDate),
                    minDate: this.formateDate(minDate)
                });
            },
            updateDate(date) {
                let newdate = date || {
                    minDate: this.min,
                    maxDate: this.max,
                };
                this.min = newdate.minDate;
                this.max = newdate.maxDate;
                this.$emit('input', newdate)
            },
            formateDate(date) {
                let mm = date.getMonth() + 1,
                    dd = date.getDate(),
                    yy = date.getFullYear();
                return ("0" + mm).slice(-2) + '/' + ("0" + dd).slice(-2) + '/' + yy;
            },
        },
        components: {
            vDatePicker,
            vMultiselect
        }
    }
</script>

<style>

</style>