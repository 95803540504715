<template>
    <div class="reportTable" :class="{'reportTable--noready':(!models || models && !models.length)}">
        <reportTable>
            <div slot="head" class="report-row report-head">
                <div class="report-col"><p> Date</p></div>
                <div class="report-col"><p><span class="d-none d-md-inline">Current</span> Amount</p></div>
                <div class="report-col"> <p>Paid Via</p></div>
                <div class="report-col jc-center"> <p> Status </p></div>
            </div>
            <div slot="cont" v-if="models.length > 0" v-for="item in models" class="report-row">
                <div class="report-col" :class="{'report-empty':!item.attributes.createdAt}">
                    <p>{{(isMobile? item.attributes.createdAt.replace(/-/g,'.').replace(/([0-9]+\.[0-9]+\.)/g, '$1\n'): item.attributes.createdAt) ||'empty'}}</p>
                </div>
                <div class="report-col" :class="{'report-empty':!item.attributes.amount}">
                    <p>${{item.attributes.amount ||'0.00'}}</p>
                </div>
                <div class="report-col" :class="{'report-empty':!item.getTransactionTypeFormatted()}">
                    <p>{{item.getTransactionTypeFormatted() || 'empty'}}</p>
                </div>
                <div class="report-col jc-center">
                    <p class="report-status" :class="item.getStateClass()">
                        {{item.getStateFormatted()}}
                    </p>
                </div>
            </div>
        </reportTable>
        <random-loader v-if="models == null" slot="cont" class="loader--poss"
                       :substrate="false" text="Your money request list will be shown here" />
        <emptyState v-if="models != null && models.length == 0"
                    h1text="Your money request list is empty."
        />
    </div>

</template>

<script>
    import reportTable from './report-table';
    import emptyState from '../../../components/emptyState';
    import MoneyRequest from '@/components/collections/user/money-requests';
    import randomLoader from '@/components/random-loader';
    import {mapGetters} from 'vuex';
    import {isMobile} from '../../../components/mixins/isMobile';

    export default {
        name: "money-requests",
        data() {
            return {
                models: null,
            }
        },
        mixins:[isMobile],
        async mounted() {
            let self = this;
            let moneyRequest = new MoneyRequest();
            moneyRequest.fetch({
                success() {
                    self.models = moneyRequest.models;

                },
                error(collection, error) {
                    self.$root.popup.showMessage(error);
                }
            });
        },
        computed:{
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
            })
        },
        components: {
            reportTable,
            emptyState,
            randomLoader,
        }
    }
</script>

<style lang="scss">
    .reportTable {
        
        .empty {
            position: static;
            height: 100%;
            min-height: torem(300px);
        }
    }
    
    @include media-breakpoint-down(xs) {
        .reportTable .empty {
            z-index: 1;
        }
        
    }
</style>