import Backbone from 'backbone';
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
    idAttribute: "languageId",
    defaults: {
        languageId: null,
        displayName: null
    },
    url: '/api/languages',
    getLanguageId: function () {
        return this.get('languageId');
    },
    getDisplayName: function () {
        return this.get('displayName');
    }
});
