import Backbone from 'backbone'

export default Backbone.Collection.extend({
    /**
     * Workaround:
     * We should avoid using default "Backbone.Model"
     */
    model: null,
    url: '/api/genres',
    initialize: function (models, options) {
        if (!options.model && !this.model) {
            /**
             * Workaround:
             * We get circular dependency when specify "GenreModel" as dependency for collection.
             * You should specify "options.model" for constructing GenreCollection.
             * Otherwise, we get "GenreModel" direct from "require()".
             */
            this.model = require('../models/genre');
        }

        if (options && options.url) {
            this.url = options.url;
        }
    }
});

