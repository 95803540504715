import ProductModel from '@/components/models/user/product';
import ProductCollection from '@/components/collections/user/products';
import Backbone from "backbone";
import 'backbone-relational/backbone-relational';

let list = new ProductCollection();

export default {
    state: {
        loadingPromise: false,
        loaded: false,
    },
    getters: {
        getProductList(state) {
            if (!state.loaded) {
                return null;
            }
            
            return list;
        },
    },
    mutations: {
        setLoaded(state, data) {
            state.loaded = data;
            state.loadingPromise = false;
        },
        startLoading(state, promise) {
            state.loadingPromise = promise;
        },
    },
    actions: {
        async loadProductList(store) {
            if (store.state.loaded) {
                return store.getters.getProductList;
            }
            if (store.state.loadingPromise) {
                return store.state.loadingPromise;
            }

            list = new ProductCollection();
            let loadingPromise = list.fetch().then(function (e) {
                store.commit("setLoaded", true);

                return store.getters.getProductList;
            }).catch(error => {
                return {error: error.response};
            });

            store.commit("startLoading", loadingPromise);

            return loadingPromise;
        }
    }
}

