import axios from 'axios';

export default {
    state: {
        title: false,
    },
    mutations: {
        set(state, data) {
            state.title = data;
        },
    },
    getters: {
        get: function (state) {
            return state.title;
        },
    },
};