<template>
    <button class="user"
            :class="{'ficon icon-farrow':dropmenu,'active':active}"
            v-if="userinfo"
            @click="onUserButtonClick($event)">
        <div class="user-poster" v-if="!label">
        </div>
        <div class="user-label ficon icon-disk" v-else></div>
        <p class="user-name">
            <template v-if="userinfo.getLabel() && userinfo.getLabel().getName()">
                {{ userinfo.getLabel().getName() }}
            </template>
            <template v-else>
                {{ userinfo.get('firstName') }} {{ userinfo.get('lastName') }}
            </template>
        </p>
        <ul class="user-ddl">
            <li class="user-ddl--closeBtn d-sm-none" @click="onLinkClick ">
            </li>
            <li v-for="item in dropmenu" @click.stop="onLinkClick($event)">
                <router-link class="user-ddl-link" v-if="item.vrout" :to="item.link">
                    {{item.text}}
                </router-link>
                <a class="user-ddl-link" :href="item.link" v-else> {{item.text}} </a>
            </li>
        </ul>
    </button>
    <button class="user" v-else disabled>
        <div class="user-poster">
        </div>
        <p class="user-name preload"></p>
    </button>

</template>

<script>

    export default {
        data() {
            return {
                listHeihgt: 0,
                active: false,
                label: false,
                logo: false,
                event: null,
            }
        },
        props: ['userinfo', 'dropmenu'],
        mounted() {
            if (!this.userinfo || !this.userinfo.get('label')) {
                return;
            }
            this.label = this.userinfo.get('label');
            this.logo = this.label.get('logo');
        },
        methods: {
            onUserButtonClick(event) {
                document.addEventListener('click', this.onOuterClick);
                this.active = true;
            },
            onLinkClick(event) {
                this.active = false;
            },
            onOuterClick(event) {
                if (!this.$el.contains(event.target) || event.target.classList.contains('user-ddl--closeBtn')) {
                    this.active = false;
                    document.removeEventListener("click", this.onOuterClick, true);
                }
            }
        }
    }
</script>

<style lang="scss">
    
    .user {
        background: $white-f5f;
        border-radius: torem(4px);
        border: none;
        display: flex;
        align-items: center;
        padding: torem(7px) torem(18px);
        position: relative;
        outline: none !important;
        max-width: torem(220px);
        min-width: torem(180px);
        justify-content: center;
        margin-right: 0;
        &-label {
            &.ficon.icon-disk {
                color: $mainfont;
                display: flex;
                justify-content: center;
                align-items: center;
                background: transparent;
                &:before {
                    margin: 0;
                    font-size: torem(27px);
                }
            }
            
        }
        &-poster {
            width: torem(30px);
            min-width: torem(30px);
            height: torem(30px);
            background: url(../../../assets/img/userPoster.svg) 0 0/cover no-repeat;
            border-radius: 50%;
            overflow: hidden;
            margin-right: torem(10px);
            img {
                width: 100%;
                min-width: 100%;
                height: 100%;
                min-height: 100%;
            }
        }
        &:before {
            order: 1;
            font-size: torem(7px);
            width: auto;
            margin-left: torem(8px) !important;
            font-weight: $fw-bold;
            transform: rotate(-90deg);
            transition: transform .3s;
        }
        &-name {
            
            font-style: normal;
            font-weight: $fw-bold;
            font-size: torem(14px);
            line-height: torem(16px);
            color: $mainfont;
            
            white-space: nowrap;
            max-width: 100%;
            
            text-align: left;
            
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .ficon {
            color: $white;
            background-color: $color-26;
            border-radius: 50%;
            overflow: hidden;
            width: torem(30px);
            min-width: torem(30px);
            height: torem(30px);
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-right: torem(10px);
            margin-left: 0;
            &:before {
                font-size: torem(23px);
            }
        }
        &-ddl {
            position: absolute;
            width: 100%;
            right: 0;
            top: 100%;
            padding: 0 torem(4px);
            margin: 0;
            background: $white-f5f;
            border-radius: torem(4px);
            min-width: torem(170px);
            z-index: -1;
            list-style: none;
            text-align: left;
            overflow: hidden;
            max-height: 0;
            transition: max-height .3s;
            box-shadow: 0 torem(9px) torem(21px) rgba(22, 32, 72, 0.15);
            &-link {
                font-size: torem(16px);
                font-weight: $fw-500;
                line-height: torem(26px);
                display: block;
                padding: torem(5px) torem(15px);
                color: $mainfont;
                background-color: transparent;
                border-radius: torem(4px);
                &:hover {
                    text-decoration: none;
                    color: $white;
                    background-color: $userddllink-hover-BGcolor;
                }
            }
            li {
                &:first-child {
                    margin-top: torem(5px);
                }
                &:last-child {
                    margin-bottom: torem(5px);
                }
            }
        }
        &.active &-ddl {
            max-height: 999px;
            // transition: max-height .3s, padding 0s;
        }
        &.active:before {
            transform: rotate(0deg);
            transition: transform .3s;
        }
    }
    
    @include media-breakpoint-down(sm) {
        .user {
            /*max-width: torem(138px);
            min-width: torem(138px);*/
            &-dll {
                min-width: torem(180px);
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .user {
            padding: 0;
            width: auto;
            min-width: auto;
            background: transparent;
            position: static;
            
            &:before {
                display: none;
            }
            &-ddl {
                position: fixed;
                top: 0;
                bottom: 0;
                left: 100%;
                right: -100%;
                z-index: 100;
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                transition: left .3s, right .3s;
                height: 100%;
                max-height: 100%;
                li:first-child{
                    margin-top: torem(30px);
                }
            }
            &.active &-ddl {
                left: 0;
                right: 0;
            }
            &-name {
                color: $white-f5f;
                font-size: torem(12px);
                line-height: torem(15px);
            }
            &-poster {
                $size: torem(19px);
                width: $size;
                height: $size;
                min-width: torem(1px);
                background: url(../../../assets/img/userPoster-mobile.svg) 0 0/cover no-repeat;
            }
            &-ddl &-ddl--closeBtn {
                @include closeBtn;
                position: absolute;
                top: 0;
                left: 0;
                &:before, &:after {
                    background: $mainfont;
                }
            }
            &-label.ficon.icon-disk:before{
                color:$white-f5f;
            }
        }
    }


</style>