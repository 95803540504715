export default  {
    MAIN_ARTIST: 1,
    FEATURING: 2,
    REMIXER: 3,
    WRITER: 4,
    COMPOSER: 5,
    PUBLISHER: 6,
    LEGACY_MAIN_ARTIST: 7,
    PAY_PER_PROJECT_ARTIST: 8,
    LYRICIST: 9,

    ENGINEER_PRODUCER: 10,
    ENGINEER_CO_PRODUCER: 11,
    ENGINEER_ENGINEER: 12,
    ENGINEER_EXECUTIVE_PRODUCER: 13,
    ENGINEER_MASTERING_ENGINEER: 14,
    ENGINEER_MIXING_ENGINEER: 15,

    PERFORMER_BAND: 16,
    PERFORMER_CHOIR: 17,
    PERFORMER_DJ: 18,
    PERFORMER_MC: 19,
    PERFORMER_ORCHESTRA: 20,

    getKey: function (value) {
        var key;
        var i;

        for (i in this) {
            if (this[i] === value) {
                key = i;
                break;
            }
        }

        return key;
    }
};
