<template>
    <div class="formRow" :class="rowClass">
        <div class="formRow-top" v-if="topClass!==false" :class="topClass">
            <slot name="tooltip"></slot>
            <slot name="title"></slot>
        </div>
        <div class="formRow-cont" :class="contClass">
            <div class="posr">
                <slot name="item"></slot>
            </div>
            <slot name="check"></slot>
        </div>
    </div>

</template>

<script>
    export default {
        props: ['rowClass', 'contClass', 'topClass'],
        name: "row",
    }
</script>
<style lang="scss">
    .formRow {
        display: flex;
        flex-direction: column;
        margin-bottom: torem(26px);
        width: 100%;
        &.min-width {
            min-width: torem(219px);
        }
        &.norow-top &-top {
            margin-bottom: 0;
        }
        &-top {
            position: relative;
            display: flex;
            align-items: center;
            min-height: torem(24px);
            margin-left: torem(-26px);
            .tooltipster {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
            }
        }
        &-cont {
            margin-top: torem(8px);
            &.scrool {
                margin-top: 0;
            }
        }
    }
    
    @include media-breakpoint-down(sm) {
        .formRow {
            margin-bottom: torem(20px);
            &-top {
                margin: 0;
                padding: 0;
                
            }
            &-top {
                margin-left: 0;
                .tooltipster {
                    position: static;
                    order: 1;
                    margin: 0 torem(10px);
                    &--responsive-left {
                        order: -1;
                        margin-left: 0;
                    }
                }
                
            }
        }
    }
</style>
