import Backbone from 'backbone';
import DistributionTrackModel from '../../models/distribution-project/track';
import ParticipatorsCollection from './../participators';

export default Backbone.Collection.extend({
    model: DistributionTrackModel,
    url: function () {
        var project = this.getProject();

        if (project && project.get('id')) {
            return '/api/projects/' + project.get('id') + '/tracks';
        }
        return '/api/tracks';
    },
    comparator: 'position',
    _newMainArtist: null,
    getMainArtists: function () {
        this._newMainArtist = new ParticipatorsCollection();
        var self = this;
        this.forEach(function (track) {
            if (track.hasMainArtist()) {
                self._newMainArtist.add(track.getMainArtist());
            }
        });
        return this._newMainArtist;
    },
    getPaidMainArtists: function () {
        let artists = new ParticipatorsCollection();
        this.forEach(track => {
            if (track.hasMainArtist()) {
                artists.add(track.getPaidMainArtists());
            }
        });
        return artists;
    },
    getProject: function () {
        // where did you get it?
        return this.project;
    },
    countTotalDuration: function () {
        var duration = 0;
        this.forEach(function (track) {
            duration += track.get('duration');
        });
        return duration;
    },
    moveTrack: function (track, position) {
        if (track.get('position') == position) {
            return;
        }

        // need use sync, because api returns full tracks
        // we need only positions
        return Backbone.sync('patch', this, {
            url: this.url() + '/move',
            attrs: {trackId: track.get('id'), position: position}
        }).success(function (tracks) {
            if (!Backbone.$.isArray(tracks)) {
                return;
            }

            // update positions
            for (var i in tracks) {
                var track = this.get(tracks[i].id);

                if (!track) {
                    continue;
                }

                track.set('position', tracks[i].position);
            }
            this.sort();
        }.bind(this));
    },
    reorderTracks: function () {
        // need use sync, because api returns full tracks
        // we need only positions
        return Backbone.sync('patch', this, {
            url: this.url() + '/reorder'
        }).success(function (tracks) {
            if (!Backbone.$.isArray(tracks)) {
                return;
            }

            // update positions
            for (var i in tracks) {
                var track = this.get(tracks[i].id);

                if (!track) {
                    continue;
                }

                track.set('position', tracks[i].position);
            }
            this.sort();
        }.bind(this));
    }

});

