<template>
  <div class="marketingItem">
    <div class="marketingItem-cont" >
      <div class="marketingItem-top">
        <slot name="head"></slot>
      </div>
      <div class="marketingItem-bottom">
        <slot name="content"></slot>
      </div>
    </div>
    <div class="marketingItem-tools d-none d-sm-flex">
      
      <div class="marketingItem-date ">
        <slot name="date"></slot>
      </div>
      <div class="marketingItem-status" :class="status.class">
        {{status.text}}
      </div>
      <router-link :to="showUrl" class="marketingItem-btn marketingItem-view ficon icon-eye"></router-link>
      <router-link :to="editUrl" v-if="isEditable"
                   class="marketingItem-btn marketingItem-edit ficon icon-edit"></router-link>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['status', 'isEditable', 'editUrl', 'showUrl'],
    name: "item-slot"
  }
</script>

<style lang="scss">
  .marketingItem {
    @include obj;
    @include coner;
    box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
    background: $white;
    min-height: torem(198px);
    padding: torem(37px) torem(33px) torem(56px) torem(71px);
    display: flex;
    margin-bottom: torem(40px);
    &-date{
      display: none;
    }
    &-cont {
      flex: 1 1 auto;
      width: 84.4%;
    }
    &-bottom, &-top {
      display: block;
      p {
        flex: 0 1 20%;
        font-size: torem(20px);
        line-height: torem(24px);
        
        width: 20%;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 torem(15px);
        
        color: $mainfont;
      }
    }
    &-top {
      display: flex;
      p {
        font-weight: $fw-extrabold;
        color: $blue-1f;
      }
    }
    &-bottom {
      margin-top: torem(19px);
      display: flex;
      p {
        font-weight: $fw-semibold;
        &.marketingItem-empty {
          color: $color-ac
        }
      }
    }
    &-tools {
      flex: 0 0 torem(238px);
      display: flex;
      align-items: center;
      justify-content: end;
      margin-bottom: auto;
      margin-top: torem(-12px);
    }
    &-btn {
      outline: none !important;
      $size: torem(28px);
      flex: 0 0 $size;
      width: $size;
      height: $size;
      margin: 0 torem(9px);
      background: transparent;
      border: none;
      box-shadow: 0 torem(4px) torem(4px) $editiconshadow;
      border-radius: 50%;
      padding: 0;
      color: $mainfont !important;
      text-decoration: none !important;
      
      &:before {
        margin: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:before {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.icon-eye:before {
        margin-top: torem(2px);
      }
    }
    &-status {
      flex: 0 0 auto;
      width: auto;
      
      margin-right: torem(23px);
      margin-left: auto;
      border: torem(3px) solid $color-94;
      border-radius: torem(30px);
      
      justify-content: center;
      align-items: center;
      display: flex;
      
      line-height: torem(22px);
      color: $mainfont;
      text-transform: capitalize;
      
      padding: 0 torem(20px);
      font-size: torem(16px);
      font-weight: $fw-extrabold;
      height: torem(40px);
     
      &.rouge,&.filled {
        border-color: $color-f4;
      }
      &.grey, &.draft {
        border-color: $color-94;
      }
      &.blue, &.processed {
        border-color: $blue-18;
      }
      &.green {
        border-color: $color-51;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .marketingItem {
      flex-direction: column;
      padding: torem(28px) torem(24px);
      &-date{
        font-weight: $fw-bold;
        font-size: torem(18px);
        line-height: torem(22px);
        display: flex;
        align-items: center;
        color: $blue-18;
      }
      &-tools {
        width: 100%;
        flex: 1 1 100%;
        margin: 0;
        margin-bottom: torem(38px);
      }
      &-cont {
        order: 2;
        flex-direction: row;
        display: flex;
        flex-wrap: nowrap;
        width: auto;
        margin-left: torem(-12px);
        margin-right: torem(-12px);
      }
      &-top, &-bottom {
        flex: 1 1 50%;
        width: 50%;
        display: flex;
        flex-direction: column;
      }
      &-bottom p, &-top p {
        width: 100%;
        flex: 1 1 100%;
        max-width: 100%;
        display: block;
        border-bottom: torem(1px) solid $color-ac;
        padding: torem(12px);
        &:last-child {
          border-bottom: none;
        }
      }
      &-bottom {
        margin: 0;
        p {
          text-align: right;
          font-size: torem(18px);
        }
      }
      &-top {
        max-width: torem(200px);
      }
    }
  }
  
</style>