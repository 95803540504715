import Backbone from 'backbone';
import moment from 'moment/moment';
import 'backbone-relational/backbone-relational';

var stateLabels = {
  1: {text: 'Not confirmed', class: 'unconfirm'},
  2: {text: 'In Progress', class: 'inprogress'},
  3: {text: 'Rejected', class: 'reject'},
  4: {text: 'Completed', class: 'complite'}
};

var transactionTypeLabels = {
  1: 'Paypal',
  2: 'Check'
};

export default Backbone.RelationalModel.extend({
  url: "/api/user/payoff-request",

  defaults: {
    createdAt: null,
    completedAt: null,
    amount: 0,
    transactionType: null,
    state: false,
    currency: 'usd',
  },

  getId: function () {
    return this.get("id");
  },

  getUser: function () {
    return this.get('user');
  },

  getAmount: function () {
    return this.get('amount');
  },

    getFormattedAmount: function() {
        switch (this.getCurrency()) {
            case 'eur':
                return '€' + this.getAmount();
            case 'usd':
                return '$' + this.getAmount();
            default:
                return this.getAmount() + ' ' + this.getCurrency();
        }
    },

  getCreatedAt: function () {
    return this.get('createdAt');
  },

  getCreatedAtFormatted: function () {
    return moment(this.get('createdAt'), 'MM-DD-YYYY').format('MMMM DD, YYYY');
  },

  getCompletedAt: function () {
    return this.get('completedAt');
  },

  getCompletedAtFormatted: function () {
    return moment(this.get('completedAt'), 'MM-DD-YYYY').format('MMMM DD, YYYY');
  },

  getTransactionType: function () {
    return this.get('transactionType');
  },

  getTransactionTypeFormatted: function () {
    return transactionTypeLabels[this.get('transactionType')];
  },

  getState: function () {
    return this.get('state');
  },

  getStateFormatted: function () {
    return this.getStateFull().text;
  },

  getStateClass: function () {
    return this.getStateFull().class;
  },
  getStateFull: function () {
    return stateLabels[this.get('state')];
  }
});