import Backbone from 'backbone';
import OrderModel from '@/components/models/user/order';
import PaginationMetaModel from '@/components/models/pagination/meta';

export default Backbone.Collection.extend({
  model: OrderModel,
  meta: new PaginationMetaModel(),
  url: '/api/user/orders',
  parse: function (response) {
    this.meta.set(response.meta);

    return response.data;
  },
  getMeta: function () {
    return this.meta;
  }
});
