<template>
    <label class="radioBtn" :class="{'active':isChecked}">
        <input
            type="radio"
            :value="radioValue"
            :name="name"
            :id="id"
            :checked="isChecked"
            @change="onChange"
        />
        <span class="radioBtn-text">
            <slot></slot>
        </span>
    </label>
</template>

<script>
    export default {
        name: "radio-btn",
        props: ['value', 'name', 'id', 'radioValue'],
        methods: {
            onChange(event) {
                this.$emit('input', event.target.value);
            }
        },
        computed: {
            isChecked() {
                return this.value == this.radioValue;
            },
        }
    }
</script>

<style lang="scss">
    .radioBtn {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        input {
            display: none;
        }
        &:before {
            display: none;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            margin-top: torem(4px);
            height: torem(4px);
            border-radius: torem(4px);
            background-color: $blue-18;
        }
        &.active:before {
            display: block;
        }
        &-text {
            font-weight: $fw-extrabold;
            font-size: torem(24px);
            line-height: torem(29px);
            color: $color-ac;
            transition: color .3s;
        }
        &:disabled {
            color: $color-ac;
        }
        &.active &-text {
            color: $blue-18;
        }
        &--dots {
            display: flex;
            align-items: center;
            cursor: pointer;
            input {
                display: none;
            }
            &:before {
                position: static;
                display: block;
                background-color: transparent;
                content: '';
                $size: torem(20px);
                flex: 0 0 $size;
                width: $size;
                height: $size;
                border-radius: 50%;
                overflow: hidden;
                border: torem(1px) solid $color-62;
                box-shadow: inset 0 0 0 torem(0px) $color-62;
                transition: box-shadow .3s;
                margin-right: torem(11px);
            }
            &:hover:before {
                box-shadow: inset 0 0 0 torem(1px) $color-62;
            }
            &.active:before {
                box-shadow: inset 0 0 0 torem(6px) $blue-18;
            }
            
            .radioBtn-text {
                font-weight: $fw-semibold;
                font-size: torem(24px);
                line-height: torem(29px);
                color: $color-62;
                transition: color .3s;
            }
            &:disabled {
                color: $color-ac;
            }
            &.active .radioBtn-text {
                color: $blue-18;
            }
        }
        
    }
    
    @include media-breakpoint-down(sm) {
        .radioBtn {
            &-text {
                font-size: torem(22px);
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .radioBtn {
            &:before{
                height:torem(2px);
            }
            &-text{
                font-size: torem(14px);
                line-height: torem(17px);
            }
            &--dots {
                opacity: .2;
                display: flex;
                width: auto;
                background-color: $mainfont;
                color: $white;
                margin: 0 torem(5px) 0 0;
                min-width: torem(110px);
                flex: 0 0 auto;
                justify-content: center;
                align-items: center;
                min-height: torem(44px);
                border-radius: torem(4px);
                
                &.active{
                    opacity: 1;
                }
                p{
                    color: $white;
                    display: inline-block;
                }
                &.active:before{
                    box-shadow: none;
                }
                &.active:before,&:before {
                    $size:torem(20px);
                    order: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-size: torem(6px);
                    font-family: fontello;
                    content: '\e840';
                    box-shadow: none;
                    border: torem(1px) solid $white;
                    margin: 0 0 0 torem(6px);
                    width: $size;
                    height: $size;
                }
            }
        }
    }
</style>