<template>
    <div class="wrap">
        
        <vheader ref="vheader" />
        <div ref="mcontainer" class="mcontainer">
            <router-view></router-view>
            
        </div>
        <vfooter ref="vfooter" />
        <popup ref="popup" />
    </div>
</template>

<script>
    import vheader from './header/header';
    import vfooter from './footer/footer';
    import popup from '@/components/popup';
    import {mapGetters} from 'vuex';

    export default {
        //'main entry-point for vue part',
        name: "vue-dasahboard-page",
        mounted() {
            this.$root.popup = this.$refs.popup;
            
        },

        components: {
            vheader,
            popup,
            vfooter,
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
                deviceState: 'resolution/getCurrentState'
            })
        },
       


    }
</script>

<style lang="scss">
    body {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
    div#vue-app,.wrap {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }
    footer {
        margin-top: auto;
    }
    @include media-breakpoint-down(sm) {
    
    }
    @include media-breakpoint-down(xs) {
        .wrap{
            background-color: $mainfont;
        }
        .mcontainer{
            border-radius: torem(22px) torem(22px) torem(4px) torem(4px);
            background-color: $white-f5f;
            padding-top: torem(10px);
        }
    
    }


</style>