<template>
    <div class="row flex-fill ">
        <div class="col-12 flex-fill d-flex flex-column  align-items-stretch ">
            <div class="plans-pricing"
                 :class="{'flex-fill d-flex flex-column  align-items-stretch':!products || artistCount === null }">
                <template v-if="products && artistCount !== null">
                    <h1 class="plans-pricing-h1 main-h1 d-none d-sm-flex">Plans</h1>
                    <p class="plans-pricing-h1sub main-sub d-none d-sm-block">
                        Please select a plan to start distributing your music.
                    </p>
                    <div class="plans-pricing-radio"
                         :class="{'plans-pricing-radio--onlyPlans':currentSubscription != null}">
                        <radio-btn radioValue="subscriptions" :value="picked" @input="toggle($event)" name="test">
                            Unlimited uploads <span class="d-none d-md-inline">(Save 100% royalties)</span>
                        </radio-btn>
                        <radio-btn v-if="!userHasActualSubscription" radioValue="payPerProject"
                                   :value="picked"
                                   @input="toggle($event)"
                                   name="test">
                            Pay Per Project
                            <span class="d-none d-md-inline">
                                (Save 92% royalties)
                            </span>
                        </radio-btn>
                    </div>
                    <div class="plans-pricing-slick" v-if="products">
                        <slick-slider ref="sliderDistr" :slickOptions="{initialSlide:currentSlideIndex}"
                                      v-if="picked === 'subscriptions'">
                            <plan-item
                                v-for="subscription in subscriptions"
                                :product="getProductById(subscription.getId())"
                                :userArtistCount="artistCount"
                                :currentProject="currentProject"
                                :currentSubscription="currentSubscription"
                                :key="subscription.getId()"
                            >
                                <p slot="title">{{subscription.getTitle().replace('Music Distribution. ','')}}</p>
                                <p slot="price">
                                    ${{subscription.getPrice()}}<span>/{{subscription.getRecurringPeriod().period}}</span>
                                </p>
                                <ul slot="list">
                                    <template
                                        v-if="subscription.getParamsData().min_artists !== subscription.getParamsData().max_artists ">
                                        <li>
                                            <p>Unlimited uploads with any <br>
                                               {{subscription.getParamsData().min_artists}}
                                               to
                                               {{subscription.getParamsData().max_artists}}
                                               artists or band names
                                            </p>
                                        </li>
                                    </template>
                                    <li v-else><p>Unlimited uploads with any <br> 1 artist or band name</p></li>
                                    <li v-if="subscription.getId() === 25"><p>Label Registration is included</p></li>
                                    <li><p>Free UPC and ISRC codes</p></li>
                                    <li><p>No per store fees</p></li>
                                    <li><p>600+ retailers in over 140 countries</p></li>
                                </ul>
                                <p slot="notif" v-if="subscription.hasRequireProduct()">
                                    *Label Registration is required (${{getlabelPrice}})</p>
                            </plan-item>
                        </slick-slider>
                        <slick-slider ref="sliderSubs"
                                      v-else-if="!userHasActualSubscription && picked === 'payPerProject' ">
                            <pppItem
                                v-if="single.length"
                                :products="getProductsById([
                                    productTypes.ID_DISTRIBUTE_1_TRACK,
                                    productTypes.ID_DISTRIBUTE_2_TRACKS,
                                    productTypes.ID_DISTRIBUTE_3_TRACKS,
                                ])"
                                :currentProject="currentProject"
                            >
                                <p slot="title">
                                    Single <br> {{singleInfo.songs}}
                                </p>
                                <p slot="price">${{single[0].getPrice()}}<span>/project</span></p>
                                <p slot="notif" class="no-center">{{singleInfo.pricePerTrack}} </p>
                            </pppItem>
                            <pppItem
                                v-if="album.getAvailability()"
                                :products="getProductsById([productTypes.ID_DISTRIBUTE_AN_ALBUM])"
                                :currentProject="currentProject"
                            >
                                <p slot="title">
                                    Album <br>
                                    ({{album.getParamsData().min_tracks }} - {{album.getParamsData().max_tracks}} Songs)
                                </p>
                                <p slot="price">${{album.getPrice()}}<span>/project</span></p>
                                <p slot="notif" class="no-center">
                                    Switch to an Unlimited Plan anytime. <br>
                                    (Start earning 100% of your royalties)
                                </p>
                            </pppItem>
                        </slick-slider>
                    </div>
                    <div class="plans-pricing-notif" v-if="picked === 'subscriptions'">
                        <p>Keep 100% of your royalties</p>
                        <p>Upload as much music as you want!</p>
                    </div>
                    <div class="plans-pricing-notif" v-else>
                        <p>No Annual Payment</p>
                        <p>Keep 92% of your royalties</p>
                    </div>
                </template>
                <random-loader v-else class="loader--poss" :substrate="false" :randomText="true" />
            </div>
        </div>
    </div>
</template>


<script>
    import radioBtn from '@/components/radioBtn'
    import slickSlider from '@/components/slick-slider'
    import planItem from './plan-item'
    import pppItem from './ppp-item'

    import vueTooltipster from '@/components/vue-tooltipster'

    import ProjectCollection from '@/components/collections/distribution-projects';
    import ProductType from '@/components/models/product/type';
    import randomLoader from '@/components/random-loader';

    export default {
        name: "plansLoop",
        props: [
            'currentProject',
            'cart',
            'products',
            'currentSubscription',
            'usedArtistCount',
        ],
        data() {
            return {
                picked: 'subscriptions',
                projectCollection: false,
                artistCount: null,
                singleInfo: null,
                single: null,
                album: null,
            }
        },
        async mounted() {
            if (typeof this.usedArtistCount != 'undefined') {
                this.artistCount = this.usedArtistCount;
            } else {
                this.projectCollection = new ProjectCollection();
                this.projectCollection
                    .loadUsedMainArtistCount()
                    .then((count) => {
                        this.artistCount = count;
                    });
            }
            if (!this.single) {
                this.single = this.products.models.filter((product) => {
                    return [20, 21, 22].includes(product.getId()) && product.getAvailability()
                });
            }
            this.defineSingleInfo();
            this.album = this.products.findWhere({id: 23});
        },


        methods: {
            toggle(value) {
                this.picked = null;
                this.$nextTick(() => {
                    this.picked = value;
                })
            },
            getProductById(id) {
                return this.products.findWhere({id: id});
            },
            getProductsById(ids) {
                return this.products.filter((product) => ids.indexOf(product.id) != -1);
            },
            /**
             * @return {string}
             */
            defineSingleInfo() {
                if (this.singleInfo || !this.single.length) {
                    return
                }
                this.singleInfo = {};
                let single = this.products.models.filter((product) => {
                    return [20, 21, 22].includes(product.getId()) && product.getAvailability()
                });
                if (single.length > 1) {
                    let minSingle = single[0],
                        maxSingle = single[single.length - 1];
                    this.singleInfo.songs = '(' + minSingle.getParamsData().min_tracks + ' - ' + maxSingle.getParamsData().max_tracks + ' Songs)';
                    this.singleInfo.pricePerTrack = '($' + (single[1].getPrice() - minSingle.getPrice()).toFixed(2) + ' for-additional tracks up to ' + maxSingle.getParamsData().max_tracks + ')';
                } else {
                    let minSingle = single[0];
                    this.singleInfo.songs = '(' + minSingle.getParamsData().max_tracks + ' Song)';
                    this.singleInfo.pricePerTrack =
                        '(' + minSingle.getPrice() + 'for-additional tracks up to ' + minSingle.getParamsData().max_tracks + ')'
                }
            },

            isCurrentSubscription(product) {
                return this.currentSubscription
                    && (product.getParamData('max_artists') || 0)
                        == this.currentSubscription.get('maxArtistCount');
            },
        },
        computed: {
            productTypes() {
                return ProductType;
            },
            userHasActualSubscription() {
                return this.currentSubscription && this.currentSubscription.isCurrent();
            },
            currentSlideIndex() {
                if (!this.currentSubscription) {
                    return 0;
                }

                let currentIndex = this.subscriptions.findIndex(this.isCurrentSubscription);

                if (currentIndex < 0) {
                    return 0;
                }

                let page = Math.floor((currentIndex + 1) / 2);

                return page * 2;
            },
            subscriptions() {
                return this.products.filter(product => {
                    return product.get('type') == ProductType.DISTRIBUTION_MUSIC
                        && (product.getAvailability()
                            || this.isCurrentSubscription(product)
                        );
                });
            },
            getlabelPrice() {
                return this.products && this.products.findWhere({id: 5}).getPrice();
            },
        },
        components: {
            radioBtn,
            slickSlider,
            vueTooltipster,
            planItem,
            pppItem,
            randomLoader
        }
    }
</script>


<style lang="scss">
    .plans-pricing {
        @include simpleObj;
        @include coner;
        width: 100%;
        box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
        padding: torem(81px) torem(160px);
        
        &-radio {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: torem(48px) 0;
            margin-bottom: torem(58px);
            margin-left: torem(-50px);
            &--onlyPlans {
                margin-left: 0;
            }
            .radioBtn {
                margin: 0 torem(19px);
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        p.plans-pricing-notif {
            font-weight: $fw-medium;
            font-size: torem(18px);
            line-height: torem(22px);
            display: flex;
            align-items: center;
            text-align: center;
            color: $color-62;
        }
        p.plans-pricing-price {
            margin-bottom: torem(2px);
        }
        &-h1 {
            line-height: torem(39px);
            justify-content: center;
            margin: 0 0 torem(14px) 0;
            
        }
        
        &-notif {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                margin: 0 torem(38px);
                font-weight: $fw-medium;
                font-size: torem(17px);
                line-height: torem(21px);
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
                &:before {
                    content: '';
                    width: torem(19px);
                    height: torem(4px);
                    background-color: $mainfont;
                    border-radius: torem(20px);
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: torem(12px);
                    margin-top: torem(-2px);
                }
            }
        }
        &-slick {
            margin: torem(40px) 0;
            margin-top: 0;
        }
    }
    
    @include media-breakpoint-down(sm) {
        .plans-pricing {
            padding: torem(29px) torem(120px) torem(46px);
            &-h1sub {
                font-size: torem(16px);
                line-height: torem(20px);
            }
            &-radio {
                margin-top: torem(32px);
                margin-bottom: torem(41px);
                margin-left: 0;
                .radioBtn {
                    margin: 0 torem(14px);
                    white-space: nowrap;
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            &-notif {
                margin-left: torem(-100px);
                margin-right: torem(-100px);
                
                p {
                    font-size: torem(14px);
                    line-height: torem(17px);
                    margin: 0 torem(10px);
                }
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .plans-pricing {
            padding: 0;
            background: transparent;
            box-shadow: none;
            &-radio {
                margin-top: 0;
                margin-bottom: torem(24px);
            }
            &-slick {
                margin-left: torem(-20px);
                margin-right: torem(-20px);
            }
            &-notif {
                flex-wrap: wrap;
                margin: 0;
                justify-content: left;
                p {
                    margin: 0;
                    line-height: 2;
                }
            }
            
        }
    }
</style>