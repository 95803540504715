import Backbone from 'backbone';
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
    defaults: {
        price: 0.0,
        title: '',
        description: '',
        type: 0,
        paramsData: {},
        updatedAt: 0,
        recurringPeriod: null,
        availability:null,
        REGISTRATION_LABEL_ID: 5,
        MONDOBLAST_ID: 10,
        MONDOBLAST_PRICE: 99.99,
        LANDR_MASTERING_ID: 17,
    },

    getId: function () {
        return this.get('id');
    },
    getTitle: function () {
        return this.get('title');
    },
    getPrice: function () {
        return this.get('price');
    },
    getDescription: function () {
        return this.get('description');
    },
    getType: function () {
        return this.get('type');
    },
    getParamsData: function () {
        return this.get('paramsData');
    },
    getParamData: function (param) {
        let data = this.get('paramsData');
        if (data) {
            return data[param];
        }
    },
    getAvailability(){
        if(!this.get('availability') || this.get('availability') === 'out of stock'){
            return false
        }
        return true
    },
    getRecurringPeriod: function () {
        return this.get('recurringPeriod');
    },
    hasRequireProduct: function () {
        return this.getRequireProductId() > 0;
    },
    getRequireProductId: function () {
        return this.get('requireProductId');
    },
    isRecurring: function () {
        var recurring = this.getRecurringPeriod() || {};

        return Boolean(recurring && recurring.period && recurring.frequency);
    },
    getRecurringMonthsCount: function () {
        var recurring = this.getRecurringPeriod() || {};
        var months = 0;

        if (!this.isRecurring()) {
            return months;
        }

        switch (recurring.period.toLowerCase()) {
            case 'month':
                months = 1;
                break;
            case 'year':
                months = 12;
                break;
        }

        return parseInt(months * recurring.frequency);
    },
    getRecurringString: function () {
        var recurring = this.getRecurringPeriod() || {};
        var period = recurring.period;
        var frequency = '';

        if (!this.isRecurring()) {
            return '';
        }

        if (recurring.frequency > 1) {
            frequency = recurring.frequency + ' ';
            period += 's';
        }

        return frequency + period;
    },
    hasSameGroup: function (product) {
        return product.get('group') === this.get('group');
    },
});
/*

ProductModel.REGISTRATION_LABEL_ID = 5;

ProductModel.MONDOBLAST_ID = 10;
ProductModel.MONDOBLAST_PRICE = 99.99;
ProductModel.LANDR_MASTERING_ID = 17;

return ProductModel;
});
*/
