import Backbone from 'backbone';
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
  getId: function () {
    return this.get("id");
  },
  getSiteName: function () {
    return this.get("siteName");
  },
  getSiteUrl: function () {
    return this.get("url");
  },
  getComment: function () {
    return this.get("comment");
  }
});
