<template>
    <div class="check">
        <label class="check-holder ficon icon-checksvg"
               :class="{'active ':active}"
               :name="name">
            <input :ref="cref||name" type="checkbox" class="checkbox" v-model="active">
        </label>
        {{text}}
    </div>

</template>

<script>


    export default {
        name: "check",
        props: {
            info: {
                type: Object,
                default: () => {
                },

            },
            cref: {
                type: String,
                default: '',
            },
            text: {
                type: String,
                default: '',
            },
            name: {
                type: String,
                default: '',
            },
            target: {
                type: String,
                default: '',
            },
            check: {
                type: Boolean,
                default: false,
            }
        },
        mounted() {
            var self = this;


            $(this.$el).find('input').on('change', function (ev) {
                self.$emit('tchange', {ev, self});
            })
        },
        data() {
            return {
                active: this.check
            }
        }
    }
</script>

<style lang="scss">
    .check {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        &-holder {
            position: relative;
            background: $white;
            border: torem(1px) solid $color-de;
            box-sizing: border-box;
            border-radius: torem(4px);
            width: torem(24px);
            height: torem(24px);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
            margin-right: torem(7px);
            flex: 0 0 torem(24px);
            &:before {
                font-size: torem(8px);
                color: $white;
                margin: 0px;
                padding: 0px;
                width: auto;
                height: auto;
            }
            &.active {
                background-color: $main;
            }
        }
        input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            opacity: 0;
        }
    }
</style>