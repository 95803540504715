import Products from '@/components/collections/products'



export default {
  state: {
    list: false,
    loading : false
  },
  mutations: {
    set(state, data) {
      state.list= data
    },
    setLoad(state,data){
      state.loading = data
    }
  },
  getters: {
    get(state) {
      return state.list
    },
    getLoad(state) {
      return state.loading
    },
  },
  actions: {
    async load(store) {
      if (store.list) {
        return
      }
      if(store.state.loading)
      {
        return await store.getters.getLoad
      }
      let products = new Products();
      let load = products.fetch().then(function (e) {
        store.commit("set", e);
        return true;
      }).catch(error => {
        return {error: error.response};
      });
      store.commit("setLoad", load);
      await load;
    }
  }
}

