<template>
    <div :class="['member',{'hide':isShowing}]" v-if="load">
        <ttip-Slot class="settings-top--notif"
                   ttipText="You can select to receive royalties via Paypal or<br>by physical check (physical checks are only sent<br>to artists in the United States).  Artists keep 100%<br>net royalties for every sale along with detailed<br>quarterly reports!">
            <h2 class="sform-title" @click="toggleHider">Membership</h2>
        </ttip-Slot>
        <div class="member-hider">
            <div class="member-top">
                <p>Your distribution package:</p>
                <div class="vsubscription-badge" v-if="badge" :class="badge.getStatusClass()">
                    {{badge.getStatusTitle()}}
                </div>
            </div>
            <div class="member-middle">
                <p class="member-title" v-if="subscription.isNull()">
                    You have no distribution package yet
                </p>
                <p class="member-title" v-else>
                    {{badge.getTitle()}}
                </p>
                <router-link :to="badge.getButtonUrl()||'/plans'"
                             v-if="subscription.isNull() || !subscription.isCurrent()"
                             class="vsubscription-link orange">
                    {{badge.getButtonText() || 'Choose One'}}
                </router-link>
            </div>
            <div class="member-buttons" v-if="subscription.isActual() && !isWaiting">
                <router-link
                    v-if="badge.getButtonText()"
                    class="btn btn-small"
                    :to="badge.getButtonUrl()">
                    {{badge.getButtonText()}}
                </router-link>

                <button class="btn btn-small btn-blue" @click="showConfirm()" v-if="badge && badge.hasCancelButton()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import ttipSlot from '@/components/ttip-slot';
    import Badge from '@/components/models/subscription/music-distribution-badge'
    import PayPall from '@/components/models/paypal/paypal-profile';
    import axios from 'axios';
    import {toggle} from '../mixins/toggle';

    export default {
        name: "Membership",
        mixins: [toggle],
        data() {
            return {
                subscription: null,
                badge: null,
                load: false,
                change: false,
                payPal: null,
                confirm1: 'Are you sure you want to CANCEL your UNLIMITED DISTRIBUTION subscription?\n' +
                '<p>This CANNOT be undone.</p>',
                confirm2: 'Without an active subscription, your account will receive 92% of net royalties. Only active subscriptions will earn 100% royalties\n' +
                '<p>Still want to CANCEL your membership?</p>',
                confirm1Essensial: 'Are you sure you want to CANCEL your Essensial subscription before your 3 months commitment?\n' +
                  '<p>This CANNOT be undone.</p>',
                confirm2Essensial: 'Without an active subscription, your account will receive 50% of net royalties for the agreed term of 1 year from start date\n' +
                  '<p> Only active subscriptions will earn 100% royalties. Still want to CANCEL your membership?</p>',
            }
        },
        async mounted() {
            const self = this;

            await this.$store.dispatch('subscription/loadSubscription').then(subscription => {

                if (subscription) {
                    this.subscription = subscription;
                }
                else {
                    this.subscription = this.$store.getters['subscription/getNewSubscription'];
                }
                this.status = this.subscription.attributes.status;

                this.change = this.$store.getters["subscription/getChange"];

            });
            let payPal = new PayPall();
            await payPal.fetch().then((payPal) => {
                this.payPal = payPal
            });
            this.badge = new Badge.createFromSubscription(this.subscription, !!this.payPal.status && this.payPal.status === 'active');

            this.load = true;
        },
        methods: {
            showConfirm() {
                const self = this;

                this.$root.popup.showMessage(this.getConfirmationMessage(1));
                this.$root.popup.onConfirm = function () {
                    self.$nextTick(() => {
                        self.$root.popup.clear();
                        self.showSeccondConfirm();
                    })
                };
                this.$root.popup.setAddText('Yes, i sure');
                this.$root.popup.setCancleText('Close');

            },
            showSeccondConfirm() {
                const self = this;

                this.$root.popup.showMessage(this.getConfirmationMessage(2));
                this.$root.popup.onConfirm = function () {
                    self.$root.popup.clear();
                    self.cancleSubscription();
                };
                this.$root.popup.setAddText('Yes, i want');
                this.$root.popup.setCancleText('Close');
            },
            cancleSubscription() {
                const self = this;
                axios.post(this.badge.getCancelButtonUrl()).then((e) => {
                    self.$root.popup.showMessage('You have successfully cancelled this subscription!');
                    self.subscription.fetch().then(() => {
                        this.badge = new Badge.createFromSubscription(this.subscription, false);
                        self.$store.commit('subscription/setChange', true);
                    });
                }).catch(error => {
                    self.$root.popup.showMessage('Uh Oh! Something went wrong.<br> Let’s try that again.')
                });
            },
            getConfirmationMessage(occasion) {
              let variable='confirm';

              variable = variable + occasion + ((!this.subscription.isCancelWithoutFineAllowed()) ? 'Essensial' : '');

              return this[variable];
            }
        },
        computed: {
            isWaiting() {
                this.badge = new Badge.createFromSubscription(this.subscription, !!this.payPal.status && this.payPal.status === 'active');
                return this.subscription.isWaiting();
            },
        },
        components: {
            ttipSlot
        }
    }
</script>

<style lang="scss">
    .member {
        &-top {
            display: flex;
            align-items: center;
            margin-top: torem(30px);
            p {
                font-weight: $fw-800;
                font-size: torem(20px);
                line-height: torem(24px);
                display: flex;
                align-items: center;
                color: $mainfont;
            }
            .vsubscription-badge {
                margin-left: torem(10px);
                margin-bottom: torem(3px);
                margin-top: torem(-3px);
            }
        }
        &-middle {
            display: flex;
            align-items: center;
            padding: torem(4px) 0;
        }
        &-title {
            font-weight: $fw-600;
            font-size: torem(16px);
            line-height: torem(26px);
            display: flex;
            align-items: center;
            color: $blue-18;
        }
        &-buttons {
            margin-top: torem(15px);
            display: flex;
            align-items: center;
            .btn {
                min-width: torem(120px);
                width: auto;
                &:first-child {
                    margin-right: torem(16px);
                }
            }
        }

    }
    @include media-breakpoint-down(xs) {
        .member {
            &-hider {
                max-height: torem(9999px);
                transition: max-height .3s;
            }
            &.hide &-hider {
                max-height: 0;
                overflow: hidden;
            }
            &.hide .sform-title:after{
                transform: rotate(0deg);
            }
            .member-middle, &-top{
                flex-wrap: wrap;
            }
            .vsubscription-badge{

            }
            &-title {
                margin-right: torem(8px);
                line-height: torem(34px);
                font-size: torem(14px);
            }
            &-top {
                flex: 1 1 100%;
                p{
                    font-size: torem(18px);
                    margin-bottom: torem(5px);
                    flex: 1 1 100%;
                }
            }

            .vsubscription-badge{
                margin-left: 0;
                bottom: 0;
            }
        }
    }
</style>
