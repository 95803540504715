var statusCssClassMap = {
  "draft": "gray",
  "filled": "blue",
  "processed": "green"
};
import Backbone from 'backbone'
export default Backbone.Model.extend({
  defaults: {},
  urlRoot: "/api/marketing/bio/questionnaires",
  getCreatedAt: function () {
    return this.get('createdAt');
  },
  getStageName: function () {
    return this.get('stageName');
  },
  getStatus: function () {
    return this.get('status');
  },
  getRecordLabel: function () {
    return this.get('recordLabel');
  },
  getContributors: function () {
    return this.get('contributors');
  },
  getMusicCategories: function () {
    return this.get('musicCategories');
  },
  getMusicDescription: function () {
    return this.get('musicDescription');
  },
  getMusicTopics: function () {
    return this.get('musicTopics');
  },
  getPreviousReleases: function () {
    return this.get('previousReleases');
  },
  getLocation: function () {
    return this.get('location');
  },
  getPlayedIn: function () {
    return this.get('playedIn');
  },
  getEnjoyAboutListening: function () {
    return this.get('enjoyAboutListening');
  },
  getReasonToMakeMusic: function () {
    return this.get('reasonToMakeMusic');
  },
  getExistsBio: function () {
    return this.get('existsBio');
  },
  getMessage: function () {
    return this.get('message');
  },
  getWebsite: function () {
    return this.get('website');
  },
  isDraft: function () {
    return (this.get('status') == "draft");
  },
  getStatusCssClass: function () {
    return statusCssClassMap[this.get('status')];
  },
  getPageUrl: function () {
    return '/dashboard/marketing/bio/questionnaires/' + this.get("id");
  },
  getEditUrl (){
    return '/marketing/bio/' + this.get("id")+'/edit';
  },
  getShowUrl (){
    return '/marketing/bio/' + this.get("id")+'/show';
  },
  complete: function () {
    var self = this;

    return Backbone
      .sync("update", self, {
        url: self.url() + "/complete"
      })
      .done(function (data) {
        self.set(data);
      });
  }
});
