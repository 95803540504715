define([
    'backbone',
    'moment/moment',
    'backbone-relational/backbone-relational'
], function (Backbone, moment) {

    return Backbone.RelationalModel.extend({

        url: "/api/user/royalty-report",

        defaults: {
            projectTitle: null,
            labelName: null,
            period: null,
            amount: 0,
            grossRevenue: 0,
            reportUrl: null
        },

        getId: function () {
            return this.get("id");
        },

        getUser: function () {
            return this.get('user');
        },

        getProjectTitle: function () {
            return this.get('projectTitle');
        },

        getLabelName: function () {
            return this.get('labelName');
        },

        getPeriod: function () {
            return this.get('period');
        },

        getGross: function () {
            return this.getAmount();

            if (this.get('grossRevenue') == 0){
                return this.get('amount');
            }
            return this.get('grossRevenue');
        },

        emptyGross: function () {
            return this.getGross() === '0.00'
        },

        getPeriodFormatted: function () {
            var period = this.getPeriod();

            if (!period) {
                return '';
            }

            return moment(period, 'YYYY-MM').format('MMMM');
        },

        getMonthByViewPort: function(isMobile = false) {
            return isMobile === true
                ? new Date(this.getPeriod()).getMonth() + 1  // ex. 01
                : this.getPeriodFormatted() // ex. April
        },

        emptyMonth: function() {
            return ! this.getPeriodFormatted()
        },

        getReportUrl: function () {
            return this.get('reportUrl');
        },

        getAmount: function () {
            return this.get('amount');
        },

        emptyAmount: function () {
            return this.getAmount() === '0.00'
        },

        getTitle: function () {
            var projectName = this.getProjectTitle(),
                labelName = this.getLabelName();

            if (projectName && !labelName) {
                return projectName
            }

            if (labelName && !projectName) {
                return labelName
            }

            return projectName + ' ' + labelName;
        },

        emptyTitle: function() {
            return ! this.getTitle()
        }
    });
});
