var Config = function () {
};

function showError(msg) {
  console.error(msg);
}

Config.prototype = {
  getAll: function () {
    if (typeof Mondo === 'undefined' || typeof Mondo.Config === 'undefined') {
      showError("Mondo config doesn't set.");

      return {};
    }

    return Mondo.Config;
  },

  get: function (key) {
    var config = this.getAll();

    if (typeof config[key] === 'undefined') {
      showError(key + " doesn't set in the config.");
      return;
    }

    return config[key];
  },

  has: function (key) {
    return !(typeof this.get(key) === 'undefined');
  }
};

export default new Config();
