<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="main-h1">
                    Money & Reports
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <switcher :itemList="switcherItemList" v-model="active" @input="switcherChanget()" />
            </div>
        </div>
        <div class="row flex-md-nowrap">
            <div class="col-12 moneyAndReports-cont">
                <router-view></router-view>
            </div>
            <div class="col-12 moneyAndReports-board" v-if="balanceInfo">
                    <royaltyBoard  v-for="wallet in balanceInfo.get('wallets')"
                                   :wallet="wallet"
                                   :reasonIfUserCannotMakeNewRequest="balanceInfo.reasonIfUserCannotMakeNewRequest()"
                                   :key="wallet.getCurrency()" />
            </div>
        </div>
    
    </div>
</template>

<script>
    import switcher from '../marketing/components/switcher'
    import royaltyBoard from './components/royalty-board'
    import BalanceInfo from '@/components/models/user/balance-info'

    export default {
        name: "reports-page",
        data() {
            return {
                active: 0,
                balanceInfo: null,
                switcherItemList: [
                    {
                        text: 'Royalty Report',
                        'link': 'royalty-reports'
                    },
                    {
                        text: 'Money Request',
                        'link': 'money-requests'
                    }
                ]
            }
        },
        mounted() {
            let self = this;
            this.active = this.$route.name === 'royaltyReports' ? 0 : 1;
            let balanceInfo = new BalanceInfo();
            balanceInfo.fetch({
                success(){
                    self.balanceInfo = balanceInfo;
                },
                error(collection, error) {
                    self.$root.popup.showMessage(error);
                    self.balanceInfo = false;
                }
            });
        },
        methods: {
            switcherChanget() {
                this.balanceInfo.fetch();
            }
        },
        components: {
            switcher,
            royaltyBoard
        }
    }
</script>

<style lang="scss">
    
    .moneyAndReports {
        &-cont {
            flex: 1 1 auto;
            padding-right: torem(5px);
            display: flex;
        }
        &-board {
            padding-left: torem(5px);
            flex: 1 1 torem(412px);
            min-width: torem(412px);
        }
    }
    
    @include media-breakpoint-down(sm) {
        .moneyAndReports {
            &-cont, &-board {
                padding: 0 torem(15px);
                flex: 1 1 100%;
            }
            &-board {
                order: -1;
                margin-bottom: torem(18px);
            }
        }
    }
    
    @include media-breakpoint-down(sm) {
        .moneyAndReports-board {
            padding: 0;
            min-width: torem(1px);
        }
        .moneyAndReports-cont, .moneyAndReports-board {
            padding: 0 torem(15px);
        }
    }

</style>