<template>
    <ul class="social" v-if=" false || social">
        <li v-for="item in social">
            <a :href=" item.link " class="social-link" :class="item.icon" target="_blank" v-html="item.svg"></a>
        </li>
    </ul>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'social',
        computed: {
            ...mapGetters({
                social: 'social/get',
            })
        },
    }
</script>

<style lang="scss">
    .social {
        display: flex;
        list-style: none;
        padding: 0;
        flex: 0 0 auto;
        li {
            margin: 0 torem(4px);
            &:last-child {
                margin-right: 0;
            }
            &:first-child {
                margin-left: 0;
            }
        }
        &-link {
            $size: torem(31px);
            $svgSize: torem(21px);
            font-size: $size;
            width: $size;
            height: $size;
            color: $white;
            background-color: $footerSocial-color;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            text-decoration: none !important;

            svg {
              fill: #fff;
              width: $svgSize;
              height: $svgSize;
            }

            &:hover {
                background: transparent;
                color: $footerSocial-color;
                box-shadow: inset 0 0 0 torem(2px) $footerSocial-color;

              svg {
                fill: #000;
              }
            }
            &.icon-facebook {
                font-size: torem(18px);
            }
            &.icon-twitter {
                font-size: torem(15px);
            }
            &.icon-instagramm {
                font-size: torem(15px);
            }
            &.icon-googleplus {
                font-size: torem(19px);
            }
            &.icon-youtube {
                font-size: torem(13px);
                &:before {
                    width: auto;
                }
            }
            &.icon-linkidin {
                font-size: torem(16px);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .social {
            justify-content: center;
            &-link {
                $size: torem(35px);
                font-size: $size;
                width: $size;
                height: $size;

            }
            li {
                margin: 0 torem(7px);
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .social-link {
            color: $mainfont;
            background: $white-f5f;
            width: torem(42px);
            height: torem(42px);
            font-size: torem(24px);
            &:hover {
                background: $white-f5f;
                color: $mainfont;
                box-shadow: inset 0 0 0 torem(2px) $white-f5f;
            }
            &.icon-facebook {
                font-size: torem(22px);
            }
            &.icon-twitter {
                font-size: torem(19px);
            }
            &.icon-instagramm {
                font-size: torem(19px);
            }
            &.icon-googleplus {
                font-size: torem(23px);
            }
            &.icon-youtube {
                font-size: torem(17px);
            }
        }
    }
</style>
