<template>
    <div class="masteringMobile">
        <v-select ref="select" @change="onChangeSelect($event)">
            <option
                :selected="index===selectedIndex"
                :value="index" v-for="(item,index) in list">
                <template v-if="  cart.getItemBy({type: 6}) && cart.getItemBy({type: 6}).getProduct().getId() === item.getId() ">✓</template>
                {{item.getTitle().replace('Mastering. ','')}}
                ({{item.getParamsData().min_tracks}}-{{item.getParamsData().max_tracks}})
            </option>
        </v-select>
        
        
        <hr>
        <div class="masteringMobile-bottom">
            <ui-spinner
                ref="spinner"
                :min="Number.parseInt(selectedItem.getParamsData().min_tracks)"
                :max="Number.parseInt(selectedItem.getParamsData().max_tracks)"
                v-model="count"
                :disabled="isInCart"
            />
            <p class="masteringMobile-price">
                ${{selectedItem.getPrice()}}
            </p>
            <button ref="button"
                    :class="['btn masteringMobile-btn ficon',{'icon-plus':!isInCart},{'icon-minus':isInCart}]"
                    @click="onClick"></button>
        
        </div>
    
    </div>
</template>

<script>
    import uiSpinner from '@/components/spinner'
    import vSelect from '@/components/v-select'

    export default {
        name: "mastering-item-mobile",
        props: ['list', 'cart'],
        data() {
            return {
                selectedIndex: 0,
                count: 1,
                masteringInCart:false,
                cartIndex:false,
            }
        },

        mounted() {
            const cartItem = this.cart.getItemBy({type: 6});
            if (cartItem) {
                this.count = cartItem.getProductAttributes().tracks_count;
                this.list.some((mastering, index) => {
                    const is = mastering.getId() === cartItem.getProduct().getId();
                    this.masteringInCart = cartItem;
                    if (is) {
                        this.masteringInCart = cartItem;
                        this.cartIndex = index;
                        return true
                    }
                });
                let vSelect = this.$refs.select;
                vSelect.$refs.select.selectedIndex = this.selectedIndex;
                vSelect.onChangeSelect(this.cartIndex||0);
            }
        },
        methods: {
            onClick() {
                const method = !this.isInCart ? 'add' : 'remove';
                this.$emit(method, {
                    product: this.selectedItem,
                    productAttributes: {tracks_count: this.count},
                    btn: this.$refs.button
                })
            },
            onChangeSelect(index) {
                this.selectedIndex = index;
                if(this.selectedIndex === this.cartIndex){
                    this.count = this.masteringInCart.getProductAttributes().tracks_count;
                }
                this.$nextTick(() => {
                    this.$refs.spinner.refresh();
                });
            },
        },
        computed: {
            selectedItem() {
                return this.list[this.selectedIndex];
            },
            isInCart() {
                const cartItem = this.cart.getItemBy({type: 6});
                if (!cartItem) {
                    return false
                }
                const id = cartItem.getProduct().id,
                    count = cartItem.getProductAttributes().tracks_count;
                return id === this.selectedItem.getId();
            }
        },
        components: {
            uiSpinner,
            vSelect
        }
    }
</script>

<style lang="scss">
    .masteringMobile {
        display: block;
        width: 100%;
        &-bottom {
            display: flex;
            align-items: center;
        }
        &-price {
            margin-left: auto;
            font-weight: $fw-600;
            font-size: torem(19px);
            line-height: torem(23px);
        }
        &-btn {
            min-width: 0;
            padding: 0;
            width: torem(29px);
            height: torem(29px);
            font-size: torem(15px);
            margin-left: auto;
            &:before {
                margin: 0;
                width: 100%;
                text-align: center;
            }
            &.icon-minus {
                font-size: torem(5px);
            }
        }
        &-mobile {
            width: 100%;
        }
        &-bottom {
            display: flex;
        }
    }
</style>