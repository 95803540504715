<template>
    <div class="home">
        <div class="container" v-if="user">
            <div class="row d-none d-sm-flex">
                <div class="col-12">
                    <h1 class="main-h1 text-left">
                        Start a New Project
                    </h1>
                </div>
            </div>
            <div class="row home-row">
                <div class="col-12 home-content">
                    <itemService data-step="5">
                        <i slot="icon" class="ficon icon-headphones"></i>
                        <p slot="title">Distribute a new project</p>
                        <p slot="discr">Distribute a new single or album</p>
                        <router-link v-if="hasFreeSingle" to="/project/freesingle" slot="links"
                                     class="main-calltoAction">
                            Get Free Single
                        </router-link>
                        <router-link to="/project/create" slot="links" class="main-link ficon  icon-arrowright ">
                            Get started
                        </router-link>
                    </itemService>

                    <itemService style="background-color: lightgoldenrodyellow;" v-show="this.user.attributes.ai_access">
                        <i slot="icon" class="ficon icon-rocket"></i>
                        <p slot="title">Octiive ACE introduction</p>
                        <p slot="discr">We are proude to announce "ACE" is ready to use. Meet your AI assistant!</p>
                        <div slot="links">
                            <a @click="aiLink" href="https://ai.octiive.com/version-test/lyrics" class="main-link ficon icon-arrowright">Lyrics Generator</a>
                            <a @click="aiLink" href="https://ai.octiive.com/version-test/art" class="main-link ficon icon-arrowright">Artwork Generator</a>
                        </div>
                    </itemService>
                    <itemService data-step="6">
                        <i slot="icon" class="ficon icon-dynamic"></i>
                        <p slot="title">Promote your music</p>
                        <p slot="discr">Reach new fans</p>
                        
                        <router-link to="/services/bio" slot="links" class="main-link ficon  icon-arrowright ">
                            Artist custom bio
                        </router-link>
                        <router-link to="/services/prblast" slot="links" class="main-link ficon  icon-arrowright ">PR
                                                                                                                   blast
                        </router-link>
                    </itemService>
                    <div class="row">
                        <div class="col-12 home-content">
                            <h1 class="main-h1 home-h1 text-left">
                                Additional Services
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 home-content">
                            <itemService data-step="7">
                                <i slot="icon" class="ficon icon-governor"></i>
                                <p slot="title">Mastering</p>
                                <p slot="discr">Polish your sound</p>
                                <router-link to="/services/mastering" slot="links"
                                             class="main-link ficon  icon-arrowright ">Start
                                </router-link>
                            </itemService>
                        </div>
                    
                    </div>
                </div>
                    <div class="col-12 d-sm-none">
                        <h1 class="main-h1 text-left">
                            Start a New Project
                        </h1>
                    </div>
                <div class="col-12 home-sidebar">
                    <information-block data-step="3" :info="balanceInfo"
                                       :isLabel="!!user.attributes.label" />
                    <!--<reglabel v-if="!user.attributes.label" />-->
                    <reglabel data-step="4" :isLabel="!!user.attributes.label" />
                </div>
            </div>
        
        </div>
        <random-loader v-else class="loader--poss" :substrate="false" :randomText="true" />
    </div>
</template>


<script>
    import BalanceInfo from '@/components/models/user/balance-info.js'

    import itemService from './components/item-service';
    import informationBlock from './components/information-block';
    import reglabel from './components/label-home';
    import randomLoader from '@/components/random-loader';
    import QuickGuide from './components/quickGuide';

    import {mapGetters} from 'vuex';
    import {isMobile} from '../../components/mixins/isMobile';

    import '@/sass/cusom-intro.scss';


    export default {
        name: "home",
        mixins:[isMobile],
        data() {
            return {
                user: false,
                balanceInfo: false,
                loader: false,
                hasFreeSingle: null,
                quickGuide: null,
                actualSubscription: false,
                subscriptionInCart: false,
            }
        },
        mounted: async function () {
            let self = this;
            let balanceInfo = new BalanceInfo();
            balanceInfo.fetch({
                success(){
                    self.balanceInfo = balanceInfo;
                },
                error(collection, error) {
                    self.$root.popup.showMessage(error);
                    self.balanceInfo = false;
                }
            });
            this.user = this.$store.getters['user/user'];
            if (!this.user) {
                this.$store.dispatch('user/loadUser')
                    .then((user) => {
                        this.user = user;
                        if(!this.isMobile && !this.isTablet) {
                            this.guidInit()
                        }
                    });
            }
            else {
                this.user.loadCurrent();
            }

            this.$store.dispatch('subscription/loadSubscription').then(subscription => {
                if (subscription) {
                    this.actualSubscription = subscription.isActual();
                }
                else {
                    subscription = this.$store.getters["subscription/getNewSubscription"];
                }
                subscription.fetch().done(e => {
                    this.$store.commit("subscription/setFreeSingle", e.hasFreeSingle);
                    this.hasFreeSingle = this.$store.getters['subscription/freeSingle'];
                });


            });

            this.$store.dispatch('cart/loadCart').then(cart => {
                this.subscriptionInCart = cart.getItemBy({type: 1});
            });

        },
        methods: {
            guidInit() {
                let self = this;
                this.quickGuide = QuickGuide;
                this.quickGuide.initialize(this.$intro()).done((response) => {
                    if (!response) {
                        self.$root.isIntro = true;
                        let intro = self.$intro(), closeButton = document.createElement('button');
                        closeButton.className = 'guid-close ficon icon-subtract';
                        closeButton.onclick = function () {
                            self.quickGuide.setQuickGuidePassed();
                            self.quickGuide.exit();
                        };
                        intro.onchange(
                            function (el) {
                                let callback = this._introItems[this._currentStep].onchange,
                                    oldClone = document.querySelector('.guid-clone');
                                if (oldClone) {
                                    oldClone.remove();
                                }
                                if (callback) {
                                    callback(el);
                                }
                            });
                        intro.onafterchange(function (el) {
                            let itemoptions = this._introItems[this._currentStep],
                                callback = itemoptions.onafterchange;
                            if (callback) {
                                callback(el);
                            }
                            let clearClass = 'introjs-helperNumberLayer';
                            if (itemoptions.numberClass) {
                                document.querySelector('.introjs-helperNumberLayer').className = clearClass + ' ' + itemoptions.numberClass;
                            }
                            else {
                                document.querySelector('.introjs-helperNumberLayer').className = clearClass;
                            }
                            document.querySelector('.introjs-tooltip').appendChild(closeButton);
                        });
                        self.quickGuide.init(intro)

                    }
                    else {
                        this.quickGuide = null;
                    }
                }).fail(error => {
                    self.$root.popup.showMessage(error);
                });
            },

            aiLink(e) {
                e.preventDefault();
                
                let url = e.target.href;

                let separator = url.includes('?') ? '&' : '?';

                url += separator + 'token=' + this.user.attributes.ai_token;

                window.open(url, '_blank');
            },
        },
        beforeDestroy() {
            if (this.quickGuide) {
                this.quickGuide.exit();
            }
        },
        computed:{
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
            })
        },
        components: {
            itemService,
            informationBlock,
            reglabel,
            randomLoader,
        },
    }
</script>

<style lang="scss">
    
    .home {
        display: flex;
        flex: 1 1 100%;
        &-content {
            flex: 1 1 100%;
            max-width: 100%;
        }
        &-row {
            margin-bottom: torem(20px);
            display: flex;
            flex-wrap: nowrap;
        }
        &-sidebar {
            flex: 0 0 torem(620px);
            width: torem(620px);
            margin-left: auto;
            display: flex;
            flex-direction: column;
            justify-content: stretch;
        }
    }
    
    @include media-breakpoint-down(sm) {
        .home {
            &-row {
                flex-wrap: wrap;
            }
            &-content {
                order: 1
            }
            &-sidebar {
                width: 100%;
                flex: 1 1 auto;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .home{
            padding-top: torem(29px);
            &-sidebar{
                order: -1;
            }
        }
    }

</style>