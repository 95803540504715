import Backbone from 'backbone';
import 'backbone-relational/backbone-relational';
export default Backbone.RelationalModel.extend({
        defaults: {
            url: ''
        },
        initialize: function () {},
        getUrl: function () {
            return this.get('url');
        },
        getInternalType: function () {
            return this.get('internalType');
        },
        isLandrMastering: function () {
            return this.getInternalType() ==25  // TrackUrlModel.INTERNAL_TYPE_WAV_LANDR;
        }
    });
/*

    TrackUrlModel.INTERNAL_TYPE_PUBLIC = 0;
    TrackUrlModel.INTERNAL_TYPE_WAV_RETAILERS = 24;
    TrackUrlModel.INTERNAL_TYPE_WAV_LANDR = 25;

    return TrackUrlModel;
});
*/
