import Backbone from 'backbone';
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({

    defaults: {
        id: "",
        isValid: false,
        track: null
    },

    urlRoot: function () {
        return "/api/landr/purchase-claim";
    },

    /**
     * @returns {Object}
     */
    toJSON: function () {
        var product = this.get("product");
        var result = product || {};

        result.trackId = this.get("track").getId();

        return result;
    },

    /**
     * @returns {String}
     */
    getId: function () {
        return this.get("id");
    },
    /**
     * @returns {bool}
     */
    isValid: function () {
        return this.get("isValid");
    },
    /**
     * @param {bool} isValid
     */
    setIsValid: function (isValid) {
        return this.set("isValid", isValid);
    },

    updateStatus: function () {
        this.save(
            {id: this.getId(), isValid: this.isValid()},
            {url: this.url() + "/update-status", patch: true}
        ).fail(function () {
            throw new Error("Failed update purchase claim status");
        });
    }
});
