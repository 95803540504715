<template>
    <div class="vdate ficon icon-calendar" :class="[{'disabled':isDisabled},customClass]">
        <date-Picker class="vdate-input"
                     :class="{'warn':warn,'disabled':isDisabled}"
                     ref="dataPicker"
                     :itemName="name"
                     :options="options"
                     :disabled="isDisabled"
                     :string="string"
                     :showString="isShowString"
                     @update="update($event)"
                     @onOpen="topen"
                     @onClose='tclose'
                     @keydown="onkeyDown($event)"
                     v-model="value"
        />
    
    </div>
</template>

<script>
    import datePicker from '@/components/data-picker';

    export default {
        name: "vdate",
        props: {
            string: {
                type: String | Boolean,
                default: '',
            },
            value: {
                type: String | Boolean,
                default: '',
            },
            name: {
                type: String,
                default: ''
            },
            options: {
                type: Object,
                default: () => {
                }
            },
            warn: {
                type: Boolean,
                default: false
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            isShowString: {
                type: Boolean,
                default: false
            },
            nullValue: {
                type: Boolean,
                default: false
            },
            customClass: {}
            
        },
        data() {
            return {
                inputval: false
            }
        },
        mounted() {
            let currentDate = this.value;
            if (!this.nullValue && !currentDate) {
                currentDate = this.fixDate();
            }
            if (!this.isDisabled) {
                this.$refs.dataPicker.setDate(currentDate);
            }
        },
        methods: {
            //events
            topen(data) {
                $(this.$el).addClass('focus')
            },
            tclose(data) {
                $(this.$el).removeClass('focus')
            },
            onkeyDown(date) {
                if (!this.isDisabled) {
                    this.$emit('keyDown', date);
                    this.onChangeDate(date);
                }
            },
            update(date) {
                if (!this.isDisabled) {
                   this.onChangeDate(date);
                }
            },
            onChangeDate(date){
                if(this.isDate(date) && this.value !== date){
                    this.$emit('input', date);
                    this.$emit('dateChange', date);
                }
            },
            isDate(date){
                return /(\d{2})+\/+(\d{2})+\/+(\d{4})\b/.test(date)
            },
            fixDate() {
                var currentDate = this.value;
                if (this.isValidate) {
                    return currentDate;
                }
                currentDate = this.$refs.dataPicker.getFormatedMinDate() ||
                    this.$refs.dataPicker.getFormatedMaxDate() ||
                    this.$refs.dataPicker.getFormatedDate(new Date());
                this.update(currentDate);
                return currentDate;
            },
            setMinDate(date) {
                if (!this.isDisabled) {
                    this.$refs.dataPicker.setMinDate(date);
                }
                this.update(this.$refs.dataPicker.getFormatedDate());
            },
            getDate(){
                return this.$refs.dataPicker.getFormatedMinDate()
            },
            isValid() {
                return this.isValidate;
            }
        },
        computed: {
            isValidate() {
                return  /(\d{2})+\/+(\d{2})+\/+(\d{4})\b/.test(this.value);
            }
        },
        components: {
            datePicker
        }
    }
</script>

<style lang="scss">
    .vdate {
        position: relative;
        background: $white;
        border: torem(1px) solid $color-62;
        box-sizing: border-box;
        border-radius: torem(4px);
        overflow: hidden;
        &.ficon {
            margin: 0;
        }
        &-input {
            width: 100%;
            box-shadow: none;
            border: none;
            font-size: torem(17px);
            line-height: torem(21px);
            font-weight: $fw-medium;
            
            min-width: torem(1px);
            max-width: 100%;
            height: torem(43px);
            background-color: transparent;
            padding-right: torem(44px);
            padding-left: torem(12px);
            
            overflow: hidden;
            position: relative;
            z-index: 2;
            cursor: pointer;
            
            color: $blue-1f;
        }
        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: torem(1px) auto auto auto;
            padding: 0 torem(12px);
            line-height: torem(30px);
            width: auto;
            z-index: 1;
            font-size: torem(25px);
            color: $blue-18;
            display: flex;
            align-items: center;
        }
        &.focus {
            // border-color: $main;
        }
        &.error {
            /*background: $bg-errorcolor !important;*/
            border-color: $errofontColor;
            &:before {
                color: $errofontColor !important;
            }
        }
        &.error {
            .vdate-input {
                
                color: $errofontColor !important;
                &::placeholder {
                    color: $errofontColor !important;
                }
            }
        }
        &.disabled {
            border-color: $color-ac;
            &:before {
                color: $color-ac !important;
            }
        }
    }
    
    @include media-breakpoint-down(sm) {
        .vdate {
            &-input {
                height: torem(38px);
            }
        }
    }
    
    @include media-breakpoint-down(sm) {
        .vdate {
            &-input {
                font-size: torem(14px);
            }
        }
    }
</style>