import Backbone from 'backbone';
import ParticipatorModel from '../participator'
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
    relations: [
        {
            type: Backbone.HasOne,
            key: 'internalParticipator',
            relatedModel: ParticipatorModel,
        },
    ],
    defaults: {
        platform: '',
        platformId: '__NEW__',
    },
    urlRoot: '/api/user/participators/platform',
    validate: function (attrs) {
        if (!('platform' in attrs) || attrs.platform.length === 0) {
            return 'Platform is required';
        }
        if (!('platformId' in attrs) || attrs.platformId.length === 0 || attrs.platformId === '__NEW__') {
          return 'Platform ID is required';
        }
    },
    getPlatformId: function () {
        return this.get('platformId') === '__NEW__' ? '' : this.get('platformId');
    },
});
