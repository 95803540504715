import Backbone from 'backbone'
import SubscriptionAgreementModel from './../../subscription/subscription-agreement'
import moment from "moment/moment"
import 'backbone-relational/backbone-relational'

var PRODUCT_ICON = {
  1: "",
  2: "",
  3: "",
  4: "",
  5: "",
  6: "",
  7: "",
  8: "",
  9: "",
  10: "",
  11: "",
  12: "",
  13: "",
  14: "",
  15: "",
  16: "",
  17: "",
  18: "",
  19: ""
};

var STATUSES = {
  CREATED: 'created',
  PENDING: 'pending',
  WAIT_CONFIRMATION: 'wait_confirmation',
  CANCEL: 'cancel',
  PAID: 'paid',
  FAILED: 'failed',
  DATA: {}
};

STATUSES.DATA[STATUSES.CREATED] = {
  class: 'blue-lightly small',
  text: 'Created'
};
STATUSES.DATA[STATUSES.PENDING] = {
  class: 'blue large',
  text: 'Pending'
};
STATUSES.DATA[STATUSES.PAID] = {
  class: 'blue',
  text: 'Paid'
};
STATUSES.DATA[STATUSES.FAILED] = {
  class: 'rouge',
  text: 'Failed'
};
STATUSES.DATA[STATUSES.WAIT_CONFIRMATION] = {
  class: 'blue-light middle',
  text: 'Wait confirmation'
};
STATUSES.DATA[STATUSES.CANCEL] = {
  class: 'rouge',
  text: 'Cancel'
};


export default Backbone.RelationalModel.extend({
  relations: [
    {
      type: Backbone.HasOne,
      key: 'subscriptionAgreement',
      relatedModel: SubscriptionAgreementModel
    }
  ],
  defaults: {
    productId: null,
    title: '',
    price: 0,
    subscriptionAgreement: new SubscriptionAgreementModel(),
    paymentDate: null,
    recurringPeriod: '',
    recurringFrequency: '',
    status: STATUSES.CREATED
  },
  typeList: {
    10: 'mondoblast_id',
    12: 'bio_id',
    5: 'reglabel',
    1: 'subscription'
  },
  editLink: {
    mondoblast_id: {text: "Edit pr. Blast", link: '/marketing/prblast/{id}/edit'},
    bio_id: {text: "Edit Artist Custom bio", link: '/marketing/bio/{id}/edit'},
    reglabel: {text: "Edit Label", link: '/home/label'},
    subscription: {text: "Create Project", link: '/project/create'}
  },

  //http://dashboard.mondotunes.lo/marketing/prblast/22/edit
  //http://dashboard.mondotunes.lo/marketing/bio/25f36d1d-d455-48ca-ba57-cde5fe32ccba/edit
  getEditLink() {
    let obj = this.getEditObject();
    return obj? obj.link: obj
  },
  getEditText() {
    let obj = this.getEditObject();
    return obj? obj.text : obj
  },
  getEditObject: function () {
    let idType = this.typeList[this.getParams().product.type],
      object = this.editLink[idType],
      id = this.get('params')[idType];
    if (!idType || !object) {
      return false
    }
    if (object.link) {
      object.link = object.link.replace('{id}', id);
    }
    return object
  },

  getParams: function () {
    return this.get('params')
  },


  getTitle: function () {
    return this.get("title");
  },

  getProductId: function () {
    return this.get("productId");
  },

  getIcon: function () {
    if (PRODUCT_ICON[this.getProductId()] === undefined) {
      return PRODUCT_ICON[1];
    }
    return PRODUCT_ICON[this.getProductId()];
  },

  getPrice: function () {
    return this.get("price");
  },

  getPaymentDate: function () {
    return this.get("paymentDate");
  },

  getPaymentDateFormatted: function (format) {
    var paymentDate = this.getPaymentDate();

    format = format || 'MMMM D, YYYY';

    if (!paymentDate) {
      return '';
    }

    return moment(paymentDate).format(format);
  },

  getStatus: function () {
    return this.get("status");
  },

  getStatusData: function () {
    return STATUSES.DATA[this.getStatus()];
  },

  getRecurringPeriod: function () {
    return this.get("recurringPeriod");
  },

  getRecurringFrequency: function () {
    return this.get("recurringFrequency");
  },
  isSubscription: function () {
    return Boolean(this.getRecurringPeriod() && this.getRecurringFrequency());
  },
  hasProratedAmount: function () {
    return this.getSubscriptionAgreement().hasProratedAmount();
  },
  getSubscriptionAgreement: function () {
    return this.get('subscriptionAgreement');
  },
  recurringPeriodToString: function () {
    var result;
    var recurringFrequency = this.getRecurringFrequency();

    if (recurringFrequency == 0) {
      return "";
    }

    result = "per";

    if (recurringFrequency > 1) {
      result += " " + recurringFrequency;
    }

    result += " " + this.getRecurringPeriod();

    return result;
  },

  getType: function () {
    if (this.isSubscription()) {
      return "Subscription";
    }

    return "One Time";
  },
  toDTO: function () {
    return {
      id: this.get('id'),
      productId: this.getProductId(),
      title: this.getTitle(),
      price: this.getPrice()
    };
  }
});
