<template>
    <ValidationObserver
        v-if="user"
        ref="observer"
        :class="['sform',{'hide':isShowing}]"
        tag="form"
        @keydown.enter = "onClick()"
        v-slot="{ invalid }">
        <div class="row">
            <div class="col-12">
                <h2 class="sform-title" @click="toggleHider">
                    Social Links (optional)
                </h2>
            </div>
        </div>
        <div class="sform-hider">
            <div class="row">
                <!--firstName-->
                <!-- <div class="col-12">
                    <vinput
                        ref="socialLinkGooglePlus"
                        class="sform-input"
                        key="socialLinkGooglePlus"
                        :value="socialLinkGooglePlus"
                        :ttInfoOptions="{contentAsHTML:true}"
                        title="Google"
                        VPRules="url"
                        inputType="text"
                        inputName="socialLinkGooglePlus"
                        inputDisplayName="GooglePlus"
                        @input="onInput($event,$refs.socialLinkGooglePlus)"
                    />
                </div> -->
                <div class="col-12 col-sm-6 col-md-12">
                    <vinput
                        ref="socialLinkTwitter"
                        class="sform-input"
                        key="socialLinkTwitter"
                        :value="socialLinkTwitter"
                        :ttInfoOptions="{contentAsHTML:true}"
                        title="X"
                        VPRules="url"
                        inputType="text"
                        inputName="socialLinkTwitter"
                        inputDisplayName="Twitter"
                        @input="onInput($event,$refs.socialLinkTwitter)"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-12">
                    <vinput
                        ref="socialLinkFacebook"
                        class="sform-input"
                        key="socialLinkFacebook"
                        :value="socialLinkFacebook"
                        :ttInfoOptions="{contentAsHTML:true}"
                        title="Facebook"
                        VPRules="url"
                        inputType="text"
                        inputName="socialLinkFacebook"
                        inputDisplayName="Facebook"
                        @input="onInput($event,$refs.socialLinkFacebook)"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button ref="btn" class="btn sform-btn" :disabled="isOldValues || invalid" @click="onClick()">
                        Save links
                    </button>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
    import vinput from '@/components/input-tt';
    import {ValidationProvider, ValidationObserver,extend} from 'vee-validate';
    import {toggle} from '../mixins/toggle';
    import {marge} from '../mixins/marge';
    import {onInput} from '../mixins/onInput';
    extend('url', {
        validate(e) {
            let urlPattern = /^https?:\/\/(www\.)?[-a-zA-Z0-9:.\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
            return urlPattern.test(e);
        },
        message: 'This value is not a valid URL'
    });

    export default {
        name: "social-settongs-form",
        mixins: [toggle, marge, onInput],
        props: ['user'],
        data() {
            return {

                socialLinkGooglePlus: null,
                socialLinkTwitter: null,
                socialLinkFacebook: null,
                isOldValues: true,
            }
        },
        mounted() {

            this.socialLinkGooglePlus = this.user.get('socialLinkGooglePlus');
            this.socialLinkTwitter = this.user.get('socialLinkTwitter');
            this.socialLinkFacebook = this.user.get('socialLinkFacebook');

        },
        methods: {
            async onClick() {
                this.isOldValues = true;
                var self = this,
                    isValid = await this.$refs.observer.validate();
                if (isValid) {
                    this.marge();
                    this.user.saveSocialLinks({
                        wait: true,
                        success(e) {
                            self.$root.popup.showMessage('Social links change success');
                        },
                        error(newUserInfo, response) {
                            if (response) {
                                self.$root.popup.showMessage(response);
                            }
                            this.isOldValues = false;
                        }
                    });
                }
            },

        },
        components: {
            vinput,
            ValidationProvider,
            ValidationObserver,
            //notif
        }
    }
</script>