<template>
    <div class="chart">
        <div class="chart-colorMap">
            <p class="color" v-for="colorItem in colorMap">
                <i class="cercle" :style="{backgroundColor:getColorByName(colorItem.isrcName)}"></i>
                {{colorItem.isrcName.replace('_30',' sub-30s')}}
            </p>
        </div>
        <div @mouseleave="hideTooltip()">
            <lineChart
                class="chart-graph"
                :options="chartOptions"
                :chart-data="{labels:getLabels(),datasets:getDataSets()}"
            ></lineChart>
            
            <v-tooltip
                v-if="tooltip && tooltip.opacity"
                v-model="tooltip"
                :mouse="mousePosition"
                :colorMap="colorMap"
            />
        </div>
    </div>
</template>

<script>
    import lineChart from '@/components/line-chart';
    import vTooltip from './v-tooltip'
    import {color} from '../mixins/color'

    export default {
        name: "v-chart-line",
        props: ['value', 'date'],
        mixins: [color],
        data() {
            return {
                colorMap: [],
                chartOptions: {
                    type: 'line',
                    responsive: true,
                    maintainAspectRatio: false,
                    scaleShowValues: true,
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false,
                        mode: 'index',
                        position: 'cursor',
                        intersect: false,
                        custom: this.customTooltips
                    },
                },
                datasets: [],
                tooltip: false,
                mousePosition: null,
            }
        },
        created() {
            let self = this;
            Chart.Tooltip.positioners.cursor = function (chartElements, coordinates) {
                self.mousePosition = coordinates;
                return coordinates;
            };
        },
        mounted() {
            this.getDataSets();
            this.$emit('colorInit', this.colorMap);
        },
        methods: {
            hideTooltip() {
                this.tooltip = null;
            },
            getDataSets() {
                let self = this;
                return this.value.map(chart => {
                    return {
                        label: chart.name.replace('_30', ' sub-30s'),
                        borderColor: self.getColor(chart.name),
                        data: chart.points
                    }
                });

            },
            getLabels() {
                let res = [],
                    dateTo = new Date(this.dateFormat(this.date.to)),
                    tempDate = new Date(this.dateFormat(this.date.from));
                while (dateTo - tempDate > 0) {
                    tempDate.setDate(tempDate.getDate() + 1);
                    res.push(tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate());
                }
                return res;
            },
            customTooltips(tooltip) {
                this.tooltip = tooltip;
            },

            dateFormat(date) {
                date = date.split('/');
                return date[2] + '-' + date[0] + '-' + date[1];
            },
        },
        components: {
            lineChart,
            vTooltip
        }
    }
</script>

<style lang="scss">
    .chart {
        position: relative;
        &-colorMap {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-bottom: torem(10px);
        }
        .color {
            color: $mainfont;
            font-weight: $fw-500;
            font-size: torem(17px);
            line-height: torem(21px);
            
            display: flex;
            align-items: center;
            margin-left: torem(10px);
            .cercle {
                width: 1rem;
                height: 1rem;
                flex: 0 0 1rem;
                border-radius: 50%;
                display: inline-block;
                background-color: transparent;
                margin-right: torem(10px);
            }
            
        }
    }
</style>