<template>
  <div class="col-12 d-flex flex-column">
    <random-loader class="loader--poss" v-if="models == null" :substrate="false" text="Your Artist list will be shown here"/>
    <div v-else-if="models.length > 0" v-for="item in models" :key="item.id">
      <itemSlot  v-if="!isMobile"
        :status="{class:item.attributes.status,text:item.attributes.status}"
        :isEditable="item.isDraft()"
        :editUrl="item.getEditUrl()"
        :showUrl="item.getShowUrl()"
      >
        <p slot="head" class="d-sm-none d-md-block">Created date</p>
        <p slot="head">Title</p>
        <p slot="head">Record labels</p>
        <p slot="head">Contributors</p>
        <p slot="head">Categories</p>
        <p slot="date">
          {{item.get('createdAt')}}
        </p>
        
        <p slot="content" :class="['d-sm-none d-md-block',{'marketingItem-empty':!item.get('createdAt')}]">
          {{item.get('createdAt')||'no date'}}
        </p>
        <p slot="content" :class="{'marketingItem-empty':!item.get('stageName')}">
          {{item.get('stageName')||'no title'}}
        </p>
        <p slot="content" :class="{'marketingItem-empty':!item.get('recordLabel')}">
          {{item.get('recordLabel')|| 'no recordLabel'}}
        </p>
        <p slot="content" :class="{'marketingItem-empty':!item.get('contributors')}">
          {{item.get('contributors')||'no contributors'}}
        </p>
        <p slot="content" :class="{'marketingItem-empty':!item.get('musicCategories')}">
          {{item.get('musicCategories')||'no categories'}}
        </p>
      </itemSlot>
      <itemMobileSlot v-else
                      :status="{class:item.attributes.status,text:item.attributes.status}"
                      :isEditable="item.isDraft()"
                      :editUrl="item.getEditUrl()"
                      :showUrl="item.getShowUrl()"
      >
        <p slot="item-title">
          <span :class="{'text-empty':!item.get('stageName')}">
            {{item.get('stageName') || 'no Title'}}
          </span>
          <span class="split"></span>
          <span :class="{'text-empty':!item.get('musicCategories')}">
            {{item.get('musicCategories')||'no categories'}}
          </span>
        </p>
    
        <p slot="date" :class="{'text-empty':!item.get('createdAt')}">
          {{item.get('createdAt')||'no date'}}
        </p>
    
        <p slot="item-head-left">Record labels</p>
        <p slot="item-cont-left" :class="{'text-empty':!item.get('recordLabel')}">
          {{item.get('recordLabel')|| 'no recordLabel'}}
        </p>
        <p slot="item-head-right">Contributors</p>
        <p slot="item-cont-right" :class="{'text-empty':!item.get('musicCategories')}">
          {{item.get('musicCategories')||'no categories'}}
        </p>
  
  
      </itemMobileSlot>
    </div>
    <empty-state v-else
                h1text="You do not have Artist Custom Bio"
                text="Your Artist list will be shown here"
    />
  </div>
</template>

<script>

  import itemSlot from './item-slot'
  import emptyState from '../../../components/emptyState'
  import Bio from '@/components/collections/marketing/bio-questionnaire'
  import randomLoader from '@/components/random-loader'
  import {mapGetters} from 'vuex';
  import {isMobile} from '../../../components/mixins/isMobile';
  import itemMobileSlot from './item-mobile-slot';

  export default {
    name: "questionnaires-item",
    mixins:[isMobile],
    data() {
      return {
        models: null,
      }
    },
    async mounted() {
      let self = this;
      let bio = new Bio();
      bio.fetch({
        success() {
          self.models = bio.models;
        },
        error(collection,error) {
          self.$root.popup.showMessage(error);
        },
      });
    },
    computed: {
      ...mapGetters({
        winRes: 'resolution/get',
        sizeTab: 'resolution/getSizeTable',
      })
    },
    components: {
      itemSlot,
      emptyState,
      randomLoader,
      itemMobileSlot
    }
  }
</script>