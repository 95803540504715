<template>
  <div class="empty">
    <p class="empty-h1" v-if="h1text" v-html="h1text"></p>
    <p class="empty-p" v-if="text" v-html="text"></p>
    <router-link
      v-if="linkto"
      class="main-link ficon  icon-arrowright"
      :to="linkto">
      {{buttonText||'buy one'}}
    </router-link>
  </div>
</template>

<script>
  export default {
    props: ['h1text', 'text', 'linkto', 'buttonText'],
    name: "emptyState"
  }
</script>

<style lang="scss">
  .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    position: fixed;
    top: torem(202px);
    left: torem(138px);
    right: 0;
    bottom: torem(130px);
    z-index: -1;
    p {
      display: flex;
      align-items: center;
      text-align: center;
      color: $color-ac;
    }
    &-h1 {
      font-weight: $fw-bold;
      font-size: torem(32px);
      line-height: torem(39px);
      margin-bottom: torem(16px);
    }
    &-p {
      font-weight: $fw-500;
      font-size: torem(24px);
      line-height: torem(29px);
      margin-bottom: torem(18px);
    }
  }

  @include media-breakpoint-down(sm) {
    .empty{
      left: torem(70px);
    }
  }
  @include media-breakpoint-down(xs) {
    .empty{
      &-h1 {
        font-size: torem(29px);
        line-height: torem(35px);
      }
      &-p{
        font-size: torem(18px);
        line-height: torem(22px);
      }
      .main-link{
        margin-top: torem(54px);
      }
    }
  }
</style>