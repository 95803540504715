import axios from 'axios';

export default {
    state: {
        url: '/api/user/orders',
        order: false,
    },
    mutations: {
        set(state, data) {
            state.order = data;
        },

    },
    getters: {
        get: function (state) {
            return state.order;
        },

    },
    actions: {
        async load(store) {
            if (this.order) {
                return true;
            }
            return await axios.get(store.state.url).then(function (e) {
                store.commit("set", e.data);
                return true;
            }).catch(error => {
                return {error: error.response};
            });
        },
    },
};