const ReferenceDefaultClass = 'introjs-tooltipReferenceLayer introjs-fixedTooltip'
export default {
    dashboardSteps: [
        {
            element: '[data-step="1"]',
            intro: "Click here to view all current and past projects.",
            tooltipClass: 'guid-custom-wind',
            highlightClass: 'guid-highlight-invisible',
            position: 'right',
            numberClass: 'tmenu-correction',
            onchange: function (item) {
                clone(item);
            },

        },
        {
            element: '[data-step="2"]',
            intro: "Click here to view your current and past marketing orders.",
            tooltipClass: 'guid-custom-wind',
            position: 'right',
            highlightClass: 'guid-highlight-invisible',
            numberClass: 'tmenu-correction',
            onchange: function (item) {
                clone(item);
            },

        },
        {
            element: '[data-step="3"]',
            tooltipClass: 'guid-custom-wind',
            position: 'left',
            highlightClass: 'guid-highlight-coner',
            intro: "Click 'Go to money & reports' to view in depth royalty reports and breakdowns from each month.",

        },
        {
            element: '[data-step="4"]',
            tooltipClass: 'guid-custom-wind',
            position: 'left',
            highlightClass: 'guid-highlight-coner',
            intro: "Click 'Go' to register your label with retailers.",

        },
        {
            element: '[data-step="5"]',
            position: 'right',
            tooltipClass: 'guid-custom-wind',
            highlightClass: 'guid-highlight-coner',
            intro: "Click 'Choose One' to submit a new project.",

        },
        {
            element: '[data-step="6"]',
            position: 'right',
            tooltipClass: 'guid-custom-wind',
            highlightClass: 'guid-highlight-coner',
            intro: "Interested in marketing?</br>Click 'Artist custom bio' of  'PR blast' to begin promoting your brand/music.",
        },
        {
            element: '[data-step="7"]',
            position: 'right',
            tooltipClass: 'guid-custom-wind',
            highlightClass: 'guid-highlight-coner',
            intro: "Are your songs radio-ready?</br> Click 'Start' to submit your music for mastering.",
        },
        {
            element: '[data-step="9"]',
            position: 'left',
            tooltipClass: 'guid-custom-wind guid-custom-wind--complete',
            intro: "This is where you will see the royalties you earn. Money that is not claimed will always roll over to the following month.",
        },
    ]
}

function clone(item) {
    let clone = item.cloneNode(true),
        fzbase = Number.parseFloat(document.querySelector('html').style.fontSize),
        offset = {
            top: $(item).offset().top / fzbase + 'rem',
            left: $(item).offset().left / fzbase + 'rem',
            width: item.offsetWidth / fzbase + 'rem',
            height: item.offsetHeight / fzbase + 'rem',
        };
    //delete item.dataset.step;
    clone.classList.add('guid-clone');
    clone.style.left = offset.left;
    clone.style.top = offset.top;
    clone.style.width = offset.width;
    clone.style.height = offset.height;
    document.querySelector('body').appendChild(clone);
    return clone;
}