import axios from 'axios';

export default {
    state: {
        //url: '/api/projects',
        /*project: {
            coverArt: null,
            id: false,
            title: false,
            coverArtVersion: null,
            upc: null,
            primaryGenre: false,
            secondaryGenre: false,
            notes: false,
            preReleaseDate: null,
            desiredReleaseDate: false,
            expediteToITunes: false,
            copyrightYear: false,
            copyrightLine: false,
            albumType: false,
            trackCount: false,
            tracks: [],
            status: 8,
            mondoBlast: false,
            mondoBio: false,
            payPerProject: false,
            freeSingle: false,
            isExpediteDateHidden: false
        }*/
        project:false
    },
    mutations: {
        set(state, data) {
            state.project = data;
        },
        setTracks(state,data){
            state.project.tracks = [...state.project.tracks,...data];
        },
        assign(state,data){
            console.log({project:state.project,data});

            for (const item in state.project) {
                if(state.project[item] || !data[item]){
                    continue
                }
                if(Array.isArray(state.project[item])){
                    state.project[item] = [...state.project[item],...data[item]];
                }
                else{
                    state.project[item] = data[item];
                }
            }

            console.log({project:state.project,data});
        }
    },
    getters: {
        get: function (state) {
            return state.project;
        },
    },
    actions: {
        init(storege){

        },
        async refresh(store) {
            /*get all projects*/
            if (!store.state.project.id) {
                return store.state.project;
            }
            await axios.get(store.state.url+'/'+store.state.project.id).then(function (e) {
                store.commit("set", e.data);
                return true;
            }).catch(error => {
                return {error: error.response};
            });


        },
    },
};