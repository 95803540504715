<template>
    <footer>
        <div class="container footer">
            <div class="footer-logo">
                <el-logo key="whiteLogo" v-if="isMobile" logoName="whiteLogo" />
                <el-logo key="blackLogo" v-else logoName="blackLogo" />
            </div>
            <p class="footer-copyright">
                © {{ currentYear }}. All Rights Reserved.
            </p>
            <div class="footer-menu">
                <el-menu />
            </div>
            <div class="footer-socials">
                <elSocial />
            </div>
        </div>
    </footer>
</template>

<script>

    import social from './components/social';
    import logo from '../header/components/logo';
    import fmenu from './components/footerMenu'
    import {mapGetters} from 'vuex'
    import {isMobile} from '../../components/mixins/isMobile';


    export default {
        name: "vfooter",
        mixins: [isMobile],
        components: {
            elSocial: social,
            elLogo: logo,
            elMenu: fmenu
        },
        computed: {
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
                getSocial: 'social/get',
            }),
            currentYear () {
                return new Date().getFullYear(); // e.g., 2024
            }
        },
    }
</script>

<style lang="scss">
    footer {
        @include obj;
        background-color: $white-f5f;
        border-top-left-radius: torem(44px);
        border-top-right-radius: torem(44px);
        border: none;
        box-shadow: torem(6px) torem(3px) torem(13px) $headFooterboxshadow;
        padding: torem(28px) 0 torem(28px) $sideMenuWidth;
    }

    .footer {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        &-logo {
            margin-top: torem(-6px);
            .logo {
                max-width: torem(133px);
                width: torem(133px);
                img {
                    max-width: 100%;
                }
            }
        }
        &-socials {
            flex: 0 1 auto;
        }
        &-copyright {
            color: $mainfont;
            font-weight: bold;
            font-size: torem(12px);
            line-height: torem(15px);
            flex: 0 1 auto;
            margin-left: torem(8px);
        }
        &-menu {
            margin: 0 auto;

        }
    }

    @include media-breakpoint-down(sm) {
        footer {
            padding: torem(22px) torem(30px) torem(22px) $sideMenuWidth-sm;
        }
        .footer {
            flex-wrap: wrap;
            justify-content: center;
            padding: 0;
            padding-left: torem(22px);
            &-logo {
                margin-top: torem(14px);
                margin-bottom: torem(19px);
                img {
                    width: 100%;
                }
            }
            &-menu {
                flex: 1 1 100%;
            }
            &-socials {
                order: -1;
                flex: 1 1 100%;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        footer {
            padding: torem(40px) torem(20px);
            border-radius: 0;
            background-color: $mainfont;
        }
        .footer {
            padding-left: 0;
            &-copyright {
                color: $white-f5f;
            }

            &-logo {
                max-width: torem(120px);
                order: 0;
                margin: 0;
                .logo {
                    max-width: 100%;
                }
            }

            &-copyright {
                max-width: torem(214px);
                margin-left: 0;
                margin-right: torem(-7px);
                padding-left: torem(8px);
                order: 0;
            }

            &-socials {
                order: 1;
                margin: torem(35px) 0;

            }

            &-menu {
                order: 2;
            }
        }
    }

</style>
