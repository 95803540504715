<template>
    <div
        class="dnd-area"
        :class="[customClass,{'dnd-disabled':disabled}]"
        @drop.prevent="drop"
        @dragover.prevent="dragover($event)"
    >
        <div class="dnd-note">
            <p class="" v-html="text"></p>
            <button class="dnd-btn" :disabled="disabled" @click="btnClick()">
                <span v-if="files">Browse</span>
                <span v-else>Change</span>
            </button>
            <input ref="input" class="dnd-input" type="file"
                   @input="upload($event)"
                   :accept="accept"
                   :multiple="multiple"
            >
        </div>
        <div class="dnd-shape"
             v-if="isShape"
             @dragleave="dragleave"
             :class="{'active':shapeShow}">
            <div class="dnd-holder">
                <div class="dnd-shapewind">
                    <p>Drop & Drop media here</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as musicMetadata from 'music-metadata-browser';

    export default {
        name: "Drag-and-Drop",
        props: {
            text: {},
            disabled: {
                type: Boolean,
                default: false
            },
            accept: {
                type: String,
                default: '',
            },
            multiple: {
                type: Boolean,
                default:
                    false,
            },
            customClass: {},
            shape: {
                /*
                * allow or disabled full screen shape*/
                type: Boolean,
                default: true
            },
            filter: {}
        },
        data() {
            return {
                files: [],
                shapeShow: false,
                event: null,
            }
        },
        async mounted() {
            if (!this.shape) {
                return;
            }


            /*document.querySelector('body').ondragenter = function (e) {
              if (self.disabled || e.dataTransfer.types.indexOf("Files") === -1) {
                return false;
              }
              self.shapeShow = true;
            };*/

        },
        destroyed() {
            if (!this.shape) {
                return false;
            }
            document.querySelector('body').ondragenter = null;
        },
        methods: {
            btnClick() {
                this.$refs.input.click();
            },
            upload(e) {
                this.$emit('drop', e.currentTarget.files);
                this.parseMetaData(e.currentTarget.files);
            },

            setCover(cover) {
                this.cover = cover;
                this.$emit('imageOnloadEnd', this.cover);

                return this.cover;
            },

            previewFile(file) {
                let reader = new FileReader(), self = this;
                reader.readAsDataURL(file);
                reader.onloadend = function () {
                    return self.setCover(reader.result);
                }
            },

            async parseMetaData(blobs) {
                let parsedBlobs = [];
                for (const blob of blobs) {
                    if (/image/.test(blob.type)) {
                        this.previewFile(blob);
                        parsedBlobs.push(blob);
                    } else if (/audio/.test(blob.type)) {
                        blob.meta = await musicMetadata.parseBlob(blob);
                        /* this.getTracktime(blob);*/
                        parsedBlobs.push(blob);
                    }
                }
                this.$emit('upload', parsedBlobs);
            },

            drop(e) {
                if (this.disabled) {
                    return false;
                }
                let list = e.dataTransfer.files;
                if (this.filter) {
                    let tempList = [];
                    for (let file of list) {
                        let res = this.filter.some(filter => {
                            return new RegExp(filter).test(file.type)
                        });
                        if (res) {
                            tempList.push(file)
                        }
                    }
                    list = tempList;
                }
                this.$emit('drop', list);
                this.parseMetaData(list);
                this.shapeShow = false;
            },

            dragover(event) {

            },
            dragleave(event) {
                this.shapeShow = false;
            }
        },
        computed: {
            isShape() {
                let self = this;
                if (this.shape) {
                    if (!this.event) {
                        this.event = function (e) {
                            if (self.disabled || e.dataTransfer.types.indexOf("Files") === -1) {
                                return false;
                            }
                            self.shapeShow = true;
                        };
                    }
                    document.addEventListener('dragenter', this.event, true);
                }
                else {
                    document.removeEventListener("dragenter", this.event, true);
                }
                return this.shape
            }
        }
    }
</script>
<style lang="scss">
    .dnd {
        display: flex;
        width: 100%;
        &-input {
            display: none;
        }
        &-area {
            overflow: hidden;
            max-width: torem(191px);
            height: torem(191px);
            flex: 1 1 torem(191px);
            border: torem(2px) dashed $color-c9;
            box-sizing: border-box;
            border-radius: torem(6px);
            display: flex;
            align-items: center;
            justify-content: center;
            &--large {
                max-width: torem(600px);
                flex: 1 1 torem(600px);
                .dnd-note {
                    display: inline-block;
                }
                .dnd-btn {
                    margin: 0px;
                    display: inline-block;
                    margin-left: torem(8px);
                }
            }
            /*&:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }*/
        }
        &-note {
            text-align: center;
            
            p {
                font-weight: $fw-bold;
                font-size: torem(20px);
                line-height: torem(24px);
                color: $blue-1f;
                
                display: inline-block;
            }
            &--scnd {
                display: flex;
                justify-content: center;
                align-items: center;
                p {
                    margin-bottom: 0;
                    margin-right: torem(22px);
                }
            }
        }
        &-pregress {
            display: none;
        }
        &-prev {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            img {
                max-height: 100%;
                max-width: 100%;
            }
        }
        &-btn {
            background-color: transparent;
            margin: auto;
            padding: 0;
            border: none;
            position: relative;
            color: $blue-18;
            outline: none !important;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                border: torem(1.5px) solid $blue-18;
                transform: rotate(180deg);
                border-radius: torem(3px);
                background-color: $blue-18;
            }
            span {
                font-weight: $fw-bold;
                font-size: torem(18px);
            }
            input {
                display: none;
            }
        }
        input {
            display: none;
        }
        &-shape {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            
            background-color: $popup-bg;
            z-index: 1000;
            
            display: none;
            &.active {
                display: block;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 2;
            }
        }
        &-holder {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            max-width: 100%;
            max-height: 100%;
            display: flex;
            justify-content: stretch;
            padding: torem(190px) torem(210px);
            padding-right: torem(70px);
            padding-bottom: torem(50px);
        }
        &-shapewind {
            @include obj;
            flex: 1 1 auto;
            border-radius: torem(44px);
            box-shadow: torem(4px) torem(4px) torem(21px) $popup-shadow;
            z-index: 1;
            align-items: center;
            justify-content: center;
            display: flex;
            position: relative;
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: torem(135px);
                left: torem(135px);
                right: torem(135px);
                bottom: torem(135px);
                margin: auto;
                border: torem(2px) dashed $color-c2;
                border-radius: torem(44px);
            }
            p {
                font-weight: $fw-bold;
                font-size: torem(32px);
                line-height: torem(39px);
                display: flex;
                align-items: center;
                text-align: center;
                color: $blue-1f;
            }
        }
        &-area.error {
            &, .dnd-note p {
                border-color: $red-f3 !important;
                color: $red-f3 !important;
            }
        }
        &-disabled &-btn:before {
            background-color: $color-ac;
            border-color: $color-ac;
        }
        &-disabled &-note p,
        &-disabled &-btn span {
            color: $color-ac;
        }
    }
    
    @include media-breakpoint-down(xs) {
        .dnd {
            &-note {
                p {
                    display: block;
                    text-align: center;
                    font-size: torem(16px);
                    line-height: torem(20px);
                }
            }
            &-btn {
                span {
                    font-size: torem(16px);
                    line-height: torem(20px);
                }
            }
            &-holder {
                padding: torem(20px);
            }
            
            &-shapewind {
                &:before {
                    width: auto;
                    height: auto;
                    left: torem(20px);
                    right: torem(20px);
                    top: torem(20px);
                    bottom: torem(20px);
                }
                p {
                    font-size: torem(20px);
                }
            }
            
            
        }
    }
</style>