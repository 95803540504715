<template>
    <tab
        v-if="track"
        :class="{'active':isActive,'changed':isChanged,'valid': valid , 'invalid': !valid, }"
        @click="$emit('switchTo',tabindex)"
        :trackProgress="track.get('progress')"
        :file="track.get('file')"
        :isNewTrack="track.get('isNewTrack')"
    >
        <div slot="icon">
            <div v-if="handle && !isMobile" class="handle ficon icon-draggable-handle">
            </div>
            <div class="tab-icon ficon"
                 ref="playBtn"
                 :class="{'icon-play':!track.get('isPlay'),'icon-pause':track.get('isPlay'),'uploading':!track.attributes.file}"
                 @click.stop="onPlayPauseClick" @tap="onPlayPauseClick">
                <div v-if="track && !track.attributes.file && track.get('isNewTrack')" class="thumbs">
                    <img src="../../../assets/img/track-thumbs.svg" alt="">
                </div>
            </div>
        </div>
        <p v-if="!track.get('file') && !track.get('isNewTrack')" slot="name" class="tab-name tab-name--error">
            Audio file is required
            <br>
            {{track.get('isPlay') || track.attributes.isPlay}}
        </p>
        <p slot="name" class="tab-name" v-else>
            {{ track.get('position') }}. {{ track.getTabLabel() }}
        </p>
        <p slot="discr" class="tab-discr"
           v-if="track.getMainArtistName()">
            {{ track.getMainArtistName() }}
        </p>
        <button slot="btn" ref="removeBtn" class="tab-btn ficon icon-subtract" v-if="tools" @click.stop=" askToRemove(track)"></button>
        <vueAudio
            v-if="audio"
            :isPlay="track.get('isPlay')"
            slot="audio"
            ref="refVueAudio"
            :src="track.attributes.file" />
    </tab>


</template>

<script>
    import tab from './tab';
    import vueAudio from './vue-audio.vue';
    import {mapGetters} from 'vuex';
    import {isMobile} from '../../../components/mixins/isMobile'

    export default {
        name: "track-tab",
        props: ['track', 'isActive', 'tabindex', 'trackSrc', 'handle','audio','tools'],
        data() {
            return {
                play: false,
                valid: false,
            }
        },
        mixins:[isMobile],
        components: {
            tab,
            vueAudio
        },

        methods: {
            onPlayPauseClick() {
                if (!this.track.attributes.file) {
                    return false;
                }
                /*const oldAudioPlaying = document.querySelector('.icon-pause');
                if (oldAudioPlaying && oldAudioPlaying !== this.$refs.playBtn) {
                    oldAudioPlaying.click();
                }
                this.play = !this.play;
                this.$emit('playPause',this.play);
                this.$refs.refVueAudio.playPause();*/
                if(this.track.get('isPlay')){
                    this.onPause();
                    return
                }
                this.onPlay();
            },
            onPlay(){
                this.track.getProject().stopPlayingAllTrack();
                this.$emit('playPause',true);
            },
            onPause(){
                this.$emit('playPause',false);
            },
            askToRemove(track) {
                if (!track.get('file') && !track.get('isNewTrack')) {
                    this.remove(track);
                    return
                }
                let message = 'Do you really want to delete track: ' + track.getTitle() + '?'
                    , self = this;
                this.$root.popup.showMessage(message);
                this.$root.popup.onConfirm = function () {
                    self.remove(track);
                    self.$nextTick(() => {
                        self.$root.popup.clear();
                    });
                };
                this.$root.popup.onCancel = function () {
                    self.$nextTick(() => {
                        self.$root.popup.clear();
                    });
                };
                this.$root.popup.setAddText('Yes');
                this.$root.popup.setCancleText('no');
            },
            remove(track) {
                if (!this.isActive) {
                    return
                }
                this.$emit('remove', {id: track.id, removeBtn: this.$refs.removeBtn});
            },
        },
        computed: {
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
            }),
            isChanged() {
                this.valid = !this.track.validate(this.track.attributes);
                return this.track.get('changed');
            },
        }
    }
</script>
<style lang="scss">
    $validColor: $color-44;
    $invalidColor: $red-f3;
    .tab {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: torem(-1px);
        width: torem(550px);
        height: torem(130px);
        border-radius: torem(4px);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: torem(1px) solid $tabborder;
        padding: 0 torem(39px);
        transition: .3s box-shadow;
        position: relative;
        border-right: 0;
        
        &:hover, &.sortable-drag {
            .handle {
                opacity: 1;
            }
        }
        &.sortable-ghost {
            cursor: grabbing;
        }
        .handle {
            /*opacity: 0;*/
            transition: opacity .3s;
            width: torem(14px);
            position: absolute;
            top: 0;
            bottom: 0;
            left: torem(12px);
            display: flex;
            justify-content: center;
            font-size: torem(22px);
            height: torem(22px);
            margin: auto 0;
            color: $draggableHandler-color;
            cursor: grab;
        }
        &-progressholder {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: torem(6px);
            color: $color-ac;
        }
        &-progress {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0;
            transition: width .3s;
            background-color: $color-f4;
        }
        /*&.active:after {
            display: none;
        }*/
        &:first-child {
            margin-top: 0;
        }
        &-name, &-discr {
            font-size: torem(22px);
            line-height: torem(27px);
            color: $mainfont;
            
            white-space: nowrap;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &-name {
            font-weight: $fw-bold;
            &--error {
                color: $red-f3;
            }
        }
        &-icon {
            margin-right: auto;
            flex: 0 0 torem(36px);
            position: relative;
            .thumbs {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                transform: rotate(555deg);
                animation: 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite rotate;
                img {
                    width: torem(27px);
                    height: torem(13px);
                }
            }
            @keyframes rotate {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }
            .thumbs img {
                position: absolute;
                bottom: 0;
                left: torem(1px);
            }
        }
        &-btn {
            margin-left: auto;
            flex: 0 0 torem(28px);
            padding: 0px;
            border-radius: 50%;
            box-shadow: none;
            border: none;
            background: transparent;
            outline: none !important;
            &:disabled {
                opacity: .3 !important;
            }
        }
        &-text {
            display: inline-block;
            text-align: left;
            padding: 0 torem(35px);
            flex: 1 1 80%;
            max-width: 80%;
        }
        &-top {
            @include coner;
        }
        &.active {
            background-color: $white;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
            position: relative;
            margin-top: 0;
            border: 0;
            margin-left: torem(1px);
            z-index: 1;
            & + .tab {
                margin-top: 0;
            }
            .icon-subtract {
                opacity: 1;
            }
        }
       
        &.changed {
            position: relative;
            overflow: hidden;
            z-index: 1;
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: torem(2px);
                z-index: -1;
            }
            &:after {
                font-family: "Fontello";
                position: absolute;
                content: '\e805';
                top: torem(6px);
                left: torem(8px);
                z-index: -1;
                color: $validColor;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &.valid {
                &:before {
                    background: $validColor;
                }
            }
            &.invalid {
                &:after {
                    content: '\e82a';
                    top: torem(3px);
                    left: torem(2px);
                    
                    height: torem(14px);
                    color: $invalidColor;
                }
                &:before {
                    background: $invalidColor;
                }
            }
        }
        .icon{
            &-file-1 {
                color: $blue-18;
                font-size: torem(23px);
                width: torem(42px);
                height: torem(42px);
                line-height: torem(42px);
                background-color: $white;
                border-radius: 50%;
                box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
                margin-right: torem(-6px);
                text-align: center;
                flex: 0 0 auto;
            }
            &-edit {
                cursor: pointer;
                font-size: torem(17px);
                width: torem(28px);
                height: torem(28px);
                line-height: torem(28px);
                text-align: center;
                background-color: $white;
                border-radius: 50%;
                box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
            }
            &-play, &-pause {
                cursor: pointer;
                font-size: torem(22px);
                color: $blue-18;
                width: torem(36px);
                height: torem(36px);
                line-height: torem(36px);
                border-radius: 50%;
                box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
                background: $white;
        
                &:before {
                    margin-left: torem(9px);
                    margin-right: 0;
                }
        
            }
            &-subtract {
                cursor: pointer;
                font-size: torem(24px);
                color: $errofontColor;
                margin-left: auto;
                opacity: 0;
                transition: opacity .3s;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .tab {
            height: torem(92px);
            width: torem(238px);
            padding-right: 0;
            padding-left: torem(27px);
            max-width: 100%;
            overflow: hidden;
            &-text {
                padding-right: 0;
                padding-left: torem(12px);
                max-width: 63%;
            }
            &-btn {
                &.icon-edit, &.icon-subtract {
                    position: absolute;
                    top: torem(10px);
                    left: torem(197px);
                    margin: 0;
                }
                &.icon-subtract {
                    right: torem(28px);
                    left: torem(203px);
                    margin: 0;
                }
            }
            &-name, &-discr {
                font-size: torem(14px);
                line-height: 1.3;
            }
            &-icon {
                margin: 0;
                &.ficon, &.icon-file-1 {
                    width: torem(38px);
                    height: torem(38px);
                    font-size: torem(24px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .handle {
                font-size: torem(14px);
                height: torem(14px);
                left: torem(8px);
            }
            .icon{
                &-subtract {
                    font-size: torem(17px);
                    &:before {
                        margin: 0;
                    }
                }
                &-play, &-pause, &-file-1 {
                    width: torem(34px);
                    height: torem(34px);
                    font-size: torem(19px);
                    margin-right: 0;
                    &:before {
                        margin-left: torem(4px);
                        margin-right: 0;
                    }
                }
                &-pause, &-file-1 {
                    &:before {
                        margin-left: 0;
                    }
                }
            }
            
        }
    }
    
    @include media-breakpoint-down(xs) {
        .tab {
            &,&.active {
                width: 100%;
                padding: torem(15px) torem(20px);
                height: torem(77px);
                border: none;
                background-color: $white;
                box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
                margin-bottom: torem(16px);
                border-radius: torem(74px);
            }
            &-top,&-top.active{
                border-radius: torem(4px);
                margin-bottom: torem(18px);
                padding: torem(20px);
                height: torem(88px);
            }
            &-btn.icon-edit{
                position: static;
            }
            .handle{
                display: none;
            }
            .icon-subtract{
                position: static;
                opacity: 1;
                font-size: torem(20px);
            }
    
            &.changed {
                &:before {
                    width: 100%;
                    height: 100%;
                    background: transparent !important;
                    border: torem(3px) solid transparent;
                    border-radius: torem(50px);
                }
                &.valid:before {
                    border-color:$validColor;
                }
                &.invalid:before {
                    border-color:$invalidColor;
                }
                &:after {
                    display: none;
                }
            }
    
            
    
            
    
            
    
        }
    }
</style>