<template>
    <div class="cartList" v-if="cart">
        <div class="cartList-item cartList-item--subs"
             :class="{'cartList-item-top':cartItem.attributes.productAttributes && cartItem.attributes.productAttributes.tracks_count}"
             v-for="cartItem in cart.attributes.cartItems.models"
             v-if="isSubs(cartItem)" :key="cartItem.id">
            <div class="top">
                <div class="title">
                    <p>
                        {{cartItem.get('product').get('title').replace(/Music Distribution. /g,'')}}
                    </p>
                    <p class="cartList-item-subtitle d-sm-none"
                       v-if="cartItem.attributes.productAttributes && cartItem.attributes.productAttributes.tracks_count">
                        {{cartItem.attributes.productAttributes.tracks_count}} tracks.
                        ${{cartItem.attributes.price}}/track
                    </p>
                </div>
                <div class="price">
                    <p class="oldPrice" v-if="cartItem.getDiscountAmount()">
                        <s>${{cartItem.get('product').get('price')}}</s>
                    </p>
                    <p>&nbsp;${{cartItem.getPrice()}} </p>
                </div>
                <button v-if="remove" @click="askToRemove($event,cartItem) "
                        class="remove cart-remove ficon icon-subtract"></button>
            </div>
            <p class="cartList-item-subtitle d-none d-sm-inline-block"
               v-if="cartItem.attributes.productAttributes && cartItem.attributes.productAttributes.tracks_count">
                {{cartItem.attributes.productAttributes.tracks_count}} tracks. ${{cartItem.attributes.price}}/track
            </p>
        </div>
        <div class="cartList-item"
             v-for="cartItem in cart.attributes.cartItems.models"
             v-if="!isSubs(cartItem)" :key="cartItem.id">
            <div class="title">
                <p> {{cartItem.get('product').get('title')}} </p>
            </div>
            <div class="price">
                <p class="oldPrice" v-if="cartItem.getDiscountAmount()">
                    <s>${{cartItem.get('product').get('price')}}</s>
                </p>
                <p>&nbsp;${{cartItem.getPrice()}}</p>
            </div>
            <button v-if="remove" @click="askToRemove($event,cartItem)"
                    class="remove cart-remove ficon icon-subtract"></button>
        </div>
        <hr class="cartList-hr">
        <div class="cartList-promo">
            <promo :edittable="edittable"></promo>
        </div>
        <div class="cartList-total" v-if="cart">
            <div class="cartList-total--text">
                <p>Total:</p>
            </div>
            <div class="cartList-total--price">
                <p> ${{cart.getPrice()}} </p>
            </div>
        </div>
        <div class="cartList-bottom">
            <div v-if="requireTermsAndConditionAgreement" class="feature-subtext main-sub">
                Ready to rock? Confirm your agreement with our <a href="https://www.octiive.com/terms-and-conditions" target="_blank">terms</a>, and we’ll drop your song worldwide!

                <div style="margin-top: 20px;">
                    <label for="termsAndConditionAgreement" style="cursor: pointer;">
                        <input type="checkbox" v-model="isTermsAndConditionsAgreementChecked" id="termsAndConditionAgreement" /> I agree with the terms &amp; conditions.
                    </label>
                </div>
            </div>

            <button ref="cartBtn" class="cartList-btn btn" :disabled="requireTermsAndConditionAgreement && !isTermsAndConditionsAgreementChecked" @click="$emit('click',$refs.cartBtn)"> {{buttonText}}
            </button>
        </div>
    </div>
</template>

<script>
    import promo from './promo'

    export default {
        name: "cart-item-list",
        props: ['cart', 'remove', 'buttonText', 'edittable', 'requireTermsAndConditionAgreement'],
        data() {
            return {
                isTermsAndConditionsAgreementChecked: false,
            }
        },
        methods: {
            isSubs(cartItem) {
                return [1, 13, 6].some(type => {
                    return cartItem.attributes.product.attributes.type === type
                })
            },
            askToRemove(event, cartItem) {
                let message = 'Do you really want to delete ' + cartItem.get('product').get('title') + '?'
                    , self = this
                    , btn = event.currentTarget;
                this.$root.popup.showMessage(message);
                this.$root.popup.onConfirm = function () {
                    self.removeFromCart(btn, cartItem);
                    self.$nextTick(() => {
                        self.$root.popup.clear();
                    });
                };
                this.$root.popup.onCancel = function () {
                    self.$nextTick(() => {
                        self.$root.popup.clear();
                    });
                };
                this.$root.popup.setAddText('Yes');
                this.$root.popup.setCancleText('no');
            },
            removeFromCart(btn, cartItem) {
                let self = this;
                btn.disabled = true;
                this.cart.removeItem(cartItem,
                    function success() {
                        self.$emit('remove', cartItem);
                    },
                    function error(model, error) {
                        self.$root.popup.showMessage(error);
                        btn.disabled = false;
                    }
                );
            },
        },
        components: {
            promo
        }
    }
</script>

<style lang="scss">
    .cartList {
        width: 100%;
        &-item {
            &, .top {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                margin: torem(28px) 0;
            }
            &-top {
                flex-wrap: wrap;
            }
            .top {
                margin-top: 0;
                margin-bottom: 0;
                width: 100%;
            }
            &-subtitle {
                font-size: torem(14px);
                line-height: torem(17px);
                color: $color-62;
                font-weight: $fw-medium;
            }
            .title, .price, .remove {
                flex: 1 1 auto;
                padding: 0 torem(10px);
                align-items: center;
                font-size: torem(22px);
                color: $mainfont;
                font-weight: $fw-semibold;
                p {
                    margin: 0;
                }
            }
            .title {
                padding-left: 0;
                max-width: 70%;
                
            }
            .price {
                margin-left: auto;
                text-align: right;
                align-self: flex-start;
                display: flex;
                justify-content: flex-end;
                
                .oldPrice {
                    color: $grey-ba;
                }
            }
            .remove {
                $size: torem(18px);
                font-size: $size;
                color: $red-f3;
                width: $size;
                height: $size;
                flex: 0 0 torem(28px);
                margin: 0;
                background-color: transparent;
                border: none;
                box-shadow: none;
                display: flex;
                padding: 0 0 0 torem(12px);
                &:before {
                    margin: 0;
                }
                &:disabled {
                    cursor: pointer;
                    opacity: .5;
                }
            }
            &--subs {
                margin-top: torem(40px);
                margin-bottom: torem(28px);
                .title, .price {
                    /*align-self: top;*/
                    p {
                        font-weight: $fw-extrabold;
                        
                    }
                    
                }
            }
        }
        &-hr {
            opacity: .8;
            border-top: torem(2px) solid $subline-color;
            margin: torem(58px) 0 torem(33px) 0;
        }
        &-total {
            display: flex;
            margin-bottom: torem(100px);
            &--text {
                flex: 1 1 auto;
                p {
                    font-weight: $fw-extrabold;
                    font-size: torem(32px);
                    line-height: torem(39px);
                    display: flex;
                    align-items: center;
                    color: $mainfont;
                }
            }
            &--price {
                flex: 0 0 auto;
                margin-left: auto;
                display: flex;
                justify-content: flex-end;
                
                p {
                    &.oldPrice {
                        color: $grey-ba;
                    }
                    font-weight: $fw-extrabold;
                    font-size: torem(32px);
                    line-height: torem(34px);
                    display: flex;
                    align-items: center;
                    text-align: right;
                    color: $mainfont;
                }
            }
            
        }
        &-bottom {
            margin-top: auto;
        }
        &-btn {
            margin-top: torem(50px);
            height: torem(58px);
            width: 100%;
        }
    }
    
    @include media-breakpoint-down(sm) {
        .cartList {
            min-height: torem(407px);
            &-item {
                margin: torem(18px) 0;
                .top {
                    align-items: flex-start;
                }
                .title, .price {
                    font-size: torem(16px);
                }
                .price {
                    padding-right: 0;
                }
            }
            &-hr {
                margin-top: torem(40px);
                margin-bottom: torem(18px);
            }
            &-total {
                &--text p {
                    font-size: torem(20px);
                }
                &--price p {
                    font-size: torem(20px);
                }
                margin-bottom: torem(40px);
            }
            &-hr {
                margin-top: torem(28px);
                margin-bottom: torem(14px);
            }
            .promo {
                &-name {
                    font-size: torem(16px);
                    flex-wrap: wrap;
                    align-items: start;
                    strong {
                        display: block;
                        width: 100%;
                    }
                }
                &-discount {
                    font-size: 16px;
                    align-self: baseline;
                }
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .cartList {
            &-item {
                margin: torem(5px) torem(0px);
                background-color: $white;
                border: torem(4px);
                padding: torem(12px) torem(18px);
                min-height:torem(63px) ;
                .title, .price, .remove {
                    align-self: auto;
                    flex: 0 0 auto;
                    p {
                        font-size: torem(17px);
                        line-height: torem(21px);
                    }
                }
                .title {
                    flex: 1 1 100%;
                    max-width: 56%;
                    margin-right: auto;
                    p {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 100%;
                        white-space: nowrap;
                    }
                    .cartList-item-subtitle {
                        font-size: torem(13px);
                        font-weight: $fw-500;
                    }
                }
                .remove {
                    padding-left: torem(32px);
                    &:before {
                        font-size: torem(23px);
                    }
                }
                .price {
                    padding-right: 0;
                    align-items: normal;
                    display: flex;
                    flex-direction: column;
                }
            }
            &-total {
                padding: 0 torem(16px);
                font-size: torem(18px);
                line-height: torem(22px);
            }
            &-promo {
                padding: 0 torem(18px);
            }
    
            p.oldPrice s {
                font-weight: 600;
            }
        }
    }
</style>