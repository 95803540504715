<template>
    <div class="container d-flex flex-column  align-items-stretch">
        <random-loader v-if="status==null || !orderList || (!orderID && !failedReason)" class="loader--poss"
                       :substrate="false" :randomText="true" />
        <div v-else class="row flex-fill ">
            <div class="col-12 flex-fill d-flex flex-column  align-items-stretch ">
                <purchase
                    v-if="status==true && cart"
                    :cart="cart"
                    linkTo="/home"
                    buttonText="Back to home"
                    h1Text="Congratulations! "
                    text="payment was successful"
                    icon="icon-ok"
                    :order="orderList.findWhere({id:orderID})"
                />
                <purchase
                    v-else-if="status==false && cart"
                    :cart="cart"
                    linkTo="/home"
                    buttonText="Back to home"
                    h1Text="Something went wrong"
                    :text="failedReason"
                    icon="icon-fail"
                />
            </div>
        </div>
    </div>
</template>


<script>
    import purchase from '../purchaseResult/purchaseView';
    import randomLoader from '@/components/random-loader';
    import GoogleAnalytics from '@/components/GoogleAnalytics/GoogleAnalytics';
    import axios from 'axios';
    import OrdersList from '@/components/collections/user/orders';

    export default {
        name: "cart-paypal-payment",
        data() {
            return {
                status: null,
                cart: null,
                failedReason: null,
                orderList: null,
                orderID: null,
            }
        },
        async beforeMount() {
            let self = this;
            axios.post('/api/order/paypal/confirm?token=' + this.$route.query.token + (this.$route.query.PayerID != undefined ?  '&PayerID=' + this.$route.query.PayerID : ''))
                .then(function (response) {
                    if (response.data.order) {
                        self.status = response.data.success;
                        self.orderID = response.data.order.id

                        GoogleAnalytics.sendEcommerceTransaction(response.data.order);
                        GoogleAnalytics.sendDataLayerEvents(response.data.gtmTags);
                    }

                    return true;
                }).catch(error => {
                    if (error.response.data.message) {
                        self.status = false;
                        this.failedReason = this.$root.popup.parseMessage(error.response.data.message);
                    }
                    self.status = false;
                    //this.$root.popup.showMessage(error)
                    this.failedReason = this.$root.popup.parseMessage(error);
                });
            let orderList = new OrdersList();
            orderList.fetch().done(() => {
                self.orderList = orderList;
            });
            this.initCart();

        },
        methods: {
            initCart() {
                var self = this;
                this.$store.dispatch('cart/loadCart').then(cart => {
                    this.cart = cart;
                });
            },
        },
        components: {
            purchase,
            randomLoader
        }
    }
</script>
