import Backbone from 'backbone';
import ProductModel from '../models/product'

export default Backbone.Collection.extend({

    model: ProductModel,
    url: '/api/products',
    getLandrMastering: function () {
        return this.find(function (product) {
            return product.getId() == ProductModel.LANDR_MASTERING_ID;
        });
    },
    getItemById: function (id) {
        for (var i = 0; i <= this.models.length; i++) {
            if (this.models[i].id === id) {
                return this.models[i];
            }
        }
        return -1;
    },
    getItemPriority(itemattributes) {

        var key = Object.keys(itemattributes.paramsData)[0];
        if (itemattributes.price && key) {
            return itemattributes.price * parseInt(itemattributes.paramsData[key]);
        }
        return 0;
    },
    /* return Returns a new array with models filtered by groups */
    getItemsByGroupsID(groups) {
        let res = [];
        this.models.forEach(item => {
            let flag = false;
            flag = groups.some(groupId => {
                return item.attributes.group && groupId === item.attributes.group.id
            });
            if (flag) {
                res.push(item);
            }
        });
        return res;
    }

})