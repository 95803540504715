<template>
    <div class="inputTT" :class="{'inputTT-disabled':disabled}">
        <div class="inputTT-top" v-if="title || ttInfoLabel">
            <vue-tooltipster
                v-if="ttInfoLabel"
                :tooltipsterOptions="ttInfoOptions"
                class="inputTT-info"
                :label="ttInfoLabel">
                <i class="ficon icon-info"></i>
            </vue-tooltipster>
            <p class="inputTT-title" v-if="title" v-html="title"></p>
        </div>
        <ValidationProvider
            :key="'ValidationProvider'+inputName"
            :name="VPtitle"
            :vid="inputName"
            v-slot="{errors, classes}"
            :rules="VPRules">
            <div
                :class="['inputTT-bottom',VPClass,{'inputTT-password':inputType==='password'},{'inputTT-error':(classes.invalid || ttErrorLabel )}]">
                <input
                    :autocomplete="autocomplete"
                    :x-autocompletetype="xAutocompletetype"
                    :class="['inputTT-input',inputClass]"
                    :key="inputName"
                    ref="input"
                    :value="value"
                    :name="inputDisplayName || inputName"
                    :placeholder="inputPlaceHolder"
                    @input="$emit('input',$event.target.value)"
                    :type="showpass?'text':inputType"
                    :maxlength="maxlength"
                    @focus="onfocus($event)"
                    @blur="onblur($event)"
                    @change="onchange($event)"
                    :disabled="disabled"
                    @keydown.ctrl.enter="onEnterPress($event)"
                />
                <vue-tooltipster
                    v-if="classes.invalid || ttErrorvis"
                    :class="['inputTT-ttError',ttErrorClass]"
                    themeClass="inputTT-ttError-tooltipster"
                    :tooltipsterOptions="ttErrorOptions"
                    :label="ttErrorLabel||errors[0]">
                    <i class="ficon icon-ic_error tooltipstered"></i>
                </vue-tooltipster>
                <button  :key="'btn'+inputName" v-if="inputType==='password'" tabindex="-1" @click.prevent="togglePass()" :class="{'show':showpass}"
                        class="ficon icon-eye"></button>
            </div>
        </ValidationProvider>
    </div>
</template>

<script>
    import vueTooltipster from '@/components/vue-tooltipster';
    import {ValidationProvider} from 'vee-validate';

    export default {
        name: "input-tt",
        props: [
            'value',
            'title',
            'ttInfoOptions',
            'ttInfoLabel',
            'VPtitle',
            'VPClass',
            'VPRules',
            'inputType',
            'inputClass',
            'inputDisplayName',
            'inputName',
            'inputPlaceHolder',
            'ttErrorClass',
            'ttErrorOptions',
            'ttErrorLabel',
            'ttErrorvis',
            'disabled',
            'maxlength',
            'autocomplete',
            'x-autocompletetype'
        ],
        data() {
            return {
                showpass: false
            }
        },
        methods: {
            onfocus(e) {
                this.$emit('focus', e)
            },
            onblur(e) {
                this.$emit('blur', e)
            },
            onchange(e) {
                this.$emit('change', e)
            },
            onEnterPress(e){
                e.preventDefault();
                e.stopPropagation();
                this.togglePass();
            },
            togglePass() {
                if (this.showpass) {
                    this.showpass = false;
                    this.$emit('hidepass');
                }
                else {
                    this.showpass = true;
                    this.$emit('showpass');
                }

            }
        },
        components: {
            vueTooltipster,
            ValidationProvider
        }
    }
</script>

<style lang="scss">
    .inputTT {
        display: flex;
        flex-direction: column;
        &-disabled {
            .inputTT-title {
                color: $color-ac;
            }
        }
        &-top {
            flex: 1 1 auto;
            width: auto;
            display: flex;
            padding-left: torem(26px);
            position: relative;
            margin-left: torem(-26px);
            margin-right: torem(-26px);
        }
        &-info {
            position: absolute;
            font-size: torem(18px);
            cursor: pointer;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
        }
        &-bottom {
            width: 100%;
            display: flex;
            position: relative;
            border: torem(1px) solid $color-62;
            border-radius: torem(4px);
            overflow: hidden;
        }
        &-ttError {
            margin: auto;
            margin-right: torem(22px);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: torem(18px);
            &.tooltipster .ficon {
                color: $errofontColor;
            }
            &-tooltipster .tooltipster-content {
                font-weight: $fw-medium;
                color: $errofontColor
            }
        }
        &-password &-ttError {
            margin-right: torem(10px);
        }
        &-title {
            font-weight: $fw-600;
            font-size: torem(19px);
            line-height: torem(24px);
            display: flex;
            align-items: center;
            color: $mainfont;
            margin-bottom: torem(8px);
            
        }
        &-input {
            width: 100%;
            background: $white;
            box-sizing: border-box;
            min-width: 1px;
            flex: 1 1 auto;
            padding: 0 torem(12px);
            color: $blue-1f;
            line-height: torem(48px);
            font-size: torem(17px);
            font-weight: $fw-500;
            box-shadow: none;
            border: none;
        }
        &-error {
            border-color: $errofontColor;
            color: $errofontColor;
            &::placeholder {
                color: $errofontColor;
            }
        }
        .ficon.icon-eye {
            margin: 0;
            padding: 0;
            background: transparent;
            border: none;
            width: torem(60px);
            text-align: center;
            outline: none;
            font-size: torem(16px);
            padding-right: torem(22px);
            &:before {
                margin: 0;
                width: torem(26px);
            }
            &.show {
                opacity: .3;
            }
        }
    }
    
    @include media-breakpoint-down(sm) {
        .inputTT {
            &-info {
                position: relative;
                order: 1;
                margin: 0 torem(10px);
                .ficon:before {
                    margin: 0;
                }
            }
            &-title {
                font-size: torem(20px);
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .inputTT {
            margin-bottom: torem(23px);
            &-title {
                font-size: torem(18px);
                line-height: torem(22px);
                margin-bottom: torem(6px);
            }
            &-input {
                font-size: torem(14px);
                line-height: torem(38px);
                background-color: transparent;
            }
        }
    }
</style>