export const pay = {
    methods: {
        savePaymentInfo() {
            this.$refs.btn.disabled = true;
            let isNew = this.payment.isNew();
            let data = {
                paypalEmail:this.paypalEmail,
                payoneerEmail:this.payoneerEmail,
                checkName:this.checkName,
                checkCity:this.checkCity,
                checkAddress:this.checkAddress,
                checkZip:this.checkZip,
                checkState:this.checkState,
                isOldValues:this.isOldValues,
            };

            this.payment.savePaymentInfo(data).done(e => {
                if (isNew) {
                    this.$root.popup.showMessage('Successfully created!');
                } else {
                    this.$root.popup.showMessage('Please, check your email to confirm changing your payment info!');
                }
            }).catch(e => {
                this.$refs.btn.disable = false;
                this.$root.popup.showMessage(e.errors);
            });
        },
    },
    computed: {
        isOldMethod() {
            return this.paymentMethod.name === this.payment.getPaymentMethod();
        }
    },

};