import Backbone from 'backbone'
import DistributionProject from '../models/distribution-project.js'

export default Backbone.Collection.extend({
    model: DistributionProject,
    url: '/api/projects',
    getMainArtist() {
        let artists = [];
        let allowStatus = {
            1: true, //PENDING_PAYMENT,
            2: true, //PAYMENT_FAILED,
            3: true, //DISTRIBUTION_IN_PROGRESS,
            4: true, //DISTRIBUTION_FAILED,
            5: true, //DISTRIBUTION_COMPLETE,
            6: true, //WAITING_PAYMENT_CONFIRMATION,
            7: true, //READY_FOR_DISTRIBUTION,
            8: true, //INCOMPLETE,
            9: false, //TAKE_DOWN,
            11: true, //DISTRIBUTION_BACK
        };
        this.each(project => {
            if (allowStatus[project.getStatus()]) {
                let tempList = project.getPaidArtistsNames();
                if (!artists.length) {
                    artists = tempList;
                } else {
                    tempList.forEach(artistTemp => {
                        let repeat = artists.some(artist => {
                            return artist.toLowerCase() == artistTemp.toLowerCase()
                        });
                        if (!repeat) {
                            artists.push(artistTemp);
                        }
                    })
                }
            }
        });
        return artists.length;
    },
    loadUsedMainArtistCount(project) {
        return this.sync(
            'read',
            { trigger: function() {} }, // fuckoff backbone
            { url: this.url + '/used_main_artists' }
        ).then((list) => {
            var artistNames = [];
            if (project != null) {
                for (var name of project.getPaidArtistsNames()) {
                    list.push({name: name});
                }
            }
            for (var artist of list) {
                if (!artist || !artist.name) {
                    continue;
                }
                var name = artist.name.toLocaleLowerCase();
                if (!artistNames.includes(name)) {
                    artistNames.push(name);
                }
            }

            return artistNames.length;
        });
    },
    getProjectsWitchUpc() {
        return this.models.filter(project => {
            return project.get('upc') !== null
        })
    },
    getProjectsForAnalytics() {
        let allowStatus = {
            1: true, //PENDING_PAYMENT,
            2: false, //PAYMENT_FAILED,
            3: true, //DISTRIBUTION_IN_PROGRESS,
            4: true, //DISTRIBUTION_FAILED,
            5: true, //DISTRIBUTION_COMPLETE,
            6: true, //WAITING_PAYMENT_CONFIRMATION,
            7: false, //READY_FOR_DISTRIBUTION,
            8: false, //INCOMPLETE,
            9: false, //TAKE_DOWN,
            11: false, //DISTRIBUTION_BACK
        };
        return this.models.filter(project => {
            return project.get('upc') !== null && allowStatus[project.getStatus()];
        })
    },
    getProjectByUpc(upc) {
        return this.find({upc})
    },
    getTreckby(attrs) {
        let track = false;
        this.models.some(project => {
            let temp = project.getTracks().findWhere(attrs);
            if (temp) {
                track = temp;
                return true
            }
        });
        return track
    },
    /*getTrackNameByAnalyticItem(analyticItem) {
        let project = this.getProjectByUpc(analyticItem.get('upc_ean'));
        if (project) {

            var title = project.getTrackByIsrc(analyticItem.get('isrc')).getTitle();
            /!* console.log({
                 title,
                 project,
                 isrc:analyticItem.get('isrc'),
                 upc:analyticItem.get('upc_ean'),
             });*!/
            return project.getTitle() + '[' + title + ']'
        }
        else if (analyticItem.get('isrc')) {
            var title = '';
            this.models.some(project => {
                return project.getTracks().some(track => {
                    if (track.get('isrc') && track.get('isrc').toUpperCase() == analyticItem.get('isrc')) {
                        title = track.getTitle();
                    }
                })
            });
            console.log({
                title,
                project,
                isrc: analyticItem.get('isrc'),
                upc: analyticItem.get('upc_ean'),
            });
            return title
        }
        return false
    }*/
})

