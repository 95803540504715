<template>
    <div class="tabs" @click="$emit('click')" @tap="$emit('click')" :class="{'isFocuse':tabsFocuse}">
        <div class="tabs--container">
            <tab :class="[{'active':activeTab === 'projectForm'},'tab-top']"
                 @click="$emit('activeTabChange','projectForm')">
                <div slot="icon" class="tab-icon ficon icon-file-1"></div>
                <p slot="name" class="tab-name">Project <br class="d-none d-sm-block d-md-none"> Information</p>
                <div slot="btn" v-if="!isMobile" class="tab-btn ficon icon-edit"></div>
            </tab>
            <draggable
                ref="trackTabList"
                :class="['edit-project-scrooltabs']"
                :options="{forceFallback : true}"
                v-if="loaded"
                :value="currentProject.attributes.tracks.models"
                group="tracks"
                handle=".handle"
                @end="onMoveTrack"
            >
                <track-tab
                    v-for="(track, index) in currentProject.attributes.tracks.models"
                    :key="track.attributes.id || track.attributes.position"
                    :track="track"
                    :tabindex="index"
                    @switchTo="$emit('activeTabChange',index)"
                    @remove="removeTrack(track, index, $event)"
                    @playPause="onPlayPause($event,index)"
                    :is-active="activeTab === index"
                    :handle="true"
                    :audio="true"
                    :tools="true"
                >
                </track-tab>
            </draggable>
        </div>
    </div>
</template>

<script>
    import tab from './tab';
    import draggable from 'vuedraggable'
    import trackTab from './track-tab';
    import {mapGetters} from 'vuex';
    import {isMobile} from '../../../components/mixins/isMobile'
    export default {
        name: "tabs",
        props: ['currentProject', 'activeTab', 'loaded', 'tabsFocuse'],
        mixins:[isMobile],
        data() {
            return {
                extendable: false,
                startCoord: null,
                lastTap: false,
            }
        },
        mounted() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
            /*this.isLarge();*/
        },
        methods: {
            onMoveTrack(event) {
                let track = this.currentProject.get('tracks').models[event.oldIndex];
                this.currentProject.placeTrackOnPosition(track, event.newIndex + 1);
                this.$emit('activeTabChange', event.newIndex);
            },
            async removeTrack(track, activeTabIndex, event) {
                event.removeBtn.disabled = true;
                let self = this;
                await track.destroy({
                    wait: true, success() {
                        self.$emit('activeTabChange', self.activeTab === activeTabIndex ? 'projectForm' : self.activeTab);
                        self.currentProject.fixTrackPositions();
                        self.currentProject.updateAlbumType();
                        self.$emit('removeTrack');
                    }
                });
            },
            onResize() {
                if (this.$el) {
                    if (this.isTablet &&  !this.isMobile) {
                        this.extendable = true;
                        this.$nextTick(() => {
                            this.initListener();
                        });
                    }
                    else {
                        this.extendable = false;
                        this.clearExtend();
                    }
                }
            },
            clearExtend() {
                this.$el.style = null;
            },
            initListener() {
                let el = this.$el.querySelector('.tabs-extender'), self = this;
                if (!el) {
                    return false
                }
                el.addEventListener("touchstart", self.touchStart, false);
                el.addEventListener("touchmove", self.touchMove, false);
            },
            onPlayPause(isPay,index){
                this.currentProject.getTracks().models[index].set('isPlay',isPay);
            }
        },
        computed:{
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
            })
        },
        components: {
            draggable,
            tab,
            trackTab
        }
    }
</script>

<style lang="scss">
    .tabs {
        position: relative;
        margin-bottom: torem(150px);
        &:after {
            content: '';
            width: torem(4px);
            height: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            right: torem(-1px);
            display: block;
            background-color: $color-c2;
        }
        &--container {
            
            ::-webkit-scrollbar-track {
                background: transparent;
            }
            
            ::-webkit-scrollbar-thumb {
                background: $mainfont;
                cursor: pointer;
            }
            
            ::-webkit-scrollbar {
                width: torem(4px);
            }
            
        }
    }
    
    @include media-breakpoint-down(sm) {
        .tabs {
            position: relative;
            overflow: inherit;
            width: torem(74px);
            min-width: torem(86px);
            max-width: torem(238px);
            transition: width .3s;
            .tab-text {
                padding-left: torem(30px);
                transition: padding-left .3s;
                padding-right: 0;
            }
            &.isFocuse {
                width: 100%;
                .tab-text {
                    padding-left: torem(12px);
                }
            }
            
            &-extender {
                z-index: 300;
                width: torem(10px);
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .tabs{
            width: 100%;
            max-width: 0;
            min-width: 0;
            /*overflow: hidden;*/
            /*transition: max-width .3s;*/
            margin: 0;
            display: none;
            &.isFocuse {
                overflow: visible;
                max-width: torem(375px);
                display: block;
            }
            &:after{
                display: none;
            }
           
            &.isFocuse .tab-text {
                padding: 0;
                padding-left: torem(15px);
                padding-right: torem(15px);
                max-width: 78%;
            }
        }
        
    }
</style>