<template>
    <div class="container d-flex flex-column  align-items-stretch">
        <template v-if="successPay === null">
            <div class="row posr">
                <i class="checkout-back-icon ficon icon-arrow-left d-sm-none" @click="goBack" @tap="goBack"> </i>
                <div class="col-12 ">
                    <h1 class="main-h1">
                        Checkout
                    </h1>
                </div>
            </div>
            <div class="row" v-if="cart && cart.attributes.cartItems.length">
                <div class="col-12">
                    <div class="checkout">
                        <div class="checkout-cartlist"  v-if="!isMobile">
                            <div class="checkout-cartlist-wind">
                                <h1 class="checkout-h1 main-h1">
                                    My order
                                </h1>
                                <cartList :edittable="false" :cart="cart" @click="goBack" :buttonText="'Back'" />
                            </div>
                        </div>
                        <hr class="checkout-hr"  v-if="!isMobile">
                        <div class="checkout-form">
                            <div class="checkout-form-wind">
                                <div class="checkout-form-h1">
                                    Payment method
                                </div>
                                <div class="checkout-radio">
                                    <radioBtn class="radioBtn--dots" radioValue="1" v-model="payMethod"
                                              @input="toggle($event)"
                                              name="payMethod">
                                        <div class="checkout-radio-title">
                                            <p class="checkout-radio-p">PayPal</p>
                                            <i class="checkout-radio-img checkout-radio-img--PayPal d-none d-sm-inline-block"></i>
                                            <p class="checkout-radio-discr d-none d-sm-block">
                                                You will be redirected to PayPal, where <br> you can pay and complete
                                                your order
                                            </p>
                                        </div>
                                    </radioBtn>
                                    <radioBtn class="radioBtn--dots" radioValue="3" v-model="payMethod"
                                              @input="toggle($event)"
                                              name="payMethod">
                                        <div class="checkout-radio-title">
                                            <p class="checkout-radio-p">
                                                <template v-if="!isMobile">
                                                    Pay with a debit or credit card
                                                </template>
                                                <template v-else>
                                                    Card
                                                </template>
                                            </p>
                                            <i class="checkout-radio-img checkout-radio-img--cards d-none d-sm-inline-block"></i>
                                        </div>
                                    </radioBtn>
                                </div>
                                
                                <checkoutform
                                    @successPay="changePayStatus(true,$event)"
                                    @failadPay="changePayStatus(false,$event)"
                                    :payMethod="payMethod"
                                />
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <random-loader v-else class="loader--poss" :substrate="false" :randomText="true" />
        </template>
        <template v-else-if="successPay">
            <purchase
                :cart="cart"
                :linkTo="'/cart'"
                buttonText="Back to cart"
                h1Text="Congratulations! "
                text="payment was successful"
                icon="icon-ok"
                :order="order"
            />
        </template>
        <template v-else-if="successPay === false">
            <purchase
                :cart="cart"
                :linkTo="'/cart'"
                buttonText="Back to cart"
                h1Text="Something went wrong"
                text="payment failed"
                icon="icon-fail"
            />
        </template>
    </div>
</template>

<script>
    import checkoutform from '@/components/checkout-form';
    import radioBtn from '@/components/radioBtn'
    import purchase from '../purchaseResult/purchaseView';
    import cartList from './components/cart-item-list';
    import randomLoader from '@/components/random-loader';
    import OrdersList from '@/components/collections/user/orders';
    import {mapGetters} from 'vuex';
    import {isMobile} from '../../components/mixins/isMobile';
    export default {
        name: "checkout-page",
        mixins:[isMobile],
        data() {
            return {
                cart: false,
                successPay: null,
                payMethod: '1',
                order: null,
            }
        },
        async mounted() {
            this.innerWidth = window.innerWidth;
            this.$store.dispatch('cart/loadCart').then(cart => {
                this.cart = cart;
                if (!this.cart.attributes.cartItems.length) {
                    this.$router.go(-1)
                }
            });
        },
        methods: {
            changePayStatus(status, orderModel) {
                let self = this;
                self.successPay = status;
                if (!self.successPay) {
                    return false;
                }
                let orlderList = new OrdersList();
                orlderList.fetch().then(() => {
                    this.order = orlderList.findWhere({id: orderModel.getId()});
                })
            },
            toggle(e) {
                this.payMethod = false;
                this.$nextTick(() => {
                    this.payMethod = e;
                })
            },
            goBack() {
                this.$router.go(-1);
            }
        },
        components: {
            checkoutform,
            purchase,
            radioBtn,
            cartList,
            randomLoader,
        },
        computed:{
        ...mapGetters({
                sizeTab: 'resolution/getSizeTable',
                winRes: 'resolution/get',
            })
        }
    }
</script>

<style lang="scss">
    .checkout {
        @include obj;
        @include coner;
        box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
        /*padding: torem(64px) torem(138px) torem(64px) torem(94px);*/
        width: 100%;
        flex: 1 1 auto;
        display: flex;
        align-items: stretch;
        
        padding: torem(80px) 0;
        .btn {
            height: torem(58px);
        }
        &-radio {
            .radioBtn {
                align-items: flex-start;
                margin: torem(10px) 0;
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &:before {
                    margin-top: torem(7px);
                }
            }
            &-title {
                display: inline-block;
                vertical-align: middle;
            }
            &-p {
                font-weight: $fw-semibold;
                font-size: torem(22px);
                line-height: torem(27px);
                color: $mainfont;
                display: inline-block;
                vertical-align: middle;
            }
            &-discr {
                flex: 1 1 100%;
                font-size: torem(14px);
                line-height: torem(17px);
                color: $color-62;
                font-weight: normal;
                margin-top: torem(4px);
            }
            &-img {
                display: inline-block;
                background-image: url(../../assets/img/sprite.png);
                background-size: torem(230px) torem(28px);
                margin-left: torem(8px);
                vertical-align: middle;
                &--PayPal {
                    width: torem(24px);
                    height: torem(26px);
                    background-position: torem(49px);
                }
                &--Stripe {
                    width: torem(26px);
                    height: torem(26px);
                    background-position: 100%;
                }
                &--cards {
                    display: block;
                    width: torem(181px);
                    height: torem(28px);
                    margin-left: 0;
                    margin-top: torem(4px);
                }
            }
        }
        &-form {
            &-h1 {
                font-weight: $fw-extrabold;
                font-size: torem(24px);
                line-height: torem(29px);
                display: flex;
                align-items: center;
                margin-bottom: torem(40px);
                color: $mainfont;
            }
        }
        &-h1 {
            margin: 0;
            margin-bottom: 0;
        }
        &-hr {
            width: 0;
            max-width: 0;
            height: auto;
            opacity: 0.8;
            margin: 0;
            border: torem(1px) solid $subline-color;
        }
        &-cartlist {
            flex: 1 1 torem(743px);
            &-wind {
                width: torem(510px);
                display: flex;
                flex-direction: column;
                align-self: start;
                margin-left: auto;
                margin-right: auto;
            }
        }
        &-form {
            flex: 1 1 torem(896px);
            &-wind {
                width: torem(618px);
                display: flex;
                flex-direction: column;
                margin-left: auto;
                margin-right: auto;
                height: 100%;
            }
            
        }
        &-cart-hr {
            opacity: 0.8;
            border-top: torem(2px) solid $blue-18;
            margin: torem(27px) 0 torem(48px) 0;
        }
        &-total {
            display: flex;
            margin-bottom: torem(100px);
            &--text {
                flex: 1 1 auto;
                p {
                    font-weight: $fw-extrabold;
                    font-size: torem(32px);
                    line-height: torem(39px);
                    display: flex;
                    align-items: center;
                    color: $mainfont;
                }
            }
            &--price {
                flex: 0 0 auto;
                margin-left: auto;
                p {
                    font-weight: $fw-extrabold;
                    font-size: torem(32px);
                    line-height: torem(34px);
                    display: flex;
                    align-items: center;
                    text-align: right;
                    color: $mainfont;
                }
            }
            
        }
        
        &-bottom {
            margin-top: auto;
        }
        &-btn {
            width: 100%;
        }
        .cartList-hr {
            border-color: $subline-color;
            opacity: .8;
        }
    }
    
    @include media-breakpoint-down(sm) {
        .checkout {
            padding-top: torem(22px);
            &-form, &-cartlist {
                max-width: 50%;
                overflow: hidden;
                padding: 0 torem(18px);
            }
            
            &-form-wind,
            &-cartlist-wind {
                max-width: 100%;
            }
            &-hr {
                margin: 0;
            }
            &-form {
                &-h1 {
                    margin-bottom: torem(28px);
                }
                
            }
            &-radio {
                &-p {
                    font-size: torem(18px);
                    font-weight: 500;
                }
                &-discr {
                    font-size: torem(12px);
                }
                .radioBtn {
                    margin-bottom: torem(24px);
                }
                
            }
            .btn {
                margin-top: torem(52px);
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .checkout {
            padding-top: 0;
            flex-wrap: wrap;
            display: flex;
            flex-direction: column;
            background: transparent;
            box-shadow: none;
            border: none;
            &-back-icon{
                position: absolute;
                top: 0;
                bottom: 0;
                left: torem(16px);
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                font-size: torem(22px);
                color:$mainfont;
                z-index: 10;
                &:before{
                    margin: 0;
                }
            }
            &-hr {
                display: none;
            }
            &-form, &-cartlist {
                flex: 1 0 100%;
                max-width: 100%;
                padding: 0;
            }
            
            .cartList-item {
                &, .top {
                    display: flex;
                    flex-wrap: wrap;
                }
                .title {
                    max-width: 100%;
                    padding-bottom: torem(10px);
                    border-bottom: torem(1px) solid $color-13;
                    margin-bottom: torem(10px);
                }
            }
            &-h1 {
                display: none;
            }
            &-radio{
                display: flex;
                align-items: center;
                min-height: torem(44px);
                .radioBtn{
                    margin: 0 torem(5px) 0 0;
                    align-items: center;
                    &:before{
                        margin-top: 0;
                    }
                }
            }
        }
        
    }
</style>