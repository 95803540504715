<template>
    <div class="pitem" :class="{'pitem-isFree':item.isFreeSingle()}">
        <div class="pitem-top">
            <div class="pitem-coverArt">
                <img v-if="coverArt && coverArt.attributes.path"
                     :src="coverArt.attributes.path"
                     class="pitem-coverArt-img"
                     alt="some">
                <div class="pitem-coverArt-none">
                    <i class="ficon icon-genre-metal" v-if="icon == 1"></i>
                    <i class="ficon icon-genre-rock" v-else-if="icon == 2"></i>
                    <i class="ficon icon-genre-pop" v-else-if="icon == 3"></i>
                    <i class="ficon icon-ycah" v-else></i>
                    <i class="ficon" :class="{
                        'icon-genre-metal':projectType == 'metal',
                        'icon-ycah':''
                    }"></i>
                </div>
                <!--todo: await all icons for all genre-->
                <img src="../../../assets/img/squere.png" alt="" class="pitem-coverArt-squere">
            </div>
            <div class="pitem-discr">
                <div class="pitem-discr--top">
                    <div class="pitem-discr--left">
                        <div class="pitem-discr--name">
                            <p class="name" :class="{'noTitle':!item.attributes.title}">{{item.attributes.title ||
                                                                                        'Title is empty'}} </p>
                            <i class="ficon icon-split"></i>
                            <p class="type">{{item.getFullAlbumType().text}}</p>
                        </div>
                        <div class="pitem-discr--middle" v-if="mainArtistName  || item.attributes.primaryGenre ">
                            <p class="group" v-if="mainArtistName">
                                {{mainArtistName}}
                            </p>
                            <p class="info-item info-item-upc marg-left-none " v-if="item.get('upc') && !isMobile">
                                UPC: {{item.get('upc')}}
                            </p>
                            <p class="info-item marg-left-none" v-if="item.attributes.primaryGenre && !isMobile">
                                {{item.attributes.primaryGenre.get('title') }}</p>
                        </div>
                        <div class="pitem-status" :class="item.get('upc')" v-if="isMobile">
                            {{item.get('upc')}}
                        </div>

                    </div>
                    <div class="pitem-discr--right" :class="{'active':isShowing}" v-if="!viewMode ">
                        <div class="pitem-dots-holder">
                            <ul class="pitem-tools">
                                <li :class="{'only':!item.isEditable()}" v-if="!isMobile">
                                    <div class="tools-label" :class="item.getFullStatus().class">
                                        {{item.getFullStatus().text}}
                                    </div>
                                </li>
                                <li v-if="item.isFreeSingle() && !isTablet">
                                    <p class="tools-free d-none d-sm-block main-calltoAction">
                                        Free Single
                                    </p>
                                </li>
                                <li v-if="item.isEditable()">
                                    <router-link class="tools-icon" :to="'/project/'+item.id+'/edit'">
                                        <i class="ficon icon-edit"></i>
                                    </router-link>
                                </li>
                                <li v-if="item.isEditable()">
                                    <button @click="confirmToRemove(item)" ref="removeBtn"
                                            class="tools-remove ficon icon-subtract">
                                    </button>
                                </li>
                                <li v-if="item.getStatus()===5">
                                    <button class="tools-remove tools-bgtransporent ficon font2 icon-block" @click="takeDown()"></button>
                                </li>
                            </ul>
                            <button class="pitem-dots ficon icon-dots" @click="toggleHider"
                                    v-if="isMobile && item.isEditable()"></button>
                            <button v-if="isMobile && item.getStatus()===5" class="pitem-takeDown ficon font2 icon-block" @click="takeDown()"></button>
                        </div>
                    </div>
                </div>
                <div class="pitem-discr--bottom" v-if="!isMobile">
                    <div class="pitem-discr--left">
                        <div class="info" v-if="item.attributes.tracks && item.attributes.tracks.length">
                            <p class="info-item" v-if="item.attributes.desiredReleaseDate">
                                <span class="custom-icons custom-calendar ">
                                    <img src="../../../assets/img/svgicon-calendar.svg" alt="">
                                </span>
                                {{ getFormattedDate() }}
                            </p>
                            <p class="info-item">
                                <span class="custom-icons custom-clock">
                                    <img src="../../../assets/img/svgicon-clock.svg" alt="">
                                </span>
                                {{ getFormattedDuration(item.countTotalDuration()) }}
                            </p>
                            <p class="info-item blue" @click="songListToggle()"> {{item.attributes.tracks.length}} songs
                                <i class="ficon icon-arrowright" :class="{'active':itemActive}"></i>
                            </p>
                        </div>
                    </div>
                    <div class="pitem-discr--right"
                         v-if="item.readyForDistribute() && item.isEditable() && !viewMode && item.getStatus()!==11">
                        <button ref="distributionButton" class="btn list-project-distribute" @click="checkEmailVerification($event)">
                          Distribute
                        </button>
                        <loader-small class="product-item-loader--poss" :substrate="false" v-if="loader" text="Resending verification email..."/>
                    </div>
                </div>
            </div>
        </div>
        <div class="pitem-status pitem-status--mt10" :class="item.getFullStatus().class" v-if="isMobile">
            {{item.getFullStatus().text}}
        </div>
        <div class="pitem-bottom" v-if="isMobile">
            <div class="info" v-if="item.attributes.tracks && item.attributes.tracks.length">
                <p class="info-item" v-if="item.attributes.desiredReleaseDate">
                    {{ getFormattedDate() }}
                </p>
                <p class="info-item">
                    {{ getFormattedDuration(item.countTotalDuration()) }}
                </p>
                <p class="info-item blue" @click="songListToggle()"> {{item.attributes.tracks.length}} songs
                    <i class="ficon icon-arrowright" :class="{'active':itemActive}"></i>
                </p>
            </div>
            <router-link :to="'/project/' + this.item.attributes.id + '/distribute'"
                         v-if="item.readyForDistribute() && !viewMode && item.getStatus()!==11"
                         class="pitem-link main-link ficon  icon-arrowright">
                Distribute
            </router-link>
        </div>
        <div class="pitem-trackList" ref="pitemTrackList" v-if="showTrackList" style="display: none;">
            <div class="trackList-table">
                <div class="trackList-row trackList-row--head">
                    <div class="trackList-col trackList-col--index"><p>№</p></div>
                    <div class="trackList-col trackList-col--title"><p>Title</p></div>
                    <div class="trackList-col trackList-col--mArtist"><p>Artist</p></div>
                    <div class="trackList-col trackList-col--mArtist d-none d-sm-block"><p>ISRC</p></div>
                    <div class="trackList-col trackList-col--durat d-none d-sm-block"><p>Duration</p></div>
                </div>
                <div class="trackList-row" v-for="(track,index) in item.attributes.tracks.models">
                    <div class="trackList-col trackList-col--index"><p>{{index+1}} </p></div>
                    <div class="trackList-col trackList-col--title"><p>{{track.getTitle()}}</p></div>
                    <div class="trackList-col trackList-col--mArtist "><p>{{track.getMainArtistName()}}</p></div>
                    <div class="trackList-col trackList-col--mArtist "><p>{{track.get('isrc')|| '-' }}</p></div>
                    <div class="trackList-col trackList-col--durat d-none d-sm-block">
                        <p>{{ track.get('file') ? getFormattedDuration(track.get('duration')) : '-' }}</p>
                    </div>
                </div>
            </div>
        </div>

        <p v-if="isTablet && item.isFreeSingle()" class="tools-free main-calltoAction">
            Free Single
        </p>
    </div>
</template>

<script>
    import $ from 'jquery';
    import {toggle} from './../../settings/mixins/toggle'
    import {isMobile} from '../../../components/mixins/isMobile'
    import {mapGetters} from 'vuex';
    import axios from "axios";
    import loaderSmall from "../../../components/loader-small";

    window.$ = window.jQuery = $;
    export default {
        name: "project-item",
        data() {
            return {
                icon: 0,
                showTrackList: false,
                projectType: false,
                mainArtistName: null,
                coverArt: false,
                itemActive: false
            }
        },
        props: {
            item: {
                type: Object,
                default: {}
            },
            viewMode: {
                type: Boolean,
                default: false
            },
            loader: {
              type: Boolean,
              default: false
            },
        },
        mixins: [toggle, isMobile],
        mounted() {
            this.mainArtistName = this.item.getArtistsNames().join(', ');
            this.coverArt = this.item.getCoverArt();
        },
        methods: {
            checkEmailVerification(event) {
                let self = this, button = event.currentTarget  || this.$refs.distributionButton;
                button.disabled = true;

                axios.get('/api/user/verify-email').then((response) => {
                  if (typeof response.data === 'object' && response.data.status) {
                    self.$router.push('/project/' + self.item.attributes.id + '/select-dsp')
                    // self.$router.push('/project/' + self.item.attributes.id + '/distribute')
                  } else {
                    button.disabled = false;
                    self.$root.popup.showMessage('Email - "' + response.data.email + '" has not been verified! Please verify the email.');

                    self.$root.popup.onConfirm = function () {
                      self.loader = true;
                      axios.post('/api/email-confirmation/resend-verification/signup').then((response) => {
                        let message = '';
                        self.loader = false;
                        if (typeof response.data === 'object' && response.data.code == 200) {
                          message = response.data.message;
                        } else {
                          message = 'Something went wrong!'
                        }
                        self.$root.popup.clear();
                        self.$root.popup.showMessage(message);
                      }).catch(error => {
                        // shows an error
                        this.$root.popup.showMessage(error);
                      });
                    };
                    self.$root.popup.onCancel = function () {
                      self.$nextTick(() => {
                        self.$root.popup.clear();
                      });
                    };
                    self.$root.popup.setAddText('Resend Verification Email');
                    self.$root.popup.setCancleText('Ok');
                  }
                }).catch(error => {
                  // shows an error
                  this.$root.popup.showMessage(error);
                });
            },
            takeDown() {
                const self = this;
                this.$root.popup.showMessage('Are you sure you want to remove this project from all online retailers?');
                this.$root.popup.onConfirm = function () {
                    console.log('td');
                    self.item.sendRequestTakeDown()
                        .done(() => {
                            self.$root.popup.showMessage('' +
                                'We\'ve requested a worldwide takedown of your project.  While our request is an immediate one, do note that most retailers may take up to 60 days (per their terms/conditions) before a project is fully removed.' +
                                '');
                        })
                        .fail(error => {
                            self.$root.popup.showMessage(error);
                        });
                    self.$root.popup.clear();
                };
                this.$root.popup.onCancel = function () {
                    self.$root.popup.clear();
                };
                this.$root.popup.setAddText('Yes');
                this.$root.popup.setCancleText('No');
            },
            songListToggle() {
                if (this.itemActive) {
                    var self = this;
                    this.$nextTick(function () {
                        this.itemActive = false;
                        $(this.$refs.pitemTrackList).hide(300, function () {
                            self.showTrackList = false;
                        });
                    })
                }
                else {
                    this.showTrackList = true;
                    this.$nextTick(function () {
                        this.itemActive = true;
                        $(this.$refs.pitemTrackList).show(300);
                    })
                }
            },
            getFormattedDuration(duration) {
                let hrs = Math.floor(duration / 3600);
                duration -= hrs * 3600;
                let min = Math.floor(duration / 60);
                duration -= min * 60;

                return (hrs ? hrs.toString().padStart(2, '0') + ':' : '')
                    + min.toString().padStart(2, '0') + ':'
                    + duration.toString().padStart(2, '0');
            },
            getFormattedDate() {
                let desiredReleaseDate = this.item.getDesiredReleaseDate();
                if (!desiredReleaseDate) {
                    return
                }
                let date = new Date(desiredReleaseDate);
                const month = [
                    "jan.",
                    "feb.",
                    "march",
                    "april",
                    "may",
                    "june",
                    "july",
                    "aug.",
                    "sep.",
                    "oct.",
                    "nov.",
                    "dec."
                ];
                return date.getDate() + ' ' + month[date.getMonth()] + ' ' + date.getFullYear()
            },
            confirmToRemove(project) {
                const self = this;
                this.$root.popup.showMessage('Do you really want to delete project "' + (project.getTitle() || 'Title is empty') + '" ?');
                this.$root.popup.onConfirm = function () {
                    self.remove(project);
                    self.$root.popup.clear();
                };
                this.$root.popup.onCancel = function () {
                    self.$root.popup.clear();
                };
                this.$root.popup.setAddText('Yes');
                this.$root.popup.setCancleText('No');
            },
            remove(project) {
                let self = this;
                this.$refs.removeBtn.disabled = true;
                project.destroy({
                    wait: true,
                    success() {
                        self.$refs.removeBtn.disabled = false;
                    },
                    error(model, error) {
                        self.$root.popup.showMessage(error);
                        self.$refs.removeBtn.disabled = false;
                    }
                });
            },
        },
        components: {
          loaderSmall
        },
        computed: {
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
            }),
        },
    }
</script>

<style lang="scss">
    .info {
        margin-top: auto;
        display: flex;
        &-item {
            margin: 0 torem(15px);
            font-weight: $fw-semibold;
            font-size: torem(18px);
            line-height: torem(22px);
            display: flex;
            align-items: center;
            color: $mainfont;
            position: relative;
            &-upc {
                margin-bottom: torem(5px);
            }
            &:first-child {
                margin-left: 0;
            }
            &.blue {
                color: $blue-18;
                cursor: pointer;
                font-weight: $fw-700;
                .ficon {
                    color: $blue-18;
                    font-size: torem(12px);
                    position: relative;
                    transition: left .3s;
                    top: torem(1px);
                    left: 0;
                    &:before {
                        margin: 0;
                        margin-left: torem(8px);
                    }
                }
                /*
                                &:hover {
                                    .ficon {
                                        left: torem(4px);

                                    }
                                }*/
            }
            .custom-icons {
                position: relative;
                margin-right: torem(8px);
                display: flex;
                img {
                    max-width: 100%;
                }
                width: torem(19px);
                height: torem(19px);
                &.custom-calendar {
                    /*width: torem(16px);
                    height: torem(14px);*/
                    margin-top: torem(-2px);
                    max-width: 100%;
                }
                &.custom-clock {
                    /*width: torem(16px);
                    height: torem(16px);;*/
                }
                .ficon {
                    margin: 0;
                    &:before {
                        margin: 0;
                    }
                }
                i.ficon.icon-cercle + i.ficon.icon-arrows {
                    font-size: torem(8.5px);
                }
                .ficon.icon-calendar1 + .ficon.icon-checksvg {
                    margin-top: torem(3px);
                    font-size: torem(6px);
                }
                .custom-icons-center {

                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    &:before {
                        margin: auto;
                        color: #FF6337;
                    }
                }
            }
            .ficon {
                font-size: torem(16px);
                font-weight: $fw-semibold;

                margin-left: 0;
                margin-right: torem(8px);
                &:before {
                    margin: 0;
                    font-weight: inherit;
                    position: relative;
                    transition: transform .3s;
                    width: auto !important;
                }
                &.active {
                    &:before {
                        position: relative;
                        transform: rotateZ(90deg);
                    }
                }
            }
        }
    }

    .product-item-loader--poss,
    .project-edit-loader--poss {
      left: 0;
      right: 0;
      top: 50%;
      bottom: 50%;
      margin: auto;
      position: absolute;
      width: 100%;
      min-height: 300px;
    }

    .list-project-distribute {
      FONT-VARIANT: JIS04;
      height: 2.7142857143rem;
      font-size: 1.2857142857rem;
      line-height: 1;
      margin-top: 0rem;
      margin-bottom: 8rem;
      margin-right: 4rem;
    }

    .pitem {
        @include simpleObj;
        @include coner;
        margin: torem(33px) 0;
        padding: torem(32px);
        display: flex;
        flex-direction: column;
        &-isFree {
            border-top-right-radius: 0;
        }
        &-top {
            display: flex;
        }
        &-trackList {
            width: 100% !important;
            opacity: 1 !important;
        }
        &-coverArt {
            @include coner;
            flex: 1 0 torem(176px);
            width: torem(176px);
            max-width: torem(176px);
            /*height: torem(176px);*/
            position: relative;
            z-index: 0;
            overflow: hidden;
            margin-right: torem(30px);
            img {
                width: 100%;
                z-index: 2;
            }
            &-img, &-none {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: auto;
                height: auto;
                z-index: 1;
            }
            &-none {
                background-color: $color-ac;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;

                &:after {
                    position: absolute;
                    content: '';
                    top: torem(6px);
                    bottom: torem(6px);
                    left: torem(6px);
                    right: torem(6px);
                    border: torem(3px) solid $white;
                    width: auto;
                    height: auto;
                    z-index: 1;
                    @include coner;

                }
                i {
                    color: $white;
                    font-size: torem(94px);
                    width: auto;
                    margin: 0;
                    text-align: center;
                    &:before {
                        width: auto;
                        margin: 0;
                    }
                    &.icon-photo {
                        font-size: torem(57px);
                    }
                    &.icon-ycah {
                        font-size: torem(75px);
                    }
                }
            }
            &-squere {
                width: 100%;
            }
        }

        &-discr {
            flex: 1 1 auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            /*padding-top: torem(9px);*/

            &--name {
                font-weight: $fw-bold;
                font-size: torem(24px);
                line-height: torem(29px);
                align-items: center;
                display: flex;

                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                width: auto;
                .ficon {
                    margin: 0 torem(8px);
                    position: relative;
                    bottom: torem(2px);
                    &:before {
                        font-size: torem(19px);
                        width: auto;
                        margin: 0;
                    }
                }
                .type, .name {
                    flex: 0 0 auto;
                    text-overflow: ellipsis;
                    max-width: 100%;
                    overflow: hidden;

                }
                .name {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 80%;
                    flex: 0 1 auto;
                    &.noTitle {
                        color: $color-ac;
                    }
                }
                .type {
                    color: $blue-18;
                }
            }

            .group {
                font-weight: $fw-semibold;
                font-size: torem(24px);
                line-height: torem(29px);
                display: flex;
                align-items: center;
                color: $color-62;
                margin-top: torem(4px);
                margin-bottom: torem(14px);
            }
            &--top {
                width: 100%;
                display: flex;
                margin-bottom: torem(4px);
                /*padding-top: torem(8px);*/
            }
            &--bottom {
                margin-top: auto;
                display: flex;

            }
            &--left {
                flex: 1 1 auto;
                overflow: hidden;
            }
            &--right {
                margin-left: auto;
                flex: 0 0 auto;
            }
        }
        &-tools {
            display: flex;
            padding: 0;
            margin: 0;
            list-style: none;
            align-items: center;
            justify-content: center;
            /*background-color: #ccc;*/
            li {
                margin: 0 torem(10px);
                &:last-child {
                    margin-right: 0;
                }
                &:first-child {
                    margin-left: 0;
                    /*margin-right: torem(32px);*/
                }
                &.only {
                    margin: 0;
                }
            }
            .tools {

                &-label {
                    font-weight: $fw-bold;
                    font-size: torem(18px);

                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    color: $mainfont;
                    min-width: torem(145px);
                    padding: 0 torem(18px);
                    line-height: torem(36px);

                    border: torem(3px) solid transparent;
                    box-sizing: border-box;
                    border-radius: torem(30px);

                    &.warn, &.blue {
                        border-color: $projectYellow-color;
                    }
                    &.red {
                        border-color: $projectRed-color;
                    }
                    &.gray {
                        border-color: $projectGrey-color;
                    }
                    &.green {
                        border-color: $projectGreen-color
                    }
                }
                &-icon {
                    border-radius: 50%;
                    text-decoration: none !important;
                    text-align: center;
                    justify-content: center;
                    width: torem(28px);
                    height: torem(28px);
                    box-shadow: 0 torem(4px) torem(4px) $editiconshadow;
                    color: $black;
                    display: block;
                    text-align: center;
                    line-height: torem(30px);

                    .ficon {
                        &, &:before {
                            width: 100%;
                            margin-right: 0;
                            margin-left: 0;
                        }
                    }
                }
                &-remove {
                    background-color: transparent;
                    border-radius: 50%;
                    text-decoration: none !important;
                    text-align: center;
                    justify-content: center;
                    width: torem(24px);
                    height: torem(24px);
                    border: none;
                    box-shadow: none;
                    outline: none !important;
                    font-size: torem(24px);
                    color: $red-f3;

                    display: flex;
                    margin: 0 0 torem(-4px) 0;
                    padding: 0;
                    &:disabled {
                        cursor: default;
                        opacity: .5;
                    }
                }
            }

        }
        &-link {
            margin-top: auto;
            margin-left: auto;
            font-weight: $fw-700;
            font-size: torem(18px);
            padding-right: torem(5px);
            //line-height: 22px;
        }
        .trackList {
            &-table {
                padding-top: torem(43px);
            }
            &-col {
                flex: 1 1 auto;
                margin: torem(8px) torem(16px);
                p {
                    margin: 0;
                    font-weight: $fw-medium;
                    font-size: torem(18px);
                    line-height: torem(22px);
                    color: $mainfont;
                }
                &--index {
                    min-width: torem(25px);
                    flex: 0 0 torem(25px);
                }
                &--title {
                    max-width: torem(475px);
                    flex: 1 1 torem(475px);
                }
                &--mArtist {
                    max-width: torem(506px);
                    flex: 1 1 torem(506px);
                }
                &--durat {
                    max-width: torem(93px);
                    flex: 0 0 torem(93px);
                    text-align: right;
                }
            }
            &-row {
                border-bottom: torem(2px) solid $color-c2;
                display: flex;
                &--head {
                    p {
                        font-weight: $fw-bold !important;
                        font-size: torem(20px);
                        line-height: torem(24px);
                        color: $blue-1f;
                    }
                }
            }

        }
    }

    @include media-breakpoint-down(sm) {
        .info {
            &-item {
                font-size: torem(14px);
                margin: 0 torem(9px);
                &.blue {
                    .ficon:before {
                        margin-left: torem(6px);
                    }
                }

            }
        }
        .pitem {
            padding: torem(18px);
            position: relative;
            &-link.main-link {
                font-size: torem(14px);
                padding-right: 0;
                margin-right: torem(15px);
                &:before {
                    margin-left: torem(6px);
                }
            }
            &-coverArt {
                width: torem(127px);
                max-width: torem(127px);
                height: torem(127px);
                margin-right: torem(16px);
            }
            &-coverArt-none {
                i.icon-ycah {
                    font-size: torem(61px);
                }
            }
            &-discr {
                &--name {
                    font-size: torem(18px);
                    line-height: 1;
                    margin: torem(5px) 0;
                    max-width: torem(230px);
                }
                .group {
                    font-size: torem(18px);
                    margin-bottom: torem(6px);
                    line-height: 1;
                }
            }
            &-tools {
                .tools {
                    &-label {
                        min-width: torem(102px);
                        height: torem(32px);
                        font-size: torem(12px);

                    }
                    &-free {
                        display: none;
                    }
                }
            }
            .trackList {
                &-col p {
                    font-size: torem(14px);
                }
            }
            .tools-free {
                position: absolute;
                bottom: 100%;
                right: 0;
                font-size: torem(12px);
                border-radius: torem(4px);
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                height: torem(20px);
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .pitem {
            padding: torem(12px);
            border-radius: torem(8px);
            /*adaptive*/
            &-takeDown{
                background: none;
                height: auto;
                width: auto;
                border: none;
                margin: auto;
                padding: 0;
            }
            &-isFree {
                border-top-right-radius: 0;
            }
            &-tools {
                max-width: 0;
                overflow: hidden;
                transition: max-width .3s;
                li {
                    margin: 0;
                    margin-right: torem(10px) !important;
                    margin-left: torem(10px) !important;
                }
                .tools-remove {
                    font-size: torem(20px);
                    &:before {
                        margin: 0;
                    }
                }
                .tools-icon,
                .tools-remove {
                    line-height: torem(20px);
                    height: torem(20px);
                    width: torem(20px);
                    margin: 0;
                    box-shadow: none;
                    background: transparent;
                }
            }
            .active &-tools {
                max-width: torem(999px);
            }
            &-dots {
                background: transparent;
                border: none;
                box-shadow: none;
                font-size: torem(17px);
                padding: 0;
                margin: auto;
                width: torem(25px);
                min-width: torem(25px);
                border-radius: 50%;
                outline: none;
                &:before {
                    width: auto;
                }

                &-holder {
                    background: $white;
                    display: flex;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    transition: all .3s;
                }
            }
            .active &-dots-holder {
                box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
                border-radius: torem(30px);

            }
            &-discr--right {
                position: relative;
                display: flex;
                width: torem(20px);
                height: torem(25px);
            }
            &-coverArt {
                $size: torem(70px);
                width: $size;
                height: $size;
                max-width: $size;
                margin-right: torem(10px);
                &-none i.icon-ycah {
                    font-size: torem(20px);
                }
            }
            &-discr {
                &--name {
                    max-width: torem(200px);
                    .name, .type {
                        font-size: torem(16px);
                        line-height: torem(20px);
                    }
                    .ficon:before {
                        font-size: torem(14px);
                    }
                }
                .group {
                    font-size: torem(14px);
                    line-height: torem(17px);
                }
            }
            &-status {
                font-size: torem(14px);
                line-height: torem(17px);
                font-weight: $fw-bold;
                &--mt10 {
                    margin-top: torem(10px);
                }
                &.warn, &.blue {
                    color: $projectYellow-color;
                }
                &.red {
                    color: $projectRed-color;
                }
                &.gray {
                    color: $projectGrey-color;
                }
                &.green {
                    color: $projectGreen-color
                }
            }
            &-bottom {
                margin-top: torem(28px);
                &, .info {
                    display: flex;
                }
                p, .pitem-link {
                    white-space: nowrap;
                    color: $mainfont;
                    font-size: torem(13px);
                    line-height: torem(16px);
                    font-weight: $fw-500;
                }

            }
            .trackList-col {
                margin: 0;
                padding: torem(5px);
                overflow: hidden;
                p {
                    font-size: torem(14px);
                    line-height: torem(17px);
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .trackList-row {
                border-bottom: 0;
            }
            .trackList-row--head {
                padding-bottom: torem(5px);
                margin-bottom: torem(5px);
                border-bottom: torem(2px) solid $white-f5f;
            }
            .tools-free {
                /*position: absolute;
                bottom: 100%;
                right: 0;
                font-size: torem(12px);
                border-radius: torem(4px);

                height: torem(20px);*/
                border-radius: torem(8px);
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
</style>
