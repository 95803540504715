<template>
  <div class="notFound container">
    <div class="notFound-wind flex-center">
      <img class="notFound-absolute" src="../../assets/img/404.svg">
      <div class="notFound-content flex-center">
        <img src="../../assets/img/guitar.svg" class="notFound-guitar" alt="some">
        <p class="notFound-h1">
          Ooops...
        </p>
        <p class="notFound-text">
          Something went wrong
        </p>
        <router-link to="/home" class="btn notFound-btn">Go to home page</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "notFound.vue"
  }
</script>

<style lang="scss">
  .notFound {
    
    display: flex;
    flex-direction: row;
    justify-items: stretch;
    flex: 1 1 auto;
    padding-top: torem(55px);
    /*padding-bottom: torem(55px);*/
    &-text{
      margin-top: torem(9px);
    }
    &-h1 {
      font-size: torem(72px);
      color: $mainfont;
      line-height: torem(88px);
      font-weight: $fw-600;
      margin-top: torem(37px);
    }
    &-guitar {
      width: torem(360px);
    }
    &-wind {
      @include obj;
      @include coner;
      display: flex;
      flex: 1 1 100%;
      position: relative;
      z-index: 1;
    }
    &-content {
      flex-direction: column;
    }
    &-absolute {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding-bottom: torem(211px);
      z-index: -1;
      width: torem(731px);
    }
    &-btn{
      margin-top: torem(32px);
      padding: torem(6px) torem(23px);
      
    }
  }
  @include media-breakpoint-down(sm) {
    .notFound{
      max-width: torem(520px);
      &-absolute{
        max-width: 90%;
      }
      &-guitar{
        max-width: 50%;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .notFound{
      &-h1{
        font-size: torem(50px);
      }
    }
  }
</style>