import Backbone from 'backbone';
import CartItemModel from './cart/item';
import CartItemCollection from '../collections/cart-items';
import 'backbone-relational/backbone-relational';
import Products from '@/components/collections/products';
import Store from '@/Store/store';


export default Backbone.RelationalModel.extend({
  relations: [
    {
      type: Backbone.HasMany,
      key: 'cartItems',
      relatedModel: CartItemModel,
      collectionType: CartItemCollection
    }
  ],
  products: null,
  url: '/api/cart',
  defaults: {
    cartItems: [],
    canRemoveItems: true,
    promoCode:null
  },
  getItems: function () {
    return this.get('cartItems');
  },

  isEmpty: function () {
    return this.getItems().isEmpty();
  },

  getPromocode: function () {
    return this.get('promoCode');
  },

  addPromo(promo) {
    var self = this;
    return Backbone.$.ajax({
      type: 'POST',
      url: '/api/cart/promo-code',
      data: {code: promo}
    })
  },
  removePromo(){
    return Backbone.$.ajax({
      type: "DELETE",
      url: "/api/cart/promo-code"
    })
  },

  getPrice: function () {
    var items = this.getItems();
    var totalAmount = 0;
    items.forEach(function (item) {
      totalAmount += item.getSubPrice();
    });
    return (totalAmount).toFixed(2);
  },

  getDiscountAmount: function () {
    var items = this.getItems();
    var totalDiscountAmount = 0;
    items.forEach(function (item) {
      totalDiscountAmount += item.getDiscountAmount();
    });
    return (totalDiscountAmount).toFixed(2);
  },

  canRemoveItems: function () {
    return this.get('canRemoveItems');
  },

  async hasUserProduct(productId) {
    let product = await Store.dispatch('user-products/loadProductList').then(list => {
      return list.findWhere({productId: productId, active: true});
    });

    return product != null;
  },

  getItemsByType(types) {
    let res = [];
    this.attributes.cartItems.each((item) => {
      let flag = types.some(type => {
        return item.attributes.product && type === item.attributes.product.attributes.type
      });
      if (flag) {
        res.push(item);
      }
    });
    return res;
  },

  async addItemToCart(product, productAttributes, successCallback, errorCallback) {
    //check if product is already in cart
    let existingItem = this.getItemBy({id: product.id});
    if (existingItem) {
      if (typeof successCallback !== 'function') {
        return;
      }
      successCallback();
      return;
    }
    if (typeof productAttributes === 'function') {
      errorCallback = successCallback;
      successCallback = productAttributes;
      productAttributes = {};
    }
    let self = this;
    /*
    * check repeat items or product has reqProduct */
    if (product.getRequireProductId() || product.attributes.group || product.attributes.type) {
      /* get product list */
      if (!this.products) {
        this.products = new Products();
        await this.products.fetch({
          error(collection, error) {
            errorCallback(error);
          }
        });
      }
      /*
      * add requireProductId*/
      if (product.getRequireProductId()
        &&  !(await this.hasUserProduct(product.getRequireProductId()) )
      ) {
        let requireItem = this.products.where({id: product.attributes.requireProductId})[0];
        await this.addItemToCart(requireItem, null, errorCallback);
      }
      /*
      *check and remove same group or type item */
      if (product.attributes.group || product.attributes.type ) {
        for (const cartItem of this.attributes.cartItems.models) {
          var cartProduct = self.products.where({id: cartItem.productId})[0];
          if (
            cartProduct &&
            (
              cartProduct.attributes.group && product.attributes.group &&
              cartProduct.attributes.group.id === product.attributes.group.id

            ) || (
                  cartProduct.attributes.type && product.attributes.type &&
                  cartProduct.attributes.type === product.attributes.type
              )
          ) {
            await self.removeItem(cartItem, null, errorCallback);
          }
        }
      }
    }
    var cartItem = new CartItemModel({
      product: product,
      productId: product.attributes.id,
      productAttributes: productAttributes,
    });
    /*
    * add item*/
    return cartItem.save(null).then(async () => {
      await this.fetch();
      if (typeof successCallback !== 'function') {
        return;
      }
      successCallback()
    }).catch(e => {
      if (typeof errorCallback !== 'function') {
        return;
      }
      errorCallback(e)
    });
  },

  async removeItem(cartItem, successCallback, errorCallback) {
    // todo can i remove
    // todo: mb massive remove
    let self = this;
    let product = cartItem.getProduct();
    return cartItem.destroy({
      wait: true,
      success: async function (model, resp, options) {
        await self.fetch();
        if (typeof successCallback !== 'function') {
          return;
        }

        // @workaround see cart/item.initialize()
        model.set('product', product);
        successCallback(model, resp, options);
      },
      error: function (model, xhr, sync) {
        if (typeof errorCallback !== 'function') {
          return;
        }
        errorCallback(model, xhr, sync);
      }
    });
  },

  getItemBy(prop) {
    let ret = false;
    this.attributes.cartItems.models.some(cartItem => {
      for (let a in prop) {
        if (cartItem.attributes.product.attributes[a] === prop[a]) {
          ret = cartItem;
          return true
        }
      }
    });
    return ret
  }

});
