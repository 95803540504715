import $ from "jquery"
import Config from './config'

let Captcha = function () {
};

Captcha.prototype = {
  captchaScriptUrl: "https://www.google.com/recaptcha/api.js",
  isInitialized: false,
  isInitializing: false,
  grecaptcha: null,
  captchaId: 'captcha',

  isDefine: function () {
    return typeof (this.grecaptcha) == 'object'
  },

  init: function () {
    var self = this;
    var captchaKey = Config.get('recaptchaPublic');

    if (this.isInitialized) {
      console.warn('Captcha already initialized. Call refresh instead');

      return;
    }
    if (this.isInitializing) {
      console.warn('Captcha already being initializing.');

      return;
    }

    if (!captchaKey) {
      console.error('Key for captcha is not found');

      return;
    }

    if (this.grecaptcha) {
      this.initProcess(captchaKey);
      // rerender captcha

      return;
    }

    this.isInitializing = true;

    $.getScript(this.captchaScriptUrl)
      .done(function () {
        // give time to execute
        var setIntervalID = setInterval(function () {
          if (window.grecaptcha) {
            self.grecaptcha = window.grecaptcha;
            window.grecaptcha = null;
            clearInterval(setIntervalID);
            self.initProcess(captchaKey);
          } else {
            console.log('not grecaptcha found');
          }
        }, 300);
      });
  },

  initProcess: function (captchaKey) {
    if (!this.isDefine()) {
      console.error('Captcha is not define');
      return;
    }
    console.log(window.grecaptcha);
    console.log(this.grecaptcha);
    this.grecaptcha.render(this.captchaId, {
      'sitekey': captchaKey
    });

    this.isInitialized = true;
    this.isInitializing = false;
    console.info('Captcha has been initialized');
  },

  /**
   * Get new captcha from google
   */
  refresh: function () {
    if ($('#' + this.captchaId).children().length == 0) {
      this.isInitialized = false;
    }
    if (!this.isInitialized) {
      this.init();

      return;
    }
    this.grecaptcha.reset();
  },

  getResponse: function() {
    if (this.grecaptcha && this.grecaptcha.getResponse) {
      return this.grecaptcha.getResponse();
    }
  },

  destroy: function() {
    $('#' + this.captchaId).html('');
    this.grecaptcha.reset();
    this.isInitialized = false;
  },
};

export default Captcha;

