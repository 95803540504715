<template>
    <div class="notiffication" v-html="html">
    
    </div>
</template>

<script>
    export default {
        name: "notiffication",
        props:['html']
    }
</script>

<style lang="scss">
    .notiffication{
        padding: 0 torem(24px);
        border-radius: torem(4px);
        background: $white-f5f;
        display: flex;
        flex-direction: column;
        height: torem(74px);
        justify-content: center;
        position: relative;
        margin-left: torem(4px);
        text-align: left;
        &:before{
            position: absolute;
            top: 0;
            bottom: 0;
            left: torem(-4px);
            background: $blue-18;
            width: torem(4px);
            content: '';
            height: 100%;
            border-radius: torem(4px);
        }
        p{
            font-size: torem(14px);
            line-height: torem(23px);
            color: $mainfont;
            text-align: left;
        }
    }
    @include media-breakpoint-down(xs) {
        .notiffication {
            padding: torem(13px) torem(12px);
            background-color: $blue-18-opacity;
            border-radius: 0;
            &:before {
                display: none;
            }
            p{
                font-size: torem(14px);
                line-height: torem(23px);
                
            }
        }
    }
</style>