<template>
    <div class="inputBtn">
        <div class="inputBtn-title" v-if="title">
            <p>{{title}}</p>
        </div>
        <div class="inputBtn-input" :class="{'inputBtn-error':error}">
            <input type="text"
                   key="inputSearch"
                   ref="input"
                   v-model="typeValue"
                   :placeholder="placeholder"
                   @focus="onFocus"
                   @keydown.enter="onEnterPress($event)"
                   @keyup.38="onKeyUpArrow"
                   @keyup.40="onKeyDownArrow"
                   @keyup.esc="onEscPress"
                   @input="onInput($event)">
            <ul ref="list" v-if="isShowModal" class="inputBtn-list">
                <li :class="['inputBtn-item',{'highLight': highLightIndex === index}] "
                    :key="'item-'+index"
                    @click="selectItem(item)"
                    @mouseover="onMouseover(index)"
                    v-for="(item,index) in autoComplitteFilter">
                    {{item}}
                </li>
            </ul>
            <vue-tooltipster
                v-if="error"
                class="inputBtn-tooltip"
                themeClass="slotTT-ttError-tooltipster"
                :label="error">
                <i class="ficon icon-ic_error tooltipstered"></i>
            </vue-tooltipster>
            <button @click="onBtnClick"
                    class="btn inputBtn-btn ficon icon-plus" :disabled="disabled">
                {{textBtn || 'Apply'}}
            </button>
        </div>
    </div>
</template>

<script>
    import vueTooltipster from '@/components/vue-tooltipster';

    export default {
        name: "inputBtn",
        props: ['title', 'value', 'textBtn', 'placeholder', 'disabled', 'error', 'autoCompleteData'],
        data() {
            return {
                typeValue: '',
                modal: false,
                autoCompleteList: null,
                highLightIndex: -1,
            }
        },
        methods: {
            onEscPress() {
                this.highLightIndex = -1;
                this.modal = false;
            },
            onInput() {
                this.modal = true;
            },
            onBtnClick(e) {
                this.$emit('click', this.typeValue);
                this.typeValue = null;
                this.modal = false;
            },
            onEnterPress() {
                if (this.highLightIndex >= 0 && this.autoComplitteFilter.length) {
                    this.selectItem(this.autoComplitteFilter[this.highLightIndex]);
                }
                else {
                    this.onBtnClick();
                }
            },
            onFocus() {
                this.modal = true;
                document.addEventListener('click', this.onOuterClick);
            },
            onMouseover(index) {
                this.highLightIndex = index;
            },
            onKeyUpArrow() {
                this.listItemHighLight(-1);
            },
            onKeyDownArrow() {
                this.typeValue = this.typeValue || '';
                this.modal = true;
                this.listItemHighLight(1);
            },
            listItemHighLight(value) {
                this.highLightIndex += value;
                if (this.highLightIndex >= this.autoComplitteFilter.length) {
                    this.highLightIndex = 0;
                }
                else if (this.highLightIndex <= -1) {
                    this.highLightIndex = this.autoComplitteFilter.length - 1;
                }
                ;
                this.scroolToElement()

            },
            onOuterClick(event) {
                if (!this.$el.contains(event.target)) {
                    this.modal = false;
                    document.removeEventListener("click", this.onOuterClick, true);
                }
            },
            selectItem(item) {
                this.typeValue = item;
                this.modal = false;
            },
            scroolToElement() {
                if (!this.$refs.list) {
                    return
                }
                let hightLightElement = this.$refs.list.querySelectorAll('li')[this.highLightIndex].offsetTop,
                    scrollWiev = this.$refs.list.scrollTop + (this.$refs.list.offsetHeight / 2);
                if (hightLightElement > scrollWiev) {
                    this.$refs.list.scrollTop = hightLightElement - (this.$refs.list.offsetHeight / 2);
                } else if (hightLightElement < this.$refs.list.scrollTop) {
                    this.$refs.list.scrollTop = hightLightElement;
                }
            }
        },
        computed: {
            autoComplitteFilter() {
                return this.autoCompleteData && this.autoCompleteData.filter(data => {
                    return data.toLowerCase().startsWith(this.typeValue);
                })
            },
            isShowModal() {
                let res = !!this.autoComplitteFilter && !!this.autoComplitteFilter.length && !!this.modal;
                if (!res) {
                    this.highLightIndex = -1;
                }
                return res
            }
        },
        components: {
            vueTooltipster,
        }
    }
</script>

<style lang="scss">
    .inputBtn {
        display: flex;
        flex-direction: column;
        position: relative;
        &-title {
            p {
                margin: 0;
                font-weight: $fw-bold;
                font-size: torem(20px);
                line-height: torem(24px);
            }
            margin-bottom: torem(10px);
        }
        &-input {
            border: torem(1px) solid $color-62;
            box-sizing: border-box;
            border-radius: torem(4px);
            overflow: hidden;
            display: flex;
            line-height: torem(46px);
            align-items: center;
            padding: torem(5px);
            
            input {
                border: none;
                width: 100%;
                padding: 0 torem(10px);
                line-height: 1;
                font-size: torem(17px);
                font-weight: $fw-500;
                min-width: torem(1px);
                background-color: transparent;
            }
        }
        &-error {
            border-color: $red-f3;
            input {
                color: $red-f3;
            }
        }
        &-tooltip {
            right: torem(22px);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: torem(18px);
            margin: 0 torem(10px);
            line-height: 1;
            &.tooltipster .ficon {
                color: $errofontColor;
            }
            &-tooltipster .tooltipster-content {
                font-weight: $fw-medium;
                color: $errofontColor
            }
            
        }
        &-btn {
            font-weight: $fw-bold;
            font-size: torem(18px);
            /*line-height: torem(32px);*/
            padding: 0 torem(22px);
            margin: 0;
            width: auto;
            min-width: torem(84px);
            flex: 1 1 auto;
            outline: none !important;
            height: torem(38px);
            &:before {
                display: none;
            }
        }
        &-list {
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            z-index: 10;
            list-style: none;
            padding: 0;
            margin: torem(3px) 0;
            border: torem(1px) solid $multiselectContentWrapper-BorderColor;
            border-radius: torem(4px);
            background-color: $multiselectContentWrapper-BGcolor;
            color: $multiselectOption-Color;
            overflow: auto;
            max-height: torem(300px);
            li {
                font-size: torem(17px);
                line-height: torem(28px);
                white-space: nowrap;
                padding: torem(1px) torem(12px);
                &:hover, &.highLight {
                    background-color: $multiselectOptionSelectedHighlight-BGcolor;
                    color: $multiselectOptionSelectedHighlight-Сolor;
                }
            }
        }
    }
    
    @include media-breakpoint-down(sm) {
        .inputBtn {
            &-btn {
                font-size: 0;
                min-width: torem(30px);
                width: torem(30px);
                height: torem(30px);
                padding: 0;
                margin: torem(4px) 0;
                &:before {
                    display: block;
                    font-size: torem(15px);
                    margin: 0 0 torem(1px) 0;
                }
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .inputBtn {
            
            width: 100%;
            &-input {
                border: none;
                padding: 0;
                line-height: 1;
                input {
                    border: torem(1px) solid $color-62;
                    border-radius: torem(4px);
                    line-height: torem(36px);
                    font-size: torem(14px);
                }
            }
            &-error {
                border-color: $red-f3;
            }
            &-btn {
                $size: torem(38px);
                flex: 0 0 $size;
                width: $size;
                height: $size;
                margin: 0 0 0 torem(16px);
            }
            &-error &-btn {
                margin-left: 0;
            }
        }
        
    }
</style>