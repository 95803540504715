<template>
    <input ref="tinput" @change="onKeyup" @blur="onKeyup" :disabled="isDisabled" />
</template>
<script>
    import 'jquery-ui/ui/widgets/spinner';

    export default {
        props: {
            value: {},
            min: {
                default: null,
                type: Number
            },
            max: {
                default: null,
                type: Number
            },
            disabled: {
                default: false,
                type: Boolean
            }
        },
        mounted: function () {
            this.init();
        },
        methods: {
            init() {
                let self = this;

                $(this.$el).spinner({
                    min: self.min,
                    max: self.max,
                    icons: {
                        down: "ui-spinner-button-down-icon ficon icon-arrowleft ",
                        up: "ui-spinner-button-up-icon ficon icon-arrowright"
                    },
                    spin: function (event, ui) {
                        self.$emit('input', ui.value);
                    }
                });
                $(self.$el).spinner("value", (self.value || self.min));
                $(self.$el).spinner({disabled: self.disabled});
            },
            refresh() {
                this.destroy();
                this.init();
                this.onKeyup();
            },
            destroy() {
                $(this.$el).spinner("destroy");
            },
            onKeyup() {
                let
                    text = this.$refs.tinput.value.replace(/[^\dA-Z]/g, ''),
                    res = Number.parseInt(text || 0);
                if (!res) {
                    this.$refs.tinput.value = this.min;
                    this.$emit('input', this.min);
                    return
                }
                this.$refs.tinput.value = text;
                if (res > this.max) {
                    res = this.max
                }
                if (res < this.min) {
                    res = this.min
                }
                this.$refs.tinput.value = res;
                this.$emit('input', res);
            },

        },
        computed: {
            isDisabled() {
                $(this.$el).spinner({disabled: this.disabled});
                return this.disabled
            }
        }
    }
</script>
