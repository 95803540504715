<template>
    <ValidationObserver
        ref="observer"
        class="sform"
        tag="form"
        @submit.prevent=""
        @keydown.enter = "onClick()"
        v-slot="{ invalid }">
        <div class="row">
            <!--firstName-->
            <div class="col-12">
                <vinput
                    ref="paypalEmail"
                    class="sform-input"
                    key="Email"
                    :value="paypalEmail"
                    :ttInfoOptions="{contentAsHTML:true}"
                    title="Email"
                    VPRules="required|email"
                    inputType="text"
                    inputDisplayName="Paypal Email"
                    inputName="paypalEmail"
                    @input="onInput($event,$refs.paypalEmail,payment)"
                />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button ref="btn" class="btn sform-btn" :disabled="(isOldValues && isOldMethod) || invalid" @click="savePaymentInfo">
                    Save payment Info
                </button>
            </div>
        </div>
    </ValidationObserver>
</template>


<script>
    import vinput from '@/components/input-tt';
    import {ValidationProvider, ValidationObserver} from 'vee-validate';
    import {onInput} from '../mixins/onInput';
    import {pay} from '../mixins/paymentMixins';
    export default {
        name: "paypal-form",
        props: ['payment','paymentMethod'],
        mixins:[onInput,pay],
        data(){
            return {
                paypalEmail:null,
                isOldValues:true,
            }
        },
        mounted(){
            this.paypalEmail = this.payment.get('paypalEmail');
        },
     
        components:{
            ValidationProvider,
            ValidationObserver,
            vinput
        }
    }
</script>

