import Cart from '@/components/models/cart';

export default {
  state: {
    cartId: false,
    loadingPromise: false
  },
  getters: {
    cart(state) {
      if (!state.cartId) {
        return null;
      }
      return Backbone.Relational.store.find(Cart, state.cartId);
    },
  },
  mutations: {
    setcartId(state, data) {
      state.cartId = data;
      state.loadingPromise = false;
    },
    startLoading(state, promise) {
      state.loadingPromise = promise;
    }
  },
  actions: {
    async loadCart(store) {
      if (store.state.cartId) {
        return store.getters.cart;
      }
      if (store.state.loadingPromise) {
        return store.state.loadingPromise;
      }
      let cart = new Cart();
      let loadingPromise = cart.fetch().then(function (e) {
        store.commit("setcartId", e.id);

        return store.getters.cart;
      }).catch(error => {

        return {error: error.response};
      });
      store.commit("startLoading", loadingPromise);

      return loadingPromise;
    }
  }
}

