<template>
    <div class="ttable">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "projectTable",
        mounted() {
            if (this.$route.path === '/project') {
                this.$router.push('/project/list');
            }
        },
        updated() {
            if (this.$route.path === '/project') {
                this.$router.push('/project/list');
            }
        }
    }
</script>

<style lang="scss">
    
    .ttable {
        display: flex;
        flex: 1 1 auto;
        & > .container {
            flex: 1 1 auto;
        }
    }
</style>