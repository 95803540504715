import Backbone from 'backbone';
import _ from 'underscore';
import UserModel from './user';
import LogoModel from './label/logo';
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
    relations: [
        {
            type: Backbone.HasOne,
            key: 'user',
            relatedModel: UserModel
        },
        {
            type: Backbone.HasOne,
            key: 'logo',
            relatedModel: LogoModel
        }
    ],

    url: "/api/label",

    defaults: {
        name: '',
        isEdm: false,
        websiteUrl: null,
        facebookUrl: null,
        twitterUrl: null,
        instagramUrl: null,
        logo: null,
        isRealized: false
    },

    required: [
        'name'
    ],

    getId: function () {
        return this.get("id");
    },

    getName: function () {
        return this.get('name');
    },

    isEDM: function () {
        return this.get('isEdm');
    },

    getWebsiteUrl: function () {
        return this.get('websiteUrl');
    },

    getFacebookUrl: function () {
        return this.get('facebookUrl');
    },

    getTwitterUrl: function () {
        return this.get('twitterUrl');
    },

    getInstagramUrl: function () {
        return this.get('instagramUrl');
    },

    isRealized: function () {
        return this.get('isRealized');
    },

    hasLogo: function () {
        return !!this.getLogo();
    },

    getLogo: function () {
        return this.get('logo');
    },

    saveModel: function (options) {
        var attributes = {
            websiteUrl: this.getWebsiteUrl(),
            facebookUrl: this.getFacebookUrl(),
            twitterUrl: this.getTwitterUrl(),
            instagramUrl: this.getInstagramUrl()
        };

        options = options || {};
        options.patch = this.isRealized();

        return this.save(attributes, options);
    },

    validate: function (attributes) {
        var errors = {},
            urlPattern = /^https?:\/\/(www\.)?[-a-zA-Z0-9:.\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
            requiredFields = this.required.slice();
        var field;

        if (this.isEDM()) {
            requiredFields.push('logo');
        }

        for (field in attributes) {
            // check required

            if ((Backbone.$.inArray(field, requiredFields) !== -1) && !attributes[field]) {
                errors[field] = 'Field is required';
            } else if (attributes[field]) {
                // check links
                if ((field.indexOf("Url") !== -1) && !urlPattern.test(attributes[field])) {
                    errors[field] = 'This value is not a valid URL';
                }
            }
        }

        // return errors if it was any
        if (Object.keys(errors).length > 0) {
            return errors;
        }
    },

    toJSON: function () {
        var newAttrs = _.clone(this.attributes);

        delete newAttrs.user;

        return newAttrs;
    }
});
