<template>
  <div class="container ">
    <div class="row">
      <div class="col-12">
        <h1 class="main-h1">
          Cover art builder
        </h1>
      </div>
    </div>
    
  </div>

</template>

<script>
  export default {
    name: "cover-page"
  }
</script>

<style lang="scss">

</style>