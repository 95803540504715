<template>
    <div class="marketingItem mobile">
        <div class="mobile-header">
            <div class="mobile-header-top">
                <div class="mobile-title">
                    <slot name="item-title">
                    
                    </slot>
                
                </div>
                <div class="mobile-status" :class="status.class">
                    {{status.text}}
                </div>
                <div class="mobile-tools " :class="{'active':isShowing}">
                    <div class="mobile-tools-holder">
                        <div class="mobile-tools-list">
                            <router-link :to="showUrl" class="mobile-btn mobile-view ficon icon-eye"></router-link>
                            <router-link :to="editUrl" v-if="isEditable"
                                         class="mobile-btn mobile-edit ficon icon-edit"></router-link>
                        </div>
                        <button class="mobile-tools-button ficon icon-dots" @click="toggleHider"></button>
                    </div>
                </div>
            </div>
            <div class="mobile-header-bottom">
                <slot name="date"></slot>
            </div>
        </div>
        <div class="mobile-cont">
            <div class="mobile-item">
                <div class="mobile-item-title">
                    <slot name="item-head-left"></slot>
                </div>
                <div class="mobile-item-cont">
                    <slot name="item-cont-left"></slot>
                </div>
            </div>
            
            <div class="mobile-item">
                <div class="mobile-item-title">
                    <slot name="item-head-right"></slot>
                </div>
                <div class="mobile-item-cont">
                    <slot name="item-cont-right"></slot>
                </div>
            </div>
        
        </div>
    </div>
</template>
<script>
    import {toggle} from './../../settings/mixins/toggle'

    export default {
        name: "item-mobile-slot",
        props: ['status', 'isEditable', 'editUrl', 'showUrl'],
        mixins: [toggle]
    }
</script>

<style lang="scss">
    .mobile {
        background: $white;
        box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
        border-radius: torem(4px);
        height: auto;
        min-height: 0;
        .text-empty {
            color: $color-ac !important;
        }
        &-title {
            display: flex;
            font-weight: $fw-600;
            font-size: torem(16px);
            line-height: torem(20px);
            overflow: hidden;
            flex: 0 0 auto;
            max-width: 68%;
            p {
                flex: 0 1 auto;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: $mainfont;
            }
            .split {
                width: torem(3px);
                height: torem(12px);
                background-color: $mainfont;
                margin: auto torem(4px);
                display: inline-block;
            }
        }
        &-header {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            &-top {
                display: flex;
                width: 100%;
                align-items: center;
            }
            &-bottom {
                width: 100%;
                p {
                    font-size: torem(10px);
                    line-height: torem(12px);
                }
            }
        }
        &-status {
            border: torem(2px) solid $color-94;
            box-sizing: border-box;
            border-radius: torem(30px);
            margin-left: torem(10px);
            margin-right: auto;
            font-weight: $fw-bold;
            font-size: torem(10px);
            line-height: torem(12px);
            padding: torem(2px) torem(8px);
            display: flex;
            align-items: center;
            &.rouge, &.filled {
                border-color: $color-f4;
            }
            &.grey, &.draft {
                border-color: $color-94;
            }
            &.blue, &.processed {
                border-color: $blue-18;
            }
            &.green {
                border-color: $color-51;
            }
        }
        &-tools {
            position: relative;
            $size: torem(25px);
            width: $size;
            height: $size;
            &-holder {
                background: $white;
                display: flex;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                transition: all .3s;
            }
            
            &-button {
                width: $size;
                height: $size;
                padding: 0;
                margin: 0;
                background: transparent;
                border: none;
                box-shadow: none;
                display: inline-block;
                font-size: torem(17px);
                color: $blue-1f;
                
            }
            &-list {
                
                display: flex;
                overflow: hidden;
                max-width: 0;
                transition: max-width .4s;
                align-items: center;
                a,a:hover,a:focus {
                    text-decoration: none;
                    color: $blue-1f;
                    font-size: torem(17px);
                }
            }
            &.active &-holder {
                box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
                border-radius: torem(30px);
            }
            &.active &-list {
                max-width: torem(100px);
            }
        }
        &-cont {
            display: flex;
            margin-top: torem(22px);
        }
        &-item {
            flex: 1 1 50%;
            max-width: 50%;
            &-title{
                font-weight: bold;
                font-size: torem(12px);
                line-height: torem(15px);
            }
            &-cont{
                font-size: torem(10px);
                line-height: torem(12px);
            }
        }
    }
</style>