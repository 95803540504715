<template>
    <div class="ttip">
        <div class="ttip-top">
            <vue-tooltipster
                    v-if="ttipText"
                    :tooltipsterOptions="{contentAsHTML:true}"
                    class="ttip-info"
                    :label="ttipText">
                <i class="ficon icon-info"></i>
            </vue-tooltipster>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import vueTooltipster from '@/components/vue-tooltipster';

    export default {
        name: "ttipSlot",
        props:['ttipText'],
        components: {
            vueTooltipster
        }
    }
</script>

<style lang="scss">
    .ttip {
        position: relative;
        &-info {
            position: absolute;
            font-size: torem(18px);
            cursor: pointer;
            top: 0;
            left: 0;
            bottom: auto;
            margin: auto;
        }
        &-top {
            flex: 1 1 auto;
            width: 100%;
            display: flex;
            padding-left: torem(26px);
            position: relative;
            margin-left: torem(-26px);
        }
    }
</style>