<template>
    <div class="servItem">
        <div class="servItem-top">
            <div class="servItem-h1">
                <slot name="h1"></slot>
            </div>
            <div class="servItem-h2">
                <slot name="h2"></slot>
            </div>
        </div>
        
        <div class="servItem-squere d-none d-sm-flex">
            <slot name="squere"></slot>
        </div>
        
        <div class="servItem-bottom">
            <slot name="btn"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "services-item",
        props: ['item']
    }
</script>

<style lang="scss">
    .servItem {
        @include obj;
        @include coner;
        box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        padding: torem(101px) torem(82px);
        margin-bottom: torem(50px);
        &-top {
            text-align: center;
            max-width: torem(622px);
            margin-left: auto;
            margin-right: auto;
        }
        &-h1 {
            text-align: center;
            p, .h1 {
                font-weight: $fw-extrabold;
                font-size: torem(32px);
                line-height: torem(39px);
                color: $mainfont;
                display: inline-block;
                text-align: center;
                margin: 0;
                margin-bottom: torem(18px);
                span {
                    color: $blue-18;
                }
            }
        }
        &-h2 {
            p, .h2 {
                font-weight: $fw-medium;
                font-size: torem(18px);
                line-height: torem(26px);
                text-align: center;
                color: $color-62;
                margin: 0;
            }
        }
        &-squere {
            margin: torem(80px);
            display: flex;
            align-items: stretch;
            margin-left: torem(-25px);
            margin-right: torem(-25px);
            .squere {
                flex: 0 0 torem(330px);
                margin: 0 torem(25px);
                text-align: center;
                border: torem(1px) solid rgba(172, 189, 203, .6);
                box-sizing: border-box;
                border-radius: torem(4px);
                padding: torem(46px) torem(20px);
                .ficon {
                    font-size: torem(62px);
                    margin: auto;
                    margin-bottom: torem(32px);
                    display: inline-block;
                    color: $color-f4;
                    width: auto;
                    line-height: 1;
                    &:before {
                        margin: 0;
                        width: auto;
                    }
                }
                .title {
                    font-weight: $fw-bold;
                    font-size: torem(24px);
                    line-height: torem(29px);
                    text-align: center;
                    color: $mainfont;
                    margin-bottom: torem(11px);
                }
                .discr {
                    font-size: torem(14px);
                    line-height: torem(19px);
                    text-align: center;
                    color: $color-62;
                }
            }
        }
        &-bottom {
            .btn {
                width: torem(660px);
                max-width: 100%;
                margin: auto;
                height: torem(58px);
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .servItem {
            padding: torem(36px) torem(28px) torem(55px);
            &-squere {
                flex-wrap: wrap;
                margin-left: torem(-12px);
                margin-right: torem(-12px);
                margin-top: torem(24px);
                margin-bottom: torem(47px);
                .squere {
                    max-width: torem(292px);
                    margin: torem(6px);
                    flex: 1 1 auto;
                    padding: torem(53px) torem(37px);
                    .title {
                        font-size: torem(20px);
                        line-height: torem(24px);
                        margin-bottom: torem(12px);
                    }
                    .discr {
                        font-size: torem(12px);
                    }
                }
            }
            &-h1 p, &-h1 .h1 {
                font-size: torem(20px);
                line-height: torem(24px);
                margin-bottom: torem(12px);
            }
            &-h2 p, &-h2 .h2 {
                font-size: torem(14px);
                line-height: torem(21px);
            }
        }
    }
    @include media-breakpoint-down(xs){
        .servItem{
            &-h1{
                font-size: torem(22px);
                line-height: torem(32px);
                text-align:left;
                span
                {
                    text-align: left;
                    display: block;
                }
            }
            &-h2{
                font-size: torem(14px);
                line-height: torem(21px);
                display: flex;
                text-align: left;
                margin-top: torem(8px);
                margin-bottom: torem(20px);
                p,.h2{
                    text-align: left;
                }
            }
            &-top{
                margin: 0;
                width: 100%;
                max-width: 100%;
            }
        }
    }
</style>