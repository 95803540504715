<template>
    <div class="col-12">
      <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
        class="mondoEdit"
        tag="form"
        @submit.prevent=""
      >
        
        <div class="mondoEdit-row row">
          <router-link  v-if="load" to="/marketing/bio" class="mondoblastShow-close ficon icon-subtract"></router-link>
          <div class="mondoEdit-col col-12 ">
            <p class="mondoEdit-h1">
              Artist Custom Bio
            </p>
          </div>
        </div>
        <template v-if="load">
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-md-6 ">
              <p class="mondoEdit-h2">
                1. Please answer the Artist Bio Information Form
              </p>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 ">
              <note>
                <p>
                  Please include information which may appear in your press release only. Any or all of the below may
                  <br>
                  appear in the global press.
                </p>
              </note>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                VPRules="required|max:255"
                VPtitle="stageName"
                title="What is your current stage name?*"
              >
                <input @change="checkForm()" :value="model.get('stageName')" @input="model.set('stageName',$event.target.value)" type="text">
              </inputErr>
            </div>
            <div class="mondoEdit-col col-12 col-md-6 ">
              <inputErr
                VPtitle="recordLabel"
                VPRules="max:255"
                title="What are the names of your record labels, if any?"
              >
                <input @change="checkForm()" :value="model.get('recordLabel')" @input="model.set('recordLabel',$event.target.value)" type="text">
              </inputErr>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 ">
              <inputErr
                VPRules="required|max:255"
                VPtitle="contributors"
                VPClass="textArea"
                title="How many artists are in your band, or how many contributed to your release? What are their names and jobs?*"
              >
                  <textarea @change="checkForm()" class="mondoEdit-area" :value="model.get('contributors')" @input="model.set('contributors',$event.target.value)">
                  </textarea>
              </inputErr>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-md-6 ">
              <p class="mondoEdit-h2">
                2. Please list some of your artistic influences
              </p>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 col-md-6">
              <inputErr
                VPRules="required|max:255"
                VPtitle="musicCategories"
                VPClass="textArea"
                title="What music categories best describe your music?*"
              >
                  <textarea @change="checkForm()" class="mondoEdit-area" :value="model.get('musicCategories')" @input="model.set('musicCategories',$event.target.value)">
                  </textarea>
              </inputErr>
            </div>
            <div class="mondoEdit-col col-12 col-md-6">
              <inputErr
                VPRules="required|max:255"
                VPtitle="musicDescription"
                VPClass="textArea"
                title="How would you describe your music?*"
              >
                  <textarea @change="checkForm()" class="mondoEdit-area" :value="model.get('musicDescription')" @input="model.set('musicDescription',$event.target.value)">
                  </textarea>
              </inputErr>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 col-md-6">
              <inputErr
                VPRules="required|max:255"
                VPtitle="musicTopics"
                VPClass="textArea"
                title="Generally, what are the intended meanings, themes, topics and/or inspirations for your music?*"
              >
                  <textarea @change="checkForm()" class="mondoEdit-area" :value="model.get('musicTopics')" @input="model.set('musicTopics',$event.target.value)">
                  </textarea>
              </inputErr>
            </div>
            <div class="mondoEdit-col col-12 col-md-6">
              <inputErr
                VPtitle="previousReleases"
                VPClass="textArea"
                VPRules="required|max:255"
                title="What are the names of your previous releases and what years were they released in, approximately?"
              >
                  <textarea @change="checkForm()" class="mondoEdit-area" :value="model.get('previousReleases')" @input="model.set('previousReleases',$event.target.value)">
                  </textarea>
              </inputErr>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 col-md-6">
              <inputErr
                VPRules="required|max:255"
                VPtitle="location"
                VPClass="textArea"
                title="Where did you start playing music, and where do you live now?*"
              >
                  <textarea @change="checkForm()" class="mondoEdit-area" :value="model.get('location')" @input="model.set('location',$event.target.value)">
                  </textarea>
              </inputErr>
            </div>
            <div class="mondoEdit-col col-12 col-md-6">
              <inputErr
                VPRules="required|max:255"
                VPtitle="playedIn"
                VPClass="textArea"
                title="What cities, states, and/or countries have you played in? "
              >
                  <textarea @change="checkForm()" class="mondoEdit-area" :value="model.get('playedIn')" @input="model.set('playedIn',$event.target.value)">
                  </textarea>
              </inputErr>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 col-md-6">
              <inputErr
                VPRules="required|max:255"
                VPtitle="enjoyAboutListening"
                VPClass="textArea"
                title="What do you enjoy about listening to music?*"
              >
                  <textarea @change="checkForm()" class="mondoEdit-area" :value="model.get('enjoyAboutListening')" @input="model.set('enjoyAboutListening',$event.target.value)">
                  </textarea>
              </inputErr>
            </div>
            <div class="mondoEdit-col col-12 col-md-6">
              <inputErr
                VPRules="required|max:255"
                VPtitle="reasonToMakeMusic"
                VPClass="textArea"
                title="Why do you make music?* "
              >
                  <textarea @change="checkForm()" class="mondoEdit-area" :value="model.get('reasonToMakeMusic')" @input="model.set('reasonToMakeMusic',$event.target.value)">
                  </textarea>
              </inputErr>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 ">
              <p class="mondoEdit-h2">
                3. Please note some of your musical highlights or accomplishments.
              </p>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col mondoEdit-title-bottom col-12 col-md-6">
              <inputErr
                VPRules="required|max:255"
                VPtitle="existsBio"
                VPClass="textArea"
                title="Please copy and paste an existing artist bio you may have"
              >
                  <textarea @change="checkForm()" class="mondoEdit-area" :value="model.get('existsBio')" @input="model.set('existsBio',$event.target.value)">
                  </textarea>
              </inputErr>
            </div>
            <div class="mondoEdit-col col-12 col-md-6">
              <inputErr
                VPRules="required|max:255"
                VPtitle="message"
                VPClass="textArea"
                title="Is there anything else you would like to say on the record for your press release, such as a message to your audience and fans?"
              >
                  <textarea @change="checkForm()" class="mondoEdit-area" :value="model.get('message')" @input="model.set('message',$event.target.value)">
                  </textarea>
              </inputErr>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 col-md-6">
              <inputErr
                VPtitle="website"
                :VPRules="{website:''}"
                title="What is your official website? *"
              >
                <input @change="checkForm()" :value="model.get('website')" @input="model.set('website',$event.target.value)">
              </inputErr>
            </div>
          </div>
          <div class="mondoEdit-row row">
            <div class="mondoEdit-col col-12 mondoEdit-buttons ">
              <button :disabled="!load && !isFormValid" ref="sandButton" class="mondoEdit-btn btn" @click="send()">
                Send
              </button>
              <button :disabled="!load" ref="draftButton" class="mondoEdit-btn btn btn-blue"
                      @click="saveDraft()"> Save draft
              </button>
            </div>
          </div>
        </template>
        <random-loader v-else class="loader--poss" :substrate="false" :randomText="true" />
      </ValidationObserver>
    </div>
</template>

<script>
  import inputErr from '@/components/error-slot'
  import note from './note'
  import Questionnaires from '@/components/models/marketing/bio-questionnaire'
  import multiselect from 'vue-multiselect';
  import {ValidationObserver, extend} from 'vee-validate';
  import randomLoader from '@/components/random-loader';

  extend('website', {
    validate(e) {
      let regExp = /https?:\/\/(www\.)?[-a-zA-Z0-9:.\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
      //let regExp = /^https?:\/\/(www\.)?[-a-zA-Z0-9:.\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
      return e.length && regExp.test(e);
    },
    message: 'Must be website link '
  });
  export default {
    name: "questionnaires-edit",
    data() {
      return {
        load: false,
        model: false,
        isFormValid: false,
      }
    },
    async mounted() {
      let self = this;
      this.model = Backbone.Relational.store.find(Questionnaires, this.$route.params.id);
      if (!this.model) {
        this.model = new Questionnaires({id: this.$route.params.id});
        await this.model.fetch({
          error(collection, error) {
            self.$root.popup.showMessage(error);
          }
        });
      }
      if (this.model.attributes.qaReleaseFormat) {
        this.qaReleaseFormat = this.releaseFormatList[this.model.attributes.qaReleaseFormat - 1]
      }
      this.checkForm();
      this.load = true
    },
    methods: {
      async send() {
        let self = this;
        this.checkForm();
        if (this.isFormValid) {
          this.$refs.sandButton.disabled = true;
          let res = this.model.complete()
            .done(function () {
              //self.$root.popup.showMessage('Your form was successfully submitted!');
              self.$router.push('/marketing/bio');
            })
            .fail(function (xhr) {
              self.$refs.sandButton.disabled = false;
              var response = xhr.responseJSON || {},
                message = response.message || xhr.statusText;

              if (xhr.status !== 400) {
                self.$root.popup.showMessage(message);
                if (message == 'OK') {
                  self.$root.popup.showMessage(message);
                }
                return;
              }
              self.$root.popup.showMessage(message || "Please check form information");

            })
        }

      },
      async saveDraft() {
        let self = this, draftButton = this.$refs.draftButton;
        draftButton.disabled = true;
        let isValidate = await this.$refs.observer.validate();
        await this.model.save(
          null, {
            success(e) {
              if (!isValidate) {
                self.$root.popup.showMessage('Successfully saved as a draft but form has errors');
              }
              else {
                self.$root.popup.showMessage('Successfully saved as a draft');
              }
              draftButton.disabled = false;
            },
            error(ret, error) {
              self.$root.popup.showMessage('Successfully saved as a draft but form has errors');
              draftButton.disabled = false;
            }
          }
        );
        draftButton.disabled = false;
      },
      showMessage(text) {
        this.showModal = true;
        this.popupText = text;
      },
      onCnahgeReleaseFormat(selected) {
        this.qaReleaseFormat = selected || null;
        this.model.attributes.qaReleaseFormat = selected ? selected.id : null;
      },
      async checkForm() {
        this.isFormValid = await this.$refs.observer.validate();
      }

    },
    components: {
      ValidationObserver,
      multiselect,
      inputErr,
      note,
      randomLoader
    }
  }
</script>

