<template>
    <div class="container" v-if="user">
        <div class="row d-none d-sm-flex">
            <div class="col-12">
                <h1 class="main-h1">
                    Account Settings
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="settings">
                    <div class="settings-table">
                        <div class="settings-header">
                            <h1 class="main-h1 settings-h1 ">
                                Account setting
                            </h1>
                            <notif class="settings-notif"
                                   html="<p>The more you tell us about yourself, the better we can help you promote your music to new fans across the world!</p>" />
                        </div>
                        <div class="settings-form">
                            <userInfoForm :user="user"></userInfoForm>
                        </div>
                        <div class="settings-form">
                            <socialSettingsForm :user="user"></socialSettingsForm>
                        </div>
                        <div class="settings-form">
                            <password-form :user="user"></password-form>
                        </div>
                        <div class="settings-form">
                            <paymentMethod-form :user="user"></paymentMethod-form>
                        </div>
                        <div class="settings-form">
                            <membership />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <random-loader v-else class="loader--poss" :substrate="false" :randomText="true" />
</template>

<script>


    
    import randomLoader from '@/components/random-loader';
    import notif from '@/components/notiffication';
    import userInfoForm from './components/user-info-form'
    import socialSettingsForm from './components/social-sittings-form'
    import passwordForm from './components/password-form'
    import membership from './components/membership'
    import paymentMethodForm from './components/payment-method-form'
    export default {
        name: "settings",
        data() {
            return {
                user: false,
            }
        },
        async mounted() {
            
            this.$store.dispatch('user/loadUser')
                .then(user => {
                    this.user = user;
                    let self = this;
                });
        },
        components: {
            notif,
            randomLoader,
            membership,
            userInfoForm,
            socialSettingsForm,
            passwordForm,
            paymentMethodForm
        }
    }
</script>

<style lang="scss">
    .settings {
        border-radius: torem(4px);
        @include coner;
        background-color: $white;
        box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
        overflow: hidden;
        width: 100%;
        padding-top: torem(94px);
        text-align: center;
        margin-bottom: torem(30px);
        padding-bottom: torem(50px);
        &-table {
            max-width: torem(562px);
            margin: auto;
            width: 100%;
        }
        &-h1 {
            margin-bottom: torem(18px);
        }
        &-h2 {
            font-weight: $fw-medium;
            font-size: torem(18px);
            line-height: torem(26px);
            text-align: center;
            color: $color-62;
        }
        &-notif {
            margin-top: torem(18px);
            margin-bottom: torem(40px);
        }
        &-form {
            margin-bottom: torem(80px);
        }
        &-select {
            margin-top: torem(50px);
            margin-bottom: torem(30px);
        }
        &-top--notif {
            .sform-title {
                margin-bottom: 0;
            }
        }
    }

    .sform {
        &-title {
            font-weight: $fw-extrabold;
            font-size: torem(24px);
            line-height: torem(29px);
            color: $mainfont;
            text-align: left;
            margin-bottom: torem(40px);
        }
        &-input {
            margin-bottom: torem(30px);
        }
        &-btn {
            width: 100%;
            padding-top: torem(13px);
            padding-bottom: torem(13px);
        }
        .row {
            margin-left: torem(-6px);
            margin-right: torem(-6px);
        }
        [class^='col'] {
            padding-left: torem(6px);
            padding-right: torem(6px);
        }
    }
    @include media-breakpoint-down(sm) {
        .settings {
            padding: torem(28px) torem(80px) torem(150px);
            &-h1 {
                font-size: torem(20px);
                line-height: torem(24px);
                margin-top: 0;
                
                &-form {
                    margin: torem(23px) 0;
                }
            }
        }
        .sform{
            &-title{
                font-size: torem(20px);
                line-height: torem(24px);
                margin-bottom: torem(30px);
                white-space: nowrap;
                font-weight: $fw-bold;
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .settings{
            padding: torem(30px) 0 0 0 ;
            background-color: transparent;
            box-shadow: none;
            &-h1{
                justify-content: center;
            }
            &-form {
                margin-bottom: torem(46px);
            }
        }
        .sform{
            &-title {
                font-size: torem(22px);
                line-height: torem(27px);
                margin-bottom: 0;
                white-space: inherit;
                &:after{
                    position: absolute;
                    content: "\E816";
                    font-family: 'fontello';
                    transition: transform .3s;
                    transform: rotate(90deg);
                    margin-left: torem(22px);
                }
            }
            &-hider {
                transition: max-height .3s, margin-top .3s;
                max-height: torem(9999px);
                margin-top: torem(30px);
            
            }
            &.hide &-hider{
                margin-top: 0;
                max-height: 0;
                overflow: hidden;
            }
            &.hide &-title:after{
                transform: rotate(0deg);
            }
        }
    }
</style>