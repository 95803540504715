<template>
    <div class="tmenu" ref="main" v-if=" tmenu" :style="{'height': innerSize.height+'px'}">
        <button class="tmenu-closeBtn d-sm-none" @click="$emit('close')"></button>
        <ul class="tmenu-list">
            <li class=" tmenu-li" v-for="(item,index) in tmenu">
                <div class="tmenu-link-holder" :data-step="item.introStep" @click="$emit('close')">
                    <a v-if="/^https?:\/\//.test(item.link)"
                        :href="item.link" 
                        target="_blank"
                        v-on="item.callback !== undefined ? { click: item.callback } : {}"
                        class="tmenu-link vue-rout"
                        :class="[item.iconclass,{ active: (new RegExp(item.regExp,'g')).test($route.fullPath)}, item.cssclass]">
                        {{item.text}}
                    </a>
                    <router-link v-else
                        :to="item.link" class="tmenu-link vue-rout"
                        :class="[item.iconclass,{ active: (new RegExp(item.regExp,'g')).test($route.fullPath)}]">
                        {{item.text}}
                    </router-link>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        props: ['tmenu', 'aitem'],
        computed: {
            ...mapGetters({
                innerSize: 'resolution/getInnerSize',
            }),
        },
    }
</script>

<style lang="scss">
    .tmenu {
        &-list {
            list-style: none;
            display: block;
            padding-top: torem(17px);
        }
        li {
            position: relative;
            padding: 0;
            text-align: center;
            
        }
        &-logo {
            flex: 0 0 torem(150px);
            display: none;
            img {
                height: torem(30px);
            }
        }
        &-link-holder {
            /* background: $sideBar-Bg;*/
            text-align: center;
            padding: torem(17px) torem(10px);
            display: inline-block;
            width: 100%;
        }
        &-link {
            text-align: center;
            font-size: torem(15px);
            line-height: torem(22px);
            font-weight: $fw-bold;
            
            color: $sideBar-color;
            display: inline;
            width: 100%;
            transition: all .3s;
            position: relative;
            text-transform: uppercase;
            &:after {
                content: '';
                background: $orange-ff;
                border-radius: torem(4px);
                height: torem(5px);
                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                width: 0;
                margin: auto;
                margin-top: torem(6px);
                transition: width .3s;
            }
            &:before, &.font2.ficon:before {
                display: none;
            }
            &:hover, &.active { 
                text-decoration: none;
                color: $white;
                &:after {
                    width: 100%;
                }
            }
            
            .icon {
                margin-right: torem(10px);
                font-size: torem(24px)
            }
            
        }
        &-btn {
            @include burger;
            height: torem(25px);
        }
        &-submenu {
            position: absolute;
            top: 0;
            left: 100%;
            background-color: $white;
            max-width: 0;
            overflow: hidden;
            transition: max-width .3s;
            a {
                white-space: nowrap;
                padding-right: torem(38px);
            }
        }
        &-clone {
            position: fixed;
            width: auto;
        }
        li:hover &-submenu {
            max-width: torem(999px);
            transition: max-width .3s .3s;
        }
    }
    
    @include media-breakpoint-down(sm) {
        .tmenu {
            max-height: 100%;
            overflow: auto;
            &-list{
                padding-bottom: torem(96px);
            }
            &-link {
                font-size: torem(8px);
                display: inline-block;
                margin: auto;
                width: auto;
                line-height: 1;
                
                &:before,&.font2.ficon:before {
                    font-size: torem(32px);
                    margin: auto;
                    display: block;
                    margin-bottom: torem(6px);
                }
                &:after {
                    height: torem(4px);
                    margin-top: torem(4px);
                }
                &.icon-tmenu-projects {
                    &:before {
                        position: relative;
                        right: torem(-4px);
                    }
                }
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .tmenu {
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            &-closeBtn {
                position: absolute;
                top: 0;
                left: 0;
            }
            &-link {
                font-size: torem(22px);
                line-height: torem(27px);
                &:before,&.font2.ficon:before {
                    display: none;
                }
                
            }
            &-closeBtn {
                @include closeBtn;
            }
            &-list{
                padding-bottom: 0;  
            }
        }
    }
</style>