<template>
    <!--<p class="vlink" @click="onClick">
        {{text}}
    </p>
    -->
    <router-link :to="to" class="vlink">
        {{text}}
    </router-link>

</template>

<script>
    export default {
        name: "vlink",
        props: ['value', 'text', 'to'],
        methods: {
            /* onClick(){
                 
                 window.history.pushState({section: this.switchTo}, null, '/' + this.switchTo);
                 this.$emit('input',this.switchTo);
                 
             }*/
        }
    }
</script>

<style lang="scss">
    .vlink {
        display: inline-block;
        cursor: pointer;
        font-weight: $fw-medium;
        font-size: torem(17px);
        line-height: torem(25px);
        align-items: center;
        color: $blue-18;
        text-decoration: none !important;
        border-bottom: torem(1.5px) solid $blue-18;
    }
</style>