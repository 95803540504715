<template>
    <div v-if="product">
        <div class="plan-item" :class="{
      'plan-item-disabled': !isEnabled,
      'plan-item-current': isCurrentPlan,
    }">
            <div class="plan-item-head" v-if="!!$slots['title']">
                <slot name="title"></slot>
            </div>
            <div class="plan-item-price" v-if="!!$slots['price']">
                <slot name="price"></slot>
            </div>
            <div class="plan-item-list" v-if="!!$slots['list']">
                <slot name="list"></slot>
            </div>
            <div class="plan-item-notif" v-if="!!$slots['notif']">
                <slot name="notif"></slot>
            </div>
            <div class="plan-item-notif" v-if="currentProject && maxArtistCount <= userArtistCount && isCurrentPlan">
                <p>
                    Congrats! Looks like your roster is growing. Please upgrade your plan and distribute even more
                    artists globally.<b> "You have {{userArtistCount}} active artists "</b>
                </p>
            </div>
            <div class="plan-item-bottom">
                <button ref="buttonAddToCart"
                        class="btn plan-item-btn" @click="addOrRemoveFromCart()"
                        :disabled="!isEnabled"
                >
                    {{ buttonText }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "plan-item",
        props: [
            'product',
            'currentProject',
            'userArtistCount',
            'currentSubscription',
        ],
        data() {
            return {
                cart: null,
                threeMonthCommitmentPackages: [
                    'Essential monthly',
                    'Pro monthly',
                ]
            }
        },
        mounted() {
            this.$store.dispatch('cart/loadCart').then(cart => {
                this.cart = cart;
            });
        },
        methods: {
            async addOrRemoveFromCart() {

                let _this = this, exists = this.threeMonthCommitmentPackages.some(function(packageName) {
                  return packageName === _this.product.getTitle()
                });
                this.$refs.buttonAddToCart.disabled = true;
                let cartItem = this.cart.getItemBy({id: this.product.get('id')});
                if (cartItem) {
                    this.cart.removeItem(cartItem,
                        () => this.refreshAddButton(),
                        (model, error) => {
                            this.$root.popup.showMessage(error);
                            this.refreshAddButton();
                        }
                    );

                    return;
                }

                let productAttributes = null;

                if (this.currentSubscription && this.currentSubscription.isCurrent()) {
                    // upgrade subscription
                    let maxArtistCount = this.product.getParamData('max_artists') || 0;
                    if (this.currentSubscription.get('maxArtistCount') == maxArtistCount) {
                        productAttributes = {user_subscription_renewal: true};
                    } else if (this.currentSubscription.get('maxArtistCount') < maxArtistCount) {
                        productAttributes = {user_subscription_upgrade: true};
                    } else {
                        // something went wrong
                        this.refreshAddButton();

                        return;
                    }
                }

                if (exists) {
                  this.$root.popup.showMessage('By choosing a monthly plan, you agree to a minimum 3-month service. If you cancel early, we will recoup our fees by reducing your royalties by 50% for 12 months from the signup date.');
                  this.$root.popup.onConfirm = function () {
                    _this.addItemToCart(productAttributes, 1);
                  };
                  this.$root.popup.onCancel = function () {
                    this.$root.popup.clear();
                    _this.refreshAddButton();
                  };
                  this.$root.popup.setAddText('I agree');
                  this.$root.popup.setCancleText('Close');
                } else {
                  _this.addItemToCart(productAttributes, 0);
                }
            },
            refreshAddButton() {
                if (!this.$refs.buttonAddToCart) {
                    return;
                }
                this.$refs.buttonAddToCart.disabled = !this.isEnabled;
            },
            addItemToCart(productAttributes, clearPopup = 0) {
                this.cart.addItemToCart(this.product, productAttributes,
                  () => {
                    if (clearPopup) {
                      this.$root.popup.clear();
                    }
                    this.refreshAddButton();
                  },
                  (error) => {
                    if (error) {
                      this.$root.popup.showMessage(error);
                    }
                    this.refreshAddButton();
                  }
                )
            }
        },
        computed: {
            isCurrentPlan() {
                return this.currentSubscription
                    && this.maxArtistCount == this.currentMaxArtistCount
                    && this.currentSubscription.get('title') == this.product.get('title');
            },
            buttonText() {
                if (this.isCurrentPlan) {
                    return this.isRenewal ? "Renewal" : "Current";
                }
                if (!this.product.getAvailability()) {
                    return 'Not available';
                }
                if (this.isInCart) {
                    return "Remove";
                }
                if (this.isUpgrade) {
                    return "Upgrade";
                }
                if (!this.hasUserCurrentPlan) {
                    return this.maxArtistCount >= this.userArtistCount
                        ? "Select"
                        : "You have " + this.userArtistCount + " active artists";
                }

                return "Not available";
            },
            isEnabled() {
                if (!this.product.getAvailability()) {
                    return false;
                }

                if (!this.hasUserCurrentPlan) {
                    return this.maxArtistCount >= this.userArtistCount;
                }
                // waiting for payment
                if (this.currentSubscription.isWaiting()) {
                    return false;
                }

                return this.isUpgrade || this.isRenewal;
            },
            isInCart() {
                return this.cart && !!this.cart.getItemBy({id: this.product.get('id')});
            },
            /**
             * @return {Boolean}
             */
            isUpgrade() {
                if (this.isCurrentPlan || !this.hasUserCurrentPlan) {
                    return false;
                }

                return this.maxArtistCount > this.currentMaxArtistCount && this.currentSubscription.get('isUpgradeAllowed');
            },
            /**
             * @return {Boolean}
             */
            isRenewal() {
                return this.isCurrentPlan
                    && this.currentSubscription.isCurrent()
                    && this.currentSubscription.get('isRenewalAllowed');
            },
            /**
             * Current item max artist count
             *
             * @return {Number}
             */
            maxArtistCount() {
                return this.product.getParamData('max_artists') || 0;
            },
            /**
             * Curretn user plan max artist count
             *
             * @return {Number}
             */
            currentMaxArtistCount() {
                return this.currentSubscription
                    ? this.currentSubscription.get('maxArtistCount')
                    : 0;
            },
            /**
             * @return {Boolean}
             */
            hasUserCurrentPlan() {
                return this.currentSubscription && this.currentSubscription.isCurrent();
            },
        }
    }
</script>
<style lang="scss">
    .plan-item {
        border: torem(3px) solid $blue-18;
        padding: torem(45px) torem(54px);
        width: torem(420px);
        height: torem(570px);
        margin: auto;
        display: flex;
        flex-direction: column;

        &-disabled {
            border-color: $color-ac;
        }
        &-current {
            border-color: $color-f4;
        }
        .tooltipster .ficon:before {
            margin: 0;
            top: torem(-5px);
            position: relative;
        }
        &-head {
            margin-left: torem(-20px);
            margin-right: torem(-20px);
            p {
                font-weight: $fw-extrabold;
                font-size: torem(28px);
                line-height: torem(37px);
                text-align: center;
                color: $mainfont;
                margin-bottom: torem(10px);
            }
        }
        &-price {
            p {
                font-weight: $fw-bold;
                font-size: torem(50px);
                line-height: torem(61px);
                color: $blue-18;
                margin-bottom: torem(30px);
                text-align: center;
                span {
                    font-size: torem(22px);
                    font-weight: $fw-semibold;
                    margin-left: torem(4px);
                    text-transform: lowercase;
                }
            }
        }
        &-list {
            list-style: none;
            width: torem(312px);
            li {
                display: flex;
                margin-bottom: torem(18px);
                &:last-child {
                    margin-bottom: 0;
                }
                &:before {
                    font-family: 'fontello';
                    font-size: torem(14px);
                    content: '\e805';
                    color: $color-44;
                    margin-right: torem(18px);
                }
                p {
                    margin-bottom: 0;
                    font-weight: $fw-medium;
                    font-size: torem(18px);
                    line-height: torem(22px);
                    display: flex;
                    align-items: center;
                    color: $mainfont;
                }
            }
        }
        &-notif {
            margin: 0 auto;
            flex: 1 1 auto;
            justify-content: center;
            display: flex;

            p {
                font-weight: $fw-medium;
                font-size: torem(16px);
                line-height: torem(20px);
                display: block;
                text-align: center;
                color: $color-62;
                margin: auto;
                &.no-center {
                    margin-top: torem(13px);
                }
                span {
                    font-weight: $fw-bold;
                    font-size: torem(27px);
                    line-height: torem(33px);
                    /* identical to box height */
                    text-align: center;
                    color: $color-62;
                    display: block;
                    margin-bottom: torem(10px);
                    i {
                        font-size: torem(17px);
                    }
                }
            }
        }
        &-bottom {
            margin-top: auto;
        }
        &-btn {
            width: 100%;
            height: torem(44px);
            font-size: torem(18px);
            padding: torem(6px) 0;
        }
        &--tracks &-price {
            margin-top: torem(61px);
        }

    }

    @include media-breakpoint-down(sm) {
        .plan-item {
            max-width: 100%;
            height: torem(486px);
            &-price p {
                font-size: torem(30px);
                line-height: torem(37px);
            }
            &-head p {
                font-size: torem(24px);
                line-height: torem(29px);
            }
            &-notif p {
                font-size: torem(14px);
                line-height: torem(17px);
                margin: torem(36px) 0;
            }
            li {
                margin-bottom: torem(12px);
                p {
                    font-size: torem(15px);
                }
            }

        }
    }

    @include media-breakpoint-down(xs) {
        .plan-item {
            max-width: 100%;
            margin: auto torem(9px);
            width: auto;
            padding: torem(24px) torem(18px);
            height: torem(362px);
            &-head {
                p {
                    font-size: torem(20px);
                    line-height: torem(24px);
                    margin-bottom: torem(7px);
                }
            }
            &-price {
                p {
                    font-size: torem(25px);
                    line-height: torem(30px);
                    margin-bottom: torem(13px);
                }
            }
            &-list {
                max-width: 100%;
                li {
                    margin-bottom: torem(12px);
                    p {
                        font-size: torem(13px);
                        line-height: torem(16px);
                    }
                }
            }
            &-notif p {
                margin: auto;
            }
        }
    }
</style>
