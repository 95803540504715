<template>
    <div class="container d-flex flex-column  align-items-stretch">
        <div class="row">
            <div class="col-12 ">
                <h1 class="main-h1">
                    Plans & Pricing
                </h1>
            </div>
        </div>
        <plans-loop
            v-if="products != null"
            :products="products"
            :currentSubscription="subscription"
        />
    </div>
</template>

<script>
    import plansLoop from './components/plans-loop'
    import Products from '@/components/collections/products'
    import {mapGetters} from 'vuex';

    export default {
        name: "plans-and-pricing-page",
        data() {
            return {
                products: null,
                subscription: null,
                loadingText: [
                    "We have right options just for you",
                    "You should look at what we have here",
                    "We have a bunch of useful products just for you",
                    "Checking your browser history...",
                    "Checking our warehouses...",
                    "Wash your hands before getting your cart",
                    "All products are just cleaned",
                    "There are no masks left, sorry...",
                    // "Don't forget to order delivery to you grannies",
                ],
            }
        },
        async mounted() {
            this.initSubscription();
            this.initProducts();
        },
        methods: {
            async initProducts() {
                if (!this.productList) {
                    await this.$store.dispatch('product-list/load');
                }

                this.products = new Products(this.productList);
            },
            async initSubscription() {
                return this.$store.dispatch('subscription/loadSubscription').then(subscription => {
                    this.subscription = subscription;
                });
            }
        },
        computed: {
            ...mapGetters({
                productList: 'product-list/get'
            })
        },
        components: {
            plansLoop
        }
    }
</script>
