<template>
  <div class="slick-container">
    <Slick
      ref="slick"
      :options="mergedOptions"
    >
      <slot></slot>
    </Slick>
    <div ref="dotscontainer" class="slick-dotscontainer"></div>
  </div>
</template>

<script>
  import Slick from 'vue-slick';
  import 'slick-carousel/slick/slick.css';
  import _ from 'underscore';

  export default {
    name: "slick-slider",
    props: ['slickOptions'],
    data() {
      return {
        options: {
          appendDots: null,
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          infinite: true,
          prevArrow: '<button class="ficon icon-arrow-left left" aria-label="Previous" type="button"></button>',
          nextArrow: '<button class="ficon icon-arrow-right right" aria-label="Next" type="button"></button>',
            responsive: [{
                breakpoint: 1210,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },{
                breakpoint: 768,
                settings:{
                    centerMode: true,
                    slidesToShow: 1,
                    centerPadding: '2.8571428571rem',
                }
            }
            ,]
        }
      }
    },
    mounted() {
      this.options.appendDots = this.$refs.dotscontainer;
      // this.$nextTick(() => {
      //   $(this.$refs.dotscontainer).append( $(this.$refs.slick.$el).find('.slick-dots') );
      // });
    },
    methods: {
      /* extend polyfill https://gomakethings.com/vanilla-javascript-version-of-jquery-extend/ */
      extend() {
        var self = this;
        // Variables
        var extended = {};
        var deep = false;
        var i = 0;
        var length = arguments.length;

        // Check if a deep merge
        if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
          deep = arguments[0];
          i++;
        }

        // Merge the object into the extended object
        var merge = function (obj) {
          for (var prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
              // If deep merge and property is an object, merge properties
              if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
                extended[prop] = self.extend(true, extended[prop], obj[prop]);
              } else {
                extended[prop] = obj[prop];
              }
            }
          }
        };

        // Loop through each object and conduct a merge
        for (; i < length; i++) {
          var obj = arguments[i];
          merge(obj);
        }

        return extended;

      },
      slideTo(slideIndex){
        this.$refs.slick.goTo(2);
      }
    },
    components: {
      Slick
    },
    computed: {
      mergedOptions() {
        return _.extend(this.options, this.slickOptions);
      }
    },
  }
</script>

<style lang="scss">
  .slick {
    &-container {
      max-width: torem(917px);
      margin: auto;
      position: relative;
    }
    
    &-arrow {
      outline: none !important;
      padding: 0;
      background-color: transparent;
      border: none;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      height: torem(42px);
      width: torem(42px);
      color: $blue-18;
      
      &.left {
        right: 100%;
        left: torem(-61px);
      }
      &.right {
        left: 100%;
        right: torem(-61px);
      }
      &:before {
        width: auto;
        font-size: torem(22px);
        margin: 0;
      }
    }
    &-disabled {
      color: $color-ac;
      cursor: default !important;
    }
    &-dots {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: torem(40px);
      li {
        font-size: 0;
        width: torem(18px);
        height: torem(18px);
        margin: 0 torem(3px);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s;
        button {
          outline: none!important;
          width: torem(14px);
          height: torem(14px);
          border-radius: 50%;
          background: transparent;
          border: torem(1px) solid $blue-18;
          padding: 0;
        }
        &.slick-active button{
          background: $blue-18;
          width: torem(17px);
          height: torem(17px);
        }
      }
    }
  }
</style>