<template>
    <input :name="itemName" @keyup="onKeyDown" :class="{'string':isString}" :value="isString? string:value" autocomplete="off" />
</template>

<script>
    import 'jquery-ui/ui/widgets/datepicker';

    export default {

        props: ['dateFormat', 'itemName', 'options', 'regex', 'value','string','showString'],
        data() {
            return {
                date: false,
                dateInput: false,
                optionsChange: '',
            }
        },
        mounted: function () {
            var self = this, options;
            options = this.$props.options || {};
            options['dateFormat'] = this.dateFormat;
            options['onSelect'] = function (date) {
                self.date = date;
                self.$emit('update', date);
            };
            options['beforeShow'] = function (nput, inst) {
                self.$emit('onOpen', {nput, inst});
            };
            options['onClose'] = function (dateText, inst) {
                self.$emit('onClose', {dateText, inst});
            };
            options['dayNamesMin'] = ["S", "M", "T", "W", "T", "F", "S"];
            this.dateInput = $(this.$el);
            this.dateInput.datepicker(options);
        },
        methods: {
            setDate(date) {
                this.dateInput.datepicker("setDate", date);
            },
            pushString(){
                if(!this.dateInput){ return };
                this.dateInput[0].value = this.string;
            },
            getDate() {
                return this.dateInput.datepicker('getDate');
            },
            setMinDate(date) {
                this.dateInput.datepicker("option", "minDate", date);
            },
            getMindate() {
                return $.datepicker._determineDate(this.dateInput, this.getOption('minDate'))
            },
            getMaxDate() {
                return $.datepicker._determineDate(this.dateInput, this.getOption('maxDate'))
            },
            getFormatedMinDate() {
                return this.formatDate(this.getMindate());
            },
            getFormatedMaxDate() {
                return this.formatDate(this.getMaxDate());
            },
            setOption(option, value) {
                this.dateInput.datepicker("option", option, value);
            },
            getOption(option) {
                return this.dateInput.datepicker("option", option);
            },
            getFormatedDate() {
                return this.formatDate(this.getDate());
            },
            onKeyDown(e) {
                this.date = this.$el.value;
                this.$emit('keydown', this.date);
            },
            formatDate(date) {
                var res;
                try {
                    return $.datepicker.formatDate(this.dateFormat || 'mm/dd/yy', date)
                } catch (error) {
                    res = null;
                }
                return res;
            },
            parseDate(date) {
                var res;
                try {
                    res = $.datepicker.parseDate(this.dateFormat || 'mm/dd/yy', date);
                } catch (error) {
                    res = null;
                }
                return res;
            }
        },
        watch: {
            options(options) {
                for (let option in options) {
                    this.setOption(option, options[option]);
                }
                if(!this.getDate() && !this.showString){
                    this.setDate(this.value);
                }
                if(this.showString){
                    this.pushString();
                }
            },
        },
        computed:{
            isString(){
                /*this.pushString();*/
                return this.showString
            }
        },
        beforeDestroy: function () {
            $(this.$el).datepicker('hide').datepicker('destroy');
        }
    };

</script>
