<template>
    <ul class="fmenu">
        <li>
            <a href="https://www.octiive.com/blog">Read Our Blog</a>
        </li>
        <li>
            <a href="https://www.octiive.com/about-us?_ga=2.94827609.475467687.1585323549-1486466316.1570199008">
                Get To Know Our Team
            </a>
        </li>
        <li>
            <a href="https://www.octiive.com/sell-your-music">Sell Your Music</a>
        </li>
        <li>
            <a href="https://www.octiive.com/privacy-policy">Privacy Policy</a>
        </li>
        <li>
            <a href="https://www.octiive.com/terms-and-conditions">Terms and Conditions</a>
        </li>
    </ul>

</template>

<script>
    export default {
        name: "footerMenu"
    }
</script>

<style lang="scss">
    .fmenu {
        display: flex;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin: 0 torem(15px);
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            a {
                font-weight: $fw-bold;
                font-size: torem(13px);
                line-height: torem(15px);
                text-decoration-line: underline;
                color: $blue-18;
            }
        }
    }
    
    @include media-breakpoint-down(sm) {
        .fmenu {
            justify-content: space-around;
            li {
                
                a {
                    font-size: torem(11px);
                    
                }
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .fmenu {
            justify-content: space-around;
            flex-wrap: wrap;
            justify-content: flex-start;
            li {
                margin: 0;
                margin-right: auto !important;
                a {
                    color: $white-f5f;
                }
                &:nth-child(2) {
                    order: 1;
                }
                &:last-child {
                    margin-right: auto;
                    order: 1;
                }
            }
        }
    }
</style>