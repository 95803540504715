<template>
    <div class="container d-flex flex-column  align-items-stretch" >
        <div class="row">
            <div class="col-12">
                <h1 class="main-h1 marg-bottom-none">
                    List of projects
                </h1>
            </div>
            <div class="col-12">
                <router-link class="ficon   icon-arrowright main-link main-link-h1"
                             :to="'/project/create'">
                    Create project
                </router-link>
            </div>
        </div>
        
        <div class="row" v-if="load && models.length" :key=" ">
            <div class="col-12">
                
                <div class="projects-obj" v-if="models" v-for="project in models">
                    <project-item :key="project.getId()" :item="project" />
                </div>
            
            </div>
        </div>
        <emptyState
            v-else-if="load && !models.length"
            h1text="No projects"
            text="You have no projects yet"
            buttonText="Get Started"
            linkto="/project/create"
            class="project-empty"
        ></emptyState>
        <random-loader class="loader--poss" v-else :substrate="false" text="Your projects will be shown here" />
    </div>
</template>


<script>

    import ProjectCollection from '@/components/collections/distribution-projects';
    import projectItem from './project-item';
    import randomLoader from '@/components/random-loader';
    import emptyState from '@/components/emptyState';
    
    export default {
        name: "vtable",
        data() {
            return {
                projectCollection: false,
                models: false,
                load: false
            }
        },
        async mounted() {
            let self = this;

            this.projectCollection = new ProjectCollection();
            await this.projectCollection.fetch({
                success() {
                    self.models = self.projectCollection.models;
                    self.load = true
                },
                error(collection, error) {
                    self.$root.popup.showMessage(error);
                },
                data: {limit: 100}
            });

        },
        components: {
            projectItem,
            randomLoader,
            emptyState
        },
    }
</script>

<style lang="scss">
    .projects {
        
        &-table {
            width: 100%;
            height: 100%;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            .container {
                flex: 1 1 auto;
            }
        }
        
        &-link {
            
            font-size: torem(19px);
            line-height: torem(23px);
        }
        
        &-obj {
            .pitem {
                margin-top: 0;
            }
        }
    }
    .empty.project-empty{
        position: static;
        height: auto;
        flex: 1 1 auto;
        z-index: 1;
    }
</style>