<template>
        <audio ref="htmlAudio" :src="srcAudio" :isNowPlay="isTrackPlay" style="" ></audio>
</template>

<script>
    export default {
        props: ['src','isPlay'],
        name: "vue-audio",
        data() {
            return {
                srcAudio: false
            }
        },
        mounted() {
            this.$refs.htmlAudio.ontimeupdate = this.emitTimeUpdate;
            this.$refs.htmlAudio.onloadedmetadata = this.emitLoadedmetadata;
            this.$refs.htmlAudio.onended = this.ended;
        },
        methods: {

            emitTimeUpdate(e) {
                this.$emit('timeUpdate', e)
            },
            loadedmetadata(e) {
                this.$emit('loadedmetadata', e)
            },
            ended(e) {
                this.$emit('ended', e);
                this.onStop();
            },
            playPause() {
                if (!this.srcAudio) {
                    this.srcAudio = this.src;
                    return
                }
                if (this.isPlay && this.isPlay!==null) {
                    this.onPlay();
                    return
                }
                this.onPause();
            },
            onPause(e) {
                this.$refs.htmlAudio.pause();
                this.$emit('pause', e);
            },
            onPlay(e) {
               
                this.$refs.htmlAudio.play();
                this.$emit('play', e);
            },
            onStop() {
                this.pause();
                this.$refs.htmlAudio.currentTime = 0;
            },
            emitLoadedmetadata() {
                this.playPause();
            }
        },
        computed:{
            isTrackPlay(){
                this.playPause();
                return this.isPlay;
            }
        }
    }
</script>

<style lang="scss">

</style>