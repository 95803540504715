<template>
    <div class="role" v-if="value" :class="{'role-error':errors.length}">
        <div class="row flex-sm-nowrap">
            <div class="col-12 col-sm-6 role-multiselect" :class="{'multiselect-error':errors.length}" v-if="label">
                <multiselect
                    v-if="selectOptions"
                    :class="{'disabled':!canSelectType}"
                    ref="selector"
                    v-model="selected"
                    :options="selectOptions"
                    :searchable="true"
                    :show-labels="false"
                    label="typeLabel"
                    track-by="type"
                    :disabled="!canSelectType"
                    :allow-empty="false"
                ></multiselect>
            </div>
            <div class="col-12 col-sm-6 role-input" :class="rowInputClass">
                <inputBtn
                    :error="errors.length ? errors : null"
                    :placeholder="placeholder"
                    :value="name"
                    @click="onAdd($event)"
                    :disabled="disabled"
                    textBtn="Add"
                    :autoCompleteData="filterParticipator"
                />
            </div>
        </div>
        <div v-if="errors.length" class="role-errors">
            <p v-for="error in errors">
                {{ error }}
            </p>
        </div>
        <div class="role-table" v-if="listLength">
            <template v-for="item in list">
                <div class="row" :class="{
                    'warning': hasWarning(item),
                    'error': hasError(item),
                }">
                    <div class="col-8 col-sm-1 d-flex">
                      <button id="toggleBtn" class="mobile-tools-button ficonblack icon-dots" @click="toggleDetails(item)"></button>
                      <vue-tooltipster
                          ref="dsptooltip"
                          slot="tooltip"
                          :tooltipsterOptions="{contentAsHTML:true}"
                          class="tooltipster--responsive-left"
                          :label="'To show / hide DSP identificators click \'3 horizontal dots\' button'">
                      </vue-tooltipster>
                    </div>
                    <div class="col-4 col-sm-5 d-flex">
                        <p>{{ item.attributes.typeLabel }}</p>
                    </div>
                    <div class="col-8 col-sm-6 d-flex">
                        <p>{{ item.attributes.name }}</p>
                        <i class="ficon icon-subtract" @click="onRemove(item)"></i>
                    </div>
                </div>
                <div class="id-table" v-if="isEligibleToAttachDSPIdentifier(item) && areDetailsShown(item)">
                    <div class="row info-box" v-if="item.attributes.usedInSubmittedProjects">
                        Uh-oh! Can’t tweak artist info once it’s in. Need a change? <a href="mailto:support@octiive.com">Contact support</a>
                    </div>
                    <div class="row info-box" v-else>
                        Does this artist already have a profile on streaming platforms? Input the identifiers as shown. Is this a new Artist? Simply leave the fields empty. Don't know how to find the artist identifier? Click on the information ("<strong>i</strong>") marks.
                    </div>
                    <div class="row participator-list-row" v-for="platformArtist in getAllPlatformArtists(item)">
                        <div class="col-4 col-sm-6 d-flex">
                            <vue-tooltipster slot="tooltip"
                                            :tooltipsterOptions="{contentAsHTML:true}"
                                            class="tooltipster--responsive-left"
                                            :label="'Does the artist has a profile on <strong>'
                                                + platformArtist.get('platform') + '</strong>?<br>'
                                                + 'If yes, please add it here.<br>'
                                                + 'Don\'t you know how to find '
                                                + platformArtist.get('platform') + ' identifier? Click on this information mark'">
                                                <a target="_blank" :href="getHelpLink(platformArtist.get('platform'))">
                                                    <i class="ficon icon-info tooltipstered" style="margin-top: 10px;"></i>
                                                </a>
                            </vue-tooltipster>

                            <p>{{ platformArtist.get('platform') }}</p>
                        </div>
                        <div v-if="item.attributes.usedInSubmittedProjects" class="col-8 col-sm-6 d-flex">
                            <div class="inputTT" style="padding-top: 10px; color:gray; font-style: italic;">
                                {{ platformArtist.getPlatformId() ? platformArtist.getPlatformId() : 'Marked as new artist' }}
                            </div>
                        </div>
                        <div class="col-8 col-sm-6 d-flex" v-else>
                            <div class="inputTT">
                                <div class="inputTT-bottom" @dblclick="unlockPlatformIdentifier($event)">
                                <input type="text"
                                        :value="platformArtist.getPlatformId()"
                                        :placeholder="platformArtist.get('platform') + ' Identifier'"
                                        @change="savePlatformArtist(item, platformArtist, $event)"
                                        class="inputTT-input">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-4 col-sm-6 d-flex participator-list-new-artist">
                            <label class="checkbox active-blue-text">
                                <input
                                  type="checkbox"
                                  name="new_artist_check"
                                  @change="processNewArtist(item, $event)"
                                />
                                <p>New artist?</p>
                            </label>
                        </div>
                    </div> -->
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import multiselect from 'vue-multiselect';
    import Participator from '@/components/models/participator'
    import ParticipatorType from '@/components/models/participator/type'
    import inputBtn from '@/components/inputBtn'
    import PlatformArtist from '@/components/models/platform/platform-artist'
    import vueTooltipster from '../../../components/vue-tooltipster';

    export default {
        props: {
            errors: {},
            placeholder: {
                type: String,
                default: ''
            },
            options: {
                type: Array,
                default: [],
            },
            label: {
                type: Boolean,
                default: true,
            },
            rowInputClass: {
                type: String,
                default: 'col-md-6'
            },
            requiredType: {default: false},
            userParticipators: {},
            trackBy: {},
            value: {},
        },
        data() {
            return {
                selected: '',
                name: '',
                trackListParticipators: null,
                disabled: false,
                selectOptions: false,
                list: 0,
                availableDSPs: [],
                selectedDSP: '',
                artistDSPIdentifier: '',
                artistDSPIdentifiers: [],
                shownWithDetails: [],
                showTooltip: true,
                newArtists: []
            }
        },
      watch: {
        options(newValue, oldValue) {
          // update if value is changed dynamically
          this.selectOptions = this.toObject(this.options);

          // if the current selected item is not on the list, use the first one
          if (!this.options.includes(this.selected.type)) {
            this.selected = this.selectOptions[0];
          }
        },
      },
        mounted() {
            this.selectOptions = this.toObject(this.options);
            if (this.requiredType) {
                this.selected = this.toObject([this.requiredType])[0]
            } else {
              this.selected = this.selectOptions[0]
          }

            if (!this.availableDSPs || this.availableDSPs.length == 0) {
                this.$store.dispatch('participators/loadDSPList')
                    .then((list) => {
                        this.availableDSPs = list;
                        if (list.length > 0) {
                            this.selectedDSP = list[0];
                        }
                    }).catch(error => {
                        return self.$root.popup.showMessage(error);
                    });
            }
        },
        methods: {
            onAdd(name) {
                // TODO add validation for artist dsp identifier
                // For now we are collecting artist identifiers using add button.
                // we might need to change the process because user should be asked
                // for enter artist dsp ids. it can be handled on another event.
                if (this.artistDSPIdentifier) {
                  // create a collection of dsp identifiers
                  this.artistDSPIdentifiers.push({
                    platform: this.selectedDSP,
                    platformId: this.artistDSPIdentifier
                  })
                  // Cleanup all identifiers, for now we only have spotify
                  this.artistDSPIdentifier = '';
                }

                // create a local collection of identifiers
                let identifiers = this.artistDSPIdentifiers || [];

                // cleanup global collection
                this.artistDSPIdentifiers = []

                if (this.disabled || !name) {
                    return false;
                }
                this.disabled = true;
                this.selected = this.selected || this.selectOptions[0];
                let
                    participatorData = {
                        typeLabel: this.selected.typeLabel,
                        type: this.selected.type,
                        name: name,
                        platformArtists: identifiers
                    },
                    self = this,
                    participator = this.findParticipator(
                        participatorData.name,
                        participatorData.type
                    ),
                    cloneOfParticipators = this.shownWithDetails.map((x) => x);

                if (!participator) {
                    participator = new Participator(participatorData);
                    if (participator.isValid()) {
                        // save and then add to collection
                        participator.save(participatorData, {
                            success: function (model) {
                                self.userParticipators.add(participator);
                                self.$emit('add', participator);
                                self.$emit('input', participator);
                                self.disabled = false;
                            },
                            error: function (item, error) {
                                self.$root.popup.showMessage(error);
                                if (error.responseJSON && error.responseJSON.errors) {
                                    self.error = error.responseJSON.errors[0].message;
                                }
                                self.disabled = false;
                            }
                        });
                    }
                } else {
                    participator.setAvailablePermits(this.userParticipators.where(
                        {
                            type: participatorData.type,
                            name: participatorData.name
                        })[0]);
                    if (this.isAlreadyInList(participator)) {
                        this.disabled = false;
                        return false
                    }
                    this.$emit('add', participator);
                    this.$emit('input', participator);
                    this.disabled = false;
                }

                cloneOfParticipators.forEach(participator => {
                    if (this.areDetailsShown(participator)) {
                        this.hideDetails(participator);
                    }
                });

                this.showDetails(participator);
                // this.$nextTick(() => {
                //     if (this.$refs.dsptooltip[0] && participatorData.type == ParticipatorType.MAIN_ARTIST && this.showTooltip) {
                //         this.$refs.dsptooltip[0].TtOpen();
                //         this.showTooltip = false;
                //         setTimeout(() => {
                //             this.$refs.dsptooltip[0].TtClose();
                //         }, 4000);
                //     }
                // });
            },
            onRemove(participator) {
                let message = 'Do you really want to delete participator: ' + participator.get('name') + '?'
                    , self = this;
                this.$root.popup.showMessage(message);
                this.$root.popup.onConfirm = function () {
                    self.remove(participator);
                    self.$nextTick(() => {
                        self.$root.popup.clear();
                    });
                };
                this.$root.popup.onCancel = function () {
                    self.$nextTick(() => {
                        self.$root.popup.clear();
                    });
                };
                this.$root.popup.setAddText('Yes');
                this.$root.popup.setCancleText('no');
            },
            async remove(participator) {
                await this.$emit('remove', {id: participator.id});
                await this.$emit('input', {id: participator.id});
                if (this.requiredType && !this.value.where({type: +this.requiredType}).length) {
                    this.selected = this.toObject([this.requiredType])[0]
                }
            },
            isAlreadyInList(participator) {
                return this.value.some((vParticipator) => {
                    return vParticipator.attributes.id === participator.get('id')
                })
            },
            findParticipator(name, type) {
                name = name.toLowerCase();
                return this.userParticipators ? this.userParticipators.find(function (participator) {
                        return participator.get('type') === type &&
                            participator.get('name').toLowerCase() === name
                    }) : null;
            },
            savePlatformArtist(participator, platformArtist, event)
            {
              let platformId = event.target.value;
              let dsp = platformArtist.get('platform').toLowerCase();

              // TODO i don't like this idea of manual validating but that's the fastest one without
              // refactoring too many places. We should consider refactoring this on the new version

              // validate DSP ID
              let validationPattern, help, link = '';

              if (dsp.includes('spotify')) {
                // If user provided a URL, extract the id from there and correct it
                let match = platformId.match(/\/(\w+)\/(\w+)\?/)
                if (match) {
                  platformId = `spotify:${match[1]}:${match[2]}`;
                  event.target.value = platformId;
                }

                validationPattern = /^spotify:artist:[a-zA-Z0-9]+$/;
                help = 'Example: <strong>spotify:artist:4J6lI0OoIr98AuWpXdle9C</strong>'
              } else if (dsp.includes('apple')) {
                // If user provided a URL, extract the id from there and correct it
                let match = platformId.match(/\/(\d+)$/)
                if (match) {
                  platformId = match[1];
                  event.target.value = platformId;
                }

                validationPattern = /^\d+$/;
                help = 'Example: <strong>111051</strong>'
              } else if (dsp.includes('soundcloud')) {
                // If user provided a URL, extract the id from there and correct it
                let match = platformId.match(/soundcloud\.com\/(\w+)/)
                if (match) {
                  platformId = match[1];
                  event.target.value = platformId;
                }

                validationPattern = /^(?!https?:\/\/)[a-zA-Z0-9]+$/;
              }

              if (validationPattern && !validationPattern.test(platformId)) {
                event.target.value = '';
                this.$root.popup.showMessage(`Oops! Looks like the ${platformArtist.get('platform')} ID provided is invalid. Let's try again! <br> ${help} <br> ${link}`);
                return;
              }

                let spinnerElement = document.createElement("div");

                spinnerElement.classList.add("spinner-border");
                spinnerElement.classList.add("spinner-border-sm");
                spinnerElement.style.marginTop = "7%";
                event.target.parentElement.append(spinnerElement);

                // if (!platformId) {
                //     return;
                // }

                event.target.disabled = true;
                platformArtist.set('platformId', platformId);
                participator.get('platformArtists').add(platformArtist);

                participator.save().done(() => {
                  event.target.disabled = false;
                  spinnerElement.remove();
                  this.$emit('input', participator);
                }).catch(error => {
                    // TODO not a good idea to show error message here. maybe something more user friendly and safer??
                    this.$root.popup.showMessage(error);
                    spinnerElement.remove();
                    event.target.disabled = false;
                });
            },
            getHelpLink(dsp)
            {
                if (dsp == 'Spotify') {
                    return 'https://community.spotify.com/t5/Spotify-Answers/What-s-a-Spotify-URI/ta-p/919201';
                }

                return 'https://www.google.com/search?q=how+to+find+artist+identifier+on+' + dsp;
            },
            toObject(arrayTypes) {
              let roleList = [
                { type: 1, typeLabel: "Main" },
                { type: 2, typeLabel: "Featuring" },
                { type: 3, typeLabel: "Remixer" },
                { type: 4, typeLabel: "Writer" },
                { type: 5, typeLabel: "Composer" },
                { type: 6, typeLabel: "Publisher" },
                { type: 7, typeLabel: "Main" },
                { type: 8, typeLabel: "Main" },
                { type: 9, typeLabel: "Lyricist" },

                // Engineering roles
                { type: 10, typeLabel: "Producer" },
                { type: 11, typeLabel: "Co-Producer" },
                { type: 12, typeLabel: "Engineer" },
                { type: 13, typeLabel: "Executive Producer" },
                { type: 14, typeLabel: "Mastering Engineer" },
                { type: 15, typeLabel: "Mixing Engineer" },

                // Performing roles
                { type: 16, typeLabel: "Band" },
                { type: 17, typeLabel: "Choir" },
                { type: 18, typeLabel: "DJ" },
                { type: 19, typeLabel: "MC" },
                { type: 20, typeLabel: "Orchestra" }
              ];

              return roleList.filter(role => arrayTypes.includes(role.type))
            },
            hasWarning(participator)
            {
                if (!this.isEligibleToAttachDSPIdentifier(participator)) {
                    return false;
                }

                // if (!this.newArtists.includes(participator)) {
                //     let artists = this.filterByAvailableDSPs(participator.get('platformArtists'));

                //     return this.availableDSPs.length > artists.length
                //       && artists.length > 0;
                // }

                return 0;
            },
            hasError(participator)
            {
                if (!this.isEligibleToAttachDSPIdentifier(participator)) {
                    return false;
                }

                return 0;
            },
            filterByAvailableDSPs(platformArtists)
            {
                return platformArtists.filter((platformArtist) =>
                    this.availableDSPs.includes(platformArtist.get('platform'))
                );
            },
            isEligibleToAttachDSPIdentifier(participator)
            {
                return participator.get('type') == ParticipatorType.MAIN_ARTIST
                    || participator.get('type') == ParticipatorType.FEATURING
            },
            areDetailsShown(participator)
            {
                return this.shownWithDetails.includes(participator);
            },
            showDetails(participator)
            {
                if (this.areDetailsShown(participator)) {
                    return;
                }

                this.shownWithDetails.push(participator);
            },
            hideDetails(participator)
            {
                let index = this.shownWithDetails.indexOf(participator);
                if (index == -1) {
                    return;
                }

                this.shownWithDetails.splice(index, 1);
            },
            toggleDetails(participator)
            {
                if (this.areDetailsShown(participator)) {
                    this.hideDetails(participator);
                } else {
                    this.showDetails(participator);
                }
            },
            getAllPlatformArtists(participator)
            {
                let artists = [];
                for (var dsp of this.availableDSPs) {
                    let artist = participator.get('platformArtists')
                        .findWhere({platform: dsp});
                    if (!artist) {
                        artist = new PlatformArtist();
                        artist.set('platform', dsp);
                    }

                    artists.push(artist);
                }

                return artists;
            },
            processNewArtist(participator, event) {
                if (event.target.checked) {
                    event.target.parentElement.classList.add("active");
                } else {
                    event.target.parentElement.classList.remove("active");
                }

                if (!this.newArtists.includes(participator) && event.target.checked) {
                    this.newArtists.push(participator);
                } if (!event.target.checked && this.newArtists.includes(participator)) {
                  this.newArtists.splice(
                    this.newArtists.indexOf(participator),
                    1
                  );
                }
                this.$emit('toggleNewArtist', event.target.checked);
                this.$emit('input', participator);
            },
            unlockPlatformIdentifier(event) {
                if (event.target.disabled) {
                  event.target.disabled = false;
                }
            }
        },
        computed: {
            filterParticipator: function () {
                let self = this, res;
                res = self.userParticipators.filter(participator => {
                    return self.selected && self.selected.type === participator.get('type');
                }).map(participator => participator.get('name'));

                return res;
            },
            listLength() {
                this.list = this.value.getParticipatorsByTypes(this.options);
                return this.value.models.length ? this.list : false;
            },
            canSelectType() {
                if (!this.requiredType) {
                    return true
                }
                let self = this;
                return this.value.models.some(participator => {
                    return participator.attributes.type === +self.requiredType
                });
            },
            eligibleToAttachDSPIdentifier() {
                return this.selected.typeLabel === 'Featuring'
                  || this.selected.typeLabel === 'Main';
            },
            dspIdentifierPlaceholder() {
              return this.selectedDSP + ' Identifier'
            }
        },
        components: {
            multiselect,
            inputBtn,
            vueTooltipster,
        }
    }
</script>

<style lang="scss">

    .info-box {
        border: 1px dashed #336699;
        padding: 10px;
        margin: 10px 0;
        border-radius: 5px;
        background-color: #edf4ff;
    }

    .info-box strong {
        display: contents;
    }

    .info-box a {
        display: contents;
        font-weight: bold;
    }

    .participator-list-row {
        margin-top: 0.5rem;
    }
    .inputTT {
        margin-bottom: 1rem !important;
    }
    .participator-list-new-artist {
        margin-bottom: 3rem;
    }
    input:disabled {
        background-color: #eee;
    }
    .ficonblack.icon-dots:before {
        content: '\e843';
    }
    .title {
        font-size: 110%;
        color: #333333;
        padding: 10px 0;
        display: inline-block;
    }

    .role {
        &.fullwidth .role-input {
            width: 100%;
            min-width: 100%;
        }
        &-btn {
            font-size: torem(18px);
            width: torem(84px);
            min-width: torem(84px);
            height: torem(38px);
            padding: 0;
        }
        &-table {
            width: 100%;
            margin-top: torem(30px);
            padding-left: torem(14px);
            padding-right: torem(14px);
            p {

                flex: 1 1 auto;
                font-weight: $fw-bold;
                font-size: torem(17px);
                line-height: torem(40px);
                color: $mainfont;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .col {
                display: flex;
                flex-wrap: nowrap;
                &:nth-child(2n) {
                    p {
                        padding-left: torem(15px);
                    }
                }
            }
            .ficon {
                font-size: torem(18px);
                color: $errofontColor;
                cursor: pointer;
                margin: 0 torem(15px) 0 0;
                display: flex;
                align-items: center;
                justify-content: center;
                &:before {
                    margin: 0;
                }
            }
            .ficonblack {
              font-size: torem(18px);
              color: $black;
              cursor: pointer;
              margin: 7px torem(15px) 0 0;
              display: flex;
              align-items: center;
              justify-content: center;
              &:before {
                margin: 0;
              }
          }
            > .row {
                &.has-details >:first-child {
                    cursor: pointer;
                }

                &:nth-child(2n-1) {
                    background: $color-f7;
                    border-radius: torem(4px);
                }
                &.warning {
                    >:first-child {
                        border-left: torem(5px) solid $red-f3;
                        padding-left: torem(9px);
                    }
                }
                &.error {
                    >:first-child {
                        border-left: torem(5px) solid $errofontColor;
                        padding-left: torem(9px);
                    }
                }
            }
            .id-table {
                width: 100%;
                padding-left: torem(14px);
                padding-right: torem(14px);
            }
        }
        &-input {
            display: flex;
            min-width: torem(231px);
            .input {
                width: torem(177px) !important;
                margin-right: torem(13px);
            }
        }
        &-multiselect {
            flex: 1 1 50%;
            max-width: 50%;
        }
        .inputBtn {
            width: 100%;
        }
        .multiselect-error {
            .multiselect__input,
            .multiselect__placeholder,
            .multiselect__single,
            .multiselect__select:before {
                border-color: $red-f3;
                color: $red-f3;
            }
        }
        .multiselect--disabled {
            opacity: .5;
        }
        &-errors {
            padding-top: torem(6px);
            p {
                font-weight: $fw-medium;
                font-size: torem(17px);
                line-height: torem(21px);
                display: flex;
                align-items: center;
                color: $red-f3;
            }

        }
        &-error {
            .role-table {
                margin-top: torem(3px);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .role {
            padding-left: torem(10px);
            padding-right: torem(10px);
            &-table {
                margin-left: torem(-43px);
                margin-right: torem(-43px);
                width: auto;
                p {
                    font-size: torem(20px);
                }
                > .row {
                    background: $color-f7;
                    padding-left: torem(30px);
                    padding-right: torem(30px);
                    margin-bottom: torem(10px);
                    flex-wrap: nowrap;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .col {
                    &:first-child {
                        flex: 1 1 50%;
                        max-width: 50%;
                    }
                    &:last-child {
                        min-width: torem(231px);
                        p {
                            font-weight: $fw-medium;
                        }
                    }
                }
                .ficon,.ficonblack {
                    margin-right: torem(6px);
                }
            }
            .row {
                margin-left: torem(-4px);
                margin-right: torem(-4px);
                [class^=col] {
                    padding-left: torem(4px) !important;
                    padding-right: torem(4px) !important;
                }
            }
            &-multiselect {
                width: 100%;
                max-width: 100%;
                margin-bottom: torem(21px);
            }
            &-input {
                margin-bottom: torem(21px);
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .role {
            &-table {
                .ficon,.ficonblack {
                    flex: 0 0 torem(38px);
                    margin: 0;
                }
                .row {
                    padding: torem(21px) torem(30px);
                    background-color: $white !important;
                    [class^=col]:nth-child(2) p {
                        font-weight: $fw-500;
                        font-style: torem(15px);
                        line-height: torem(18px);
                    }
                }
                p {
                    flex: 1 1 auto;
                    font-size: torem(17px);
                    line-height: torem(21px);
                    padding-left: torem(10px);
                }

            }
        }
    }
    .checkbox {
      cursor: pointer;
      margin: 0;
      font-size: torem(17px);
      font-weight: $fw-medium;
      line-height: torem(25px);
      display: flex;
      align-items: center;
      color: $color-62;
      &:before {
        $size: 21px;
        font-family: 'fontello';
        content: '';
        background-color: transparent;
        border: torem(1px) solid $color-62;
        box-sizing: border-box;
        border-radius: torem(4px);
        width: torem($size);
        flex: 0 0 auto;
        height: torem($size);
        margin-right: torem(12px);
        transition: border-color .3s, background-color .3s;
        color: $blue-18;
        font-size: torem(12px);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: torem($size);
      }
      &.error:before {
        border-color: $red-f3;
      }
      &.active:before {
        content: '\e805';
        border-color: $blue-18;
      }
      &.active.active-blue-text {
        color: $blue-18;
      }
      input {
        display: none;
      }
    }
</style>
