<template>
  <div class="checkform" v-if="cart!==null">
    <div class="checkform-bottom" v-if="payMethodChange == '1'">
      <button ref="ppbtn" @click="onPayPalClick()" class="checkform-btn btn">Make a payment</button>
    </div>
    <div v-else-if="payMethod == '2'">
      Stripe
    </div>
    <div v-else-if="payMethod == '3'">
      <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
        class=""
        @submit.prevent=""
        tag="form">
        <p class="checkform-h1">
          Card Information
        </p>
        <div class="checkform-row">
          <div class="checkform-col-5 checkform-col-sm-12 checkform-col">
            
            <inputTT
              ref="number"
              :value="number"
              :maxlength="maxCardLength"
              title="Card Number *"
              inputName="number"
              inputPlaceHolder="xxxx xxxx xxxx xxxx"
              @input="onCardInput($event)"
              @focus="onCardFocus"
              @blur="onCardBlur($event)"
              VPtitle="Card number"
              :VPRules="{required:true,min:currentCard.length,creditCard:cardInvalid}"
              autocomplete="cc-number" x-autocompletetype="cc-number"
            />
          
          </div>
          <div class="checkform-col-3 checkform-col-sm-7 checkform-col">
            <inputTT
              :VPRules="{required:true,numeric:true,min:2,max:2,monthValidate:'',checkFullDate:{month:sendData.expirationMonth,year}}"
              VPtitle="Month"
              :maxlength="2"
              v-model="sendData.expirationMonth"
              inputName="expiration-month"
              inputPlaceHolder="Month"
              title="Expiration Date *"
              autocomplete="cc-exp-month" x-autocompletetype="cc-exp-month"
            />
          </div>
          <div class="checkform-col-2 checkform-col-sm-5 checkform-col checkform-decor">
            <inputTT
              VPtitle="Year"
              :maxlength="4"
              :VPRules="{required:true,numeric:true,min:yearMin,max:yearMin,yearValidate:'',checkFullDate:{month:sendData.expirationMonth,year}}"
              :value="year"
              @input="yearInput($event)"
              inputPlaceHolder="Year"
              inputName="expiration-year"
              autocomplete="cc-exp-year" x-autocompletetype="cc-exp-year"
            />
          </div>
        </div>
        <div class="checkform-row">
          <div class="checkform-col-5 checkform-col-sm-7 checkform-col">
            <inputTT
              title="Name on the card *"
              VPtitle="card on name"
              VPRules="required|fullname|noNumbers"
              autocomplete="on"
              v-model="fullName"
              @input="fullnameInput()"
              inputName="fullname"
              inputPlaceHolder="John Snow"
            />
          </div>
          <div class="checkform-col-3 checkform-col-sm-5 checkform-col">
            <inputTT
              VPtitle="Cvv"
              :VPRules="{required:true,max:4,numeric:true}"
              title="CVV *"
              v-model="sendData.cvv"
              name="expiration-month"
              :inputType="'password'"
              inputPlaceHolder="xxx"
              inputName="cvv"
              :maxlength="4"
              autocomplete="cc-csc"
            />
          </div>
        </div>
        <p class="checkform-h1">
          Billing Address
        </p>
        <div class="checkform-row">
          <div class="checkform-col-6 checkform-col-sm-12 checkform-col">
            <p class="inputTT-title">
              Country *
            </p>
            <ValidationProvider
              name="Country"
              tag="div"
              v-slot="{errors, classes}"
              rules="required">
              <multiselect
                ref="multiselect"
                :class="{'error':errors[0]}"
                v-model="countryCode"
                :options="country"
                track-by="id"
                label="label"
                :searchable="true"
                :show-labels="false"
                :tabindex="1"
                placeholder="Choose..." />
            </ValidationProvider>
          </div>
          <div class="checkform-col-6 checkform-col-sm-12 checkform-col">
            <inputTT
              VPtitle="city"
              VPRules="required"
              v-model="sendData.city"
              title="City *"
              inputName="city"
            />
          </div>
        </div>
        <div class="checkform-row">
          <div class="checkform-col-12 checkform-col">
            <inputTT
              v-model="sendData.stateOrProvince"
              VPtitle="State\Region"
              VPRules="required"
              title="State\Region *"
              inputName="stateOrProvince"
            />
          </div>
        </div>
        <div class="checkform-row">
          <div class="checkform-col-12 checkform-col">
            <inputTT
              v-model="sendData.addressLine1"
              VPtitle="Address"
              VPRules="required"
              title="Address Line *"
              inputName="addressLine1"
            />
          </div>
        </div>
        <div class="checkform-row">
          <div class="checkform-col-12 checkform-col">
            <inputTT
              v-model="sendData.addressLine2"
              VPtitle="Address"
              title="Address Line 2"
              inputName="addressLine2"
            />
          </div>
        </div>
        <div class="checkform-row">
          <div class="checkform-col-12 checkform-col">
            <inputTT
              v-model="sendData.postCode"
              VPtitle="Zip"
              VPRules="required"
              title="Zip\Postal Code *"
              inputName="postCode"
            />
          </div>
        </div>
        <div class="checkform-row" style="margin-bottom: 0px">
          <div class="checkform-col-12 checkform-col">
            <button ref="submitButton" class="checkform-btn btn" @click="onPayCreditCard()"> Make a payment</button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import inputTT from '@/components/input-tt';
  import OrderModel from '@/components/models/user/order';
  import GoogleAnalytics from '@/components/GoogleAnalytics/GoogleAnalytics';

  import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
  import multiselect from 'vue-multiselect';


  extend('fullname', {
    validate(e) {
      return e.trim().split(' ').length === 2;
    },
    message: 'Please type First name and Last name separated by space'
  });
  extend('noNumbers', {
    validate(e) {
      return !/[1-9]/g.test(e)
    },
    message: 'Please use only letters'
  });
  extend('creditCard', {
    validate(cardCode,otherValue) {
      return false;
    },
    message: 'invalid card number'
  });
  extend('yearValidate', {
    validate(e) {
      //todo: check full date ??
      let date = new Date(), year;
      if (e.length <= 2) {
        year = Number.parseInt(e) + 2000
      }
      else {
        year = e
      }
      return year >= date.getFullYear();
    },
    message: 'Please enter a value greater than or equal to ' + Math.floor(Date.now() / (365 * 24 * 60 * 60 * 1000) + 1970)
  });
  extend('monthValidate', {
    validate(month) {
      return +month <= 12 && +month >= 1
    },
    message: 'Month must be between 1 and 12'
  });
  extend('checkFullDate', {
    params: ['target'],
    validate(e, {target}) {
        if(!target.year){
            return false;
        }
        let year = target.year.length === 4? target.year : Number.parseInt(target.year)+2000;
      return new Date(year, target.month, 0) >= new Date();
    },
    message:'Wrong expiration date'
  });

  export default {
    name: "checkout-form",
    props: ['payMethod', 'cartitems'],
    data() {
      return {
        yearMin: 2,
        number: null,
        fullName: null,
        year: null,
        countryCode: null,
        confirmText: null,
        paypalPaymentUrl: Mondo.Config.paypalPaymentUrl,
        orderModel: null,
        cart: null,
        maxCardLength:19,
        cardInvalid:true,
        sendData: {
          addressLine1: "",
          addressLine2: "",
          city: null,
          countryCode: null,
          cvv: null,
          expirationMonth: null,
          expirationYear: null,
          firstName: null,
          lastName: null,
          number: String,
          postCode: null,
          stateOrProvince: null,

        },
        country: [
          {id: 'GB', label: "United Kingdom"},
          {id: 'US', label: "United States"},
          {id: 'AF', label: "Afghanistan"},
          {id: 'AX', label: "Åland Islands"},
          {id: 'AL', label: "Albania"},
          {id: 'DZ', label: "Algeria"},
          {id: 'AS', label: "American Samoa"},
          {id: 'AD', label: "Andorra"},
          {id: 'AO', label: "Angola"},
          {id: 'AI', label: "Anguilla"},
          {id: 'AQ', label: "Antarctica"},
          {id: 'AG', label: "Antigua and Barbuda"},
          {id: 'AR', label: "Argentina"},
          {id: 'AM', label: "Armenia"},
          {id: 'AW', label: "Aruba"},
          {id: 'AU', label: "Australia"},
          {id: 'AT', label: "Austria"},
          {id: 'AZ', label: "Azerbaijan"},
          {id: 'BS', label: "Bahamas"},
          {id: 'BH', label: "Bahrain"},
          {id: 'BD', label: "Bangladesh"},
          {id: 'BB', label: "Barbados"},
          {id: 'BY', label: "Belarus"},
          {id: 'BE', label: "Belgium"},
          {id: 'BZ', label: "Belize"},
          {id: 'BJ', label: ">Benin"},
          {id: 'BM', label: "Bermuda"},
          {id: 'BT', label: "Bhutan"},
          {id: 'BO', label: "Bolivia, Plurinational State of"},
          {id: 'BQ', label: "Bonaire, Sint Eustatius and Saba"},
          {id: 'BA', label: "Bosnia and Herzegovina"},
          {id: 'BW', label: "Botswana"},
          {id: 'BV', label: "Bouvet Island"},
          {id: 'BR', label: "Brazil"},
          {id: 'IO', label: "British Indian Ocean Territory"},
          {id: 'BN', label: "Brunei Darussalam"},
          {id: 'BG', label: "Bulgaria"},
          {id: 'BF', label: "Burkina Faso"},
          {id: 'BI', label: "Burundi"},
          {id: 'KH', label: "Cambodia"},
          {id: 'CM', label: "Cameroon"},
          {id: 'CA', label: "Canada"},
          {id: 'CV', label: "Cape Verde"},
          {id: 'KY', label: "Cayman Islands"},
          {id: 'CF', label: "Central African Republic"},
          {id: 'TD', label: "Chad"},
          {id: 'CL', label: "Chile"},
          {id: 'CN', label: "China"},
          {id: 'CX', label: "Christmas Island"},
          {id: 'CC', label: "Cocos (Keeling) Islands"},
          {id: 'CO', label: "Colombia"},
          {id: 'KM', label: "Comoros"},
          {id: 'CG', label: "Congo"},
          {id: 'CD', label: "Congo , the Democratic Republic of the"},
          {id: 'CK', label: "Cook Islands"},
          {id: 'CR', label: "Costa Rica"},
          {id: 'CI', label: "Côte d'Ivoire"},
          {id: 'HR', label: "Croatia"},
          {id: 'CU', label: "Cuba"},
          {id: 'CW', label: "Curaçao"},
          {id: 'CY', label: "Cyprus"},
          {id: 'CZ', label: "Czech "},
          {id: 'DK', label: "Denmark"},
          {id: 'DJ', label: "Djibouti"},
          {id: 'DM', label: "Dominica"},
          {id: 'DO', label: "Dominican Republic"},
          {id: 'EC', label: "Ecuador"},
          {id: 'EG', label: "Egypt"},
          {id: 'SV', label: "El Salvador"},
          {id: 'GQ', label: "Equatorial Guinea"},
          {id: 'ER', label: "Eritrea"},
          {id: 'EE', label: "Estonia"},
          {id: 'ET', label: "Ethiopia"},
          {id: 'FK', label: "Falkland Islands (Malvinas)"},
          {id: 'FO', label: "Faroe Islands"},
          {id: 'FJ', label: "Fiji"},
          {id: 'FI', label: "Finland"},
          {id: 'FR', label: "France"},
          {id: 'GF', label: "French Guiana"},
          {id: 'PF', label: "French Polynesia"},
          {id: 'TF', label: "French Southern Territories"},
          {id: 'GA', label: "Gabon"},
          {id: 'GM', label: "Gambia"},
          {id: 'GE', label: "Georgia"},
          {id: 'DE', label: "Germany"},
          {id: 'GH', label: "Ghana"},
          {id: 'GI', label: "Gibraltar"},
          {id: 'GR', label: "Greece"},
          {id: 'GL', label: "Greenland"},
          {id: 'GD', label: "Grenada"},
          {id: 'GP', label: "Guadeloupe"},
          {id: 'GU', label: "Guam"},
          {id: 'GT', label: "Guatemala"},
          {id: 'GG', label: "Guernsey"},
          {id: 'GN', label: "Guinea"},
          {id: 'GW', label: "Guinea-Bissau"},
          {id: 'GY', label: "Guyana"},
          {id: 'HT', label: "Haiti"},
          {id: 'HM', label: "Heard Island and McDonald Islands"},
          {id: 'VA', label: "Holy See (Vatican City State)"},
          {id: 'HN', label: "Honduras"},
          {id: 'HK', label: "Hong Kong"},
          {id: 'HU', label: "Hungary"},
          {id: 'IS', label: "Iceland"},
          {id: 'IN', label: "India"},
          {id: 'ID', label: "Indonesia"},
          {id: 'IR', label: "Iran, Islamic Republic of"},
          {id: 'IQ', label: "Iraq"},
          {id: 'IE', label: "Ireland"},
          {id: 'IM', label: "Isle "},
          {id: 'IL', label: "Israel"},
          {id: 'IT', label: "Italy"},
          {id: 'JM', label: "Jamaica"},
          {id: 'JP', label: "Japan"},
          {id: 'JE', label: "Jersey"},
          {id: 'JO', label: "Jordan"},
          {id: 'KZ', label: "Kazakhstan"},
          {id: 'KE', label: "Kenya"},
          {id: 'KI', label: "Kiribati"},
          {id: 'KP', label: "Korea , Democratic People's Republic of"},
          {id: 'KR', label: "Korea, Republic of"},
          {id: 'KW', label: "Kuwait"},
          {id: 'KG', label: "Kyrgyzstan"},
          {id: 'LA', label: "Lao People's Democratic Republic"},
          {id: 'LV', label: "Latvia"},
          {id: 'LB', label: "Lebanon"},
          {id: 'LS', label: "Lesotho"},
          {id: 'LR', label: "Liberia"},
          {id: 'LY', label: "Libya"},
          {id: 'LI', label: "Liechtenstein"},
          {id: 'LT', label: "Lithuania"},
          {id: 'LU', label: "Luxembourg"},
          {id: 'MO', label: "Macao"},
          {id: 'MK', label: "Macedonia"},
          {id: 'MG', label: "Madagascar"},
          {id: 'MW', label: "Malawi"},
          {id: 'MY', label: "Malaysia"},
          {id: 'MV', label: "Maldives"},
          {id: 'ML', label: "Mali"},
          {id: 'MT', label: "Malta"},
          {id: 'MH', label: "Marshall Islands"},
          {id: 'MQ', label: "Martinique"},
          {id: 'MR', label: "Mauritania"},
          {id: 'MU', label: "Mauritius"},
          {id: 'YT', label: "Mayotte"},
          {id: 'MX', label: "Mexico"},
          {id: 'FM', label: "Micronesia , Federated States of"},
          {id: 'MD', label: "Moldova, Republic of"},
          {id: 'MC', label: "Monaco"},
          {id: 'MN', label: "Mongolia"},
          {id: 'ME', label: "Montenegro"},
          {id: 'MS', label: "Montserrat"},
          {id: 'MA', label: "Morocco"},
          {id: 'MZ', label: "Mozambique"},
          {id: 'MM', label: "Myanmar"},
          {id: 'NA', label: "Namibia"},
          {id: 'NR', label: "Nauru"},
          {id: 'NP', label: "Nepal"},
          {id: 'NL', label: "Netherlands"},
          {id: 'NC', label: "New Caledonia"},
          {id: 'NZ', label: "New Zealand"},
          {id: 'NI', label: "Nicaragua"},
          {id: 'NE', label: "Niger"},
          {id: 'NG', label: "Nigeria"},
          {id: 'NU', label: "Niue"},
          {id: 'NF', label: "Norfolk Island"},
          {id: 'MP', label: "Northern Mariana Islands"},
          {id: 'NO', label: "Norway"},
          {id: 'OM', label: "Oman"},
          {id: 'PK', label: "Pakistan"},
          {id: 'PW', label: "Palau"},
          {id: 'PS', label: "Palestinian Territory"},
          {id: 'PA', label: "Panama"},
          {id: 'PG', label: "Papua New Guinea"},
          {id: 'PY', label: "Paraguay"},
          {id: 'PE', label: "Peru"},
          {id: 'PH', label: "Philippines"},
          {id: 'PN', label: "Pitcairn"},
          {id: 'PL', label: "Poland"},
          {id: 'PT', label: "Portugal"},
          {id: 'PR', label: "Puerto Rico"},
          {id: 'QA', label: "Qatar"},
          {id: 'RE', label: "Réunion"},
          {id: 'RO', label: "Romania"},
          {id: 'RU', label: "Russian Federation"},
          {id: 'RW', label: "Rwanda"},
          {id: 'BL', label: "Saint Barthélemy"},
          {id: 'SH', label: "Saint Helena, Ascension and Tristan da Cunha"},
          {id: 'KN', label: "Saint Kitts and Nevis"},
          {id: 'LC', label: "Saint Lucia"},
          {id: 'MF', label: "Saint Martin (French part)"},
          {id: 'PM', label: "Saint Pierre and Miquelon"},
          {id: 'VC', label: "Saint Vincent and the Grenadines"},
          {id: 'WS', label: "Samoa"},
          {id: 'SM', label: "San Marino"},
          {id: 'ST', label: "Sao Tome and Principe"},
          {id: 'SA', label: "Saudi Arabia"},
          {id: 'SN', label: "Senegal"},
          {id: 'RS', label: "Serbia"},
          {id: 'SC', label: "Seychelles"},
          {id: 'SL', label: "Sierra Leone"},
          {id: 'SG', label: "Singapore"},
          {id: 'SX', label: "Sint Maarten"},
          {id: 'SK', label: "Slovakia"},
          {id: 'SI', label: "Slovenia"},
          {id: 'SB', label: "Solomon Islands"},
          {id: 'SO', label: "Somalia"},
          {id: 'ZA', label: "South Africa"},
          {id: 'GS', label: "South Georgia and the South Sandwich Islands"},
          {id: 'SS', label: "South Sudan"},
          {id: 'ES', label: "Spain"},
          {id: 'LK', label: "Sri Lanka"},
          {id: 'SD', label: "Sudan"},
          {id: 'SR', label: "Suriname"},
          {id: 'SJ', label: "Svalbard and Jan Mayen"},
          {id: 'SZ', label: "Swaziland"},
          {id: 'SE', label: "Sweden"},
          {id: 'CH', label: "Switzerland"},
          {id: 'SY', label: "Syrian Arab Republic"},
          {id: 'TW', label: "Taiwan, Province of China"},
          {id: 'TJ', label: "Tajikistan"},
          {id: 'TZ', label: "Tanzania, United Republic of"},
          {id: 'TH', label: "Thailand"},
          {id: 'TL', label: "Timor-Leste"},
          {id: 'TG', label: "Togo"},
          {id: 'TK', label: "Tokelau"},
          {id: 'TO', label: "Tonga"},
          {id: 'TT', label: "Trinidad and Tobago"},
          {id: 'TN', label: "Tunisia"},
          {id: 'TR', label: "Turkey"},
          {id: 'TM', label: "Turkmenistan"},
          {id: 'TC', label: "Turks and Caicos Islands"},
          {id: 'TV', label: "Tuvalu"},
          {id: 'UG', label: "Uganda"},
          {id: 'UA', label: "Ukraine"},
          {id: 'AE', label: "United Arab Emirates"},
          {id: 'GB', label: "United Kingdom"},
          {id: 'US', label: "United States"},
          {id: 'UM', label: "United States Minor Outlying Islands"},
          {id: 'UY', label: "Uruguay"},
          {id: 'UZ', label: "Uzbekistan"},
          {id: 'VU', label: "Vanuatu"},
          {id: 'VE', label: "Venezuela, Bolivarian Republic of"},
          {id: 'VN', label: "Viet Nam"},
          {id: 'VG', label: "Virgin Islands, British"},
          {id: 'VI', label: "Virgin Islands, U.S."},
          {id: 'WF', label: "Wallis and Futuna"},
          {id: 'EH', label: "Western Sahara"},
          {id: 'YE', label: "Yemen"},
          {id: 'ZM', label: "Zambia"},
          {id: 'ZW', label: "Zimbabwe"},
        ],
        cardRegExp:[
            {
                name:'Visa',
                length:16,
                cvvLength:3,
                regExp: /^4[0-9]{12}(?:[0-9]{3})?$/,
            },
            {
                name:'DiscoverCard',
                length:16,
                cvvLength:3,
                regExp: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
            },
            {
                name:'MasterCard',
                length:16,
                cvvLength:3,
                regExp: /^5[1-5]\d{14}$/g,
            },
            {
                name:'AmericanExpress',
                length:15,
                cvvLength:4,
                regExp: /^[34|37][0-9]{14}$/g,
            },
          ],
        currentCard:null,
      }
    },

    async mounted() {
      this.orderModel = new OrderModel();

      this.$store.dispatch('cart/loadCart').then(cart => {
        this.cart = cart;
      });
      if(!this.currentCard){
          this.currentCard = this.cardRegExp[0];
      }
    },

    methods: {
      async onPayCreditCard() {
        let self = this;
        if (!(await this.$refs.observer.validate())) {
          return;
        }
        this.$refs.submitButton.disabled = true;
        this.sendData.number = this.number.replace(/[ ,-]/g, '');
        if(this.year.length===2) {
            this.sendData.expirationYear = Number.parseInt(this.year) + 2000;
        }
        else{
            this.sendData.expirationYear = this.year;
        }
        this.sendData.countryCode = this.countryCode.id;
        this.saveOrder().then(() => {
          this.orderModel.payViaCreditCard(this.sendData)
            .done((model, response) => {
              self.$emit('successPay', self.orderModel);

              // send to GA
              GoogleAnalytics.sendEcommerceTransaction(this.orderModel.toDTO());
            })
            .fail((errors) => {
              self.$root.popup.showMessage(errors);
              self.$refs.submitButton.disabled = false;

              self.$emit('failadPay', self.orderModel);
            })
            .then(() => {
              self.cart.fetch();
            });
        }).fail(()=>{
            self.$refs.submitButton.disabled = false;
        });
      },

      async onPayPalClick() {
        this.$refs.ppbtn.disabled = true;
        let self = this;
        this.orderModel.attributes.paymentMethod = 'PayPal';

        this.saveOrder().then(() => {
          this.orderModel.requestPayPalPaymentToken().done(response => {
            let params = {
              cmd: '_express-checkout',
              token: response.token
            };
            window.location.href = self.paypalPaymentUrl + '?' + Backbone.$.param(params);
          }).fail(errors => {
            self.$root.popup.showMessage(errors);
            self.$refs.ppbtn.disabled = false;
            self.cart.fetch();
          });
        });
      },

      fullnameInput() {
        let fname = this.fullName.trim();
        fname = fname.split(' ');
        if (fname.length !== 2) {
          return false
        }
        this.sendData.firstName = fname[0];
        this.sendData.lastName = fname[1];
      },

      onCardInput(cardnumber){
          let temp = cardnumber.split(' ').join(''),
              self = this;

          let validate = this.cardRegExp.some(item=>{
              if(new RegExp(item.regExp).test(temp)){
                  self.currentCard = item;
                  return true
              }
          });
          this.cardInvalid = !validate;
          this.number = cardnumber
      },

      onCardFocus(event){
          
          this.number = this.number ? this.number.split(' ').join('') : '';
          this.maxCardLength = 16;
      },

      onCardBlur(event) {
          this.maxCardLength = 19;
          this.number = this.number ? this.number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : '';
      },

      yearInput(year) {
        if (year.length > 2) {
          this.yearMin = 4
        } else {
          this.yearMin = 2
        }
        this.year = year;
      },

      saveOrder() {
        let self = this;
        if (!this.orderModel.getId()) {
          return this.orderModel
            .save(this.sendData, {
              error(model, resp) {
                self.$root.popup.showMessage(resp);
              }
            })
        }

        return Promise.resolve();
      }
    },
    computed: {
      payMethodChange() {
        /*`set hard tabindex */
        if (this.payMethod == 3) {
          this.$nextTick(() => {
            this.$refs.multiselect.$el.setAttribute('tabindex', 0);
          });
        }
        return this.payMethod;
      }
    },

    components: {
      ValidationProvider,
      ValidationObserver,
      inputTT,
      multiselect,
    }
  }
</script>

<style lang="scss">
  .checkform {
    display: flex;
    flex-direction: column;
    height: 100%;
    $colpad: torem(14px);
    .inputTT-title {
      white-space: nowrap;
    }
    &-h1 {
      font-weight: $fw-extrabold;
      font-size: torem(24px);
      line-height: torem(29px);
      display: flex;
      align-items: center;
      margin: torem(60px) 0 torem(40px) 0;
      color: $mainfont;
    }
    &-row {
      display: flex;
      align-items: flex-end;
      margin: torem(30px) 0;
      margin-right: -$colpad;
      margin-left: -$colpad;
    }
    &-col {
      padding-left: $colpad;
      padding-right: $colpad;
      
    }
    &-col-12 {
      flex: 1 1 100%;
      max-width: 100%;
    }
    &-col-6 {
      flex: 1 1 50%;
      max-width: 50%;
    }
    &-col-5 {
      flex: 1 1 43%;
      max-width: 43%;
    }
    &-col-3 {
      flex: 1 1 34%;
      max-width: 34%;
    }
    &-col-2 {
      flex: 1 1 23%;
      max-width: 23%;
    }
    &-decor {
      position: relative;
      &:before {
        content: '/';
        width: torem(28px);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        
        font-weight: $fw-medium;
        font-size: torem(19px);
        line-height: torem(23px);
        display: flex;
        align-self: center;
        
        margin-right: torem(-14px);
        text-align: center;
        align-items: center;
        justify-content: center;
        height: torem(30px);
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    &-btn {
      margin-top: torem(2px);
      width: 100%;
    }
    &-bottom {
      margin-top: auto;
    }
  }
  @include media-breakpoint-down(sm) {
    .checkform{
      &-row{
        flex-wrap: wrap;
        margin-top: 0;
        margin-bottom: 0;
      }
      .inputTT-title{
        margin-top: torem(18px);
        font-size: torem(18px);
      }
      &-col-sm{
        &-12{
          flex: 1 1 100%;
          max-width: 100%;
        }
        &-7 {
          flex: 0 0 58.3333333333%;
          max-width: 58.3333333333%;
        }
        &-5 {
          flex: 0 0 41.6666666667%;
          max-width: 41.6666666667%;
        }
      }
    }
  }
</style>