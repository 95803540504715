<template>
    <div class="total d-flex" v-if="value">
        <p class=" total-h1">
            {{selection}} of Asset and Products &nbsp;&nbsp;
        </p> &nbsp;
        <p class="count"> {{value.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}</p> &nbsp;
        <p class="ficon" :class="{
            'up icon-select-up':value.percentage>0,
            'down icon-select-down':value.percentage<0,
        }">
            {{value.percentage.toFixed(2).replace(/\.0+$/,'').replace('.',',')}} %
        </p>
    
    </div>
</template>

<script>
    export default {
        name: "v-total-analytic",
        props: ['value', 'selection'],
    }
</script>

<style lang="scss">
    .total {
        align-items: center;
        margin-bottom: torem(20px);
        p {
            font-size: torem(25px);
            display: flex;
            font-weight: $fw-bold;
            span {
                font-size: torem(25px);
                color: $grey;
                
            }
            &.count {
                color: $blue-18;
            }
            &.up {
                color: $green-21;
            }
            &.down {
                color: $red-f3;
            }
            &.ficon {
                &:before {
                    order: 1;
                    justify-self: unset;
                    align-self: center;
                    font-size: 0.5rem;
                    margin-right: 0.6rem;
                    margin-left: .6rem;
                    font-weight: bold;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .total{
            flex-wrap: wrap;
            padding: torem(20px);
            p{
                &.count{
                    margin-left: auto;
                }
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .total {
            padding: torem(50px) 0;
        }
    }
</style>