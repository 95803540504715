
function GoogleAnalytics() {}

GoogleAnalytics.prototype = {

    attemptsNumber: 0,
    maxAttemptsNumber: 2,

    sendPageView: function () {
        var titleToTrack = document.title;
        var pathToTrack = location.pathname + location.search;

        var isGaDefine = this.checkIfAnalyticsLoaded();

        if(!isGaDefine  && this.attemptsNumber <= this.maxAttemptsNumber){
            console.debug('Ga is not defined. Set timeout');
            setTimeout(function(that){that.sendPageView()}, 500, this);

            return;
        }

        if(!isGaDefine && this.attemptsNumber > this.maxAttemptsNumber){
            console.error('GA is not defined');
            return false;
        }

        ga(function() {
            var trackers = ga.getAll();
            trackers.forEach(function(tracker) {
                tracker.set('page', pathToTrack);
                tracker.set('title', titleToTrack);

                tracker.send('pageview');

                console.debug(tracker.get('name') + '.send:pageview');
                console.debug('page:' + pathToTrack);
                console.debug('title:' + titleToTrack);
            });
        });
    },
    /**
     * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce
     */
    sendEcommerceTransaction: function (order) {
        if (typeof order === 'undefined' || order.id === null) {
            console.error('Invalid order');

            return;
        }

        var isGaDefine = this.checkIfAnalyticsLoaded();

        if(!isGaDefine  && this.attemptsNumber <= this.maxAttemptsNumber){
            console.debug('Ga is not defined. Set timeout');
            setTimeout(
                function(order, that){
                    that.sendEcommerceTransaction(order)
                }, 500, order, this);

            return;
        }

        if(!isGaDefine && this.attemptsNumber > this.maxAttemptsNumber){
            console.error('GA is not defined');
            return false;
        }

        ga(function() {
            var trackers = ga.getAll();
            trackers.forEach(function(tracker) {
                var trackerName = tracker.get('name');

                ga(trackerName + '.require', 'ecommerce');
                ga(trackerName + '.ecommerce:addTransaction', {
                    // Transaction ID (Order ID). Required
                    'id': order.id,
                    // Affiliation or store name
                    'affiliation': 'Octiive',
                    // Grand Total
                    'revenue': order.price
                });
                console.debug(tracker.get('name') + '.ecommerce:addTransaction');

                order.orderItems = order.orderItems || [];
                for (var i = 0; i < order.orderItems.length; i++) {
                    ga(trackerName + '.ecommerce:addItem', {
                        // Transaction ID (Order ID). Required
                        'id': order.id,
                        // Product name. Required
                        'name': order.orderItems[i].title,
                        // SKU/code (Product ID)
                        'sku': order.orderItems[i].productId,
                        // Unit price
                        'price': order.orderItems[i].price,
                        // Quantity. We do not have "quantity" in system.
                        // For instance, for such product as Mastering we keep the price for all tracks in "price"
                        'quantity': '1'
                    });
                    console.debug(trackerName + '.ecommerce:addItem');
                }

                ga(trackerName + '.ecommerce:send');
                console.debug(trackerName + '.ecommerce:send');
            });
        });

    },
    
    sendDataLayerEvents: function (gtmTags) {
        for (var k in gtmTags){
            if (gtmTags.hasOwnProperty(k)) {
                for (var i in gtmTags[k]) {
                    var tagToBePushed = {};
                    tagToBePushed[k] = gtmTags[k][i];

                    dataLayer.push(tagToBePushed);
                }

            }
        }
    },

    checkIfAnalyticsLoaded: function(){

        if (typeof ga === "function") {
            return true;
        }

        ++this.attemptsNumber;
        return false;
    }
};


export default new GoogleAnalytics();