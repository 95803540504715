export const color = {
    methods: {
        getColor(isrcName) {
            let res,
                self = this,
                search = this.colorMap.some(color => {
                    if (color.isrcName === isrcName) {
                        res = color.value;
                        return true
                    }
                });
            if (!search) {
                res = createNewColor(isrcName);
            }

            return createColor(res);

            function createNewColor(isrcName) {
                let newColorValue = getColorValues();

                let isSame = self.colorMap.some(color => {
                    return color.value === newColorValue
                });
                if (isSame) {
                    return self.createNewColor();
                }

                self.colorMap.push({value: newColorValue, isrcName});


                return newColorValue;
            }

            function getColorValues() {
                return '' + random() + '' + random() + '' + random()
            }

            function random() {
                const rand = Math.floor(Math.random() * (255));
                return ('000' + rand).slice(-3);
            }

            function createColor(colorValue) {
                const values = colorValue.match(/.{1,3}/g);
                return 'rgb(' + values[0] + ', ' + values[1] + ', ' + values[2] + ')';
            }
        },
        getColorByName(name) {
            const findColor = this.colorMap.find(color => {
                if (color.isrcName === name) {
                    return color
                }
            });
            if (!findColor) {
                return
            }
            const values = findColor.value.match(/.{1,3}/g);
            return 'rgb(' + values[0] + ', ' + values[1] + ', ' + values[2] + ')';

        }
    }
};