var STATUS_NEW = 0;
var STATUS_DRAFT = 1;
var STATUS_IN_PROGRESS = 2;
var STATUS_COMPLETED = 3;

var STATUSES_DATA = {};

STATUSES_DATA[STATUS_NEW] = {
  class: 'rouge',
  text: 'New'
};
STATUSES_DATA[STATUS_DRAFT] = {
  class: 'gray',
  text: 'Draft'
};
STATUSES_DATA[STATUS_IN_PROGRESS] = {
  class: 'blue',
  text: 'In progress'
};
STATUSES_DATA[STATUS_COMPLETED] = {
  class: 'green',
  text: 'Completed'
};

import Backbone from 'backbone';
import moment from "moment/moment";
import MondoblastLinkModel from './mondoblast/link';
import MondoblastLinkCollection from '../../collections/marketing/mondoblast/links';
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
  relations: [
    {
      type: Backbone.HasMany,
      key: 'links',
      relatedModel: MondoblastLinkModel,
      collectionType: MondoblastLinkCollection,
      reverseRelation: {
        key: 'mondoblast',
        relationalType: Backbone.HasOne
      }
    }
  ],
  defaults: {},
  urlRoot: "/api/mondoblast",
  albumTypes: {
    1: 'Single',
    2: 'EP',
    3: 'Album'
  },
  getEditUrl: function () {
    return '/marketing/prblast/' + this.getId()+'/edit';
  },
  getShowUrl: function () {
    return '/marketing/prblast/' + this.getId()+'/show';
  },
  getUrlForSending: function () {
    return this.urlRoot + '/' + this.id + '/send';
  },
  getId: function () {
    return this.get('id');
  },
  getStatus: function () {
    return parseInt(this.get('status'));
  },
  getQaReleaseFormatText: function (type) {
    return this.albumTypes[type];
  },
  getLinks: function () {
    return this.get('links');
  },
  getStatusData: function () {
    return STATUSES_DATA[this.getStatus()];
  },
  isEditable: function () {
    var status = this.getStatus();

    return (status === STATUS_NEW || status === STATUS_DRAFT);
  },
  getCreatedAt: function () {
    return this.get('createdAt');
  },
  getCreatedAtFormatted: function (format) {
    var createdAt = this.getCreatedAt();

    format = format || 'MMMM D, YYYY';

    if (!createdAt) {
      return '';
    }

    return moment.unix(createdAt).format(format);
  },
  sendModel: function () {
    var self = this;

    return Backbone.sync("create", self, {
      url: self.getUrlForSending()
    })
      .done(function (project) {
        self.set(project);
      });
  }
});
