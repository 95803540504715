<template>
    <loader-big v-if="firstLoader" :substrate="substrate" :text="textToShow"/>
    <loader-small v-else :substrate="substrate" :text="textToShow"/>
</template>

<script>
import loaderBig from '@/components/loader-big';
import loaderSmall from '@/components/loader-small';

export default {
    props: ['text', 'substrate', 'randomText'],
    name: "random-loader",
    data() {
        return {
            textList: [
                "Hi! We’ve been waiting for you.",
                "OK - Let's rock!",
                //  "We really like your work",
                //  "Let's make Octiive great again",
                //  "Have a great time here",
                "Welcome back!",
                "We’re preparing the goodies",
                "Please Wait.",
                // "Your shoe's untied...",
                "Now entering Octiive, your homebase",
                "Our team awaits your action",
                // "Who's a good artist?!",
                // "You're looking great in this mask",
                "Remember - Wash your hands and keyboard",
                // "You're healthy when you're at home with us",
            ],
        };
    },
    computed: {
        firstLoader() {
            return Math.random() > 0.5;
        },
        textToShow() {
            // general random text
            if (this.randomText === true) {
                let index = Math.floor(Math.random() * this.textList.length);

                return this.textList[index];
            }

            // dynamic random text
            if (this.randomText && Array.isArray(this.randomText)) {
                let index = Math.floor(Math.random() * this.randomText.length);

                return this.randomText[index];
            }

            // just in case
            if (this.text && Array.isArray(this.text)) {
                let index = Math.floor(Math.random() * this.text.length);

                return this.text[index];
            }

            return this.text;
        },
    },
    components: {
        loaderBig,
        loaderSmall,
    }
 }
</script>