import Backbone from 'backbone'
import moment from 'moment/moment'
import 'backbone-relational/backbone-relational'
import WalletModel from './balance-wallet'

export default Backbone.RelationalModel.extend({
    url: "/api/user/balance-info",

    relations: [
        {
            type: Backbone.HasMany,
            key: 'wallets',
            relatedModel: WalletModel,
        }
    ],


    defaults: {
        lastRequest: null,
        lastMonthRoyalty: 0,
        lastPaidRoyalty: null,
    },

    getRolloverAmount: function () {
        return this.get('rolloverAmount');
    },

    getLastRequestDate: function () {
        return this.get('lastRequestDate');
    },

    getLastRequestDateFormatted: function (format) {
        var date = this.getLastRequestDate();

        format = format || 'MMM DD, YYYY';

        if (!date) {
            return '';
        }

        return moment(date, 'MM/DD/YYYY hh:mm:ss').format(format);
    },

    getLastMonthRoyalty: function () {
        return this.get('lastMonthRoyalty');
    },

    getLastPaidRoyaltyAmount: function () {
        return this.get('lastPaidRoyaltyAmount');
    },

    getLastPaidRoyaltyDate: function () {
        return this.get('lastPaidRoyaltyDate');
    },

    getLastPaidRoyaltyDateFormatted: function (format) {
        var date = this.getLastPaidRoyaltyDate();

        format = format || 'MMM DD, YYYY';

        if (!date) {
            return '';
        }

        return moment(date, 'MM/DD/YYYY hh:mm:ss').format(format);
    },

    getRequestThreshold: function () {
        return this.get('requestThreshold');
    },

    canMakeRequest: function () {
        return this.get('canMakeRequest');
    },

    reasonIfUserCannotMakeNewRequest: function () {
        let lastRequest = this.get('lastRequest');

        if (!lastRequest) {
            return '';
        }

        switch(lastRequest.state) {
            case 1:
                return 'You have an un-verified claim request. Please check your email and verify it first.';
            case 2: 
                return 'You already have an in-progress claim request. Please wait before making a new claim.';
            default:
                return ''
        }
    },
});
