<template>
  <div ref="tooltipster" :class="['tooltipster',adclass]" @mouseover="TtOpen()" @mouseout="TtClose">
    <slot></slot>
  </div>
</template>

<script>
  import 'tooltipster/dist/css/tooltipster.bundle.min.css';
  import $ from 'jquery';
  import 'tooltipster';

  export default {
    name: 'tooltip',
    methods: {
      TtOpen: function () {
        $(this.$el).tooltipster({
          content: this.label,
          contentAsHTML: true,
          trackerInterval: 10,
          trackOrigin: true,
          trigger: 'custom',
          distance: 8,
          minIntersection: 0,
          arrow: false,
          theme: 'tooltipster-octive ' + this.themeClass,
            functionInit: function(instance, helper){

            },
          functionPosition: function (instance, helper, position) {
            if (position.target >= window.innerWidth / 2) {
              position.coord.left = position.target - position.size.width + helper.origin.offsetWidth - 12;
            }
            else {
              position.coord.left = position.target - 12
            }
            if(position.coord.left < 0) {
                position.size.width = position.size.width + (position.coord.left + 35);
                position.coord.left = 15;
            }
            if(position.coord.left+position.size.width  > window.innerWidth){
                position.size.width = position.size.width + (window.innerWidth -( position.coord.left+position.size.width ) -15)
            }
            return position;
          },
            functionReady: function (instance, helper) {
                let oldHeigh = helper.tooltip.offsetHeight,
                    top = Number.parseFloat(helper.tooltip.style.top);
                helper.tooltip.style.height = null;
                let newHeight = helper.tooltip.offsetHeight;
                if (newHeight > oldHeigh) {
                    helper.tooltip.style.top = top + (oldHeigh - newHeight) + 'px';
                }
            }
        }).tooltipster('open');
      },
      TtClose: function () {
        $(this.$el).tooltipster('destroy');
      }
    },
    props: {
      label: {
        default: ''
      },
      adclass: {},
      themeClass: {
        default: ''
      },
      tooltipsterOptions:{}
    },
  }
</script>

<style lang="scss">
  
  .tooltipster {
    display: inline-block;
    /* margin-top: torem(-3px);
     margin-left: torem(-8px);*/
    margin: 0;
    margin-right: torem(8px);
    &-octive & {
      &-box {
        background: $white;
        box-shadow: 0 torem(9px) torem(21px) $bblshadow;
        border: none;
        border-radius: torem(4px);
      }
      &-content {
        font-size: torem(14px);
        line-height: torem(19px);
        font-weight: $fw-medium;
        color: $color-62;
      }
    }
    &-octive-error &-content {
      color: $errofontColor;
      font-weight: $fw-medium;
    }
    &-octive {
      .tooltipster-arrow-border,
      .tooltipster-arrow-background {
        border-top-color: $white !important;
        box-shadow: 0 torem(9px) torem(21px) $bblshadow !important;
      }
    }
    .ficon {
      font-size: torem(18px);
      color: $color-f4;
      width: torem(18px);
      margin: 0;
      &:before {
      
      }
    }
  }
  @include media-breakpoint-down(md){
    .tooltipster{
      .ficon{
        display: flex;
      }
    }
  }
</style>
