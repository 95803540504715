<template>
  <div class="masteringItem" :class="{'masteringItem-disabled':disabled }">
    <div class="masteringItem-info">
      <p class="masteringItem-title">
        {{item.attributes.title.replace('Mastering. ','') }} ({{item.attributes.paramsData.min_tracks}}-{{item.attributes.paramsData.max_tracks}}).
        <span>${{item.attributes.price}}</span> <span class="track">/track</span>
      </p>
    </div>
    <div class="masteringItem-spinner">
      <ui-spinner
        ref="spinner"
        :min="Number.parseInt(item.attributes.paramsData.min_tracks)"
        :max="Number.parseInt(item.attributes.paramsData.max_tracks)"
        :disabled="disabled"
        v-model="count"
      />
    </div>
    <div class="masteringItem-total">
      ${{price.toFixed(2)}}
    </div>
    <button :class="
    [
    'masteringItem-btn',
    'btn',
    'ficon',
    {'icon-plus':!disabled},
    {'icon-minus':disabled},
    {'masteringItem-btn--remove':disabled}
    ]"
            @click="onClick($event)">
      {{disabled?'Remove':'Add'}}
    </button>
  </div>
</template>

<script>
  import uiSpinner from '@/components/spinner'

  export default {
    props: ['item', 'disabled', 'buttonText'],
    data() {
      return {
        count: 1,

      }
    },
    name: "mastering-item",
    mounted() {
      this.count = Number.parseInt(this.item.attributes.paramsData.min_tracks);
    },
    methods: {
      onClick(e) {
        if (!this.disabled) {
          this.$emit('add', {
            product: this.item,
            productAttributes: {tracks_count: this.count},
            btn: e.currentTarget
          })
        }
        else {
          this.$emit('remove', {
            product: this.item,
            productAttributes: {tracks_count: this.count},
            btn: e.currentTarget
          })
        }

      }
    },
    computed: {
      price() {
        return this.item.attributes.price * this.count
      }
    },
    components: {
      uiSpinner
    }
  }
</script>

<style lang="scss">
  .masteringItem {
    box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
    background-color: $white;
    padding: torem(35px) torem(40px);
    margin: torem(46px) 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    &-info {
      flex: 1 1 100%;
      max-width: torem(528px);
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    &-title {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      
      font-weight: bold;
      font-size: torem(24px);
      line-height: torem(29px);
      color: $mainfont;
      span {
        color: $blue-18;
        &.track{
          font-weight: $fw-600;
          font-size: torem(20px);
          line-height: torem(24px);
        }
      }
    }
    &-btn {
      font-size: torem(18px);
      font-weight: $fw-bold;
      height: torem(38px);
      line-height: 1;
      outline: none!important;
      &:before{
        display: none;
      }
      &--remove{
        background: $mainfont;
        border-color: $mainfont;
        &:active,&:disabled{
          background: transparent;
          color: $mainfont;
        }
        &:hover{
          box-shadow: 0 torem(2px) torem(8px) $mainfont;
        }
      }
    }
    &-spinner {
      flex: 0 0 torem(84px);
      width: torem(84px);
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .ui-spinner {
        margin: auto;
      }
    }
    &-total {
      font-weight: $fw-bold;
      font-size: torem(24px);
      line-height: torem(29px);
      color: $mainfont;
      margin-left: auto;
      margin-right: auto;
      flex: 1 2 100%;
      max-width: torem(350px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @include media-breakpoint-down(sm) {
    .masteringItem{
      padding: torem(30px) torem(37px);
      align-items: center;
      margin-bottom: torem(22px);
      margin-top: torem(22px);
      &-spinner{
        width: auto;
      }
      &-title {
        font-size: torem(18px);
        line-height: torem(22px);
        font-weight: $fw-bold;
        span.track{
          font-size: torem(14px);
        }
      }
      &-btn{
        &.btn{
          min-width: torem(30px);
          widows: torem(30px);
          height: torem(30px);
          font-size: 0;
          &:before{
            display: block;
            font-size: torem(15px);
          }
          &.icon-minus:before{
            font-size: torem(5px);
            width: auto;
          }
        }
      }
      &-btn.btn {
        margin: 0;
      }
      &-total{
        font-size: torem(18px);
        line-height: torem(22px);
      }
      &-info {
        max-width: torem(270px);
        font-size: torem(18px);
      }
    }
  }
</style>