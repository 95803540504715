import Backbone from 'backbone'
import moment from 'moment/moment'
import 'backbone-relational/backbone-relational'

export default Backbone.RelationalModel.extend({
    defaults: {
        rolloverAmount: 0,
        requestThreshold: 0,
        canMakeRequest: false,
        currency: 'usd',
        lastRequest: null,
        lastMonthRoyalty: 0,
        lastPaidRoyalty: null,
    },

    getRolloverAmount: function () {
        return this.get('rolloverAmount');
    },

    getFormattedRolloverAmount: function () {
        return this.getFormattedAmount(this.getRolloverAmount());
    },

    getFormattedAmount: function (amount) {
        switch (this.getCurrency()) {
            case 'eur':
                return '€' + amount;
            case 'usd':
                return '$' + amount;
            default:
                return amount + ' ' + this.getCurrency();
        }
    },

    getRequestThreshold: function () {
        return this.get('requestThreshold');
    },

    canMakeRequest: function () {
        return this.get('canMakeRequest');
    },

    belowThreshold: function () {
        return parseInt(this.getRolloverAmount()) < parseInt(this.getRequestThreshold());
    },

    getLastRequest: function () {
        return this.get('lastRequest');
    },

    getLastRequestDate: function () {
        var lastRequest = this.getLastRequest();

        return lastRequest ? lastRequest.date : 0;
    },

    getLastRequestDateFormatted: function (format) {
        var date = this.getLastRequestDate();

        format = format || 'MMM DD, YYYY';

        if (!date) {
            return '';
        }

        return moment(date, 'MM/DD/YYYY hh:mm:ss').format(format);
    },

    getLastPaidRoyalty: function () {
        return this.get('lastPaidRoyalty');
    },

    getLastPaidRoyaltyAmount: function () {
        var lastPaidRoyalty = this.getLastPaidRoyalty();

        return lastPaidRoyalty ? lastPaidRoyalty.amount : 0;
    },

    getFormattedLastPaidRoyaltyAmount: function () {
        return this.getFormattedAmount(this.getLastPaidRoyaltyAmount());
    },

    getLastMonthRoyalty: function () {
        return this.get('lastMonthRoyalty');
    },

    getFormattedLastMonthRoyalty: function () {
        return this.getFormattedAmount(this.getLastMonthRoyalty());
    },

    getLastPaidRoyaltyDate: function () {
        var lastPaidRoyalty = this.getLastPaidRoyalty();

        return lastPaidRoyalty ? lastPaidRoyalty.date : 0;
    },

    getLastPaidRoyaltyDateFormatted: function (format) {
        var date = this.getLastPaidRoyaltyDate();

        if (!date) {
            return '';
        }

        format = format || 'MMM DD, YYYY';

        return moment(date, 'MM/DD/YYYY hh:mm:ss').format(format);
    },

    getCurrency: function () {
        return this.get('currency');
    },

    getFormattedThresholdAmount: function () {
        return this.getFormattedAmount(this.getRequestThreshold());
    },

    getBelowThresholdMessage: function () {
        return 'Oops! It seems you’re just shy of the ' + this.getFormattedThresholdAmount() + ' threshold to claim your royalties. Keep those tunes flowing and watch those sales climb. See you soon!';
    }
});
