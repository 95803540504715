<template>
    <div class="viewlabel" v-if="label">
        <div class="viewlabel-poster" v-if="label.getLogo()">
            <img :src="label.getLogo().getLogoUrl()" alt="">
        </div>
        <div class="viewlabel-info">
            <p class="viewlabel-uname">
                {{user.attributes.firstName}} {{user.attributes.lastName}}
            </p>
            <p class="viewlabel-lname">
                {{label.attributes.name}}
            </p>
            <p class="viewlabel-support d-none d-sm-block">
                Please email <a href="mailto:support@octiive.com"> support@octiive.com </a> to change your label artwork
            </p>
        </div>
        <p class="viewlabel-support d-block d-sm-none">
            Please email <a href="mailto:support@octiive.com"> support@octiive.com </a> to change your label artwork
        </p>
    </div>
</template>

<script>
    export default {
        props: ['label', 'user'],
        name: "view-label",
    }
</script>

<style lang="scss">
    .viewlabel {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        &-poster {
            $size: torem(157px);
            flex: 0 0 $size;
            @include coner;
            width: $size;
            height: $size;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: torem(15px);
            img {
                min-width: 100%;
                width: 100%;
                min-height: 100%;
                height: 100%;
            }
        }
        &-info {
            padding-left: torem(15px);
            flex: 1 1 100%;
            display: flex;
            flex-direction: column;
        }
        &-support {
            margin-top: auto;
        }
        &-uname {
            font-weight: $fw-extrabold;
            font-size: torem(24px);
            line-height: torem(29px);
            display: flex;
            align-items: center;
            color: $mainfont;
            margin-bottom: torem(4px);
        }
        &-lname {
            font-weight: $fw-semibold;
            font-size: torem(24px);
            line-height: torem(29px);
            display: flex;
            align-items: center;
            color: $color-94;
        }
        &-support {
            font-weight: $fw-medium;
            font-size: torem(18px);
            line-height: torem(23px);
            color: $mainfont;
            a {
                color: $blue-18;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .viewlabel{
            display: flex;
            flex-wrap: wrap;
            
            &-info{
                flex: 1 1 50%;
            }
            &-poster{
                flex: 0 0 auto;
                $size:torem(125px);
                width: $size;
                height: $size;
                padding: 0;
            }
            &-info{
                padding-left: torem(9px);
            }
            &-support{
                flex: 1 1 100%;
                margin-top: torem(8px);
                font-size: torem(16px);
                line-height: torem(21px);
                font-weight: $fw-400;
            }
            &-lname,&-uname{
                font-size: torem(18px);
                line-height: torem(22px);
            }
        }
    }
</style>