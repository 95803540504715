<template>
    <div class="container d-flex flex-column  align-items-stretch" >
        <div class="row">
            <div class="col-12 ">
                <h1 class="main-h1">
                    Services
                </h1>
            </div>
        </div>
        <template v-if="idReady">
            <div class="row" >
                <div class="col-12">
                    <sItem ref="prblast"
                           v-if="prBlast">
                        <p class="h1" slot="h1">{{prBlast.attributes.title}}
                            <span>${{prBlast.attributes.price}}</span></p>
                        <p class="h2" slot="h2"
                           v-html="'Create buzz about your latest release with our professional writers. '">
                        </p>
                        <div class="squere" slot="squere">
                            <i class="ficon icon-sq-lern"></i>
                            <p class="title">1. Learn</p>
                            <p class="discr">
                                Our marketing team will reach out to you or the band/artist and gather information about
                                your upcoming
                                release (influences, inspiration for the single, EP, album, or video, upcoming events
                                etc.).
                            </p>
                        </div>
                        <div class="squere" slot="squere">
                            <i class="ficon icon-sq-listen"></i>
                            <p class="title">2. Listen</p>
                            <p class="discr">
                                One of our world-class staff writers will actually take the time to listen to your music
                                to help get a
                                full
                                understanding of you as an artist and the mood of your single, EP, album, or video.
                            </p>
                        </div>
                        <div class="squere" slot="squere">
                            <i class="ficon icon-sq-write"></i>
                            <p class="title">3. Write</p>
                            <p class="discr">
                                Our marketing team will write a custom press release about your news for use by real
                                music journalists and
                                curious music fans all over the world!
                            </p>
                        </div>
                        <div class="squere" slot="squere">
                            <i class="ficon icon-sq-promote"></i>
                            <p class="title">4. Promote</p>
                            <p class="discr">
                                We publish your news to our network of music publications.
                                Share the news on your social media sites to generate an even bigger buzz.
                                It’s a great way to let the world hear the story behind your music!
                            </p>
                        </div>
                        
                        <button class="btn" slot="btn" :disabled="!cart" @click="addOrRemove($event,prBlast)">
                            {{cart.attributes.cartItems.models.some(cartItem=>{ return cartItem.productId === 10 })?
                            'Remove from cart':
                            'Add to cart'}}
                        </button>
                    </sItem>
                    <sItem ref="bio"
                           v-if="artistCustomBio">
                        <p class="h1" slot="h1"> {{artistCustomBio.attributes.title}}
                            <span> ${{artistCustomBio.attributes.price}} </span></p>
                        <p class="h2" slot="h2" v-html="'Tell the world your story.'">
                        </p>
                        <div class="squere" slot="squere">
                            <i class="ficon icon-sq-discover"></i>
                            <p class="title">1. Discover</p>
                            <p class="discr">
                                Our marketing team will reach out to you or the band/artist and gather information about
                                your upcoming
                                release (influences, inspiration for the single, EP, album, or video, upcoming events
                                etc.).
                            </p>
                        </div>
                        <div class="squere" slot="squere">
                            <i class="ficon icon-sq-craft"></i>
                            <p class="title">2. Craft</p>
                            <p class="discr">
                                One of our world-class staff writers will actually take the time to listen to your music
                                to help get a
                                full
                                understanding of you as an artist and the mood of your single, EP, album, or video.
                            </p>
                        </div>
                        <div class="squere" slot="squere">
                            <i class="ficon icon-sq-write"></i>
                            <p class="title">3. Write</p>
                            <p class="discr">
                                Our marketing team will write a custom press release about your news for use by real
                                music journalists and
                                curious music fans all over the world!
                            </p>
                        </div>
                        <div class="squere" slot="squere">
                            <i class="ficon icon-sq-celebrate"></i>
                            <p class="title">4. Celebrate</p>
                            <p class="discr">
                                After the biography meets the satisfaction of the artist, we deliver the material for personal use.
                                You did it! Go tell the world your story!
                            </p>
                        </div>
                        
                        <button class="btn" slot="btn" :disabled="!cart" @click="addOrRemove($event,artistCustomBio)">
                            {{cart.attributes.cartItems.models.some(cartItem=>{ return cartItem.productId === 19 })?
                            'Remove from cart':
                            'Add to cart'}}
                        </button>
                    </sItem>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mastering
                        ref="mastering"
                        :products="products"
                        :cart="cart" />
                </div>
            </div>
        </template>
        <random-loader v-else :substrate="false" :text="loadingText" class="loader--poss" />
    </div>
</template>

<script>
    import Products from '@/components/collections/products';
    import Mastering from './components/services-mastering';
    import sItem from './components/services-item';
    import randomLoader from '@/components/random-loader';

    import {mapGetters} from 'vuex';

    export default {
        name: "services",
        data() {
            return {
                cart: null,
                products: null,
                prBlast: null,
                artistCustomBio: null,
                loadingText: [
                        "Well, what do we have here?",
                        "We have a range of professional tools just for you",
                        "Checking your browser history...",
                        "Checking our warehouses...",
                        // "Wash your hands before getting your cart",
                        "All our products have been sanitized for your safety.",
                        // "There are no masks left, sorry...",
                        // "Don't forget to order delivery to you grannies",
                ],
            }
        },
        mounted() {
            this.initCart();
            this.initProducts();
            
        },
        methods: {
            scrollTo(){
                if (this.$route.name) {
                    let animate = $('html, body').animate({
                        scrollTop: $(this.$refs[this.$route.name].$el).offset().top - ($('header').height() || 0) - 40
                    }, 200);
                }
            },
            initCart() {
                var self = this;
                this.$store.dispatch('cart/loadCart').then(cart => {
                    this.cart = cart;
                });
            },
            async initProducts() {
                let self = this;
                if (!this.productList) {
                    await this.$store.dispatch('product-list/load');
                }
                this.products = new Products(this.productList);
                this.prBlast = this.products.where({id: 10})[0];
                this.artistCustomBio = this.products.where({id: 19})[0];
            },
            addOrRemove(event, product) {
                let btn = event.currentTarget, self = this;
                btn.disabled = true;
                let cartItem = this.cart.getItemBy({id: product.attributes.id});
                if (!cartItem) {
                    this.cart.addItemToCart(product, {project_id: ''},
                        null,
                        function (error) {
                            self.$root.popup.showMessage(error);
                        }).finally(() => {
                        btn.disabled = false
                    });
                }
                else {
                    this.cart.removeItem(
                        cartItem,
                        async function success() {
                            await self.cart.fetch({
                                error(collection, error) {
                                    self.$root.popup.showMessage(error);
                                }
                            });
                            btn.disabled = false;
                        },
                        function error(model, resp) {
                            self.$root.popup.showMessage(resp);
                            btn.disabled = false;
                        }
                    )
                }
            },
        },
        computed: {
            idReady(){
                if(this.products &&  this.cart){
                    this.$nextTick(() => {
                        this.scrollTo();
                    });
                }
                return  this.products &&  this.cart
            },
            ...mapGetters({
                productList: 'product-list/get'
            }),
        },
        components: {
            sItem,
            mastering: Mastering,
            randomLoader,
        }
    }
</script>

<style lang="scss">

</style>