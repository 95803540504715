<template>
    <div class="vsubscription"
         :class="{
            'vsubscription--badged':!!(subscription && subscription.getStatus()),
            'vsubscription--waiting':isWaiting,
            'vsubscription--cnahget':isCnaget
         }"
         v-if="load">
        <span v-if="badge && badge.getStatusTitle()"
              class="vsubscription-badge"
              :class="badge.getStatusClass()">
            {{badge.getStatusTitle()}}
        </span>
        <div class="vsubscription-holder">
            <template v-if="!status">
                <p>
                    You have no <br class="d-sm-none"> distribution package yet
                    <router-link v-if="isMobile"
                                 class="vsubscription-mobile-link"
                                 :to="'/plans'">
                        Choose one
                    </router-link>
                </p>
                <router-link v-if="!isMobile"
                             class="vsubscription-link orange"
                             :to="'/plans'">
                    Choose One
                </router-link>
            </template>
            <template v-else>
                <p class="active">
                    {{subscription.attributes.title}}
                    <router-link
                        v-if="isMobile"
                        class="vsubscription-mobile-link"
                        :to="'/plans'">
                        {{badge.getButtonText()}}
                    </router-link>
                </p>
            </template>
            <router-link
                v-if="badge && badge.getButtonText() && !isMobile"
                :class="['vsubscription-link',{'orange':!subscription.isCurrent()}]"
                :to="'/plans'">
                {{badge.getButtonText()}}
            </router-link>
        </div>
    </div>
</template>

<script>
    import Badge from '@/components/models/subscription/music-distribution-badge';
    import {mapGetters} from 'vuex';
    import {isMobile} from '../../../components/mixins/isMobile';

    export default {
        name: "subscription",
        mixins:[isMobile],
        data() {
            return {
                statusTitle: false,
                statusClass: false,
                subscription: false,
                status: false,
                change: null,
                badge: null,
                load: false
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                let self = this;
                this.$store.dispatch('subscription/loadSubscription').then(subscription => {
                    this.subscription = subscription;
                    if (this.subscription) {
                        this.status = this.subscription.attributes.status;
                        self.badge = new Badge.createFromSubscription(self.subscription, true);
                        if (this.subscription.isWaiting()) {
                            this.subscription.fetchPayment({
                                success() {
                                    self.badge = new Badge.createFromSubscription(self.subscription, true);
                                    self.status = self.subscription.attributes.status;
                                },
                                fail(error) {
                                    self.$root.popup.showMessage(error);
                                }
                            })
                        }
                    }
                    this.change = this.$store.getters["subscription/getChange"];
                    this.load = true;
                });
            },
        },
        computed: {
            isWaiting: function () {
                if (this.subscription) {
                    this.badge = new Badge.createFromSubscription(this.subscription, true);
                    return this.subscription.isWaiting();
                }
                return this.subscription;
            },
            isCnaget() {
                this.init();
                this.$store.commit('subscription/setChange', false);
                return this.change;
            },
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
            })
        },
    }
</script>

<style lang="scss">
    .vsubscription {
        text-align: left;
        margin-right: auto;
        
        &-holder {
            display: flex;
            align-items: center;
            p {
                font-style: normal;
                font-weight: $fw-bold;
                font-size: torem(16px);
                line-height: 147%;
                color: $color-a4;
                &.active {
                    font-weight: $fw-bold;
                    font-size: torem(16px);
                    line-height: torem(20px);
                    //text-transform: uppercase;
                    color: $blue-18;
                    position: relative;
                    padding: torem(3px) 0;
                }
            }
        }
        &-link {
            font-size: torem(16px);
            line-height: torem(20px);
            text-align: center;
            font-weight: $fw-bold;
            color: $white;
            padding: torem(6px) torem(16px);
            white-space: nowrap;
            background-color: $vsubscriptionLink-ugrade;
            border-radius: torem(30px);
            margin-left: torem(18px);
            text-decoration: none;
            &:hover {
                color: $white;
                text-decoration: none;
            }
            &.orange {
                background-color: $orange-ff;
            }
        }
        &-badge {
            font-weight: $fw-800;
            font-size: torem(12px);
            line-height: torem(15px);
            text-transform: uppercase;
            padding: torem(2px) torem(6px);
            border-radius: torem(4px);
            color: $white;
            
            position: relative;
            bottom: torem(-4px);
            
            &.active {
                background-color: $color-44;
            }
            &.payment, &.gray {
                background-color: $color-ac;
            }
            &.rouge {
                background-color: $red-f3;
            }
        }
    }
    
    @include media-breakpoint-down(sm) {
        .vsubscription {
            max-width: torem(330px);
            p{
                font-size: torem(15px);
            }
            &-link {
                margin-left: torem(8px);
                padding: torem(6px) torem(11px);
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .vsubscription {
            flex: 1 1 auto;
            width: 100%;
            max-width: 100%;
            order: 2;
            margin-top: auto;
            &-badge{
                bottom: 0;
            }
            p{
                &,&.active {
                    font-size: torem(14px);
                    color: $white-f5f;
                }
            }
            &-mobile-link{
                $mobileLink-color: $color-f4;
                color: $mobileLink-color;
                border-bottom: torem(1.5px) solid $mobileLink-color;
            }
        }
    }

</style>