<template>
    <div class="switch-holder" :class="{'active':checked}">
        <input type="checkbox" v-model="checked" :data-name="name" class="switch-input">
        <span class="switch-label"> {{spanYes||'Yes'  }}</span>
        <span class="switch-label"> {{spanNo||'No'}} </span>
    </div>


</template>

<script>
    export default {
        name: "tswitch",
        props: ['name', 'spanYes', 'spanNo', 'value'],
        data() {
            return {
                checked: false,
            }
        },
        mounted() {
            var self = this;
            this.checked = this.value;
            $(this.$el).find('input').on('change', function (ev) {
                self.$emit('input', this.checked);
            })
        },

    }
</script>

<style lang="scss">
    .switch {
        &-holder {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $white;
            border: torem(1px) solid $color-de;
            box-sizing: border-box;
            border-radius: torem(4px);
            width: torem(80px);
            height: torem(32px);
            margin-left: torem(8px);
            &:before {
                position: absolute;
                right: torem(4px);
                top: 0;
                bottom: 0;
                z-index: 1;
                margin: auto;
                display: block;
                content: '';
                width: torem(34px);
                height: torem(24px);
                background: $blue-18;
                box-shadow: 0 torem(4px) torem(7px) $main-38;
                border-radius: torem(4px);
                transition: all .3s;
                
            }
            &.active:before {
                right: 50%;
            }
        }
        &-input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            opacity: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
        }
        &-label {
            position: relative;
            z-index: 2;
            font-size: torem(13px);
            display: inline-block;
            align-items: center;
            justify-content: center;
            color: $color-bd;
            width: torem(34px);
            line-height: torem(24px);
            text-align: center;
            transition: color .3;
            &:last-child {
                color: $white;
            }
        }
        &-holder.active &-label {
            color: $white;
            &:last-child {
                color: $color-bd;
            }
        }
    }
</style>