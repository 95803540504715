import axios from 'axios';
export default {
    state:{
        url:'/api/config/uploader',
        uploaderUrl: false,
    },
    mutations:{
        set(state, data) {
            state.uploaderUrl = data.uploaderUrl;
        },
    },
    getters:{
        getUploaderUrl: function (state) {
            return state.uploaderUrl;
        },
    },
    actions: {
        async load(store) {
            if (store.state.uploaderUrl) {
                return true
            }
            return axios.get(store.state.url).then(function (e) {
                store.commit("set", e.data);
            }).catch(error => {
                console.error(error);
            });
        },


    },
};