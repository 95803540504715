import axios from 'axios';

export default {
  state: {
    url: '/api/products',
    list: false,
  },
  mutations: {
    set(state, data) {
      console.log('set', state);
      state.list = data
    },
    setProductinfo(state, product) {
      let list = state.list;
      for (var i = 0; i < list.length; i++) {
        if (list[i].id === product.id) {
          setItemInfo(list[i], product.arg);
          return true
        }
      }

      function setItemInfo(item, arg) {
        for (var e in arg) {
          if (!(typeof(arg[e]) === 'object') || !(arg[e].length === undefined)) {
            item[e] = arg[e]
          }
          else {
            setItemInfo(item[e], arg[e]);
          }
        }
      }
    },

  },
  getters: {

    get(state) {
      console.log({list: state.list});
      return state.list
    },
    getItemById(state) {
      return id => {
        for (let i in state.list) {

          if (state.list[i].id == id) {

            return state.list[i];

          }
        }
        return null;
      }
    },
    getItemPriority(state) {
      return elem => {
        return elem.price * (elem.paramsData ? elem.paramsData[Object.keys(elem.paramsData)[0]] : 1)
      }
    }
  },

  actions: {
    async load(store) {
      const addition = [
        {
          id: 1, descr: `<ul>
                            <li>UNLIMITED UPLOADS with any 1 artist or band name</li>
                            <li>Free UPC and ISRC codes</li>
                            <li>No per store fees</li>
                            <li>600+ retailers in over 140 countries</li>
                        </ul>
                    `
        },
        {
          id: 2, descr: `<ul>
                            <li>UNLIMITED UPLOADS with any 2 to 4 artists or band names</li>
                            <li>Free UPC and ISRC codes</li>
                            <li>No per store fees</li>
                            <li>600+ retailers in over 140 countries</li>
                        </ul>
                    `
        },
        {
          id: 3, descr: `<ul>
                            <li>UNLIMITED UPLOADS with any 5 to 20 artists or band names</li>
                            <li>Free UPC and ISRC codes</li>
                            <li>No per store fees</li>
                            <li>600+ retailers in over 140 countries</li>
                        </ul>
                        <br>
                        <b>Label Registration is required.<sup>*</sup></b>
                    `
        },
      ];
      await axios.get(store.state.url).then(function (e) {
        if (store.state.list) return true;
        e.data.forEach(function (product) {
          const temp = {
            min: product.paramsData['min_tracks'] || 1,
            max: product.paramsData['max_tracks'] || 1
          };
          product['data'] = {
            min: +temp.min,
            max: +temp.max,
            value: +temp.min,
            isVis: temp.min != temp.max,
            incart: store.rootGetters["cart/isincart"](product.id),
          };
          addition.some(function (e) {
            if (product.id == e.id) {
              product['htmldescr'] = e.descr;
              return true
            }
          });
        });
        store.commit("set", e.data);
        return e;
      }).catch(error => {
        return {error: error.response};
      });
    },
  },

};