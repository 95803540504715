<template>
    <div class="container d-flex flex-column  align-items-stretch">
        <div class="row">
            <div class="col-12">
                <h1 class="main-h1">
                    {{label && label.getName() ?'Your label':'Register Your Label'}}
                </h1>
            </div>
        </div>
        <div class="row flex-fill" v-if="!labelIsReady">
            <div class="col-12 flex-fill d-flex flex-column  align-items-stretch ">
                <div class="vreglabel posr">
                    <random-loader class="loader--poss" :substrate="false" :randomText="true" />
                </div>
            </div>
        </div>
        <ValidationObserver v-else-if="labelIsReady && user && label"
                            tag="form" class="row"
                            ref="observer"
                            v-slot="{ invalid }"
                            @submit.prevent=""
        >
            <div class="col-12">
                <div class="vreglabel posr">
                    <template>
                        <div class="row"  v-if="!label.isRealized()">
                            <div class="col-12">
                                <p class="vreglabel-h1">
                                    Enter label information
                                </p>
                            </div>
                        </div>
                        <div class="row" :class="{'vreglabel-View':label.isRealized()}">
                            <div class="col-12 col-md-6" v-if="!label.isRealized()">
                                <div class="vreglabel-input">
                                    <inputTT
                                        title="Label Name"
                                        VPRules="required"
                                        v-model="labelName"
                                    />
                                </div>
                                <div class="vreglabel-toggler">
                                    <ttipSlot
                                        ttipText="ONLY choose this option if you are a label distributing Electronic Dance Music. Retailers who work exclusively with EDM, have specific requirements."
                                    >
                                        <toggler
                                            v-model="isEdm"
                                            buttonText="Are you an EDM label?" />
                                    </ttipSlot>
                                </div>
                                <div class="vreglabel-uploader" :class="{'vreglabel-uploader-disabled':!isEdm}">
                                    <p class="vreglabel-uploader-h1">
                                        Upload logo
                                    </p>
                                    <p class="vreglabel-uploader-p">
                                        Logo must be 3000*3000 pixels
                                    </p>

                                    <ValidationProvider
                                        name="VPtitle"
                                        class="vreglabel-uploader-cont"
                                        tag="div"
                                        v-slot="{errors, classes}"
                                        :rules="{checkLogo:isEdm}">

                                        <DragAndDrop
                                            :imgcover="false"
                                            :customClass="{'error':classes.invalid}"
                                            :text=" isMobile ?'Logo': 'Drag & Drop logo here <br> or <br>'"
                                            @upload="preparationToUpload($event)"
                                            ref="upload"
                                            accept="image/*"
                                            :disabled="!isEdm || isBusy"
                                        />
                                        <span style="display: none" v-model="logo"></span>
                                        <div class="vreglabel-logo" v-if="logo">
                                            <img :src="logo.attributes.path" class="vreglabel-logo-img" alt="logo">
                                        </div>
                                        <div ref="logoProgress" v-show="progress"
                                             class="vreglabel-logo--progress"></div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-12 d-md-none">
                                <p class="vreglabel-bold " :class="[
                                    {'isRealized':label.isRealized()},
                                    {'isShowing':isShowing}
                                ]" @click="toggleHider">
                                    Social Links (optional)
                                    <i class="ficon icon-farrow d-sm-none"></i>
                                </p>
                            </div>
                            <div class="col-12 col-md-6 vreglabel-socials"
                                 :class="[
                                    {'realized':label.isRealized()},
                                    {'isShowing':isShowing}
                                 ]">
                                <template v-if="label.isRealized()">
                                    <view-label :user="user" :label="label" />
                                </template>
                                <div class="vreglabel-input">
                                    <inputTT
                                        title="Website"
                                        ttInfoLabel="E.g. https://www.mondotunes.com/"
                                        VPtitle="Website"
                                        :VPRules="{min:2,website:'',}"
                                        v-model="label.attributes.websiteUrl"
                                    />
                                </div>
                                <div class="vreglabel-input">
                                    <inputTT
                                        title="Facebook"
                                        ttInfoLabel="E.g. https://www.facebook.com/octiive"
                                        :VPRules="{min:2,website:''}"
                                        v-model="label.attributes.facebookUrl"
                                    />
                                </div>
                                <div class="vreglabel-input">
                                    <inputTT
                                        title="Twitter"
                                        ttInfoLabel="E.g. https://twitter.com/OctiiveMusic"
                                        v-model="label.attributes.twitterUrl"
                                    />
                                </div>
                                <div class="vreglabel-input">
                                    <inputTT
                                        title="Instagram"
                                        ttInfoLabel="E.g. https://www.instagram.com/Octiive/"
                                        :VPRules="{min:2,website:''}"
                                        v-model="label.attributes.instagramUrl"
                                    />
                                </div>
                                <button ref="submitButton" class="btn btn--full" @click="onSubmit()">
                                    <template v-if="!label.isRealized()">
                                        Register a Label
                                    </template>
                                    <template v-else>
                                        Save changes
                                    </template>
                                </button>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </ValidationObserver>
        <emptyLabel :labelProduct="products.findWhere({id: 5})" v-else-if="labelIsReady && user && !label && products" />
        <popup
            ref="popup"
            v-model="isConfirmShown"
            okButtonText="Ok"
            cancelButtonText="Cancel"
            :text="confirmText"
            @okClick="saveModel()"
            @cancelClick="confirmCancle()"
        ></popup>
    </div>
</template>

<script>
    import Products from '@/components/collections/products';
    import inputTT from '@/components/input-tt'
    import toggler from './components/toggler-item';
    import DragAndDrop from '@/Views/projects/components/drag-and-drop';
    import ttipSlot from '@/components/ttip-slot';
    import viewLabel from './components/view-label';
    import emptyLabel from './components/empty-label';
    import popup from '@/components/popup';
    import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
    import UploaderSDK from 'uploader-sdk/dist/uploader-sdk.esm';
    import {toggle} from '../../Views/settings/mixins/toggle';

    import {mapGetters} from 'vuex';
    import {isMobile} from '../../components/mixins/isMobile';
    import axios from 'axios';
    import randomLoader from '@/components/random-loader';

    extend('website', {
        validate(e) {
            let regExp = /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
            return e.length && regExp.test(e);
        },
        message: 'Must be website link '
    });
    extend('checkLogo', {
        params: ['target'],
        validate(e, target) {
            return !!(e && e.attributes.path);
        },
    });

    export default {
        name: "register-label",
        mixins:[isMobile,toggle],
        data() {
            return {
                label: null,
                labelName: null,
                logo: null,
                oldLogoPath: false,
                isConfirmShown: false,
                confirmText: null,
                isEdm: false,
                isBusy: false,
                progress: false,
                user: null,
                labelIsReady: false,
                products:null,
            }
        },
        mounted() {
            this.user = this.$store.getters['user/user'];

            if (!this.user) {
                this.$store.dispatch('user/loadUser').then(user => {
                    this.user = user;
                    this.initLabel();
                });
            }
            else {
                this.user.loadCurrent().then(() => {
                    this.initLabel();
                });
            }

            this.initUploader();
        },
        methods: {
            async initLabel() {
                this.label = this.user.getLabel();
                if (this.label) {
                    this.isEdm = this.label.attributes.isEdm;
                    this.updateLogo();
                } else{
                   await this.initProducts()
                }
                this.labelIsReady = true;
            },
            async onSubmit() {
                if (this.label.isRealized()) {
                    this.saveModel();
                }
                this.$refs.submitButton.disabled = true;
                this.label.set('name', this.labelName);
                this.label.set('isEdm', this.isEdm);
                if (!this.isEdm) {
                    this.label.set('logo', null);
                    this.updateLogo()
                }

                var isValid = this.label.isValid(), formValid = await this.$refs.observer.validate();
                var isReleased;

                if (!isValid || !formValid) {
                    return
                }

                isReleased = this.label.isRealized();

                if (!isReleased && isValid) {
                    this.showConfirm("*IMPORTANT*\nOnce submitted, your label name CANNOT be changed. " +
                        "Make all necessary proof-reading, spell checking, rough draft editing, and hangs in foolish mistakes now. " +
                        "Failure to do so will result in the misrepresentation of your soon-to-be international dynasty. " +
                        "Be as mindful as possible.");
                }
            },
            async initProducts() {
                let self = this;
                if (!this.productList) {
                    await this.$store.dispatch('product-list/load');
                }
                this.products = new Products(this.productList);
            },

            confirmCancle() {
                this.$refs.submitButton.disabled = true;
            },

            saveModel() {
                let
                    self = this,
                    label = this.label,
                    submitButton = this.$refs.submitButton;
                submitButton.disabled = true;
                let message = "Successfully saved";
                if (!label.isRealized()) {
                    message = "Congrats!\nWe received your information and are in the process " +
                        "of registering your label name with 600+ retailers in over 140 countries.";
                }
                label.saveModel({
                    success: function () {
                        submitButton.disabled = false;

                        self.$root.popup.showMessage(message);
                        submitButton.disabled = false;
                    },
                    error: function (model, xhr) {
                        submitButton.disabled = false;
                        self.$root.popup.showMessage(xhr.responseJSON);
                        label.attributes.name = '';
                        submitButton.disabled = false;
                    }
                })
            },

            updateLogo() {
                this.logo = this.label.getLogo();
                if (this.logo) {
                    this.oldLogoPath = this.logo.attributes.path;
                }
            },

            showConfirm(text) {
                this.$refs.popup.showMessage(text);
            },

            /*preparation cover to upload */
            preparationToUpload(files) {
                /*Filter by type*/
                this.$refs.submitButton.disabled = true;
                files = files.filter(e => {
                    return /image/g.test(e.type)
                });
                this.onUpload(files[files.length - 1]);
            },

            /*show new logo*/
            showTemplateLogo(src) {
                if (this.logo) {
                    this.logo.attributes.path = src
                } else {
                    this.logo = {
                        attributes: {path: src}
                    }
                }
            },

            async onUpload(file) {
                let self = this;
                this.isBusy = true;
                axios.post('/api/label/logo/slot').then((e) => {
                    self.uploader.uploadFile(
                        UploaderSDK.factory.createFile(file, e.data.id)
                    );
                }).catch(error => {
                    self.$root.popup.showMessage(error);
                });
            },
            checkLogo() {
                this.label.fetch().then(() => {
                    let newLogo = this.label.getLogo();
                    if (newLogo && newLogo.getLogoUrl() && newLogo.getLogoUrl() !== this.oldLogoPath) {
                        this.$refs.submitButton.disabled = false;
                        this.updateLogo();
                        this.isBusy = false;
                        this.showProgress(false);
                        return
                    }
                    this.checkLogo();
                });
            },

            showProgress(progress) {
                this.progress = progress;
                if (this.progress) {
                    this.$refs.logoProgress.style.width = this.progress + '%';
                }
            },

            async initUploader() {
                let self = this;
                await this.$store.dispatch('uploader-config/load');
                this.uploader = UploaderSDK.factory.createUploader(this.$store.getters['uploader-config/getUploaderUrl']);

                this.uploader.onFileProgress((file, progress) => this.showProgress(progress));
                this.uploader.onFileError((file, error) => {
                  self.$root.popup.showMessage(error)
                  self.$root.popup.onConfirm = function () {
                    self.$nextTick(() => {
                      self.$root.popup.clear();
                      self.isBusy = false;
                    });
                  }
                });
                this.uploader.onFileSuccess((file) => {
                    this.checkLogo()
                });
            },
        },
        components: {
            inputTT,
            DragAndDrop,
            toggler,
            ttipSlot,
            viewLabel,
            popup,
            ValidationObserver,
            ValidationProvider,
            emptyLabel,
            randomLoader,
        },
        computed:{
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
                productList: 'product-list/get'
            })
        }
    }
</script>

<style lang="scss">
    .vreglabel {
        @include obj;
        @include coner;
        box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 0;
        padding: torem(95px) torem(204px) torem(140px) torem(204px);
        &-h1 {
            font-weight: $fw-extrabold;
            font-size: torem(24px);
            line-height: torem(29px);
            display: flex;
            align-items: center;
            color: $mainfont;
            margin-bottom: torem(70px);
        }
        &-input {
            margin-bottom: torem(30px);
        }
        &-toggler {
            margin-top: torem(46px);
            padding-left: torem(30px);
        }
        &-logo {
            @include coner;
            overflow: hidden;
            flex: 0 0 torem(150px);
            max-width: torem(150px);
            height: torem(150px);
            margin-right: torem(17px);
            position: relative;
            order: -1;
            &--progress {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: auto;
                height: torem(4px);
                background: #FF6337;
            }
            img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                min-height: 100%;
                height: 100%;
                min-width: 100%;
                width: 100%;
            }
        }
        &-uploader {
            margin-top: torem(126px);
            .dnd-area.error {
                border-color: $red-f3 !important;
                .dnd-note p {
                    color: $red-f3 !important;
                }
            }
            &-cont {
                display: flex;
                margin-top: torem(20px);
                position: relative;
                .dnd-area {
                    flex: 1 1 auto;
                    height: torem(150px);
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
            &-h1 {
                color: $mainfont;
                font-weight: $fw-bold;
                font-size: torem(20px);
                line-height: torem(24px);
                margin: 0;
                margin-bottom: torem(8px);
            }
            &-p {
                color: $mainfont;
                font-size: torem(14px);
                margin: 0;
                line-height: torem(17px);
            }
            &-disabled {
                //.vreglabel-uploader
                p, button {
                    color: $color-ac;
                }
                button:before {
                    border-color: $color-ac;
                    background-color: $color-ac;
                }
                .dnd-area {
                    border-color: $color-ac;
                }
            }
        }
        &-View {
            justify-content: center;
        }
        &-empty {
            /*height: 75vh;*/
        }
        .viewlabel {
            margin-bottom: torem(62px);
        }
        [class*="col"] {
            padding: 0 torem(53px);
        }
        .row {
            margin-left: torem(-53px);
            margin-right: torem(-53px);
        }
        .dnd-area {
            max-width: 100%;
            width: 100%;
        }
        .dnd-btn {
            display: block;
        }
    }
    @include media-breakpoint-down(sm) {
        .vreglabel{
            padding: torem(28px) torem(45px) torem(41px);
            &-h1{
                margin-bottom: torem(28px);
                font-size: torem(20px);
                line-height: torem(24px);
            }
            &-bold{
                font-weight: $fw-800;
                font-size: torem(20px);
                line-height: torem(24px);
            }
            &-socials{
                display: flex;
                flex-wrap: wrap;
                margin-left: torem(-5px);
                margin-right: torem(-5px);
                margin-top: torem(10px);
                .vreglabel-input{
                    margin-top: torem(18px);
                    margin-bottom: 0;
                    flex: 1 1 auto;
                    max-width: 50%;
                    padding: 0 torem(5px);
                }
                &.realized{
                    .vreglabel-input{
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
            &-toggler{
                margin-top: torem(38px);
            }
            &-uploader{
                margin-top: torem(24px);
                margin-bottom: torem(42px);
            }
            .btn{
                margin-top: torem(30px);
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .vreglabel{
            background: transparent;
            border: none;
            box-shadow: none;
            min-height: 100%;
            padding-left: 0;
            padding-right: 0;
            padding-top: torem(18px);
            &-socials &-input{
                max-width: 100%;
                width: 100%;
                padding: 0;
            }
            &-uploader{
                background-color: $white;
                border-radius: torem(4px);
                margin-top: torem(30px);
                margin-bottom: torem(30px);
                padding: torem(24px) torem(20px) torem(33px) torem(20px);
                &-h1{
                    font-size: torem(18px);
                    line-height: torem(22px);
                }
                &-p{
                    font-size: torem(11px);
                    line-height: torem(13px);
                    font-weight: $fw-500;
                    color: $color-62;
                }
            }
            &-socials{
                max-height: 0;
                overflow: hidden;
                transition: max-height .3s;
                &.isShowing{
                    max-height: torem(9999px);
                }
                &.realized,&.isShowing.realized{
                    max-height: torem(9999px);
                    margin-top: 0;
                }
            }
            &-bold{
                .ficon{
                    position: relative;
                    margin-left: torem(20px);
                    font-size: torem(15px);
                    margin-right: 0;
                    &:before{
                        margin: 0;
                        transform: rotate(-90deg);
                        width: 1.6em;
                        transition: transform .3s;
                    }
                }
                &.isShowing .ficon:before{
                    transform: rotate(0deg);
                }
                &.isRealized{
                    display: none;
                }
            }
            .realized-mobile-hide{
                display: none;
            }
            .viewlabel{
                margin-bottom: torem(30px);
            }
        }
    }
</style>
