<template>
    <div class="popup" :class="{'active': !!text }" v-if="!!text">
        <div class="popup-shape" @click="close()"></div>
        <div class="popup-body">
            <button class="ficon icon-subtract popup-close" @click="close()"></button>
            <div class="popup-content" v-html="text"></div>
            <slot></slot>
            <div class="popup-footer">
                <button ref="okButton" class="btn popup-btn" @click="ok()"> {{ okText || 'Ok' }}</button>
                <button ref="cancelButton" v-if="cancleText" class="btn btn-blue popup-btn popup-btn--cancel"
                        @click="cancel()">
                    {{ cancleText }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "popup",
        props: ['okButtonText', 'cancelButtonText', 'additionalText'],
        data() {
            return {
                text: false,
                onClose: null,
                onConfirm: null,
                onCancel: null,

                okText: null,
                cancleText: null,
                addText: null,
            }
        },
        mounted() {
            if (!this.cancelButtonText && this.$refs.okButton) {
                this.$refs.okButton.focus();
            }
            this.okText = this.okButtonText;
            this.cancleText = this.cancelButtonText;
        },
        methods: {
            clear(){
                this.text = false;
                this.onClose = null;
                this.onConfirm = null;
                this.onCancel = null;
                this.okText = null;
                this.cancleText = null;
                this.addText = null;
            },
            setAddText(newText) {
                this.okText = newText;
            },
            setCancleText(newText) {
                this.cancleText = newText;
            },
            close() {
                this.text = false;
                this.$emit('closed', true);
                if (this.onClose) {
                    this.onClose();
                }
                this.onClose = this.onConfirm = this.onCancel = null;
            },
            ok() {
                if (this.onConfirm) {
                    this.onConfirm();
                }
                this.close();
                this.$emit('okClick', true);
            },

            cancel() {
                if (this.onCancel) {
                    this.onCancel();
                }
                this.close();
                this.$emit('cancelClick', true);
            },
            showMessage(message, onConfirm, onCancel, onClose) {
                this.text = this.parseMessage(message);
                this.onConfirm = onConfirm;
                this.onCancel = onCancel;
                this.onClose = onClose;
            },
            parseMessage(errors) {
                if (!errors) {
                    return '';
                }
                // just message
                if (typeof errors == 'string') {
                    return errors;
                }

                let result = [];

                // json response
                if (errors.responseJSON && errors.responseJSON.message) {
                    result.push(errors.responseJSON.message);
                }
                if (errors.responseJSON && errors.responseJSON.errors) {
                    for (let error of errors.responseJSON.errors) {
                        if (error.message) {
                            result.push((error.field ? error.field + ': ' : '') + error.message);
                        } else {
                            result.push(error.toString());
                        }
                    }

                    return result.join('<br/>');
                }

                // simple response
                if (result.length == 0 && errors.statusText) {
                    return (errors.status != 200 ? 'Server error: ' : '') + errors.statusText;
                }

                return result.length > 0 ? result.join('<br/>') : errors.toString();
            }
        },
        watch: {
          text(newValue) {
            this.$nextTick(() => {
              this.$emit('rendered');
            });
          },
        }
    }
</script>

<style lang="scss">
    $popupBg: $popup-bg;
    $popupshadow: $popup-shadow;
    .popup {
        position: fixed;
        width: 100%;
        height: 100%;

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999999999;

        opacity: 0;
        transition: opacity .3s;
        border-radius: torem(4px);
        &.active {
            opacity: 1;
        }
        &-shape {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;

            background: $popupBg;
        }
        &-close {
            border: none;
            outline: none;
            font-size: torem(12px);
            position: absolute;
            top: torem(20px);
            right: torem(20px);
            background: transparent;
            padding: 0;
            margin: 0;
            &:before {
                color: $red-f3;
                font-size: torem(24px);
                margin: 0;
            }
        }
        &-body {
            min-width: torem(300px);
            background: $white-fe;
            box-shadow: torem(4px) torem(4px) torem(21px) $popupshadow;
            position: relative;
            display: flex;
            flex-direction: column;
            max-height: 90%;
            padding: torem(50px);
            max-width: torem(732px);
            border-radius: torem(4px);
        }
        &-footer {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            /*width: torem(120px);*/
            font-weight: $fw-bold;
            font-size: torem(18px);
            line-height: torem(22px);
            text-align: center;
            margin: 0;
            padding: 0 torem(8px);
            height: torem(37px);
            width: auto;
            &--cancel {
                margin-left: torem(17px);
            }
        }
        &-content {
            margin: torem(20px) 0 torem(52px) 0;
            max-height: 70%;
            overflow: auto;
            text-align: center;

            font-style: normal;
            font-weight: $fw-medium;
            font-size: torem(25px);
            line-height: torem(37px);
        }
    }

    @include media-breakpoint-down(xs) {

        .popup-body {
            max-height: 100%;
            padding: torem(20px) 0;
            overflow: auto;
            padding-top: torem(44px);
        }
        .popup-content {
            padding: 0 torem(15px);
            flex: 1 0 auto;
        }
    }
</style>
