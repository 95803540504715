export default {
    DISTRIBUTION_MUSIC: 1,
    DISTRIBUTION_VIDEO: 2,
    RADIO: 3,
    MARKETING: 4,
    REGISTRATION_LABEL: 5,
    MASTERING: 6,
    RINGTONE: 7,
    PR_BLAST: 10,
    ARTIST_CUSTOM_BIO: 19,
    DISTRIBUTE_1_TRACK: 20,
    DISTRIBUTE_2_TRACKS: 21,
    DISTRIBUTE_3_TRACKS: 22,
    DISTRIBUTE_AN_ALBUM: 23,
    DISTRIBUTE_1_ARTIST: 1,
    DISTRIBUTE_2_4_ARTISTS: 2,
    DISTRIBUTE_5_20_ARTISTS: 3,
    DISTRIBUTE_21_40_ARTISTS: 18,

    ID_DISTRIBUTE_1_TRACK: 20,
    ID_DISTRIBUTE_2_TRACKS: 21,
    ID_DISTRIBUTE_3_TRACKS: 22,
    ID_DISTRIBUTE_AN_ALBUM: 23,
};
