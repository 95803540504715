<template>
    <label class="checkbox" :class="[adclass,{'active':value}]">
        <input
            :name="name"
            ref="input"
            type="checkbox"
            checked="checked"
            @click="click"
            v-model="value">
        <p v-html="text"></p>
    </label>
</template>

<script>
    export default {
        name: "checkbox",
        props: ['value', 'text', 'adclass', 'name'],
        methods: {
            click() {
                if (this.$refs.input.checked) {
                    this.$el.classList.add('active')
                } else {
                    this.$el.classList.remove('active');
                }
                this.$emit('input', this.$refs.input.checked)
            }
        }
    }
</script>

<style lang="scss">
    .checkbox {
        cursor: pointer;
        margin: 0;
        font-size: torem(17px);
        font-weight: $fw-medium;
        line-height: torem(25px);
        display: flex;
        align-items: center;
        color: $color-62;
        &:before {
            $size: 21px;
            font-family: 'fontello';
            content: '';
            background-color: transparent;
            border: torem(1px) solid $color-62;
            box-sizing: border-box;
            border-radius: torem(4px);
            width: torem($size);
            flex: 0 0 auto;
            height: torem($size);
            margin-right: torem(12px);
            transition: border-color .3s, background-color .3s;
            color: $blue-18;
            font-size: torem(12px);
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: torem($size);
        }
        &.error:before {
            border-color: $red-f3;
        }
        &.active:before {
            content: '\e805';
            border-color: $blue-18;
        }
        &.active.active-blue-text {
            color: $blue-18;
        }
        input {
            display: none;
        }
    }

</style>