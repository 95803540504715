import axios from 'axios';

export default {
    state: {
        dspsUrl: '/api/user/participators/dsps',
        dpsList: null,
    },
    mutations: {
        setDSPList(state, data) {
            state.dpsList = data;
        },
    },
    getters: {
        getDSPList: function (state) {
            return state.dpsList;
        },
    },
    actions: {
        async loadDSPList(store) {
            if (store.state.dpsList) {
                return store.state.dpsList;
            }
            return await axios.get(store.state.dspsUrl).then(function (e) {
                var list = Object.values(e.data);
                store.commit("setDSPList", list);

                return list;
            }).catch(error => {
                return {error: error.response};
            });
        },
    },
};