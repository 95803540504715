import Backbone from "backbone";
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
    defaults: {
        id: null,
        path: null
    },
    url: function () {
        return this.getProjectUrl() + '/cover-art';
    },
    getProjectUrl: function () {
        var project = this.get('project');

        if (project) {
            return project.url();
        }

        let url = '/api/projects';
        if (this.get('id')) {
            url += '/' + this.get('id');
        }

        return url;
    },
    getImageUrl: function () {
        return this.get('path');
    },
    toJSON: function () {
        var attributes = Backbone.$.extend(true, {}, this.attributes);

        if (attributes.project) {
            delete attributes.project;
        }

        return attributes;
    }
});
