<template>

  <div :class="{'information--fullheight':isLabel}" class="information">
    <div class="information-top">
      <p class="information-money">
        <i>$</i>Money
      </p>
      <router-link class="main-link d-none d-sm-inline-block ficon icon-arrowright"
                   to="/user/money-reports/royalty-reports">
        Go to money & reports
      </router-link>
    </div>
    <div class="information-middle flex-wrap flex-sm-nowrap">
      <loader-small v-if="!info" :substrate="false" class="loader--poss"/>
      <template v-else-if="info == null">
        <!--emptyState-->
        <div class="information-cont">
          <p class="information-p"> Rollover amount: </p>
          <div class="information-moneyCounter" data-step="9">
            ${{ '0.00' }}
          </div>
          <button :disabled="true" class="btn btn-small information-btn">
            Claim my money!
          </button>
        </div>
      </template>
      <template v-for="wallet in info.get('wallets').models" v-else>
        <div v-if="wallet.getRolloverAmount() || wallet.getRolloverAmount() === 0"
             class="information-cont">
          <p class="information-p"> Rollover amount: </p>
          <div class="information-moneyCounter" data-step="9">
            {{ wallet.getFormattedAmount((+wallet.getRolloverAmount()).toFixed(2)) }}
          </div>
          <button :disabled="parseInt(wallet.getRolloverAmount()) <= 0 || !wallet.canMakeRequest() && (!wallet.belowThreshold())" class="btn btn-small information-btn"
                  @click="climeMoney($event,wallet)">
            Claim my money!
          </button>
        </div>
        <div v-else class="information-cont">
          <!--empty state for amount-->
          <p class="information-p"> Rollover amount: </p>
          <div class="information-moneyCounter" data-step="9">
            ${{ '0.00' }}
          </div>
          <button :disabled="true" class="btn btn-small information-btn">
            Claim my money!
          </button>
        </div>
      </template>

    </div>
    <div v-show="this.reasonIfUserCannotMakeNewRequest"
         class="information-bottom payoff-unclaimable-info-box">
      {{ this.reasonIfUserCannotMakeNewRequest }}
    </div>
    <div class="fund">
      <a class="button" href="https://www.octiive.com/music-funding" target="_blank">Get Funded Up
        to $3M</a>

      <a class="more-info" href="https://www.octiive.com/music-funding" target="_blank">
        Click here and receive an advance offer on how much you can get.
      </a>
    </div>
    <router-link class="main-link d-sm-none ficon  icon-arrowright"
                 to="/user/money-reports/royalty-reports">
      Go to money & reports
    </router-link>
  </div>
</template>

<script>
import MoneyRequest from '@/components/models/user/money-request'
import loaderSmall from '@/components/loader-small';

export default {
  name: "informationBlock",
  props: ['info', 'isLabel'],
  methods: {
    climeMoney(event, wallet) {

      if (wallet.belowThreshold()) {
        this.$root.popup.showMessage(wallet.getBelowThresholdMessage());
        return;
      }

      let self = this,
        button = event.target,
        moneyRequest = new MoneyRequest({
          currency: wallet.getCurrency()
        });

      button.disabled = true;
      moneyRequest.save({}, {
        success(model, response) {
          self.$root.popup.showMessage('Ready to get paid? Check your email and confirm your payment preference to release your funds!');
          self.info.fetch();
        }, error(model, error) {
          button.disabled = false;
          self.$root.popup.showMessage(error);
          self.info.fetch();
        }
      })
    }
  },
  computed: {
    isWalletsEmpty() {
      return this.info && this.info.get('wallets').every(wallet => {
        return wallet.getRolloverAmount() + 1 <= 0;
      })
    },

    reasonIfUserCannotMakeNewRequest() {
      return this.info && this.info.reasonIfUserCannotMakeNewRequest();
    }
  },
  components: {
    loaderSmall,
  }
}
</script>

<style lang="scss">
.information {

  @include obj;
  @include coner;

  min-height: torem(360px);
  max-height: torem(460px);

  box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
  padding: torem(30px) torem(58px);

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  flex: 1 1 auto;

  &-top {
    display: flex;
    align-items: center;

    .main-link {
      margin-left: auto;
    }
  }

  .payoff-unclaimable-info-box {
    padding: 10px;
    color: #00428a;
    font-weight: 600;
    padding-top: 20px;
    text-align: center;
  }

  &-cont {
    display: flex;
    flex-direction: column;
    margin: 0 auto torem(10px);
    max-width: 50%;
    overflow: hidden;
  }

  &-money {
    font-weight: $fw-extrabold;
    font-size: torem(24px);
    line-height: torem(29px);
    color: $mainfont;

    i {
      font-style: normal;
      margin-right: torem(14px);
    }
  }

  &-p {
    //  margin-top: torem(60px);
    font-weight: $fw-bold;
    font-size: torem(20px);
    line-height: torem(29px);
    color: $color-26;
    text-align: center;

  }

  &-moneyCounter {
    text-align: center;
    margin: auto;
    font-weight: $fw-bold;
    font-size: torem(60px);
    line-height: torem(90px);
    color: $blue-18;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }

  &-btn {
    margin: 0 auto;
    text-align: center;
    min-width: torem(200px);
    line-height: torem(22px);

  }

  &-middle {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    padding-top: torem(40px);

    .loader.loader--poss {
      min-height: 100%;
    }
  }
}

.fund {
  text-align: center;
  margin: 1rem 2rem;

  a {
    display: block;
  }

  a.button {
    background-color: #FF6337;
    color: white;
    border-radius: 25px;
    padding: 4px 10px;
    font-weight: bold;
    text-decoration: none;
    transition: all 0.5s ease;
    display: inline-block;

    &:hover {
      box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
      background-color: #0e0e0e;
    }
  }

  a.more-info {
    margin-top: 1rem;
    color: #6a8faa;
    font-weight: bold;

    &:hover {
      text-decoration: none;
      color: #336699;
    }
  }
}

@include media-breakpoint-down(xs) {
  .information {
    padding: torem(28px) 0;
    justify-content: center;
    align-items: center;
    height: auto;
    max-height: initial;

    &-cont {
      margin-top: torem(32px);
      margin-bottom: torem(40px);
      min-width: torem(300px);
    }

    &-moneyCounter {
      margin-top: torem(16px);
      margin-bottom: torem(26px);
      height: auto;
      max-height: initial;
    }
  }
}
</style>
