<template>
    <div class="item">
        <div class="item-icon">
            <slot name="icon"></slot>
        </div>
        <div class="item-text">
            <div class="item-title">
                <slot name="title"></slot>
            </div>
            <div class="item-discr">
                <slot name="discr"></slot>
            </div>
            <div class="item-links">
                <slot name="links"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "itemService"
    }
</script>

<style lang="scss">
    
    .item {
        @include obj;
        @include coner;
        box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
        padding: torem(30px) torem(58px);
        margin-bottom: torem(20px);
        display: flex;
        align-items: stretch;
        min-height: torem(170px);
        &-icon {
            width: torem(50px);
            height: torem(50px);
            flex: 0 0 torem(50px);
            justify-content: center;
            align-items: center;
            align-self: center;
            display: flex;
            margin-right: torem(58px);
            i {
                font-size: torem(50px);
                color: $color-f4;
                margin: 0;
                &:before {
                    margin: 0;
                }
            }
        }
        &-text {
            display: flex;
            flex-direction: column;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &-title {
            font-weight: $fw-extrabold;
            font-size: torem(24px);
            line-height: torem(29px);
            color: $mainfont;
            margin-bottom: torem(4px);
            
        }
        &-discr {
            font-weight: $fw-medium;
            font-size: torem(18px);
            line-height: torem(26px);
            /* or 26px */
            color: $color-62;
        }
        &-links {
            margin-top: auto;
            display: flex;
            align-items: center;
            a {
                margin-right: torem(41px);
                &:last-child {
                    margin-right: 0;
                }
                &.main-calltoAction {
                    margin-right: torem(22px);
                }
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .item {
            padding: torem(24px) torem(22px);
            position: relative;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: torem(3px);
                background-color: $blue-1f;
                z-index: 2;
                border-radius: torem(2px);
            }
            &-icon {
                display: none;
            }
            &-title{
                font-size: torem(20px);
                line-height: torem(24px);
            }
            &-dicsr{
                font-size: torem(16px);
                line-height: torem(24px);
                font-weight: $fw-500;
            }
            &-links {
                margin-top: torem(35px);
                a {
                    margin-right: torem(28px);
                    &:last-child {
                        margin-right: torem(10px);
                    }
                }
            }
        }
    }
</style>