<template>
    <ValidationObserver
        ref="observer"
        class="sform"
        tag="form"
        @submit.prevent=""
        @keydown.enter = "onClick()"
        v-slot="{ invalid }">
        <div class="row">
            <!--Name-->
            <div class="col-12 col-sm-6 col-md-12">
                <vinput
                    ref="Name"
                    class="sform-input"
                    key="Name"
                    :value="checkName"
                    :ttInfoOptions="{contentAsHTML:true}"
                    title="Name"
                    VPRules="required"
                    inputType="text"
                    inputName="checkName"
                    inputDisplayName="Name"
                    @input="onInput($event,$refs.Name,payment)"
                />
            </div>
            <!--City-->
            <div class="col-12 col-sm-6 col-md-12">
                <vinput
                    ref="City"
                    class="sform-input"
                    key="City"
                    :value="checkCity"
                    :ttInfoOptions="{contentAsHTML:true}"
                    title="City"
                    VPRules="required"
                    inputType="text"
                    inputName="checkCity"
                    inputDisplayName="City"
                    @input="onInput($event,$refs.City,payment)"
                />
            </div>
            <!--Address-->
            <div class="col-12">
                <vinput
                    ref="Address"
                    class="sform-input"
                    key="Address"
                    :value="checkAddress"
                    :ttInfoOptions="{contentAsHTML:true}"
                    title="Address"
                    VPRules="required"
                    inputType="text"
                    inputName="checkAddress"
                    inputDisplayName="Address"
                    @input="onInput($event,$refs.Address,payment)"
                />
            </div>
            <!--Zip Code-->
            <div class="col-12 col-sm-6 col-md-12">
                <vinput
                    ref="checkZip"
                    class="sform-input"
                    key="Zip Code"
                    :value="checkZip"
                    :ttInfoOptions="{contentAsHTML:true}"
                    title="Zip Code"
                    VPRules="required"
                    inputType="text"
                    inputName="checkZip"
                    inputDisplayName="Zip Code"
                    @input="onInput($event,$refs.checkZip,payment)"
                />
            </div>
            <!--State-->
            <div class="col-12">
                <vinput
                    ref="paypalEmail"
                    class="sform-input"
                    key="State"
                    :value="checkState"
                    :ttInfoOptions="{contentAsHTML:true}"
                    title="State"
                    VPRules="required"
                    inputType="text"
                    inputName="checkState"
                    inputDisplayName="State"
                    @input="onInput($event,$refs.paypalEmail, payment)"
                />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button ref="btn" class="btn sform-btn" :disabled="(isOldValues && isOldMethod) || invalid" @click="savePaymentInfo">
                    Save payment Info
                </button>
            </div>
        </div>
    </ValidationObserver>
</template>


<script>
    import vinput from '@/components/input-tt';
    import {ValidationProvider, ValidationObserver} from 'vee-validate';
    import {onInput} from '../mixins/onInput';
    import {pay} from '../mixins/paymentMixins';
    export default {
        name: "check-form",
        props: ['payment','paymentMethod'],
        mixins:[onInput,pay],
        data(){
            return {
                checkName:null,
                checkCity:null,
                checkAddress:null,
                checkZip:null,
                checkState:null,
                isOldValues:true,
            }
        },
        mounted(){
            this.checkName = this.payment.get('checkName');
            this.checkCity = this.payment.get('checkCity');
            this.checkAddress = this.payment.get('checkAddress');
            this.checkZip = this.payment.get('checkZip');
            this.checkState = this.payment.get('checkState');
        },

        components:{
            ValidationProvider,
            ValidationObserver,
            vinput
        }
    }
</script>

