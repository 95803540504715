<template>
    <div class="container d-flex flex-column  align-items-stretch">
        <div class="row">
            <div class="col-12 col-md-10 ">
                <h1 class="main-h1">
                    Analytics
                </h1>
            </div>
        
        </div>
        
        <template v-if="projectCollectionReady">
            <div class="reports-obj">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12">
                                <p class="reports-title">
                                    Date Range
                                </p>
                                <div class="reports-ell">
                                    <v-analytic-date-filter
                                        v-if="minDate && maxDate"
                                        :minDate="minDate"
                                        :maxDate="maxDate"
                                        :disabled="!analyticDataReady"
                                        @input="dateUpdate($event)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <p class="reports-title">
                            Select Type
                        </p>
                        <div class="reports-ell">
                            <v-multiselect
                                :disabled="!analyticDataReady"
                                v-model="typeSelection"
                                :allow-empty="false"
                                :options="typeList"
                                :showLabels="false"
                                @input="analyticUpdate()"
                                label="name"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <p class="reports-title">Select Group</p>
                        <div class="reports-ell">
                            <v-multiselect
                                :disabled="!analyticDataReady"
                                v-model="groupSelection"
                                :allow-empty="false"
                                :options="groupList"
                                :showLabels="false"
                                @input="analyticUpdate()"
                                label="name"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3">
                        <p class="reports-title">
                            Select Projects
                        </p>
                        <div class="reports-ell">
                            <v-multiselect
                                :disabled="!analyticDataReady"
                                v-model="projectsSelection"
                                :options="getProjectList()"
                                @input="analyticUpdate()"
                                :class="'multiselect-multiple'"
                                :multiple="true"
                                :taggable="true"
                                :showLabels="false"
                                label="name"
                                track-by="id"
                                :placeholder="'Select project'"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="analyticData && analyticData.isFill() && analyticDataReady">
                <div class="reports-obj">
                    <div class="row">
                        <div class="col-12 col-sm-6 d-flex align-items-center">
                            <p class="reports-h1 reports-h1--nomargins">Chart</p>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="reports-btns">
                                <button class="btn ficon icon-chart-area" :disabled="choseChart === 'lines' "
                                        @click="choseChart = 'lines'">
                                </button>
                                <button class="btn ficon icon-chart-bar" :disabled="choseChart === 'bars' "
                                        @click="choseChart = 'bars'">
                                
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <v-total-analytic
                                :selection="typeSelection.name"
                                :value="analyticData.getTotals()"
                            />
                        </div>
                        <div class="col-12" v-if="analyticData.getChart()">
                            <v-chart-line
                                v-if="choseChart==='lines'"
                                v-model="analyticData.getChart()"
                                @colorInit="colorMap = $event"
                                :date="{
                                     from:minDate,
                                     to:maxDate,
                                 }"
                            />
                            <v-chart-bars
                                v-if="choseChart==='bars'"
                                v-model="analyticData.getChart()"
                                @colorInit="colorMap = $event"
                                :date="{
                                    from:minDate,
                                    to:maxDate,
                                }"
                            />
                        </div>
                    </div>
                </div>
                <div class=" reports-obj">
                    <div class="row">
                        <div class="col-12">
                            <v-table-analytic
                                :colorMap="colorMap"
                                :selection="typeSelection.name"
                                v-model="analyticData.getList()"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <v-empty-state class="reports-obj" v-else-if="analyticDataReady && !analyticData.isFill()"
                           h1text="Nothing to show now"
                           text="Please change filter and try again"
            />
            <div class="reports-obj" v-else>
                <random-loader class="loader--poss" :substrate="false" :randomText="true" />
            </div>
        </template>
        <random-loader v-if="!projectCollectionReady" class="loader--poss" :substrate="false" :randomText="true" />
    </div>
</template>

<script>
    import Analytic from '@/components/models/analytic';


    import pieChart from '@/components/pie-chart';
    import DoughnuteChart from '@/components/doughnut-chart';
    import polarareaChart from '@/components/polararea-chart';
    import radarChart from '@/components/radar-chart';
    import bubbleChart from '@/components/bubble-chart';
    import ProjectCollection from '@/components/collections/distribution-projects';
    import vCounup from './components/v-countup'

    import randomLoader from '@/components/random-loader';
    import vChartLine from './components/v-chart-line'
    import vChartBars from './components/v-bars-chart';
    import vMultiselect from 'vue-multiselect';
    import vAnalyticDateFilter from './components/v-analytic-date-filter';
    import vTableAnalytic from './components/v-table-analytic'
    import vTotalAnalytic from './components/v-total-analytic'
    import vEmptyState from '@/components/emptyState'

    export default {
        name: "reports",
        /*mixins: [analyticData],*/
        data() {
            return {
                testSelected: null,
                analyticCollection: null,
                analyticCollectionReady: false,
                projectCollection: null,
                projectCollectionReady: false,

                chartDate: null,
                trackStreams: null,
                trackDownloads: null,
                albumDownloads: null,

                maxDate: null,
                minDate: null,

                typeList: [
                    {
                        name: 'Streams',
                        value: 'stream'
                    },
                    {
                        name: 'Downloads',
                        value: 'download'
                    }],
                typeSelection: null,
                groupList: [
                    {
                        name: 'DSP',
                        value: 'dsp'
                    },
                    {
                        name: 'Territory',
                        value: 'territory'
                    },
                    {
                        name: 'Asset',
                        value: 'asset'
                    },
                    {
                        name: 'Artist',
                        value: 'artist'
                    }],
                groupSelection: null,
                projectsSelection: null,
                analyticData: null,

                colorMap: [],
                analyticDataReady: false,
                choseChart: 'lines',


            }
        },
        mounted() {
            let now = new Date();
            this.maxDate = this.formateDate(now);
            now.setMonth(now.getMonth() - 1);
            this.minDate = this.formateDate(now);
            this.typeSelection = this.typeList[0];
            this.groupSelection = this.groupList[0];

            this.projectCollection = new ProjectCollection();
            this.analyticData = new Analytic();
            this.projectCollection.fetch({
                // todo: fetch all projects
                data: {limit: 100},
            }).done(() => {
                this.projectCollectionReady = true;
            }).fail(errors => {
                this.$root.popup.showMessage(errors);
            });

        },
        methods: {
            
            updateCounterDate() {
                let streamDiscription = [
                        'Streaming',
                        'Streaming (Cloud)',
                        'Streaming (Ad-Supported)',
                        'Streaming (Subscription)',
                        'Streaming (Video)',
                        'Streaming (Radio)',
                        'Streaming (Free Trial)',
                        'Streaming (Locker)',
                        'Video Streaming (Subscription)'
                    ],
                    period = [this.chartDate.date.min, this.chartDate.date.max],
                    trackStreamsFilter = Object.assign(
                        {
                            sales_description: streamDiscription,
                            period
                        },
                        this.chartDate.filter),
                    trackDownloadsFilter = Object.assign(
                        {
                            sales_description: "Download (Track)",
                            period
                        },
                        this.chartDate.filter),
                    albumDownloadsFilter = Object.assign(
                        {
                            sales_description: "Download (Album)",
                            period
                        },
                        this.chartDate.filter);

                this.trackStreams = this.analyticCollection.getSumDataByFilter('quantity', trackStreamsFilter);
                this.trackDownloads = this.analyticCollection.getSumDataByFilter('quantity', trackDownloadsFilter);
                this.albumDownloads = this.analyticCollection.getSumDataByFilter('quantity', albumDownloadsFilter);
            },
            getProjectList() {
                return this.projectCollection.models.map(project => {
                    return {name: project.getTitle(), id: project.getId()}
                });
            },
            formateDate(date) {
                /*dateFormat: 'mm/dd/yy',*/
                let mm = date.getMonth() + 1,
                    dd = date.getDate(),
                    yy = date.getFullYear();
                return ("0" + mm).slice(-2) + '/' + ("0" + dd).slice(-2) + '/' + yy;
            },
            dateUpdate(newDate) {
                this.maxDate = newDate.maxDate;
                this.minDate = newDate.minDate;
                this.analyticUpdate()
            },
            analyticUpdate() {
                this.analyticDataReady = false;

                this.analyticData.fetchCurrent(
                    this.typeSelection.value,
                    this.minDate,
                    this.maxDate,
                    this.groupSelection.value,
                    this.projectsSelection,
                ).done(model => {
                    /*force create null analytic*/
                    if (model === null) {
                        this.analyticData = new Analytic(model);
                    }
                    this.analyticDataReady = true;
                }).fail(errors => {
                    this.analyticData = new Analytic(null);
                    this.analyticDataReady = true;
                    this.$root.popup.showMessage(errors);
                });
            },
        },
        components: {
            pieChart,
            DoughnuteChart,
            polarareaChart,
            radarChart,
            bubbleChart,

            vCounup,
            vAnalyticDateFilter,

            vChartLine,
            vChartBars,

            vMultiselect,

            vTableAnalytic,
            vTotalAnalytic,
            randomLoader,
            vEmptyState
        },
    }
</script>

<style lang="scss">
    .reports {
        &-btns {
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }
        &-obj {
            @include obj;
            @include coner;
            box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
            padding: torem(46px) torem(80px);
            margin-bottom: torem(46px);
            &.empty {
                position: static;
                height: 100%;
                flex: 1 1 auto;
                display: flex;
            }
        }
        &-h1, &-title {
            font-weight: $fw-bold;
            font-size: torem(28px);
            line-height: torem(34px);
            color: $mainfont;
            margin-bottom: torem(28px);
        }
        &-h1--nomargins {
            margin-bottom: 0;
        }
        &-title {
            font-size: torem(19px);
            line-height: torem(23px);
            margin-bottom: torem(9px);
            margin-top: torem(40px);
        }
    }
    
    .chart canvas {
        width: 100% !important;
        max-height: torem(450px);
    }
    
    @include media-breakpoint-down(sm) {
        .reports-sm-margin {
            margin-top: torem(40px);
        }
    }
    
    @include media-breakpoint-down(xs) {
        .reports-xs-margin {
            margin-top: torem(40px);
        }
        .reports {
            &-btns {
                display: flex;
                width: 100%;
                justify-content: center;
            }
            &-h1 {
                font-size: torem(22px);
            }
            &-title {
                font-size: torem(18px);
            }
            &-obj {
                padding: torem(36px) torem(28px) torem(55px);
            }
        }
        
    }
</style>