var intro = null;
var isBusy = false;

import $ from "jquery";
import Backbone from "backbone";
/*import Intro from 'vue-introjs';*/
import QuickGuideSteps from "./quickGuideSteps";

export default {
    urlGet: "/api/quick-guide-info/",
    urlPost: "/api/quick-guide-pass/",
    pageName: window.location.pathname.slice(1),
    quickGuideSteps: null,
    quickGuidePassed: null,
    el: '.js-quick-guide',
    initialize: function (introJS) {
        return $.get(this.urlGet + 'dashboard')
    },

    init: function (introJS) {
        intro = introJS;

        this.getSteps();
        let options = {
            "hidePrev": true,
            "hideNext": true,
            "nextLabel": 'Next',
            "prevLabel": "Back",
            "exitOnOverlayClick": false,
        };
        this.setOptions(options);
        this.addSteps(this.quickGuideSteps);
        this.start();
        this.finish();
    },

    setOptions: function (options) {
        intro.setOptions(options)
    },


    start: function () {
        intro.start();
    },

    finish: function () {
        var self = this;
        intro.oncomplete(function () {
            self.setQuickGuidePassed();
        });

    },
    exit: function () {
        if (intro) {
            intro.exit()
        }
    },

    getSteps: function () {
        if (this.quickGuideSteps === null) {
            switch (this.pageName) {
                case "home":
                    this.quickGuideSteps = QuickGuideSteps.dashboardSteps;
                    break;
                default :
                    this.quickGuideSteps = null
            }
        }
    },

    addSteps: function (stepsArray) {
        if (stepsArray && stepsArray.length) {
            intro.addSteps(stepsArray);
        }
    },

    setQuickGuidePassed: function () {
        if (isBusy) {
            return;
        }

        isBusy = true;

        $.post(this.urlPost + 'dashboard') // if home phpError pageNotAllowed
            .fail(function (error) {
                console.log(error);
            })
            .always(function () {
                isBusy = false;
            })
        ;
    }
}

