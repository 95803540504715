<template>
    <div class="tab no-selectable" @click=" $emit('click',$event); "
         @mousedown="start"
         @mouseleave="stop"
         @mouseup="stop"
         @touchstart="start"
         @touchend="stop"
         @touchcancel="stop"
    >
        <slot name="icon"></slot>
        <div class="tab-text">
            <slot name="name"></slot>
            <slot name="discr"></slot>
        </div>
        <slot name="btn"></slot>
        <slot name="audio"></slot>
        <div class="tab-progressholder">
            <div ref="progress" class="tab-progress" v-show="trackProgress!=null && isinprogress">
            </div>
        </div>
    </div>
</template>

<script>
    
    export default {
        props: ['trackProgress', 'file', 'isNewTrack'],
        name: "tab",
        data(){
            return{
                timeoutId:false,
                count:0,
            }
        },
        mounted() {

        },
        methods:{
            start(){
                if(!this.timeoutId ){
                    this.timeoutId  = setInterval(() => {
                        if(this.count){
                            this.$emit('hold')
                        }
                        this.count++
                    }, 300)
                }
            },
            stop(){
                clearTimeout(this.timeoutId);
                this.$emit('unhold');
                this.timeoutId = false;
                this.count = 0;
            },
        },
        computed: {
            isinprogress() {
                if (this.trackProgress && this.file || !this.isNewTrack) {
                    return false;
                }
                if (!this.$refs.progress || !this.trackProgress) {
                    return this.trackProgress;
                }
                this.$refs.progress.style.width = this.trackProgress + '%';
                return this.trackProgress;
            }
        }
    }
</script>

