<template>
  <div class="orderItem">
    <div class="orderItem-row orderItem-row--top d-md-none">
      <div class="orderItem-date">
        {{value.getCreatedAtFormatted()}}
      </div>
      <p class="orderItem-status" :class="'orderItem-status--'+value.getStatusData().class">
        {{value.getStatusData().text}}
      </p>
    </div>
    <div class="orderItem-row orderItem-row--sm d-md-none">
      <div class="orderItem-col orderItem-head orderItem-head--sm">
        <p class="title">Total price </p>
        <p class="price d-md-none">
          ${{value.attributes.price}}
        </p>
      </div>
      <div class="orderItem-col orderItem-head orderItem-head--sm">
        <p class="title">Item count </p>
        <p @click=" toggleTible()" ref="toggleButton" :class="{'active':showTable}"
           class="orderItem-link ficon icon-arrowright col-md-none" v-if="value.attributes.orderItems.length">
          {{value.attributes.orderItems.length}} item
        </p>
      </div>
    </div>
    <div class="orderItem-row d-none d-md-flex d-md-flex">
      <div class="orderItem-col orderItem-head d-none d-md-flex d-md-flex">
        <p>Create date </p>
      </div>
      <div class="orderItem-col orderItem-head d-none d-md-flex d-md-flex">
        <div class="orderItem-colWidth">
          <p>Order status </p>
        </div>
      </div>
      <div class="orderItem-col orderItem-head">
        <p>Total price </p>
      </div>
      <div class="orderItem-col orderItem-head">
        <p>Item count </p>
      </div>
    </div>
    
    <div class="orderItem-row d-none d-md-flex d-md-flex">
      <div class="orderItem-col">
        <p> {{value.getCreatedAtFormatted()}}</p>
      </div>
      <div class="orderItem-col">
        <div class="orderItem-colWidth">
          <p class="orderItem-status" :class="'orderItem-status--'+value.getStatusData().class">
            {{value.getStatusData().text}}
          </p>
        </div>
      </div>
      <div class="orderItem-col">
        <p>
          ${{value.attributes.price}}
        </p>
      </div>
      <div class="orderItem-col">
        <p @click=" toggleTible()" ref="toggleButton" :class="{'active':showTable}"
           class="orderItem-link ficon icon-arrowright" v-if="value.attributes.orderItems.length">
          {{value.attributes.orderItems.length}} item
        </p>
      </div>
    </div>
    <div class="orderItem-hider" style="display: none" ref="hider">
      <div class="orderItem-table">
        <div class="orderItem-row orderItem-row--noAfter">
          <div class="orderItem-col orderItem-head orderItem-head--table">
            <p>№</p>
          </div>
          <div class="orderItem-col orderItem-head orderItem-head--table">
            <div class="orderItem-colWidth">
              <p>Name</p>
            </div>
          </div>
          <div class="orderItem-col orderItem-head orderItem-head--table">
            <p>Payment Date</p>
          </div>
          <div class="orderItem-col orderItem-head orderItem-head--table">
            <p>Price</p>
          </div>
        </div>
        <div class="orderItem-row" v-for="(item,index) in value.attributes.orderItems.models">
          <div class="orderItem-col orderItem-col--table">
            <p>{{index+1}}</p>
          </div>
          <div class="orderItem-col orderItem-col--table">
            <div class="orderItem-colWidth">
              <p>{{item.attributes.title}}</p>
            </div>
          </div>
          <div class="orderItem-col orderItem-col--table">
            <p>{{item.getPaymentDateFormatted() || '-' }}</p>
          </div>
          <div class="orderItem-col orderItem-col--table">
            <p>${{item.attributes.price}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "orderItem",
    props: ['value'],
    data() {
      return {
        showTable: false,
      }
    },
    methods: {
      toggleTible() {
        let toggleButton = this.$refs.toggleButton;
        toggleButton.disabled = true;
        if (this.showTable) {
            this.showTable = false;
          $(this.$refs.hider).hide(300, () => {
            toggleButton.disabled = false
          });
        } else {
          this.showTable = true;
          this.$nextTick(() => {
            $(this.$refs.hider).show(300, () => {
              toggleButton.disabled = false
            });
          });
        }
      }
    }
  }
</script>

<style lang="scss">
  .orderItem {
    @include obj;
    @include coner;
    box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
    background: $white;
    padding: torem(42px) torem(78px);
    margin-bottom: torem(33px);
    $padd: 47px;
    &:first-child {
      margin-top: 0;
    }
    &-colWidth {
      width: torem(200px);
    }
    &-row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
    }
    &-col {
      
      flex: 1 1 35%;
      display: flex;
      align-items: center;
      justify-content: left;
      /*   &:first-child, &:last-child {
           flex: 1 1 10%;
           justify-content: left;
         }*/
      &:last-child {
        flex: 1 1 10%;
        justify-content: flex-end;
      }
      &:first-child {
        justify-content: left;
        flex: 1 1 22%;
      }
      &:nth-child(2) {
        justify-content: center;
        flex: 1 1 18%;
      }
      &:nth-child(3) {
        padding-left: torem(210px);
      }
      
      &--table {
        p {
          font-weight: $fw-500;
        }
      }
      p {
        font-weight: $fw-bold;
        font-size: torem(18px);
        line-height: torem(22px);
        color: $mainfont;
      }
    }
    &-colWidth {
      justify-content: center;
      display: flex;
    }
  
    
    &-head {
      margin-bottom: torem(22px);
      &--table {
        p {
          font-weight: $fw-bold;
        }
      }
      p {
        font-weight: $fw-800;
        font-size: torem(20px);
        line-height: torem(24px);
        color: $blue-1f;
      }
    }
    &-status {
      width: torem(145px);
      border-radius: torem(30px);
      border: torem(3px) solid $color-ac;
      text-align: center;
      white-space: nowrap;
      padding: torem(7px) 0;
      text-transform: capitalize;
      &--green {
        border-color: $color-51;
      }
      &--yellow {
        border-color: $warn;
      }
      &--blue {
        border-color: $blue-18;
      }
      &--created {
        border-color: $color-94;
      }
      &--cancel {
        border-color: $red-f3;
      }
      &--rogue {
        border-color: $red-f3;
      }
    }
    &-table {
      padding-top: torem(46px);
    }
    &-table &-colWidth {
      justify-content: left;
      padding-left: torem(85px);
      width: 100%;
    }
    &-table &-row--noAfter{
      padding-top: 0;
      padding-bottom: 0;
    }
    &-table &-row {
      position: relative;
      padding-top: torem(12px);
      padding-bottom: torem(12px);
      &:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: torem(-$padd);
        right: torem(-$padd);
        height: torem(2px);
        background-color: $color-c2;
      }
    }
    &-table &-row--noAfter {
      &:after {
        display: none;
      }
    }
    &-table &-col p {
      font-weight: $fw-500;
    }
    &-table  &-head--table p{
      font-weight: $fw-bold;
    }
    &-hider {
      width: auto !important;
      opacity: 1 !important;
      overflow: hidden;
      margin-left: torem(-$padd) !important;
      margin-right: torem(-$padd) !important;
      padding-left: torem($padd) !important;
      padding-right: torem($padd) !important;
    }
    .orderItem-link {
      justify-content: flex-end;
      font-size: torem(20px);
      line-height: torem(24px);
      color: $blue-18;
      cursor: pointer;
      transform: rotate(0);
      user-select: none;
      &:before {
        font-size: torem(13px);
        width: auto;
        transition: transform .3s;
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        vertical-align: middle;
        height: torem(12px);
        margin: auto;
        margin-left: torem(8px);
      }
      &.active:before {
        transform: rotate(90deg);
      }
    }
    
  }
  @include media-breakpoint-down(sm) {
    .orderItem {
      padding: torem(29px) torem(24px);
  
      &-row--top {
        display: flex;
        align-items: center;
        .orderItem{
          &-date{
            font-weight: $fw-bold;
            font-size: torem(18px);
            line-height: torem(22px);
            display: flex;
            align-items: center;
            color: $blue-18;
          }
          &-status {
            margin-left: auto;
            font-size: torem(12px);
            font-weight: $fw-bold;
            padding: torem(4px) 0;
          }
        }
      }
      &-row--sm{
        margin: 0;
        margin-top: torem(50px);
        //margin-bottom: torem(35px);
        justify-content: start;
        p {
          font-weight: $fw-600;
          &.title{
            margin-right: torem(10px);
            font-weight: $fw-800;
          }
          &.price{
            font-size: torem(18px);
            line-height: torem(22px);
          }
          &.orderItem-link{
            font-size: torem(20px);
            line-height: torem(24px);
          }
        }
      }
       
    
      &-hider {
        margin: 0!important;
        padding: 0!important;
      }
      &-table {
          .orderItem-colWidth {
              padding: 0;
          }
          .orderItem-col {
              padding: 0 !important;
              margin: 0;
              &:first-child {
                  flex: 0 0 10%;
              }
              &:nth-child(2) {
                  flex: 0 0 43%;
              }
              &:nth-child(3) {
                  padding: 0;
              }
          }
      }
      .orderItem-head.orderItem-head--sm {
        margin: 0;
        margin-right: torem(44px);
        flex: 0 0 auto;
      }
    }
  }
  @include media-breakpoint-down(xs){
    .orderItem-row--sm p.title {
      font-size: torem(14px);
      display: block;
      width: 100%;
      flex: 1 1 auto;
    }
  
    .orderItem .orderItem-head.orderItem-head--sm {flex-wrap: wrap;max-width: 50%;margin: 0;}
  
    p.orderItem-link.ficon.icon-arrowright.col-md-none {
      text-size-adjust: torem(14px);
      font-size: torem(14px);
      text-align: left;
      margin-right: auto;
      margin-left: 0;
    }
  
    .orderItem-col:nth-child(2) {
      flex: 1 1 auto;
    }
  
    .orderItem-colWidth {
      width: 100%;
    }
  
    .orderItem-col:nth-child(3) {
      padding: 0;
    }
  
    .orderItem-row {
      flex-wrap: wrap;
    }
  
    .orderItem-col.orderItem-head.orderItem-head--table {
    }
  
    .orderItem-table .orderItem-head--table p {
      font-size: torem(11px);
    }
  
    .orderItem-table .orderItem-col p {
      font-size: torem(11px);
    }
  
    .orderItem-table .orderItem-row {
      flex-wrap: nowrap;
    }
  
    .orderItem-table {
      padding-top: torem(10px);
    }
  
    .orderItem-table .orderItem-row:after {
      height: torem(1px);
    }
  
    .orderItem-row.orderItem-row--sm.d-md-none {
      margin-top: torem(10px);
    }
  }
</style>