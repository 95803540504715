<template>
  <div class="container d-flex flex-column  align-items-stretch">
    <div class="row">
      <div class="col-12">
        <h1 class="main-h1 marketing-h1">
          Marketing
        </h1>
        <router-link v-if="active" :to="active.slink" class="marketing-link main-link ficon  icon-arrowright">
          buy {{active.text}}
        </router-link>
      </div>
    </div>
    <div class="marketing">
      <div class="row">
        <div class="col-12">
          <switcher v-if="!$route.meta.action && active" :itemList="itemList" v-model="active.index" />
        </div>
      </div>
      <div class="row flex-grow-1 flex-shrink-1">
          <router-view></router-view>
      </div>
    </div>

  </div>

</template>

<script>
  import switcher from './components/switcher'
  export default {
    name: "marketing-page",
    data() {
      return {
        active: null,
        itemList: [
          {
            name: 'prblast',
            text: 'PR Blast',
            link:'/marketing/prblast',
            slink:'/services/prblast',
          },
          {
            name: 'bio',
            text: 'Artist Custom Bio',
            link:'/marketing/bio',
            slink:'/services/bio',
          }
        ]
      }
    },
    mounted(){
      if(!Object.keys(this.$route.meta).length){
        this.$router.push('/marketing/prblast');
      }
      this.getItem(this.$route.meta.name);
    },
    updated(){
      if(!Object.keys(this.$route.meta).length){
        this.$router.push('/marketing/prblast');
      }
      this.getItem(this.$route.meta.name);
    },
    methods:{
      getItem(name){
        let index = 0;
        for (let i =0; i< this.itemList.length; i++){
          let item = this.itemList[i];
          if(item.name == name){
            this.active = item;
            this.active.index = i;
            return
          }
        }
      }
    },
    components:{
      switcher
    }
  }
</script>

<style lang="scss">
  .mondoEdit {
    @include obj;
    @include coner;
    box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
    padding: torem(63px) torem(40px);
    position: relative;
    &-crutch{
      .slotTT-top{
        width: auto;
        margin-right: torem(-26px);
      }
    }
    &-area {
      min-height: torem(152px);
      padding-right: torem(45px);
      i.ficon.icon-ic_error.tooltipstered {
        margin-bottom: auto;
        margin-top: torem(15px);
      }
    }
    &-row {
      margin-left: torem(-11px);
      margin-right: torem(-11px);
    }
    &-col {
      padding-left: torem(11px);
      padding-right: torem(11px);
    }
    &-h1 {
      font-weight: $fw-extrabold;
      font-size: torem(28px);
      line-height: torem(34px);
      display: flex;
      align-items: center;
      color: $mainfont;
      margin-bottom: torem(46px);
    }
    &-h2 {
      font-weight: $fw-bold;
      font-size: torem(22px);
      line-height: torem(27px);
      display: flex;
      align-items: center;
      color: $mainfont;
      margin-bottom: torem(12px);
    }
    &-row {
      margin-bottom: torem(30px);
    }
    &-buttons {
      display: flex;
      button {
        margin-left: torem(18px);
        min-width: torem(132px);
        height: torem(37px);
        line-height: 1;
        font-size: torem(18px);
        &:first-child {
          margin-left: 0;
        }
      }
    }
    &-title-bottom {
      p.slotTT-title {
        height: torem(46px);
        align-items: flex-end;
      }
    }
  }
  .mondoblastShow {
    @include obj;
    @include coner;
    box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
    padding: torem(37px) torem(71px);
    position: relative;
    &-close{
      position: absolute;
      top: torem(20px);
      right: torem(20px);
      color: $mainfont;
      font-size: torem(24px);
      text-decoration: none!important;
      z-index: 1;
      &:hover{
        color: $mainfont;
      }
    }
    &-h1 {
      font-weight: $fw-bold;
      font-size: torem(24px);
      line-height: torem(29px);
      color: $blue-18;
      margin-bottom: torem(6px);
    }
    &-main-h1 {
      margin-bottom: torem(33px);
    }
    &-row {
      display: flex;
      margin-left: torem(-15px);
      margin-right: torem(-15px);
      flex-wrap: wrap;
    }
    &-col {
      flex: 1 1 auto;
      padding: 0 torem(15px);
    
    }
    &-hr {
      flex: 1 1 100%;
      border-color: $blue-18;
      opacity: .8;
    }
  }
  .marketing {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 auto;
    &-h1 {
      margin-bottom: torem(12px);
    }
    &-link {
      margin-bottom: torem(30px);
    }
    &-switcher {
      display: flex;
      margin-bottom: torem(12px);
    
    }
    &-empty {
      width: 100%;
      @include obj;
      @include coner;
      display: flex;
      /*height: 100vh;*/
      max-height: torem(300px);
      justify-content: center;
      align-items: center;
      font-size: torem(32px);
      color: $color-ac;
      font-weight: $fw-700;
      box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
      background: $white;
    }
  }
  @include media-breakpoint-down(sm) {
    .mondoEdit {
      padding: torem(28px);
      &-h1{
        font-size: torem(20px);
        line-height: torem(24px);
        margin-bottom: torem(28px);
      }
      &-h2{
        font-weight: 600;
        font-size: torem(16px);
        line-height: torem(24px);
        margin-bottom: torem(14px);
        margin-top: torem(23px);
        &--top{
          margin-top: 0;
        }
      }
      &-row {
        margin-bottom: 0;
      }
      &-buttons{
        margin-top: torem(30px);
      }
      .slotTT {
        margin-top: torem(18px);
      }
      .note {
        margin-bottom: torem(32px);
        p{
          font-size: torem(14px);
          line-height: torem(23px);
          br{
            display: none;
          }
        }
      }
    }
    .mondoblastShow{
      .strongText {
        padding: torem(10px) 0;
        border-bottom: torem(1px) solid $color-ac;
        margin: 0;
      }
  
      .mondoblastShow-h1 {
        margin-bottom: torem(10px);
        margin-top: torem(28px);
        &--top{
          margin-top: 0;
        }
      }
  
      hr.mondoblastShow-hr {
        display: none;
      }
      &-row:last-child .strongText:last-child {
        border-bottom: none;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .marketing {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      .empty, .loader {
        position: static;
        height: 100%;
      }
    }
  
    .mondoEdit {
      height: 100%;
      padding: 0;
      background: transparent;
      box-shadow: none;
      border: none;
      .loader{
        height: 80%;
      }
    }
  
    a.mondoblastShow-close {
      right: torem(-20px);
      top: torem(-20px);
    }
  
    .mondoblastShow {
      padding: 0;
      background: transparent;
      box-shadow: none;
      border: none;
      height: 100%;
      .loader{
        height: 80%;
      }
      .strongText strong {
        display: block;
        margin-left: torem(-15px);
        margin-bottom: torem(10px);
        font-size: torem(20px);
      }
      .strongText,
      &-row:last-child .strongText:last-child {
        padding: 0 torem(35px);
        display: block;
        padding-bottom: torem(20px);
        margin-bottom: torem(20px);
        margin-left: torem(-20px);
        margin-right: torem(-20px);
        font-size: torem(17px);
        border-bottom: torem(1px) solid $color-ac;
      }
    }
    .mondoblastShow .mondoblastShow-h1 {
      font-size: torem(20px);
      margin-left: torem(-20px);
      margin-right: torem(-20px);
      padding: 0 torem(20px);
    }
  }
</style>