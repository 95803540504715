<template>
    <div class="reglabel">
        <div class="reglabel-text">
            <div class="reglabel-h1">
                <template v-if="!isLabel">
                    Register your label
                </template>
                <template v-else>
                    Edit your label
                </template>
            </div>
            <router-link to="home/label" class="reglabel-link main-link ficon  icon-arrowright ">
                <template v-if="!isLabel">
                    Go
                </template>
                <template v-else>
                    Edit
                </template>
            </router-link>
        </div>
        <div class="reglabel-img">
            <img src="@/assets/img/labelprev.png" alt="poster">
        </div>
    </div>
</template>

<script>
    export default {
        name: "labelHome",
        props: ['isLabel'],
        methods: {},
    }
</script>

<style lang="scss">
    .reglabel {
        @include obj;
        @include coner;
        box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
        padding: torem(22px) torem(22px) torem(22px) torem(58px);
        display: flex;
        max-height: torem(170px);
        margin-top: auto;
        &-h1 {
            font-weight: $fw-extrabold;
            font-size: torem(24px);
            line-height: torem(29px);
            color: $mainfont;
            margin-top: torem(4px);
            .discr {
                font-weight: $fw-medium;
                font-size: torem(18px);
                line-height: torem(26px);
                color: $color-62;
                margin-top: torem(30px);
                display: block;
            }
            
        }
        &-img {
            margin-left: auto;
            @include coner;
            overflow: hidden;
            flex: 1 1 auto;
            max-width: torem(236px);
            margin-left: auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
        &-text {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            padding-right: torem(15px);
            align-items: baseline;
        }
        &-link {
            border: none;
            margin-top: auto;
            background-color: transparent;
            outline: none !important;
            margin-bottom: torem(10px);
        }
    }
    
    @include media-breakpoint-down(sm) {
        .reglabel {
            margin: torem(20px) 0;
        }
    }

    @include media-breakpoint-down(xs) {
        .reglabel {
            padding: torem(11px) torem(15px);
            height: torem(158px);
            &-text,&-img{
                flex: 1 1 auto;
            }
            &-img{
                border-top-left-radius: torem(22px);
            }
            &-h1{
                font-size: torem(20px);
                line-height: torem(24px);
            }
        }
    }

</style>