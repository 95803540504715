<template>
    <div class="report">
        <div class="report-top">
            <slot name="head"></slot>
        </div>
        <div class="report-cont">
            <slot name="cont"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "report-item"
    }
</script>

<style lang="scss">
    .report {
        @include obj;
        @include coner;
        box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
        padding: torem(54px);
        &-row {
            display: flex;
            align-items: center;
            padding: torem(9px) 0;
            margin: torem(22px) 0;
            margin-left: torem(-15px);
            margin-right: torem(-15px);
            &:last-child {
                margin-bottom: 0;
            }
            &:nth-child(2n) {
                background-color: $color-f7;
            }
        }
        &-col {
            flex: 1 1 25%;
            padding: 0 torem(15px);
            overflow: hidden;
            &.jc-center {
                justify-content: center;
            }
            p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
        &-head &-col {
            
            font-weight: $fw-800;
            font-size: torem(20px);
            line-height: torem(24px);
            display: flex;
            align-items: center;
            color: $blue-1f;
        }
        &-head &-row {
            padding: 0;
        }
        &-col {
            font-weight: $fw-600;
            font-size: torem(18px);
            line-height: torem(22px);
            display: flex;
            align-items: center;
            color: $mainfont;
            &.report-empty {
                color: $color-94;
            }
        }
        
        &-status {
            border: torem(3px) solid $blue-18;
            box-sizing: border-box;
            border-radius: torem(30px);
            width: auto;
            height: torem(42px);
            padding: 0 torem(13px);
            
            display: flex;
            justify-content: center;
            align-items: center;
            &.unconfirm {
                border-color: $blue-18;
            }
            &.inprogress {
                border-color: $color-94;
            }
            &.reject {
                border-color: $red-f3;
            }
            &.complite {
                border-color: $color-51;
            }
        }
    }
    
    @include media-breakpoint-down(sm) {
        .report {
            padding: torem(28px);
            &-row {
                padding: 0;
                margin: 0;
            }
            &-col {
                flex: 1 1 20%;
                padding: torem(10px) 0;
                p {
                    line-height: torem(22px);
                    font-size: torem(16px);
                }
            }
            &-head &-col {
                p {
                    font-size: torem(20px);
                }
            }
            &-head &-row {
                flex: 1 1 18%;
            }
            &-sm-small {
                flex: 1 1 13%;
            }
            &-status {
                height: torem(32px);
                font-size: torem(12px);
                line-height: torem(15px);
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        
        .report {
            padding: torem(18px);
            border-radius: torem(4px);
            border-top-left-radius: torem(30px);
            &-head {
                .report-col {
                    padding: 0;
                    flex: 1 1 20%;
                    p {
                        font-size: torem(12px);
                        line-height: torem(15px);
                    }
                }
            }
            &-col {
                flex: 1 1 20%;
                p {
                    font-size: torem(12px);
                    white-space: normal;
                    word-break: keep-all;
                }
                &:first-child p{
                    white-space: pre-line;
                }
            }
    
            &-col &-status{
                border: none;
                white-space: normal;
                overflow: visible;
                line-height: 1;
                text-align: center;
                &.unconfirm {
                    color: $blue-18;
                }
                &.inprogress {
                    color: $color-94;
                }
                &.reject {
                    color: $red-f3;
                }
                &.complite {
                    color: $color-51;
                }
            }
        }
    }
</style>