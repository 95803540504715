<template>
    <div class="container d-flex flex-column  align-items-stretch" v-if="currentProject">
        <div class="row">
            <div class="col-12 d-flex d-sm-block">
                <button @click="toggleFocuse(true)" class="edit-project-back ficon icon-arrow-left"
                        :class="{'visible': !tabsFocuse}" v-if="isMobile"></button>
                <h1 class="main-h1 edit-project">
                    {{ currentProject.isNew() ? 'Create project' : 'Edit project' }}
                </h1>
            </div>
        </div>
        <template v-if="isLoaded">
            <div class="edit-project-obj" :class="{'hide':isMobile && !tabsFocuse ,'edit-project-obj--loaded':!loaded}">
                <router-link class="edit-project-close ficon icon-subtract" to="/project/list"></router-link>
                <div class="row" v-if="isResubmission">
                    <div class="col-12">
                        <div class="resubmission-info ficon icon-erro3">
                            <p class="resubmission-info--p ">
                                Unfortunately the cover of your album does not meet our requirements
                                <span>
                                    Please replace it with a new one with the requirements mentioned below
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 edit-project-dnd-top">
                        <div class="edit-project-dnd">
                            <p class="edit-project-bold">
                                Upload cover art <template v-if="!isResubmission">and tracks</template>
                                <vue-tooltipster slot="tooltip"
                                                 label="Use this field to import the highest resolution<br>photo of your album artwork. Please follow the<br>specifications set by our importing system.">
                                    <i class="ficon icon-info"></i>
                                </vue-tooltipster>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="loaded">
                    <div class="col-12">
                        <div class="edit-project-top">
                            <div class="edit-project-dnd" :class="{'ready':coverArtReady}">
                                <template v-if="!isResubmission">
                                    <ValidationObserver
                                        tag="form" class="dnd"
                                        ref="observer"
                                        v-slot="{ invalid }"
                                        @submit.prevent=""
                                    >
                                        <ValidationProvider
                                            name="CoverArt"
                                            class="edit-project-dndHolder"
                                            tag="div"
                                            v-slot="{errors, classes}"
                                            rules="required">
                                            <DragAndDrop
                                                v-show="!coverArtPreview"
                                                :text="isMobile?'Cover':'Drag & Drop <br> cover art here, <br> or <br>'"
                                                @upload="onFileAdded($event)"
                                                @imageOnloadEnd="placeCoverArt($event)"
                                                :shape="isFree && currentProject.getTracks().length >= 1"
                                                accept="image/*"
                                                :customClass="{'error':classes.invalid}"
                                            />
                                            <div class="coverArt" v-if="coverArtPreview">
                                                <img :src="coverArtPreview" alt="cover art">
                                                <i @click="deleteCover"
                                                   class="coverArt-remove ficon icon-plus router-link-active"></i>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider
                                            name="Tracks"
                                            class="edit-project-dndHolder edit-project-dndHolder--large"
                                            tag="div"
                                            v-slot="{errors, classes}"
                                            rules="tracksRequired">

                                            <DragAndDrop
                                                :disabled="isFree && currentProject.getTracks().length >= 1 "
                                                :custom-class="['dnd-area--large',{'error':classes.invalid}]"
                                                :imgcover="false"
                                                :multiple="true"
                                                :text="isMobile?'Tracks':'Drag & Drop tracks here or '"
                                                @imageOnloadEnd="placeCoverArt($event)"
                                                accept="audio/*"
                                                :shape="!(isFree && currentProject.getTracks().length >= 1)"
                                                @upload="onFileAdded($event)"></DragAndDrop>
                                            <span class="hidden" style="display: none;"
                                                  v-model="currentProject.attributes.tracks.models.length"></span>
                                        </ValidationProvider>
                                    </ValidationObserver>
                                </template>
                                <template v-else>
                                    <resubmission
                                        :coverArtProgress="coverArtProgress"
                                        :coverArtPreview="coverArtPreview"
                                        @onFileAdded="onFileAdded($event)"
                                        @deleteCover="deleteCover"></resubmission>
                                </template>
                            </div>
                            <div class="edit-project-rulelist-holder">
                                <p class="edit-project-bold edit-project-bold--scnd" v-if="loaded">
                                    Artwork Requirements
                                </p>
                                <ul class="edit-project-rulelist" :class="{'resubmission':isResubmission}">
                                    <li>
                                        <p class="bold">Size:</p>
                                        <p>3000x3000px</p>
                                    </li>
                                    <li>
                                        <p class="bold">Format:</p>
                                        <p>.jpg only</p>
                                    </li>
                                    <li>
                                        <p class="bold">Quality:</p>
                                        <p>Cannot be blurry or projectFormxelated</p>
                                    </li>
                                    <li>
                                        <p class="bold">What must match:</p>
                                        <p>Email, Pricing</p>
                                    </li>
                                    <li>
                                        <p class="bold">Cannot include/refer to:</p>
                                        <p>Artists & album title on the cover art must <br> match exactly the primary
                                           artists and album in projec t information</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <span class="ace-box">Don’t have artwork? Meet ACE our
                                <a href="https://ai.octiive.com/art"  @click="aiLink">Artwork Creation Engine</a>.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12" v-if="isResubmission">
                    <button ref="resubmissionButton" class="btn edit-project-resubmission" @click="resubmission()"
                            :disabled="!oldCover || !coverArtPreview || !isCoverChange">
                        Distribute
                    </button>
                </div>
                <div class="col-12" v-else>
                    <div class="edit-project-container" v-if="loaded">
                        <tabs
                            ref="tabs"
                            :currentProject="currentProject"
                            :activeTab="activeTab"
                            :loaded="loaded"
                            @activeTabChange="tabSwitch($event)"
                            :tabsFocuse="tabsFocuse"
                            @click="!isMobile?toggleFocuse(true):toggleFocuse(false)"
                        />
                        <div v-if="genreTree"
                             class="edit-project-rs"
                             :class="[{'isLarge':!tabsFocuse},{'isSmall':tabsFocuse}]"
                             @click="toggleFocuse(false)"
                             ref="forms"
                        >
                            <div class="edit-project-cont">
                                <div class="edit-project-wind project-form">
                                    <div class="row no-gutters">

                                        <div class="col-12">
                                            <row :row-class="'single'" :top-class="'single'">
                                                <h5 slot="title" class="edit-project-h5">
                                                    Enter {{ activeTab ==='projectForm' ? 'album' : 'track' }}
                                                    information</h5>
                                            </row>
                                        </div>
                                    </div>
                                    <template v-if="!(activeTab===false) && activeTab === 'projectForm'">
                                        <projectInfoForm
                                            v-if="currentProject"
                                            ref="projectForm"
                                            :currentProject="currentProject"
                                            :dateList="dateList"
                                            :genre="genreTree"
                                            :immediatelyHighlight="immediatelyHighlight"
                                            @formHasError="scroolToError"
                                            @validateFormChange="changeForm($event)" />
                                    </template>
                                    <template
                                        v-if="!(activeTab===false) && activeTab === index && genreTree && currentProject.attributes.tracks.models.length"
                                        v-for="(track,index) in currentProject.attributes.tracks.models">
                                        <track-form
                                            :key="'trackForm-'+index"
                                            ref="trckForm"
                                            v-model="currentProject.attributes.tracks.models[index]"
                                            :projectInfo="currentProject"
                                            :dateList="dateList"
                                            :genre="genreTree"
                                            :userParticipators="userParticipators"
                                            @remove="changeForm()"
                                            :immediatelyHighlight="immediatelyHighlight"
                                            @validateFormChange="changeForm($event)"
                                            @formHasError="scroolToError"
                                        />
                                    </template>
                                </div>
                            </div>
                            <div class="row">
                                <button ref="nextButton" class="btn edit-project-next" @click="next($event)"
                                        :disabled="this.isEmailVerificationFailed ? false : true">
                                  {{ this.isEmailVerificationFailed ? 'Distribute' : 'Next' }}
                                </button>
                                <loader-small class="project-edit-loader--poss" :substrate="false" v-if="loader" text="Resending verification email..."/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row d-sm-none justify-content-center"
                 v-if="isMobile && !isResubmission">
                <button class="btn btn-small" @click="next($event)" v-if="tabsFocuse"
                        :disabled="!currentProject.readyForDistribute()">
                    Distribute
                </button>
            </div>
        </template>
        <random-loader v-if="!isLoaded" class="loader--poss" :substrate="false" :text="loadingText" />
    </div>

</template>

<script>


    /*sdk*/
    import UploaderSDK from 'uploader-sdk/dist/uploader-sdk.esm';

    /*Components*/
    import DragAndDrop from './components/drag-and-drop';
    import projectInfoForm from './components/project-form';
    import tabs from './components/tabs';
    import vueTooltipster from '../../components/vue-tooltipster';

    import trackForm from './components/track-form';
    import row from './components/row';
    import vueSwitch from './components/switch';
    import resubmission from './components/resubmission-form'
    import randomLoader from '@/components/random-loader';
    import loaderSmall from "../../components/loader-small";

    /*models*/
    import ProjectModel from '@/components/models/distribution-project';
    import Participator from '@/components/models/participator'
    import Track from '@/components/models/distribution-project/track';

    /*collections*/
    import Participators from '@/components/collections/participators';
    import Products from '@/components/collections/products'

    import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
    import {mapGetters} from 'vuex';
    import axios from 'axios';

    /*mixins*/
    import {isMobile} from '../../components/mixins/isMobile'

    extend('tracksRequired', {
        validate(e) {
            return !!e
        },
        message: 'Track required'
    });

    extend('onlyfirstUpper', {
        validate(projectName) {
            let name = projectName.slice(1, projectName.length).replace(/ /g, '').replace(/[0-9]/g, ''),
                upperName = name.toUpperCase();
            for (var i = 0; i <= name.length - 1; i++) {
                if (name[i] === upperName[i]) {
                    return false
                }
            }
            return true;
        },
        message: 'Only first letter must be capitalized upper'
    });

    extend('noUrl', {
        validate(title) {
            var regexp = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            return !regexp.test(title)
        },
        message: 'Don\'t use web links for title'
    });


    export default {
        name: "Project-edit",
        mixins: [isMobile],
        data() {
            return {
                filesInProgress: [],
                loaded: false,
                currentProject: false,
                currentProjectValidate: null,
                activeTab: 'projectForm',
                genreTree: null,
                userParticipators: null,
                nextInvalidIndex: false,
                dateList: [],
                uploader: null,
                isSavingProject: false,
                coverArtPreview: false,
                formWasChanged: false,
                firstTrackName: null,
                immediatelyHighlight: false,
                isFree: false,
                isResubmission: null,
                tabsFocuse: true,
                oldCover: null,
                coverArtProgress: null,
                isEmailVerificationFailed: false,
                user: null,
                cart: null,
                products: null,
            }
        },
        props: {
            loader: {
              type: Boolean,
              default: false
            },
        },
        created() {
            for (var i = new Date().getFullYear() + 1; i >= 1900; i--) {
                this.dateList.push(i);
            }
        },

        async mounted() {
            this.currentProjectInit().then(() => {
                this.loaded = true;

                let releaseDate  = new Date(this.currentProject.getDesiredReleaseDate()),
                    nowDate = new Date();
                if(nowDate.setDate(nowDate.getDate()+30) > releaseDate){
                    this.currentProject.set('desiredReleaseDate',null);
                }
                this.coverArtPreview = this.currentProject.getCoverArtUrl();
                if (this.currentProject.isFreeSingle()) {
                    this.currentProject.updateAlbumType();
                    this.isFree = true;
                }
                if (this.currentProject.getStatus() != 11 || this.currentProject.getStatus() != 8)
                    if (this.currentProject.getStatus() != 11) {
                        this.genreTreeInit();
                    }
                    else {
                        this.genreTree = true;
                        this.isResubmission = true;
                    }
                this.oldCover = this.currentProject.getCoverArtUrl();
                this.currentProject.getTracks().each(track => {
                    if (track.attributes.changed) {
                        track.attributes.changed = null
                    }
                });
            });

            this.user = this.$store.getters['user/user'];
            if (!this.user) {
                this.$store.dispatch('user/loadUser')
                    .then((user) => {
                        this.user = user;
                    });
            }
            else {
                this.user.loadCurrent();
            }

            this.$store.dispatch('track/reqLang');
            this.userParticipatorsInit();
            if (this.$route.query.ppp) {
                this.currentProject.attributes.payPerProject = true;
            }
            if (this.$route.name === 'free') {
                this.currentProject.attributes.freeSingle = true;
                this.isFree = true;
            }
            this.initUploader();

            // Load cart
            this.cart = await this.$store.dispatch('cart/loadCart');

            // Load products
            await this.initProducts();
        },
        destroyed() {
            if (this.currentProject) {
                this.currentProject.stopPlayingAllTrack();
            }
        },
        methods: {
            async initProducts() {
              if (!this.productList) {
                await this.$store.dispatch('product-list/load');
              }

              this.products = new Products(this.productList);
            },
            toggleFocuse(isFocuse) {
                this.tabsFocuse = isFocuse
            },
            scroolToError() {
                let firstError = $('.inputTT-error,.error,.inputBtn-error,.slotTT-error')[0];
                if (!firstError) {
                    return;
                }
                let body = $('html,body'),
                    header = $('.header'),
                    title = $('.project-form-title');
                body.animate({
                    scrollTop: $(firstError).offset().top - (header.height() + title.height() + 10)
                }, 1000);
            },

            deleteCover() {
                this.currentProject.setNewCoverArt();
                this.coverArtPreview = null;
            },

            async checkCoverArt() {
                let coverArt = this.currentProject.getCoverArt();
                if (!coverArt) {
                    this.currentProject.setNewCoverArt();
                    coverArt = this.currentProject.getCoverArt();
                }
                if (!coverArt.getImageUrl()) {
                    coverArt.fetch()
                        .then(() => this.checkCoverArt())
                        .catch(() => this.checkCoverArt());
                } else {
                    this.coverArtPreview = coverArt.getImageUrl();
                    this.coverArtProgress = null,
                        this.changeForm()
                }
            },

            async checkTrackFile(track) {
                if (track.attributes.file) {
                    this.changeForm();
                    return;
                }
                track.fetch().then(() => {
                    this.checkTrackFile(track)
                });
            },

            async currentProjectInit() {
                let self = this;
                if (this.$route.params.id) {
                    this.currentProject = Backbone.Relational.store.find(ProjectModel, this.$route.params.id);
                    if (!this.currentProject) {
                        this.currentProject = new ProjectModel();
                        this.currentProject.set('id', this.$route.params.id);
                        return this.currentProject.fetch({
                            error(collection, error) {
                                self.$root.popup.showMessage(error);
                            }
                        });
                    }
                    return this.currentProject;
                } else {
                    this.currentProject = new ProjectModel();
                    this.$set(this.currentProject.attributes, 'id', null);
                    return this.currentProject;
                }
            },

            async userParticipatorsInit() {
                let self = this;
                this.userParticipators = new Participators();
                return this.userParticipators.fetch({
                    error(collection, error) {
                        self.$root.popup.showMessage(error);
                    }
                });
            },

            genreTreeInit() {
                if (!this.genreTree) {
                    this.$store.dispatch('projects/loadGenreTree').then((tree) => {
                        this.genreTree = this.fixGenreTree(tree);
                        this.$nextTick(async () => {
                            // fire after all ready
                            this.changeForm();
                        });
                    });
                }
            },

            async onFileAdded(files) {
                let coverArt = null, freeError = false;
                for (const file of files) {
                    if (/image/.test(file.type)) {
                        if (!coverArt) {
                            // only first
                            coverArt = file;
                        }
                    } else {
                        if (!(this.isFree && (
                                this.currentProject.getTracks().length ||
                                this.filesInProgress.length)
                        )) {
                            this.filesInProgress.push(file);
                        }
                        else {
                            freeError = true;
                        }
                    }
                }
                if (freeError) {
                    this.$root.popup.showMessage('You use Free Single and u can upload only one tracks')
                }
                // fix multiple images
                if (coverArt) {
                    this.uploadCoverArtFile(coverArt);
                } else {
                    this.uploadTracks();
                    this.currentProject.updateAlbumType();
                }
                if (this.activeTab == 'projectForm' && this.$refs.projectForm) {
                    this.$refs.projectForm.checkForm();
                }
            },

            async uploadTracks() {
                if (this.currentProject.isNew()) {
                    this.currentProject.updateAlbumType();
                    this.currentProject.save(null, {validate: false}).then(() => {
                        if (this.$route.name == 'create-project') {
                            this.$router.replace("/project/" + this.currentProject.get('id') + '/edit');
                        }
                        this.uploadTracks();
                    }).catch(e => {
                        this.$root.popup.showMessage(e);
                    });

                    return;
                }

                let i = this.currentProject.attributes.tracks.length || 0;
                let
                    projectFieldMap = {
                        title: 'album',
                        notes: 'comment',
                        copyrightYear: 'year',
                        copyrightLine: 'copyright'
                    },
                    trackFieldMap = {
                        title: 'title',
                        isrc: 'isrc',
                        copyrightYear: 'year',
                        copyrightLine: 'copyright',
                        position: 'position',
                    };

                for (var file of this.filesInProgress) {

                    var track = new Track({
                        position: ++i,
                        originalFile: file.name,
                        genre: this.parseGenre(file.meta.common.genre)
                    });

                    this.parseFileMetadata(file, projectFieldMap, this.currentProject.attributes);
                    this.parseFileMetadata(file, trackFieldMap, track.attributes);
                    /* add new participators from meta */
                    if (file.meta.common.artist) {
                        await this.pushParticipatorToTrack(track, {
                            typeLabel: "Main",
                            type: 1,
                            name: file.meta.common.artist
                        });
                    }
                    if (file.meta.format.duration) {
                        track.set('duration', parseInt(file.meta.format.duration));
                    }
                    track.set('isNewTrack', true);
                    track.set('progress', 0);
                    // fix title
                    track.set('title', track.getTabLabel());

                    this.currentProject.get('tracks').add(track);

                    let trackFile = file,
                        trackCopy = track;
                    track.save(null, {validate: false})
                        .then(() => {
                            this.uploadTrackFile(trackCopy, trackFile);
                        });
                }

                this.currentProject.fixTrackPositions();

                this.filesInProgress = [];
            },

            parseFileMetadata(track, fields, obj) {
                for (const projectField in fields) {
                    let trackField = track.meta.common[fields[projectField]];
                    if (!!obj[projectField]
                        || !trackField
                    ) {
                        continue;
                    }
                    if (Array.isArray(trackField)) {
                        trackField = Object.values(trackField)[0];
                    }
                    obj[projectField] = trackField;
                }
                return obj;
            },

            parseGenre(genre) {
                if (!genre || !this.genreTree) {
                    return '';
                }
                for (let i = 0; i < this.genreTree.length; i++) {
                    let section = this.genreTree[i].children;

                    if (!section) {
                        continue;
                    }

                    for (let j = 0; j < section.length; j++) {
                        let item = section[j];
                        if (item.title === genre[0]) {
                            return item
                        }
                    }
                }
                return '';
            },

            tabSwitch(tab) {
                this.activeTab = tab;
                this.changeForm();
            },
            scroolToActiveTab(tab) {
                let el = this.$refs.tabs.$refs.trackTabList.$children[tab];
                if (!el) {
                    return
                }
                el.$el.scrollIntoView();

                let body = $('html,body'),
                    header = $('.header'),
                    editProjectCont = $('.edit-project-cont');
                if (body.scrollTop() + header.height() > editProjectCont.offset().top) {
                    body.animate({
                        scrollTop: editProjectCont.offset().top - header.height() + 'px'
                    }, 1000);
                }

            },
            async pushParticipatorToTrack(track, participatorData) {
                let
                    self = this,
                    participator = this.userParticipators.find(function (participator) {
                        return participator.get('type') === participatorData.type &&
                            participator.get('name').toLowerCase() === participatorData.name.toLowerCase()
                    });
                if (!participator) {
                    participator = new Participator(participatorData);
                    await participator.save(participatorData, {
                        success: function (model) {
                            self.userParticipators.add(participator);
                        },
                        error: function (item, error) {
                            self.$root.popup.showMessage(error);
                        }
                    });
                }
                else {
                    participator.setAvailablePermits(this.userParticipators.where(
                        {
                            type: participatorData.type,
                            name: participatorData.name
                        })[0]);
                }
                track.attributes.participators.add(participator);
            },

            fixGenreTree(array) {
                var res = [];
                /* return new array for multiselect */
                array.forEach((category) => {
                    var genrelist = [];
                    category['children'].forEach((genre) => {
                        genrelist.push({
                            id: genre['id'],
                            title: genre['title']
                        })
                    });
                    res.push({
                        category: category['title'],
                        genrelist: genrelist
                    })
                });
                return res
            },
            redirectToDistribute() {
                this.$router.push('/project/' + this.currentProject.attributes.id + '/select-dsp');
                // this.$router.push('/project/' + this.currentProject.attributes.id + '/distribute');
            },
            resubmission() {
                this.$refs.resubmissionButton.disabled = true;
                this.currentProject.continueDistribution().done(e => {
                        this.$router.push('/project/list');
                    }
                ).catch(e => {
                        this.$root.popup.showMessage(e);
                        this.$refs.resubmissionButton.disabled = false;
                    }
                )

            },

            popupConfirmation($message, $confirmText = 'Add', $cancelText = 'Cancel') {
              const popup = this.$root.popup;

              return new Promise((resolve, reject) => {
                popup.showMessage($message);
                popup.setAddText($confirmText);
                popup.setCancleText($cancelText);

                // Assign handlers to resolve or reject the promise
                popup.onConfirm = () => {
                  resolve(); // Resolves when user confirms
                };

                popup.onCancel = () => {
                  reject(); // Rejects when user cancels
                };
              });
            },

            async promoteYouTubeContentIDAddon() {
              const YT = {name: 'youtubeContentID', id: 31}

              if (this.hasAddon(YT.id)) {
                return Promise.resolve();
              }

              const message = `<h3 class="prompt-heading">🎥 Wait, before you hit Next Step 🎶</h3>
                Add YouTube Content ID for just $${this.getProductPrice(YT.id)} and start earning every time your
                track is used in a video. Don’t miss out—grab it before you check out!”`;

              try {
                await this.popupConfirmation(message, 'Add Now', 'Cancel');

                await this.addAddon(YT.id, YT.name);
              } catch {
                // User canceled; no further action needed
              }
            },

            // TODO hasAddon, getProductPrice, addAddon needs to be moved to a dedicated service.
            // this can be written much much cleaner. we have this duplicate multiple places..

            hasAddon(id) {
              let cartItem = this.cart.getItemBy({id: id});

              return cartItem && cartItem.isAddonFor(this.currentProject);
            },

            getProductPrice(productId) {
              if(!this.products) {
                return;
              }

              let product = this.products.findWhere({ id: productId });

              if (! product) {
                console.log(`Invalid product id given: `, productId);
                return 0;
              }

              return product.getPrice()
            },

          async addAddon(id, addonName, event = null) {
            try {
              const product = this.products.findWhere({ id });

              await this.cart.addItemToCart(
                product,
                  { project_id: this.currentProject.attributes.id }
                );

                this.currentProject.attributes[addonName] = true;
              } catch (error) {
                this.$root.popup.showMessage(error);
              }
            },

            async next(e) {
                let self = this, button = e.currentTarget || this.$refs.nextButton;
              button.disabled = true;
              await this.promoteYouTubeContentIDAddon();
              button.disabled = false;

                if (this.activeTab === this.nextInvalidIndex) {
                    button.disabled = true;
                    return
                }
                if (this.isResubmission) {
                    self.redirectToDistribute();
                }
                if (this.nextInvalidIndex !== false) {
                    this.immediatelyHighlight = true;
                    this.tabSwitch(this.nextInvalidIndex);
                    this.scroolToActiveTab(this.activeTab);
                    this.$nextTick(() => {
                        this.immediatelyHighlight = false;
                    });

                    return
                }
                button.disabled = true;
                this.loaded = false;
                this.currentProject.enableDistribute();
                this.currentProject.save(null, {
                    success(context, response) {
                        if (typeof response.getCode === 'function' && response.getCode() == 200 && response.getMessage()) {
                            button.disabled = false;
                            self.isEmailVerificationFailed = true;
                            self.$root.popup.showMessage(response.getMessage());
                        } else {
                            self.redirectToDistribute();
                        }
                    },
                    error(model, error) {
                        button.disabled = false;
                        self.isEmailVerificationFailed = true;

                        self.$root.popup.showMessage(error);
                        self.$root.popup.onConfirm = function () {
                        self.loader = true;
                        axios.post('/api/email-confirmation/resend-verification/signup').then((response) => {
                          let message = '';
                          self.loader = false;
                          if (response.data.code == 200) {
                            message = response.data.message;
                          } else {
                            message = 'Something went wrong!'
                          }
                          self.$root.popup.clear();
                          self.$root.popup.showMessage(message);
                        }).catch(error => {
                          self.loader = false;
                          // shows an error
                          this.$root.popup.showMessage(error);
                        });
                      };
                      self.$root.popup.onCancel = function () {
                        self.$nextTick(() => {
                          self.$root.popup.clear();
                        });
                      };
                      self.$root.popup.setAddText('Resend Verification Email');
                      self.$root.popup.setCancleText('Ok');
                    }
                }).always(
                  () => this.loaded = true
                )
            },

            placeCoverArt(renderItem) {
                this.coverArtPreview = renderItem;
            },

            async uploadTrackFile(track, file) {
                this.uploader.uploadFile(
                    UploaderSDK.factory.createFile(file, track.get('slotId'))
                );
            },

            async uploadCoverArtFile(file) {
                if (this.currentProject.isNew()) {
                    this.currentProject.updateAlbumType();
                    this.currentProject.save({tracks: null}, {validate: false}).then(() => {
                        if (this.$route.name == 'create-project') {
                            this.$router.replace("/project/" + this.currentProject.get('id') + '/edit');
                        }
                        this.uploadCoverArtFile(file)
                    }).catch(e => {
                        this.$root.popup.showMessage(e);
                    });

                    return;
                }
                this.currentProject.setNewCoverArt();
                axios.get('/api/projects/' + this.currentProject.get('id') + '/cover-slot').then((e) => {
                    this.currentProject.get('coverArt').destroy();
                    this.uploader.uploadFile(
                        UploaderSDK.factory.createFile(file, e.data.id)
                    );
                }).catch(error => {
                    // show error
                    this.$root.popup.showMessage(error);
                });
            },

            async initUploader() {
                let self = this;
                await this.$store.dispatch('uploader-config/load');
                this.uploader = UploaderSDK.factory.createUploader(this.uploadServer);
                // uploader callbacks
                this.uploader.onFileProgress((file, progress) => this.callbackByFile(
                    file,
                    () => this.showCoverProgress(progress),
                    (track) => this.showTrackProgress(track, progress)
                ));
                this.uploader.onFileError((file, error) => this.callbackByFile(
                    file,
                    () => this.showCoverError(error),
                    (track) => this.showTrackError(track, error)
                ));
                this.uploader.onFileSuccess((file) => this.callbackByFile(
                    file,
                    (file) => {
                        this.checkCoverArt();
                        this.uploadTracks();
                    },
                    (track) => {
                        this.checkTrackFile(track);
                    }
                ));
            },

            showCoverProgress(progress) {
                this.coverArtProgress = progress;
            },

            showCoverError(error) {
                console.error('Failed to upload cover', error);
            },

            showTrackProgress(track, progress) {
                track.set('progress', progress);
            },

            showTrackError(track, error) {
                console.error('Faile to upload track ' + track.getTabLabel(), error, track);
            },

            // stupid method but what are options?`
            callbackByFile(file, coverCallback, trackCallback) {
                let track = this.currentProject.get('tracks').findWhere({slotId: file.getSlotId()});
                if (track) {
                    trackCallback(track);

                    return;
                }

                coverCallback(file);
            },

            searchInvalidTrack() {
                let tracks = this.currentProject.attributes.tracks.models, returnIndex = false;
                tracks.some((track, index) => {
                    let trackError = !!track.validate(track.attributes);
                    if (trackError) {
                        returnIndex = index;
                    }
                    return trackError
                });
                return returnIndex;
            },

            changeForm() {
                if (Object.keys(this.$refs).length === 0) {
                    return false
                }
                let currentProject = this.currentProject,
                    nextButton = this.$refs.nextButton;

                this.nextInvalidIndex = false;
                currentProject.disableValidationOfCoverArt();
                currentProject.disableValidationOfTracks();
                currentProject.disableAllValidation();
                if (currentProject.hasTracks()) {
                    currentProject.enableValidationOfTracks();
                    currentProject.updateAlbumType();
                }
                let errors = currentProject.validate(currentProject.attributes);
                if (errors) {
                    console.warn('project has errors', {errors});
                    nextButton.innerHTML = 'Next';
                    this.nextInvalidIndex = 'tracks' in errors
                        ? this.searchInvalidTrack()
                        : 'projectForm';
                    nextButton.disabled = this.nextInvalidIndex === false || this.nextInvalidIndex === this.activeTab;

                    return false
                }

                if (!currentProject.hasTracks() || !currentProject.hasCoverArt()) {
                    console.warn('no cover or tracks');
                    this.$refs.observer.validate();

                    return false;
                }

                nextButton.innerHTML = 'Distribute';
                nextButton.disabled = false;
                return true
            },

            aiLink(e) {
                e.preventDefault();

                let url = e.target.href;

                let separator = url.includes('?') ? '&' : '?';

                url += separator + 'token=' + this.user.attributes.ai_token;

                window.open(url, '_blank');
            },
        },

        computed: {
            participatorCount() {
                if (!this.currentProject) {
                    return 0;
                }
                return this.currentProject.attributes.tracks.models.reduce(function (v, track) {
                    return v + track.participatorCount;
                }, 0);
            },
            isLoaded() {
                return this.currentProject
                    && this.loaded
                    && this.genreTree;
            },
            loadingText() {
                return [
                    "Time to finish what you've started",
                    // "We're responsible for what we create",
                    "Your projects need your attention",
                    "Your project will be here in a moment",
                    // "We carry for your projects",
                    "The world is waiting to hear this",
                    "Project information loading…",
                    // "Wash your hands before distributing",
                    // "Distributing your projects from home means a lot",
                    "Hmm, it’s here somewhere, let me look...",
                    this.currentProject && this.currentProject.isNew()
                        ? "You're gonna create something awesome"
                        : "Remember - Wash your songs before uploading tracks",
                ];
            },
            isCoverChange() {
                return this.oldCover !== this.coverArtPreview
            },
            readyForDistribute() {
                return !this.currentProject.validate(this.currentProject.attributes);
            },
            coverArtReady() {
                if (!!this.currentProject.getCoverArt() && !this.coverArtPreview) {
                    this.coverArtPreview = this.currentProject.getCoverArtUrl();
                }
                return !!this.currentProject.getCoverArt();
            },
            ...mapGetters({
                uploadServer: 'uploader-config/getUploaderUrl',
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
                productList: 'product-list/get',
            })
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            trackForm,
            projectInfoForm,
            DragAndDrop,
            vueTooltipster,
            row,
            vueSwitch,
            randomLoader,
            tabs,
            resubmission,
            loaderSmall
        },

    }
</script>

<style lang="scss">
    @keyframes lightPass {
        0% {
            background-position: left;
        }
        100% {
            background-position: right;
        }
    }

    .ace-box {
        font-weight: bold;
        color: #333;
        padding: 5px 10px;
    }

    .ace-box a {
        color: #1869B5;
    }

    .edit-project {
        $coverArtSize: torem(150px);
        &-coverBuilder {
            margin: 0;
            position: absolute;
            right: torem(12px);
            bottom: torem(12px);
        }
        &-next {
            margin: torem(56px) auto;
            height: torem(38px);
            font-size: torem(18px);
            line-height: 1;
        }
        &-resubmission {
            height: torem(38px);
            margin-top: torem(20px);
            font-size: torem(18px);
            margin-left: torem(40px);
        }
        &-h5 {
            font-weight: $fw-extrabold;
            font-size: torem(24px);
            line-height: torem(29px);
            display: flex;
            align-items: center;
            color: $mainfont;
            margin-bottom: torem(4px);
        }
        &-close {
            text-decoration: none !important;
            position: absolute;
            top: torem(20px);
            right: torem(20px);

            color: $mainfont;
            width: torem(24px);
            height: torem(24px);
            padding: 0;
            margin: 0;

            border-radius: 50%;
            overflow: hidden;
            min-width: torem(1px);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            font-size: torem(24px);
            &:before {
                margin: 0;
                color: $mainfont !important;
            }
        }
        &-obj {
            position: relative;
            @include coner;
            width: 100%;
            padding: torem(43px) torem(42px);
            background: $white;
            box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
            &--loaded {
                height: torem(272px);
            }
        }
        &-rs {
            width: 100%;
            z-index: 2;
        }
        &-cont {
            width: 100%;
            padding: torem(50px) torem(43px);
            background: $white;
            border-radius: torem(4px);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
            .vdate-input {
                border-color: $color-62;
            }
            .full-width .role-input {
                width: 100%;
                flex: 1 1 100%;
            }
        }
        &-bold {
            font-weight: $fw-bold;
            font-size: torem(20px);
            line-height: torem(24px);
            margin: 0 0 0 0;
            color: $black;
            &--scnd {
                margin-top: 0;
                margin-right: (22px);
                color: $blue-1f;
                margin-bottom: 0;
            }
            .tooltipster {
                vertical-align: middle;
                margin-top: torem(-5px);
                i {
                    margin-left: torem(8px);
                }
            }
        }
        &-rulelist {
            padding: 0;
            margin: 0;
            list-style: none;
            padding-top: torem(8px);
            li {
                margin: torem(4px) 0;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            &-holder {
                max-width: 53.5%;
                width: 100%;
                padding-left: torem(20px);
                //padding-top: torem(5px);

            }
            p {
                margin-left: torem(8px);
                font-size: torem(14px);
                line-height: torem(17px);
                color: $mainfont;
                margin-bottom: torem(10px);
                font-weight: $fw-medium;
                display: inline;
                &.bold {
                    margin-left: 0;
                    font-weight: $fw-bold;
                    font-size: torem(14px);
                    line-height: torem(20px);
                }
            }
            &.resubmission {
                p .bold {
                    color: $red-f3;
                }
            }
        }
        &-top {
            display: flex;
        }
        &-top .coverArt {
            width: $coverArtSize;
            height: $coverArtSize;
            border-radius: torem(4px);
            overflow: hidden;
            position: relative;
            &-remove {
                margin: 0;
                position: absolute;
                top: torem(12px);
                right: torem(12px);
                color: $mainfont;
                cursor: pointer;
                font-size: torem(10px);
                width: torem(16px);
                height: torem(16px);
                background-color: $white;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                display: flex;
                transform: rotate(45deg);
                &:before {
                    margin: 0;
                }
            }
            img {
                width: 100%;
                height: 100%;
                vertical-align: top;
            }
        }
        &-dnd {
            flex: 1 1 torem(720px);
            max-width: torem(720px);
            &-top {
                display: flex;
                align-items: center;
                margin-bottom: torem(12px);
            }
            &Holder {
                max-width: $coverArtSize;
                flex: 1 0 $coverArtSize;
                box-sizing: border-box;
                margin: torem(10px);
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                &--large {
                    max-width: torem(540px);
                    flex: 1 1 torem(600px);
                }
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
            .dnd-area {
                height: $coverArtSize;
                border-color: $color-c2;
            }
            .dnd-note p, .dnd-btn span {
                font-size: torem(14px);
            }
            .dnd-note p {
                line-height: torem(19px);
            }
        }
        &-container {
            display: flex;
            margin-top: torem(22px);
        }
        &-tabs {
            position: relative;
            margin-bottom: torem(150px);
            /*border-bottom: torem(1px) solid $tabborder;
            border-bottom-left-radius: torem(4px);*/

            .icon-file-1 {
                color: $blue-18;
                font-size: torem(23px);
                width: torem(42px);
                height: torem(42px);
                line-height: torem(42px);
                background-color: $white;
                border-radius: 50%;
                box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
                text-align: center;
                flex: 0 0 auto;
            }
            .icon-edit {
                cursor: pointer;
                font-size: torem(17px);
                width: torem(28px);
                height: torem(28px);
                line-height: torem(28px);
                text-align: center;
                background-color: $white;
                border-radius: 50%;
                box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
            }
            .icon-play, .icon-pause {
                cursor: pointer;
                font-size: torem(22px);
                color: $blue-18;
                width: torem(36px);
                height: torem(36px);
                line-height: torem(36px);
                border-radius: 50%;
                box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
                background: $white;
                &:before {
                    margin-left: torem(9px);
                    margin-right: 0;
                }
            }
            .icon-subtract {
                cursor: pointer;
                font-size: torem(24px);
                color: $errofontColor;
                margin-left: auto;
                opacity: 0;
                transition: opacity .3s;
            }
            .active .icon-subtract {
                opacity: 1;
            }

            &:after {
                content: '';
                width: torem(4px);
                height: auto;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                display: block;
                background-color: $color-c2;
                z-index: 10;
            }

            &--container {
                ::-webkit-scrollbar-track {
                    background: transparent;
                }
                ::-webkit-scrollbar-thumb {
                    background: $mainfont;
                    cursor: pointer;
                }
                ::-webkit-scrollbar {
                    width: torem(4px);
                }
            }

        }
        &-scrooltabs {
            position: absolute;
            top: torem(130px);
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 12;
            overflow: auto;
            .tab {
                max-width: 100%;
                &.active {
                    margin-left: 0;
                }
            }
        }
        &-wind {
            max-width: 100%;
            width: torem(700px);
            margin: auto;
            .inputTT {
                margin-bottom: torem(30px);
            }

            .loader, .empty {
                height: torem(919px);
            }
            .empty {
                position: static;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .project-form {
        width: torem(590px);
        margin: auto;
        max-width: 100%;
        &-large {
            width: torem(562px);
        }
        &-holder {
            margin-bottom: torem(50px);
        }
        .vee-error {
            /*display: none;*/
            font-size: torem(18px);
            line-height: torem(18px);
            //display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            right: torem(22px);
            margin: auto;
            width: torem(18px);
            height: torem(18px);
            .ficon {

                color: $errofontColor;
            }
            .tooltipster-content {
                color: $errofontColor;
            }
        }
        &-cont {
            display: flex;
            align-items: center;
            &.hider {
                overflow: hidden;
                max-height: 0;
                transition: max-height .3s;
                &.false {
                    max-height: torem(99px);
                }
            }
            &.head {
                height: torem(138px);
                justify-content: center;
            }
        }
        /*&.head &-right {
            align-items: center;
        }*/
        &-left {
            flex: 1 1 37%;
            min-width: 37%;
            /*min-width: torem(632px);*/
            padding: torem(8px) torem(15px);

        }
        &-right {
            flex: 1 1 63%;
            max-width: 63%;
            display: flex;
            padding: 0 torem(15px);
            align-items: center;
            position: relative;
            padding-left: torem(50px);
            &.single {
                padding: torem(8px) torem(15px);
                flex: 1 1 100%;
            }
        }
        &-info {
            &__top {
                bottom: auto;
            }
        }
        &-title {
            display: inline-block;
            font-weight: $fw-semibold;
            font-size: torem(20px);
            line-height: torem(24px);
            color: $mainfont;
            padding-left: torem(26px);
            span {
                font-size: torem(14px);
                line-height: torem(17px);
                color: $color-62;
                font-weight: $fw-medium;
            }

        }
        &-semi-row {
            max-width: torem(1094px);
            width: 100%;
        }
        &-tarea {
            background: $white;
            border: torem(1px) solid $color-62;
            box-sizing: border-box;
            border-radius: torem(4px);
            width: 100%;
            max-width: torem(1030px);
            min-height: torem(221px);
            padding-left: torem(12px);
            padding-top: torem(12px);
            font-size: torem(17px);
            line-height: torem(21px);
            vertical-align: top;
            color: $blue-1f;
            margin-bottom: torem(2px);
        }
        &-btn {
            height: torem(58px);
            width: 100%;
            font-style: normal;
            display: flex;
            align-items: center;
            text-align: center;
            line-height: torem(58px);
            padding: 0;
            &:disabled {
                opacity: 1;
                background: transparent;
                color: $mainfont;
                box-shadow: torem(7px) torem(5px) torem(12px) $btnshad;
            }
        }
        .full-width {
            width: 100%;
            flex: 1 1 100%;
            max-width: 100%;
        }
        .check {
            margin-left: torem(13px);
        }
        .tselect {
            width: torem(126px);
            min-width: 1px;
            margin-right: torem(13px);
            margin-left: 0;
            &__switcher {
                opacity: .4;
                transition: opacity .3s;
                &.active {
                    opacity: 1;
                }
            }

            &__head {
                font-style: normal;
                font-size: torem(14px);
                display: flex;
                align-items: center;
                color: $mainfont;
                margin-bottom: torem(8px);
                margin-top: torem(-29px);
                white-space: nowrap;
                b {
                    color: $main;
                }
            }

        }
        .preorderhider {
            overflow: hidden;
            max-height: 0px;
            margin-left: torem(-15px);
            margin-right: torem(-15px);
            padding: 0 torem(15px);
            transition: max-height, .3s;
            &.active {
                max-height: 100px;
            }
        }

        .row {
            margin-left: torem(-14px);
            margin-right: torem(-14px);
            [class^='col'] {
                padding-left: torem(14px);
                padding-right: torem(14px);
            }
        }
    }

    .additem {
        font-weight: $fw-semibold;
        font-size: torem(20px);
        line-height: torem(24px);
        display: flex;
        align-items: center;
        color: $color-ac;
        padding-left: torem(26px);
        cursor: pointer;
        p {
            /*display: flex;
            align-items: center;*/
            display: inline-block;
        }
        &.active {
            color: $mainfont;
        }
        .ficon {
            font-size: torem(14px);
            cursor: pointer;
            display: inline-flex;
            vertical-align: middle;
            color: $blue-18;
        }
        &.active .ficon:before {
            content: '\e814';
            font-size: torem(4px);
            width: torem(19px);
        }
    }

    .resubmission {
        &-info {
            display: flex;
            align-items: center;
            margin-left: 0;
            margin-bottom: torem(11px);
            &:before {
                color: $red-f3;
                font-size: torem(56px);
                flex: 0 0 auto;
                margin-left: 0;
            }
            p {
                flex: 1 1 auto;
                color: $red-f3;
                font-size: torem(20px);
                font-weight: $fw-600;
                line-height: 1;
                span {
                    margin-top: torem(2px);
                    font-weight: $fw-500;
                    display: block;
                    color: $mainfont;
                    font-size: torem(16px);
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        $coverArtSize: torem(120px);
        .edit-project {
            &-close {
                top: torem(10px);
                right: torem(10px);
            }
            &-obj {
                padding: torem(27px);

            }
            &-bold {
                font-size: torem(18px);
            }
            &-rulelist {
                &-holder {
                    display: none;
                }
            }
            &-dnd {
                &Holder {
                    max-width: $coverArtSize;
                    flex: 1 0 $coverArtSize;
                    margin-top: 0;
                    margin-bottom: 0;
                    &--large {
                        max-width: torem(540px);
                        flex: 1 1 torem(540px);
                    }
                }
                &-top {
                    margin-bottom: torem(10px);
                }
                .dnd-area {
                    height: $coverArtSize;
                }
                .dnd-note p, .edit-project-dnd .dnd-btn span {
                    font-size: torem(12px);
                }
            }
            &-scrooltabs {
                top: torem(92px);
                .tab {
                    overflow: hidden;
                }
            }
            &-rs {
                max-width: 100%;
                flex: 1 1 auto;
                overflow: hidden;
                &.isSmall {
                    .col-dynamic-sm-12 {
                        flex: 1 1 100%;
                        max-width: 100%;
                    }
                }
            }
            &-cont {
                padding-left: torem(30px);
                padding-right: torem(30px);
            }
            &-wind .inputTT {
                margin-bottom: torem(20px);
            }
            &-top .coverArt {
                height: $coverArtSize;
                width: $coverArtSize;
            }
        }
        .additem {
            padding-left: 0;
            &.tooltip-empty-response {
                padding-left: torem(27px);
            }
        }
        .project-form {
            &-title {
                padding-left: 0;
            }

        }
    }

    @include media-breakpoint-down(xs) {
        .project-form {
            .tab {
                background: transparent;
                box-shadow: none;
                padding-left: 0;
                padding-right: 0;
                &.changed {
                    padding: torem(15px) torem(20px);
                }
            }
        }
        .edit-project {
            &.main-h1 {
                margin-right: auto;
            }
            &-obj {
                padding: torem(20px);
                &.hide {
                    display: none;
                }
            }
            &-dnd {
                .dnd-note {
                    p {
                        display: block;
                        text-align: center;
                        font-size: torem(16px);
                        line-height: torem(20px);
                    }
                    span {
                        font-size: torem(16px);
                        line-height: torem(20px);
                    }
                }
                .dnd-btn, &--large {
                    margin-left: 0;
                }
                &Holder {
                    $size: torem(125px);
                    width: $size;
                    height: $size;

                    flex: 1 0 $size;
                    margin: 0 torem(7px);
                    &--large {
                        width: torem(156px);
                    }
                }
            }
            &-close {
                top: initial;
                bottom: 100%;
                margin-bottom: torem(20px);
                right: 0;
            }
            &-bold {
                .tooltipster i {
                    margin-left: torem(4px);
                }
            }
            &-rs {
                display: none;
                /*max-width: 0;
                max-height: 0;
                transition: max-width .3s;*/
                overflow: visible;
                &.isLarge {
                    max-width: torem(375px);
                    max-height: initial;
                    width: 100%;
                    display: block;
                }
            }
            &-scrooltabs {
                position: static;
                overflow: visible;
            }
            &-back {
                background: transparent;
                border: none;
                padding: 0;
                margin: 0 auto 0 0;
                opacity: 0;
                font-size: torem(22px);
                color: $blue-18;
                &.visible {
                    opacity: 1;
                }
                &:before {
                    margin: 0;
                    padding: 0;
                }
            }
            &-cont {
                padding: 0;
                background: transparent;
                box-shadow: none;
                overflow: visible;
            }
        }
        .additem {
            font-size: torem(18px);
        }
    }
</style>
