<template>
    <header class="header">


        <div class="header-sidebar sidebar">
            <div class="sidebar-top" v-if="!isMobile">
                <el-logo key="logoSM" v-if="(isTablet && !isMobile)" logoName="whiteLogoSM" />
                <el-logo key="logo" v-else logoName="whiteLogo" />
            </div>
            <div class="sidebar-cont" :class="{'active':menuActive}">
                <el-tmenu v-if="tmenu" :tmenu="tmenu" aitem="activeitem" @close="menuActive=false" />
            </div>
        </div>

        <div class="header-cont">
            <div class="header-cont-top">
                <div class="container  ">
                    <div class="row ">
                        <div class="col-12">
                            <div class="d-flex flex-wrap flex-sm-nowrap align-items-center">
                                <button v-if="isMobile"
                                        class="header-tmenuBtn d-sm-none"
                                        @click="menuActive=true">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                <el-subscription />
                                <div class="cart-header" v-if="cart">
                                    <div class="cart-header-btn ficon icon-cart"
                                      :class="{'active': cart.attributes.cartItems.length, 'disableCartButton': this.checkDistributeRoute() }"
                                      @click="onCartClick"
                                    >
                                        <span class="cart-header-counter"
                                              v-if="cart.attributes.cartItems.length"
                                              :class="[getClassesForCartCounter]"
                                        >
                                            {{cart.attributes.cartItems.length}}
                                        </span>
                                    </div>
                                </div>
                                <elUserboard v-if="user" :userinfo="user" :dropmenu="userboard" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-popup ref="notifications-popup" ok-button-text="I undestand" cancel-button-text="Close">
            <div class="popup-content">Please click "I understand" if you've read this message.</div>
        </v-popup>
    </header>
</template>
<script>
    //

    import userboard from './components/userboard';
    import logo from './components/logo';
    import tmenu from './components/tmenu';
    import subscription from './components/subscription';

    import {isMobile} from '../../components/mixins/isMobile';
    import axios from 'axios'
    import popup from '@/components/popup';

    export default {
        name: "vue-header",
        mixins: [isMobile],
        data() {
            return {
                testSize: {
                    innerWidth:window.innerWidth,
                    innerHeight:window.innerHeight,
                    clientWidth:document.documentElement.clientWidth,
                    clientHeight:document.documentElement.clientHeight,
                    orientation: null,
                },

                user: false,
                cart: false,
                test: 0,
                tmenu: [
                    {
                        text: 'Home',
                        link: '/home',
                        regExp: 'home',
                        iconclass: 'ficon icon-tmenu-home',
                    },
                    {
                        text: 'Projects',
                        link: '/project/list',
                        introStep: 1,
                        regExp: 'project',
                        iconclass: 'ficon icon-tmenu-projects',
                    },
                    {
                        text: 'ACE',
                        link: 'https://ai.octiive.com/',
                        iconclass: 'ficon icon-tmenu-projects',
                        regExp: 'ace',
                        vrout: true,
                        cssclass: 'color-ace',
                        callback: (e) => {
                            e.preventDefault();
                            let url = e.target.href;

                            let separator = url.includes('?') ? '&' : '?';

                            url += separator + 'token=' + this.user.attributes.ai_token;

                            window.open(url, '_blank');
                        }
                    },
                    {
                        text: 'services',
                        link: '/services',
                        regExp: 'services',
                        iconclass: 'ficon icon-tmenu-services',
                    },
                    {
                        text: 'reports',
                        link: '/user/money-reports/royalty-reports',
                        iconclass: 'ficon font2 icon-money',
                        regExp: 'reports',
                        vrout: true,
                    },
                    {
                        text: 'Analytics',
                        link: '/analytics',
                        iconclass: 'ficon icon-chart',
                        regExp: 'analytics',
                        vrout: true,
                    },
                    {
                        text: 'plans',
                        link: '/plans',
                        regExp: 'plans',
                        iconclass: 'ficon icon-tmenu-plans',
                    },
                    {
                        text: 'marketing',
                        link: '/marketing',
                        regExp: 'marketing',
                        iconclass: 'ficon icon-tmenu-marketing',
                        introStep: 2,
                    }
                ],
                userboard: [
                    {
                        link: '/user/settings',
                        text: 'Account Settings',
                        vrout: true
                    },
                    {
                        link: '/user/ordersList',
                        text: 'My orders',
                        vrout: true
                    },
                    {
                        link: '/home/label',
                        text: 'My label',
                        vrout: true
                    },
                    {
                        link: '/user/money-reports/royalty-reports',
                        text: 'Money & reports',
                        vrout: true
                    },
                    {
                        link: '//www.octiive.com/faq',
                        text: 'Help',
                    }, {
                        link: '/logout',
                        text: 'Log Out',
                    }
                ],
                userinfo: false,
                menuActive: false,
            }
        },

        mounted() {
            this.$nextTick(function () {
              if (this.checkDistributeRoute()) {
                let cartButton = document.querySelector(".header .cart-header-btn");
                let cartCounter = document.querySelector(".cart-header-counter");

                if (cartButton) {
                  cartButton.style.cursor = 'default';
                  cartButton.classList.add('disableCartButton');
                }

                if (cartCounter) {
                  cartCounter.classList.add('disableCartCounter');
                }
              }
            });
            this.getUserinfo();
            this.$store.dispatch('cart/loadCart').then(cart => {
                this.cart = cart;
            });
            this.size = {
                w: document.documentElement.clientWidth,
                h: document.documentElement.clientHeight
            };
        },
        methods: {
            async getUserinfo() {
                this.$store.dispatch('user/loadUser')
                    .then(user => {
                        this.user = user;
                        this.showNotifications();
                    });
            },
            checkDistributeRoute() {
              return this.$router.currentRoute.path.match('/project/\\d+/distribute') ? 1 : 0;
            },
            onCartClick() {
              if (!this.checkDistributeRoute()) {
                this.$router.push('/cart')
              }
            },
            showNotifications() {
                if (!this.user) {
                    return;
                }
                var notifications = this.user.get('notifications');
                if (!notifications) {
                    return;
                }

                for (var i in notifications) {
                    this.$refs['notifications-popup'].showMessage(
                        notifications[i],
                        () => {
                            axios.post('/api/user/skip-notification', {notification: i});
                        },
                        null,
                        () => {
                            delete notifications[i];
                            this.user.set('notifications', notifications);
                            setTimeout(() => this.showNotifications(), 1000);
                        }
                    );

                    // show only first
                    return;
                }
            },
        },
        computed: {
          getClassesForCartCounter() {
            return {
              'disableCartCounter': this.checkDistributeRoute()
            }
          }
        },

        components: {
            elUserboard: userboard,
            elLogo: logo,
            elTmenu: tmenu,
            elSubscription: subscription,
            vPopup: popup,
        },
    }

</script>

<style lang="scss">
    .some {
        position: fixed;
        top: 0;
        right: 0;
        width: 300px;
        height: 300px;
        overflow: auto;
        background: #fff;
        z-index: 1000;
    }

    @keyframes blink {
        0%, 80%, 90%, 100% { opacity: 1; }
        85%, 95% { opacity: 0; }
    }

    .color-ace {
        color: #FF6337 !important;
        animation: blink 5s linear infinite;
    }

    .header {
        background-color: $white;
        width: 100%;
        display: flex;

        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        &-h1 {
            margin: 0;

            font-style: normal;
            font-weight: bold;
            font-size: torem(30px);
            line-height: 1;
            color: $color-13;
        }
        .cart-header-btn {
          cursor: pointer;
        }
        &-sidebar {
            flex: 1 0 $sideMenuWidth;
            max-width: $sideMenuWidth;
            position: relative;
            height: $headerHeight;
            @include media-breakpoint-down(sm) {
                max-width: $sideMenuWidth-sm;
                flex: 1 0 $sideMenuWidth-sm;
            }
        }
        &-cont {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: torem(10px) 0;
            box-shadow: torem(6px) torem(3px) torem(13px) $headFooterboxshadow;
            &-top, &-bottom {
                width: 100%;
            }
            &-top {
                display: flex;
                align-items: center;
                height: torem(80px);
                background-color: $white;
                position: relative;
                z-index: 2;
            }
            &-bottom {
                background-color: $white-f5f;
            }
        }
        .sidebar {
            display: flex;
            &-top {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: $headerHeight;
                position: relative;

                z-index: 100;
                background: $sideBar-Bg;
                &:before, &:after {
                    content: '';
                    display: block;
                    background-color: $white-f5f;
                    position: absolute;
                }
                &:before {
                    top: 0;
                    bottom: 0;
                    left: 100%;
                    width: torem(3px);
                    height: 100%;
                }
                &:after {
                    top: 100%;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: torem(2px);
                }
            }
            &-cont {
                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                margin: auto;
                height: 100vh;
                background: $sideBar-Bg;
            }
        }
        .cart-header {
            margin-left: auto;
            font-size: torem(33px);
            display: flex;
            vertical-align: top;

            padding: 0;
            background-color: transparent;
            box-shadow: none;
            border: none;
            position: relative;
            margin-right: torem(30px);
            &-counter {
                position: absolute;
                top: torem(13px);
                right: 0;
                background-color: $color-f4;
                color: $white;
                margin-right: torem(-13px);
                margin-top: torem(-4px);

                display: flex;
                justify-content: center;
                width: torem(21px);
                height: torem(21px);

                font-size: torem(14px);
                line-height: torem(19px);
                font-weight: $fw-bold;
                border-radius: 50%;
                overflow: hidden;

                white-space: nowrap;
                text-overflow: ellipsis;

                align-items: center;
            }
            &-btn {
                position: relative;
                background-color: transparent;
                box-shadow: none;

                text-decoration: none !important;
                margin: 0;
                margin-top: torem(-3px);
                /*margin-bottom: torem(14px);*/
                &.active {
                    &:before {
                        color: $color-09;
                    }
                }
                &:before {
                    margin: 0;
                    color: $color-ac;
                }

            }
        }
    }

    .toolbar {
        border-bottom: torem(1px) solid $grey-light;
        display: flex;
        width: 100%;
    }

    @include media-breakpoint-down(xs) {
        .header {
            height: $headerXS-Height;
            padding-top: torem(24px);
            padding-bottom: torem(20px);

            &, &-cont-top {
                background: $mainfont;
            }
            &-cont {
                padding: 0;
                box-shadow: none;
                &-top {
                    height: 100%;
                    .container, .row, .col, .d-flex {
                        height: 100%;
                    }
                }
            }
            &-sidebar.sidebar {
                display: flex;
                flex: 0 0 auto;
            }
            .sidebar-cont {
                position: fixed;
                top: 0;
                bottom: 0;
                z-index: 100;
                justify-content: center;
                align-items: center;
                display: flex;
                transition: left .3s, right .3s;
                left: -100%;
                right: 100%;
                &.active {
                    left: 0;
                    right: 0;
                }
            }
            &-tmenuBtn {
                border: none;
                padding: 0;
                background: transparent;
                box-shadow: none;
                outline: none;
                width: torem(22px);
                span {
                    width: 100%;
                    margin-top: torem(4px);
                    background-color: $white;
                    height: torem(2px);
                    border-radius: torem(3px);
                    display: block;
                    &:first-child {
                        margin-top: 0;
                    }
                    &:nth-child(2) {
                        max-width: torem(17px);
                    }
                }
            }
            .cart-header {
                font-size: torem(14px);
                margin-right: torem(24px);
                &-counter {
                    $size: torem(14px);
                    font-size: torem(10px);
                    width: $size;
                    height: $size;
                    top: torem(3px);
                    right: torem(6px);
                }
                &-btn {
                    &.active:before, &:before {
                        color: $white-f5f;
                    }
                }
            }
        }
    }

    .disableCartButton,
    .disableCartButton:before {
      color: #a1a9ae !important;
      cursor: default !important;
    }

    .disableCartCounter {
      background-color: grey !important;
      cursor: default;
    }
</style>
