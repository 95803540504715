import Backbone from 'backbone';
import UrlModel from '../../../models/distribution-project/track/url';

export default Backbone.Collection.extend({
    model: UrlModel,
    hasLandrMastering: function () {
        return this.some(function (urlModel) {
            return urlModel.isLandrMastering();
        });
    },
    getLandrMastering: function () {
        return this.find(function (urlModel) {
            return urlModel.isLandrMastering();
        });
    }
});
