<template>
  <div class="royaltyBoard">
    <div class="royaltyBoard-head">
      <div class="royaltyBoard-top">
        <p class="royaltyBoard-h1">
          Rollover amount:
        </p>
      </div>
      <div class="royaltyBoard-price">
        <p> {{ wallet.getFormattedAmount((+wallet.getRolloverAmount()).toFixed(2)) }}</p>
      </div>
      <div class="royaltyBoard-btn">
        <button ref="climeButton" :disabled="parseInt(wallet.getRolloverAmount()) <= 0 || !(wallet && wallet.attributes.canMakeRequest) && !wallet.belowThreshold()" class="btn"
                @click="climeMoney">
          Claim my money!
        </button>
      </div>
      <p v-if="wallet && wallet.getLastPaidRoyalty()"
         class="royaltyBoard-simply royaltyBoard-simply--date">
        Last Claimed on
        <span>
                {{ wallet.getLastRequestDateFormatted() }}
            </span>
      </p>
    </div>
    <div v-show="reasonIfUserCannotMakeNewRequest" class="payoff-unclaimable-info-box">
      {{ reasonIfUserCannotMakeNewRequest }}
    </div>
    <hr class="royaltyBoard-hr">
    <div class="royaltyBoard-cont">
      <p class="royaltyBoard-h2">
        Royalty Overview
      </p>
      <p class="royaltyBoard-simply">
        Last month's royalty:
        <span> ${{ wallet && wallet.attributes.lastMonthRoyalty || '0.00' }} </span>
      </p>
      <template v-if="wallet.getLastPaidRoyalty()">
        <p class="royaltyBoard-simply">
          Last paid royalty:
          <span> {{ wallet.getFormattedAmount(wallet.getLastPaidRoyaltyAmount()) }} </span>
        </p>
        <p class="royaltyBoard-simply">
          Last paid on:
          <span> {{ wallet.getLastPaidRoyaltyDateFormatted() }} </span>
        </p>
      </template>
    </div>
    <div class="fund" style="margin-top: 2rem">
      <a class="button" href="https://www.octiive.com/music-funding" target="_blank">Get Funded Up
        to $3M</a>

      <a class="more-info" href="https://www.octiive.com/music-funding" target="_blank">
        Click here and receive an advance offer on how much you can get.
      </a>
    </div>
  </div>
</template>

<script>

import MoneyRequest from '@/components/models/user/money-request'

export default {
  name: "royalty-board",
  props: ['wallet', 'reasonIfUserCannotMakeNewRequest'],
  data() {
    return {
      moneyRequest: null,
    }
  },
  mounted() {
    let self = this;

    /*this.moneyRequest = new MoneyRequest();*/
  },
  methods: {
    climeMoney() {
      if (this.wallet.belowThreshold()) {
        this.$root.popup.showMessage(this.wallet.getBelowThresholdMessage())
        return;
      }

      this.$refs.climeButton.disabled = true;
      let moneyRequest = new MoneyRequest({currency: this.wallet.getCurrency()}), self = this;
      moneyRequest.save({}, {
        success(model, response) {
          /* do i must refresh balance Info ?*/
          self.$root.popup.showMessage('Ready to get paid? Check your email and confirm your payment preference to release your funds!');
        }, error(model, error) {
          self.$refs.climeButton.disabled = false;
          self.$root.popup.showMessage(error);
        }
      })
    },
  }
}
</script>

<style lang="scss">
.royaltyBoard {
  @include obj;
  border-radius: torem(4px);
  box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
  padding: torem(46px) torem(31px);
  margin-bottom: torem(5px);

  &-top {
    text-align: center;

  }

  &-h1, &-h2 {
    font-weight: $fw-bold;
    font-size: torem(26px);
    line-height: torem(38px);
  }

  &-h2 {
    font-size: torem(24px);
  }

  &-price {
    font-weight: $fw-bold;
    font-size: torem(70px);
    height: torem(188px);
    display: flex;
    color: $blue-18;
    align-items: center;
    justify-content: center;
  }

  .btn {
    margin: auto;
    margin-bottom: torem(38px);
    padding-left: torem(15px);
    padding-right: torem(15px);
  }

  &-simply {
    font-weight: $fw-600;
    font-size: torem(16px);
    line-height: torem(24px);
    color: $color-62;
    margin: torem(6px) 0;

    span {
      color: $blue-18;

    }

    &--date {
      font-size: torem(18px);
      text-align: center;
      margin: 0;
    }
  }

  &-hr {
    color: $blue-18;
    margin-top: torem(55px);
    margin-bottom: torem(30px);
  }

  .payoff-unclaimable-info-box {
    color: #00428a;
    font-weight: 600;
    padding-top: 20px;
  }
}

@include media-breakpoint-down(sm) {
  .royaltyBoard {
    display: flex;
    padding: torem(24px) torem(35px);
    border: torem(1px) solid $blue-18;
    border-radius: torem(4px);

    &-head {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 50%;
    }

    &-h1, &-h2, &-price {
      font-size: 20px;
      line-height: 1;
      margin: 0;
    }

    &-h1 {
      color: $blue-18;
    }

    &-price {
      padding: 0;
      flex: 1 1 23%;
      justify-content: flex-end;
      line-height: torem(20px);
      height: torem(20px);
      max-width: 23%;
      overflow: hidden;
      text-overflow: inherit;

      p {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-top {
      line-height: 1;
      flex: 1 1 77%;
      text-align: left;
      max-width: 77%;
      justify-content: center;
      align-items: center;
    }

    &-hr {
      width: torem(1px);
      border: 0;
      background: $blue-18;
      height: auto;
      margin: 0 torem(45px);
    }

    &-simply {
      font-size: torem(14px);
      line-height: 1;
      margin: torem(9px) 0;

      &--date {
        text-align: center;
        align-items: center;
        justify-content: center;
        flex: 1 1 100%;
      }
    ;
    }

    &-btn {
      flex: 1 1 100%;
      margin: torem(10px) 0 0 0;
    }

    .btn {
      margin: 0;
      margin-bottom: 0;
      flex: 1 1 100%;
      width: 100%;
      font-size: torem(18px);
      height: torem(44px);
    }
  }
}

@include media-breakpoint-down(xs) {
  .royaltyBoard {
    padding: 0;
    flex-wrap: wrap;
    max-width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    margin-bottom: torem(30px);

    &-hr {
      display: none;
    }

    .btn {
      display: inline-block;
      width: auto;
      min-width: torem(1px);
    }

    &-simply {
      span {
        display: initial;
      }

      &--date {
        flex: 1 1 auto;
        display: inline-block;
        text-align: left;
        font-size: torem(12px);
        line-height: torem(18px);
        margin: torem(17px) auto torem(17px) torem(14px);

        span {
          display: block;
        }
      }
    }

    &-btn {
      flex: 0 1 auto;
      width: auto;
      min-width: torem(1px);
      margin: torem(13px) 0;
    }

    &-top {
      flex: 0 0 auto;
      max-width: initial;
      display: inline-block;
    }

    &-price {
      flex: 1 1 auto;
      margin-right: auto;
      max-width: 100%;
      width: auto;
      display: inline-block;
    }

    &-cont {
      flex: 1 1 100%;
    }


  }
}
</style>
