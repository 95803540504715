import Backbone from 'backbone';
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
    url: "/api/subscription/music-distribution",
    defaults: {
        title: "",
        isCurrent: false,
        isPaid: false,
        isWaiting: false,
        isBoundary: false,
        isUpdatingStatus: false,
        status: false,
        maxArtistCount: 0,
        isRenewalAllowed: false,
        isCancelWithoutFineAllowed: true,
    },
    isNull: function () {
        return this.getTitle() === '';
    },
    getTitle: function () {
        return this.get('title');
    },
    isCurrent: function () {
        return this.get('isCurrent');
    },
    isActual: function () {
        return !this.isNull()
            && this.isCurrent();
    },
    hasProjectsOnDistribution: function () {
        return this.get('hasProjectsOnDistribution');
    },
    isCancelWithoutFineAllowed: function () {
        return this.get('isCancelWithoutFineAllowed');
    },
    hasToRenew: function () {
        return !this.isNull()
            && !this.isWaiting()
            && !this.isPaid()
            && this.isCurrent()
            && this.hasProjectsOnDistribution()
            ;
    },
    isPaid: function () {
        return this.get('isPaid');
    },
    isWaiting: function () {
        return this.get('isWaiting') && this.get('status') == 'active';
    },
    isBoundary: function () {
        return this.get('isBoundary');
    },
    getStatus: function () {
        return this.get('status');
    },
    fetchPayment(callback) {
        if (this.get('isUpdatingStatus')) {
            return;
        }
        this.set('isUpdatingStatus', true);
        var success = callback.success || null,
            fail = callback.fail || null,
            self = this;

        if (!this.isWaiting()) {
            this.set('isUpdatingStatus', false);

            return
        }

        function selfFetch() {
            return self.fetch().done(e => {
                self.set('isUpdatingStatus', false);
                self.fetchPayment(callback);
                if (success) {
                    success();
                }
            }).fail(error => {
                self.set('isUpdatingStatus', false);
                if (fail) {
                    fail(error);
                }
            })
        }

        setTimeout(selfFetch, 5000);
    }
});
