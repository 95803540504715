<template>
    
    <div class="byLabel">
        <router-link to="/home" class="byLabel-close ficon icon-subtract"></router-link>
        <div class="byLabel-bg"></div>
        <div class="byLabel-text">
            <h1 class="byLabel-h1">
                Register a Label.&nbsp;<span>${{labelProduct.getPrice()}}</span>
            </h1>
            <note class="byLabel-note">
                <p>Imagine a 40 story building downtown. You're in the penthouse office, taking calls with heads of
                   state to coordinate a world tour for
                   your biggest radio-topping megastar. All music industry moguls start somewhere. Be bold. Register
                   your new record label right now!
                </p>
            </note>
            <img src="../../../assets/img/labelMobile.png" v-if="isMobile " alt="" class="byLabel-img">
            <p class="byLabel-bold">Are you the next record mogul looking to brand your own label? Have Octiive register
                <br> your label with iTunes, Amazon Music, Spotify and 600+ retailers in over 140 countries!
            </p>
            <ul class="byLabel-list">
                <li>
                    <p>Your projects or artists you sign released under your own label</p>
                </li>
                <li>
                    <p> All projects are chart eligible</p>
                </li>
                <li>
                    <p> All sales are reported to SoundScan</p>
                </li>
            </ul>
            <button ref="byBtn" @click="byLabel" class="btn byLabel-button">
                Add to cart
            </button>
        </div>
    </div>

</template>

<script>
    import note from '../../marketing/components/note'
    import {isMobile} from '../../../components/mixins/isMobile';
    import {mapGetters} from 'vuex';

    export default {
        name: "emptyLabel",
        props:['labelProduct'],
        mixins:[isMobile],
        methods: {
            
            async initCart() {
                let self = this;
                return this.$store.dispatch('cart/loadCart').then(cart => {
                    this.cart = cart;
                });
            },
            async byLabel() {
                this.$refs.byBtn.disabled = true;
                await this.initCart();
                let isIncart = this.cart.getItemBy({id: 5}),
                    self = this;
                if (!isIncart) {
                   
                    await this.cart.addItemToCart(this.labelProduct, null,
                        function (error) {
                            self.$root.popup.showMessage(error);
                        })
                }
                this.$router.push('/cart');
            }
        },
        components: {
            note
        },
        computed: {
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
            })
        },
    }
</script>

<style lang="scss">
    .byLabel {
        @include obj;
        @include coner;
        display: flex;
        padding: 0;
        overflow: hidden;
        position: relative;
        &-close {
            font-size: torem(24px);
            color: $mainfont;
            position: absolute;
            top: torem(30px);
            right: torem(30px);
            text-decoration: none !important;
            &:hover {
                color: $mainfont;
            }
        }
        &-bg {
            flex: 0 0 torem(574px);
            width: torem(574px);
            background-image: url(../../../assets/img/regLabel.png);
            background-size: cover;
        }
        &-text {
            flex: 1 1 auto;
            padding: torem(60px) torem(43px);
            padding-right: torem(32px);
        }
        &-h1 {
            font-weight: 800;
            font-size: torem(32px);
            line-height: torem(39px);
            display: flex;
            align-items: center;
            color: $mainfont;
            margin-bottom: torem(27px);
            span {
                color: $blue-18;
            }
        }
        &-note {
            margin-bottom: torem(32px);
            padding-right: torem(20px);
        }
        &-p {
            margin-bottom: torem(12px);
        }
        &-list {
            display: block;
            list-style: none;
            padding: 0;
            margin: 0;
            margin-top: torem(12px);
            &:first-child p {
                margin-top: 0;
            }
            &:last-child p {
                margin-bottom: 0;
            }
            p {
                font-weight: $fw-500;
                font-size: torem(18px);
                color: $color-62;
                vert-align: middle;
                margin: torem(6px) 0;
                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    width: torem(27px);
                    height: torem(5px);
                    background-color: $blue-18;
                    border-radius: torem(4px);
                    margin-right: torem(10px);
                }
            }
        }
        &-button {
            width: torem(606px);
            height: torem(58px);
            font-size: torem(24px);
            margin-top: torem(72px);
        }
        &-bold {
            font-weight: $fw-600;
            font-size: torem(18px);
            line-height: torem(26px);
            color: $mainfont
        }
        &-img{
            display: none;
        }
    }
    @include media-breakpoint-down(sm) {
        .byLabel{
            &-bg{
                display: none;
            }
            &-text{
                padding: torem(28px) torem(22px);
            }
            &-h1{
                font-size: torem(20px);
                line-height: torem(24px);
            }
            &-note{
                padding: torem(14px);
                margin-bottom: 0;
            }
            &-bold{
                br{
                    display: none;
                }
            }
            &-img{
                display: block;
                width: 100%;
                margin: torem(22px) 0;
            }
            &-bold{
                font-size: torem(14px);
                line-height: torem(21px);
                width: 80%;
            }
            &-list{
                margin-top: torem(12px);
                p{
                    font-size: torem(14px);
                }
            }
            &-close{
                top: torem(21px);
                right: torem(10px);
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .byLabel{
            background:transparent;
            border: none;
            &-text,&-img{
                max-width: 100%;
            }
            &-text{
                padding: 0;
            }
            &-h1{
                line-height: torem(40px);
            }
            &-close{
                margin: 0;
                top: 0;
                right: 0;
                &:before{
                    margin: 0;
                }
            }
            &-button{
                max-width: 100%;
            }
        }
    }
</style>