<template>
    <div class="reportTable" :class="{'reportTable--noready':(!models || models && !models.length)}">
        <multiselect
            :class="['reportTable-multiselect']"
            v-model="year"
            :options="years"
            @input="onYearChange()"
            :searchable="false"
            :show-labels="false"
            :disabled="selectDisabled"
            placeholder="Choose..."></multiselect>
        <reportTable>
            <div slot="head" class="report-row report-head">
                <div class="report-col"><p> Month </p></div>
                <div class="report-col"><p> Royalty </p></div>
                <div class="report-col"><p> Title </p></div>
                <div class="report-col report-sm-small"><p><span class="d-none d-md-inline">Sales</span>Reports</p></div>
            </div>
            
            <div slot="cont" v-if="models.length > 0" v-for="item in models" class="report-row">
                <div class="report-col"
                     :class="{ 'report-empty': item.emptyMonth() }">
                    <p> 
                        {{ item.getMonthByViewPort(Boolean(isMobile)) || 'empty' }}
                    </p>
                </div>
                <div class="report-col" 
                    :class="{ 'report-empty': item.emptyAmount() }">
                    <!-- Net -->
                    <p>${{ item.getAmount() || '0.00' }}</p>
                </div>
              
                <div class="report-col report-sm-small"
                     :class="{'report-empty': item.emptyTitle() }">
                    <p>{{ item.getTitle() }}</p>
                </div>
                <div class="report-col">
                    <a :href="item.getReportUrl()" class="reportTable-link ficon icon-download" download>Download</a>
                </div>
            </div>
        </reportTable>
        <random-loader v-if="models == null" slot="cont" class="loader--poss" :substrate="false"
                       text="Your royalties will be shown here" />
        <emptyState v-if="(models != null && models.length == 0)"
                    h1text="Your Royalty Report list is empty."
                    text="Please choose another year. "
        />
    </div>

</template>

<script>
    import reportTable from './report-table'
    import emptyState from '../../../components/emptyState'
    import RoyaltyReport from '@/components/collections/user/royalty-reports'
    import multiselect from 'vue-multiselect';
    import randomLoader from '@/components/random-loader'

    import {mapGetters} from 'vuex';
    import {isMobile} from '../../../components/mixins/isMobile'
    export default {
        name: "royalty-reports",
        mixins:[isMobile],
        data() {
            return {
                royaltyReport: false,
                models: null,
                year: false,
                selectDisabled: true,
                years: []
            }
        },
        async mounted() {
            this.year = new Date().getFullYear();
            var yearCount = this.year;
            while (yearCount !== 2012) {
                this.years.push(yearCount--);
            }
            this.royaltyReport = new RoyaltyReport();
            await this.royaltyReport.loadReportsByYear('' + this.year);
            this.models = this.royaltyReport.models;
            this.selectDisabled = false;
        },
        methods: {
            async onYearChange() {
                this.selectDisabled = true;
                this.models = null;
                await this.royaltyReport.loadReportsByYear('' + this.year);
                this.models = this.royaltyReport.models;
                this.selectDisabled = false;

            }
        },
        computed:{
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
            })
        },
        components: {
            reportTable,
            emptyState,
            multiselect,
            randomLoader,
        }
    }
</script>

<style lang="scss">
    .reportTable {
        position: relative;
        flex: 1 1 auto;
        &--noready {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
        }
        .empty {
            position: static;
            height: auto;
            margin: auto;
            
        }
        &-link {
            color: $blue-18;
            font-weight: $fw-bold;
            font-size: torem(18px);
            line-height: torem(22px);
        }
        &-multiselect {
            position: absolute;
            right: 0;
            bottom: 100%;
            width: torem(170px);
            margin-bottom: torem(10px);
            .multiselect__single:before {
                content: '\e80a';
                color: $blue-18;
                font-family: 'fontello';
                font-size: torem(25px);
                width: auto;
                height: auto;
                margin-right: torem(12px);
            }
        }
        .multiselect {
            &__select {
                width: auto;
                
            }
            &--active &__select {
                transform: rotate(0deg) !important;
            }
            &--disabled *, &--disabled *:before {
                color: $color-ac !important;
                border-color: $color-ac;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .reportTable{
            &-link{
                font-size: 0;
                width: torem(31px);
                height: torem(31px);
                background: $mainfont;
                border-radius: torem(4px);
                color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                &:before{
                    font-size: torem(21px);
                }
                &:hover{
                    background: $white;
                    border: torem(1px) solid $mainfont;
                    &:before{
                        color: $mainfont;
                    }
                }
            }
            &-multiselect{
                position: relative;
                bottom: 0;
                margin-left: auto;
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .reportTable{
            width: 100%;
            &--noready{
                line-height: 1;
                height: auto;
                width: auto;
                flex: 0 0 auto;
                min-width: auto;
                width: 100%;
                .loader--poss {
                height: auto;
                margin: torem(20px) 0;
            }
            }
            &-link{
                font-size: 0;
                width: torem(31px);
                height: torem(31px);
                background: $mainfont;
                border-radius: torem(4px);
                color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                &:before{
                    font-size: torem(21px);
                }
                &:hover{
                    background: $white;
                    border: torem(1px) solid $mainfont;
                    &:before{
                        color: $mainfont;
                    }
                }
            }
            &-multiselect{
                position: relative;
                bottom: 0;
                margin-left: auto;
                &.multiselect {
                    flex: 1 1 100%;
                    width: 100%;
                    &__single:before{
                        font-size: torem(21px);
                        line-height: torem(1px);
                    }
                }
            }
            
    
            &-link{
                width: torem(22px);
                height: torem(22px);
                margin-right: 0;
                &:before {
                    font-size: torem(13px);
                }
            }
        }
    }

</style>