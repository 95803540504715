import Backbone from 'backbone';
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
    urlRoot: function () {
        return '/api/ringtone';
    },
    defaults: {
        title: '',
        file: null,
        startPosition: 0,
        endPosition: 30
    },
    initialize: function () {
    },
    getTitle: function () {
        return this.get('title');
    },
    getStartPosition: function () {
        return this.get('startPosition');
    },
    getEndPosition: function () {
        return this.get('endPosition');
    },
    getFile: function () {
        return this.get('file');
    },

    /**
     * Save ringtone data
     *
     * If it is new model send POST with all data
     * else send PATCH with "startPosition" and "endPosition"
     */
    saveRingtone: function () {
        return this.save(
            {
                startPosition: this.get('startPosition'),
                endPosition: this.get('endPosition')
            },
            {
                success: function (ringtone, response) {
                    console.log('Ringtone save success', response);

                    ringtone.trigger('loaded', ringtone);
                },
                error: function (ringtone, response) {
                    console.log('Ringtone save error', response);
                },
                patch: !this.isNew()
            }
        );
    },

    removeRingtone: function () {
        return this.destroy({
            success: function (ringtone, response) {
                console.log('Ringtone remove success', response);
            },
            error: function (ringtone, response) {
                console.log('Ringtone remove error', response);
            }
        });
    }
});
