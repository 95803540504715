export default function (cartItem) {
  this.getPrice = function () {
    return cartItem.getSalePrice();
  };
  this.getDiscountAmount = function () {
    return cartItem.getSaleDiscountAmount();
  };
  this.getTitle = function () {
    return cartItem.getProduct().getTitle();
  };
};

