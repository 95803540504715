<template>
  <div class="note">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "note"
  }
</script>

<style lang="scss">
  .note {
    position: relative;
    background: $white-f5f;
    border-radius: torem(4px);
    display: inline-block;
    padding: torem(16px) torem(47px) torem(16px) torem(24px);
    margin-bottom: torem(74px);
    width: auto;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 100%;
      background-color: $blue-18;
      border-radius: torem(4px);
      width: torem(5px);
    }
    p{
      font-size: torem(14px);
      color: $mainfont;
      display: inline-block;
      margin: auto;
      
    }
  }
  @include media-breakpoint-down(sm) {
    .note{
      padding: torem(14px);
      &:before{
        width: torem(3px);
      }
      p{
        font-size: torem(12px);
        line-height: torem(20px);
      }
      
    }
  }
  @include media-breakpoint-down(xs) {
    .note{
      background: $blue-18-opacity;
      &:before{
        display: none;
      }
      p{
        font-size: torem(14px);
        line-height: torem(23px);
      }
    }
  }
</style>