//move candidate
/*  var config = {
      PASSWORD_MIN_LENGTH: 6,
      PASSWORD_MAX_LENGTH: 32
  };*/

import Backbone from 'backbone';
import LabelModel from './label';
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
    urlRoot: "/api/user",

    relations: [
        {
            type: Backbone.HasOne,
            key: 'label',
            relatedModel: LabelModel
        }
    ],

    defaults: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        biography: '',
        socialLinkTwitter: '',
        socialLinkGooglePlus: '',
        socialLinkFacebook: '',
        password: '',
        newPassword: '',
        confirmPassword: '',
        label: null,
        notifications: [],
    },

    required: [
        'firstName', 'lastName'
    ],

    getId: function () {
        return this.get("id");
    },

    getFirstName: function () {
        return this.get('firstName');
    },

    getLastName: function () {
        return this.get('lastName');
    },

    getEmail: function () {
        return this.get('email');
    },

    getPhone: function () {
        return this.get('phone');
    },

    getBiography: function () {
        return this.get('biography');
    },

    getTwitterLink: function () {
        return this.get('socialLinkTwitter');
    },

    getGooglePlusLink: function () {
        return this.get('socialLinkGooglePlus');
    },

    getFacebookLink: function () {
        return this.get('socialLinkFacebook');
    },

    getPassword: function () {
        return this.get('password');
    },

    getNewPassword: function () {
        return this.get('newPassword');
    },

    getConfirmPassword: function () {
        return this.get('confirmPassword');
    },

    getLabel: function () {
        return this.get('label');
    },

    saveInfo: function (options,data) {
        options.url = this.url() + '/info';
        options.patch = true;

        return this.save({
            firstName:data.firstName || this.getFirstName(),
            lastName:data.lastName || this.getLastName(),
            phone:data.phone || this.getPhone(),
            biography:data.biography || this.getBiography()
        }, options);
    },

    saveSocialLinks: function (options) {
        options.url = this.url() + '/social-links';
        options.patch = true;

        return this.save({
            socialLinkTwitter: this.getTwitterLink(),
            socialLinkGooglePlus: this.getGooglePlusLink(),
            socialLinkFacebook: this.getFacebookLink()
        }, options);
    },

    changePassword: function (options) {
        options.url = this.url() + '/password';
        options.patch = true;

        return this.save({
            password: this.getPassword(),
            newPassword: this.getNewPassword(),
            confirmPassword: this.getConfirmPassword()
        }, options);
    },

    initialSignUpPasted: function () {
        return this.save({
            initialSignUpPast: true
        }, {
            url: this.url() + "/initial-signup-past",
            patch: true,     // send only attr initialSignUpPast
            validate: false // disable validation to allow model saving&syncing even if user validation would fail (fixing [MT-309])
        });
    },

    async loadCurrent() {
        return this.fetch({
            url: this.urlRoot + '/get-info'
        });
    },

    async loadSoundCloud() {
      return this.fetch({
        url: '/api/soundcloud/get-info'
      });
    },
});

/*
const ERROR_CODE_CONFIRM_PASSWORD = 1;
const ERROR_CODE_PASSWORD = 2;
const ERROR_CODE_EMAIL = 3;

const PASSWORD_MIN_LENGTH = 6;
const PASSWORD_MAX_LENGTH = 32;*/
