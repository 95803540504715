define([], function () {
    return {
        PENDING_PAYMENT: 1,
        PAYMENT_FAILED: 2,
        DISTRIBUTION_IN_PROGRESS: 3,
        DISTRIBUTION_FAILED: 4,
        DISTRIBUTION_COMPLETE: 5,
        WAITING_PAYMENT_CONFIRMATION: 6,
        READY_FOR_DISTRIBUTION: 7,
        INCOMPLETE: 8,
        TAKE_DOWN: 9,
        DISTRIBUTION_BACK: 11
    };
});
