<template>
    <div class="chart">
        <div class="chart-colorMap">
            <p class="color" v-for="colorItem in colorMap">
                <i class="cercle" :style="{backgroundColor:getColorByName(colorItem.isrcName)}"></i>
                {{colorItem.isrcName.replace('_30',' sub-30s')}}
            </p>
        </div>
        <div @mouseleave="hideTooltip()">
             <bars-chart
                 :options="chartOptions"
                 :chart-data="{
                     labels:getLabels(),
                     datasets:getDatasets()
                 }"
             />
            <v-tooltip
                v-if="tooltip && tooltip.opacity"
                v-model="tooltip"
                :mouse="mousePosition"
                :colorMap="colorMap"
            />
        </div>
    </div>
</template>

<script>
    import barsChart from '@/components/bar-chart';
    import vTooltip from './v-tooltip'
    import {color} from '../mixins/color'

    export default {
        name: "v-bars-chart",
        mixins: [color],
        props: ['value', 'date'],
        data() {
            return {
                tooltip: null,
                colorMap: [],
                chartOptions: {
                    type: 'bars',
                    responsive: true,
                    maintainAspectRatio: false,
                    scaleShowValues: true,
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false,
                        mode: 'index',
                        position: 'cursor',
                        intersect: false,
                        custom: this.customTooltips
                    },
                }
            }
        },
        created() {
            let self = this;
            Chart.Tooltip.positioners.cursor = function (chartElements, coordinates) {
                self.mousePosition = coordinates;
                return coordinates;
            };
        },
        mounted() {
            this.getDatasets()
        },
        methods: {
            getDatasets() {
                let self = this;
                return this.value.map(chart => {
                    return {
                        label: chart.name.replace('_30', ' sub-30s'),
                        borderColor: self.getColor(chart.name),
                        backgroundColor: self.getColor(chart.name),
                        data: chart.points
                    }
                });
            },
            getLabels() {
                let res = [],
                    dateTo = new Date(this.dateFormat(this.date.to)),
                    tempDate = new Date(this.dateFormat(this.date.from));
                while (dateTo - tempDate > 0) {
                    tempDate.setDate(tempDate.getDate() + 1);
                    res.push(tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate());
                }
                return res;
            },
            hideTooltip() {
                this.tooltip = null;
            },
            customTooltips(tooltip) {
                this.tooltip = tooltip;
            },
            dateFormat(date) {
                date = date.split('/');
                return date[2] + '-' + date[0] + '-' + date[1];
            },
        },
        components: {
            barsChart,
            vTooltip
        },
    }
</script>

<style>

</style>