<template>
    <div class="mastering">
        <div class="mastering-top">
            <p class="mastering-h1">
                Mastering.
            </p>
            <p class="mastering-h2">
                Your songs will compete with the highest industry level and sound amazing bumping through any speaker
                system!
            </p>
        </div>
        <div class="mastering-cartlist">
            <template v-if="!isMobile">
                <masteringItem
                    v-for="(mastering,index) in masteringList"
                    :key="'mastering'+index"
                    @add="masteringAddToCart($event)"
                    @remove="removeFromCart($event)"
                    :item="mastering"
                    :disabled="cart.attributes.cartItems.models.some(cartItem=>{ return cartItem.productId === mastering.attributes.id})" />
            </template>
            <template v-else>
                <masteringItemMobile
                    v-if="masteringList"
                    :list="masteringList"
                    :cart="cart"
                    @add="masteringAddToCart($event)"
                    @remove="removeFromCart($event)"
                ></masteringItemMobile>
            </template>
        </div>
        <div class="mastering-bottom">
            <button class="mastering-toggle" :class="{'active':exampleToggle}"
                    @click=" toggle()">
                Try out Samples
            </button>
        </div>
        <div class="mastering-examples" ref="example" style="display: none">
            <div class="row">
                <div class="col-12 col-sm-6" v-for="audio in sampleList">
                    <masteringAudio @play="onPlay($event)" @pause="onPause()" :src="audio.src" :title="audio.title" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import masteringItem from './mastering-item'
    import masteringItemMobile from './mastering-item-mobile'
    import masteringAudio from './mastering-audio'
    import {mapGetters} from 'vuex';
    import {isMobile} from '../../../components/mixins/isMobile';

    export default {
        name: "services-mastering",
        mixins:[isMobile],
        props: ['products', 'cart'],
        data() {
            return {
                masteringList: null,
                exampleToggle: false,
                nowPlaying:null,
                sampleList: [
                    {
                        title: 'Electronic (Before)',
                        src: '/public/mondo/music/electronic-sample1a.mp3'
                    },
                    {
                        title: 'Electronic (After)',
                        src: '/public/mondo/music/electronic-sample1b.mp3'
                    },
                    {
                        title: 'Hip Hop (Before)',
                        src: '/public/mondo/music/hh-sample4a.mp3'
                    },
                    {
                        title: 'Hip Hop (After)',
                        src: '/public/mondo/music/hh-sample4b.mp3'
                    },
                    {
                        title: 'Alternative Rock (Before)',
                        src: '/public/mondo/music/rock-sample1a.mp3'
                    },
                    {
                        title: 'Alternative Rock (After)',
                        src: '/public/mondo/music/rock-sample1b.mp3'
                    },
                    {
                        title: 'R&B (Before)',
                        src: '/public/mondo/music/rb-sample2a.mp3'
                    },
                    {
                        title: 'R&B (After)',
                        src: '/public/mondo/music/rb-sample2b.mp3'
                    },
                    {
                        title: 'Indie (Before)',
                        src: '/public/mondo/music/indie-sample3a.mp3'
                    },
                    {
                        title: 'Indie (After)',
                        src: '/public/mondo/music/indie-sample3b.mp3'
                    }]
            }
        },
        mounted() {
            this.masteringList = this.products.where({type: 6});
        },
        methods: {
            onPlay(playItem){
                if(this.nowPlaying && playItem !==this.nowPlaying ){
                    this.nowPlaying.onPause();
                }
                this.nowPlaying=playItem;
            },
            onPause(){
                this.nowPlaying=null;
            },
            masteringAddToCart(item) {
                let self = this;
                item.btn.disabled = true;
                this.cart.addItemToCart(
                    item.product,
                    item.productAttributes,
                    function () {
                        item.btn.disabled = false
                    },
                    function (error) {
                        self.$root.popup.showMessage(error);
                        item.btn.disabled = false
                    }
                )
            },
            removeFromCart(item) {
                item.btn.disabled = true;
                let cartItem = this.cart.getItemBy({id: item.product.attributes.id}), self = this;
                if (cartItem) {
                    this.cart.removeItem(cartItem,
                        async function success() {
                            await self.cart.fetch({
                                error(collection, error) {
                                    self.$root.popup.showMessage(error);
                                }
                            });
                            item.btn.disabled = false;
                        },
                        function error(model, error) {
                            self.$root.popup.showMessage(error);
                            item.btn.disabled = false;
                        }
                    )
                }
            },
            toggle() {
                if (!this.exampleToggle) {
                    $(this.$refs.example).show(300)
                }
                else {
                    $(this.$refs.example).hide(300)
                }
                this.exampleToggle = !this.exampleToggle;
            }
        },
        components: {
            masteringItem,
            masteringAudio,
            masteringItemMobile
        },
        computed: {
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
            })
        }
    }
</script>

<style lang="scss">
    .mastering {
        @include obj;
        @include coner;
        box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
        padding: torem(102px) torem(192px);
        width: 100%;
        .audio {
            max-width: torem(375px);
            margin-left: auto;
            margin-right: auto;
            
        }
        &-cartlist {
            padding-top: torem(100px);
            padding-bottom: torem(56px);
            .masteringItem {
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &-examples {
            width: 100% !important;
            padding-top: torem(28px);
        }
        &-top {
            text-align: center;
            max-width: torem(622px);
            margin-left: auto;
            margin-right: auto;
        }
        &-h1 {
            text-align: center;
            
            font-weight: $fw-extrabold;
            font-size: torem(32px);
            line-height: torem(39px);
            color: $mainfont;
            display: inline-block;
            text-align: center;
            margin: 0;
            margin-bottom: torem(18px);
            span {
                color: $blue-18;
            }
            
        }
        &-h2 {
            
            font-weight: $fw-medium;
            font-size: torem(18px);
            line-height: torem(26px);
            text-align: center;
            color: $color-62;
            margin: 0;
            
        }
        &-bottom {
            text-align: center;
        }
        &-toggle {
            outline: none !important;
            background-color: transparent;
            border: none;
            box-shadow: none;
            display: inline-block;
            text-align: center;
            margin: auto;
            font-weight: $fw-extrabold;
            font-size: torem(23px);
            line-height: torem(28px);
            cursor: pointer;
            position: relative;
            ::selection {
                background-color: transparent;
                color: $mainfont;
            }
            &:after {
                font-family: 'fontello';
                content: '\e816';
                font-size: torem(18px);
                margin-left: torem(12px);
                color: $blue-18;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                transform: rotate(0deg);
                transition: transform .3s;
            }
            &.active {
                &:after {
                    transform: rotate(90deg);
                }
            }
        }
    }
    
    @include media-breakpoint-down(sm) {
        .mastering {
            padding: torem(39px) torem(19px) torem(50px);
            &-h1 {
                font-size: torem(20px);
                line-height: torem(24px);
                margin-bottom: torem(12px);
            }
            &-h2 {
                font-size: torem(14px);
                line-height: torem(21px);
            }
            &-cartlist {
                padding: torem(30px) 0 torem(60px);
            }
            .audio {
                max-width: torem(250px);
            }
        }
    }
</style>