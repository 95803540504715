import axios from 'axios';

export default {
    state: {
        treeUrl: '/api/genres/tree',
       list: false,
       tree: false,
    },
    mutations: {
        set(state, data) {
            state.list = data;
        },
        setGenreTree(state, data) {
            state.tree = data;
        },
    },
    getters: {
        get: function (state) {
            return state.list;
        },
        getLastProject(state) {
            return state.list[0];
        },
        getType(state) {
            return (typeid) => {
                var albumTypes = {
                    1: 'Single',
                    2: 'EP',
                    3: 'Album'
                };
                return albumTypes[typeid];
            }
        },
        getNew(state){
            return state.newProject;
        },
        getGenreTree: function (state) {
            return state.tree;
        },
    },
    actions: {
        async load(store) {
            /*get all projects*/
            if (store.state.list) {
                return true;
            }
            await axios.get(store.state.url).then(function (e) {
                store.commit("set", e.data);
                return true;
            }).catch(error => {
                return {error: error.response};
            });
        },
        async getProjectById(store, id) {
            /*get project by id*/
            return axios.get(store.state.url + '/' + id).then(e => {
                return e;
            }).catch(function (e) {
                console.error({e});
                return e;
            });
        },
        async sendData(store, {item}) {
            var method = item.id ? 'put' : 'post';
            return axios[method](store.state.url + (item.id ? '/' + item.id : ''), item).then(e => {
                store.commit('setProjectId', e.data);
                return e;
            }).catch(function (e) {
                console.error({e});
                return e;
            });
        },
        async removeProject(store, id) {
            return axios.delete(store.state.url + '/' + id).then(e => {
                //store.commit('setProjectId', e.data);
                console.log({e});
                return e;
            }).catch(function (e) {
                console.error({e});
                return e;
            });
        },
        async loadGenreTree(store) {
            if (store.state.tree) {
                return store.state.tree;
            }
            return axios.get(store.state.treeUrl).then(function (e) {
                store.commit("setGenreTree", e.data);
                return e.data;
            }).catch(error => {
                console.log({ error: error });
            });
        },
    },
};