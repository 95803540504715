/*define([
    "backbone",
    'backbone-relational/backbone-relational'
], function (Backbone) {*/
import Backbone from "backbone";
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
    defaults: {
        id: null,
        path: null
    },
    url: "/api/label/logo",
    getLogoUrl: function () {
        return this.get('path');
    }
});
/*
});
*/
