<template>
  <div class="container d-flex flex-column  align-items-stretch">
    <div class="row">
      <div class="col-12 ">
        <h1 class="main-h1  marg-bottom-none">
          Order List
        </h1>
      </div>
      <div class="col-12 ">
        <router-link to="/services" class="ficon icon-arrowright main-link main-link--auto main-link-h1">
          Go to services
        </router-link>
      </div>
    </div>
    <random-loader v-if="orderList == null" class="loader--poss" :substrate="false" text="Your order list will be shown here"/>
    <div class="row" v-else-if="orderList.length > 0">
      <div class="col-12">
        <div class="orderList">
          <orderItem :value="order" v-for="order in orderList" :key="order.id"/>
        </div>
      </div>
    </div>
    <empty-state v-else
                h1text="No Order History"
                text="your order history will be shown here"
                buttonText="Go to shopping"
                linkto="/services"
    />
  </div>
</template>

<script>
  import OrdersList from '@/components/collections/user/orders';
  import emptyState from '../../components/emptyState'
  import randomLoader from '@/components/random-loader'
  
  import orderItem from './components/orderItem'
  export default {
    name: "userOrders-page",
    data(){
      return{
        orderList: null,
      }
      
    },
    async mounted(){
      let self = this;
      let orderList = new OrdersList();
      orderList.fetch({
        success() {
          self.orderList = orderList.models;
        },
        error(collection,error) {
          self.$root.popup.showMessage(error);
        }
      });
    },
    components:{
      emptyState,
      orderItem,
      randomLoader,
    }
  }
</script>

<style lang="scss">
  .order{
    &-h1{
      margin-bottom: torem(12px);
    }
    &-link{
      margin-bottom: torem(32px);
      font-size: torem(20px);
      font-weight: $fw-bold;
    }
  }
</style>