import Backbone from 'backbone';
import ParticipatorModel from '../participator';
import ParticipatorType from '../participator/type';
import ParticipatorsCollection from '../../collections/participators';
import GenreModel from '../genre';
import RingtoneModel from './track/ringtone';
import LandrPurchaseClaimModel from '../landr/purchase-claim';
import TrackUrlModel from './track/url';
import TrackUrlCollection from '../../collections/distribution-project/tracks/urls';

import LanguageModel from './language';
import Moment from 'moment/moment';

import 'moment-duration-format';
import 'backbone-relational/backbone-relational';
import "jquery";

export default Backbone.RelationalModel.extend({

  invalid: false,
  invalidBackend: false,
  justUpload: false,

  relations: [
    {
      type: Backbone.HasMany,
      key: 'participators',
      relatedModel: ParticipatorModel,
      collectionType: ParticipatorsCollection
    },
    {
      type: Backbone.HasMany,
      key: 'urls',
      relatedModel: TrackUrlModel,
      collectionType: TrackUrlCollection
    },
    {
      type: Backbone.HasOne,
      key: 'genre',
      relatedModel: GenreModel
    },
    {
      type: Backbone.HasOne,
      key: 'ringtone',
      relatedModel: RingtoneModel
    },
    {
      type: Backbone.HasOne,
      key: 'landrPurchaseClaim',
      relatedModel: LandrPurchaseClaimModel,
      reverseRelation: {
        type: Backbone.HasOne,
        key: 'track',
        includeInJSON: 'id'
      }
    },
    {
      type: Backbone.HasOne,
      key: 'language',
      relatedModel: LanguageModel
    }
  ],
  urlRoot: function () {
    var project = this.getProject();
    if (project) {
      return '/api/projects/' + project.get('id') + '/tracks';
    }
    return '/api/tracks';
  },
  required: [
    'language',
    'title', 'originalFile', 'project',
    'participators', 'copyrightYear', 'copyrightLine',
    'genre', 'isExplicit'
  ],
  defaults: {
    id: null,
    position: 1,
    language: null,
    title: '',
    originalFile: null,
    originalTitle:null,
    participators: null,
    copyrightYear: (new Date()).getFullYear(),
    copyrightLine: '',
    duration: 0,
    genre: null,
    isrc: null,
    mixNote: null,
    isExplicit: null,
    progress: 100,
    slotId: null,
    landrPurchaseClaim: null,
    changed:0,
    isSingle:false,
    isPlay:null,
    validationError: null,
    file:null,
  },
  initialize: function () {
    this.listenTo(this, 'change:progress', this.uploadProgress);
  },
  changedIncrement: function(){
    this.set('changed',this.attributes.changed+1);
  },
  getId: function () {
    return this.get('id');
  },
  getTitle: function () {
    if (this.getProject() && this.getProject().get('tracks').length === 1) {
      return this.getProject().getTitle();
    }
    return this.get('title');
  },
  getTabLabel: function() {
    return this.getTitle() || this.get('originalFile');
  },
  getOriginalFile: function () {
    return this.get('originalFile');
  },
  hasRemixers: function () {
    return this.get('participators').where({
      type: ParticipatorType.REMIXER
    }).length > 0;
  },
  hasMainArtist: function () {
    return this.getMainArtist().length > 0;
  },
  getDuration: function () {
    return this.get('duration');
  },
  getMainArtist: function () {
    let types = [
      ParticipatorType.MAIN_ARTIST,
      ParticipatorType.PAY_PER_PROJECT_ARTIST, //todo:remove
      ParticipatorType.LEGACY_MAIN_ARTIST], mainArtists = [], self = this;
    types.forEach(type => {
      let tempMass = this.get('participators').where({type: type});
      mainArtists = mainArtists.concat(tempMass);
    });
    return mainArtists;

  },
  getPaidMainArtists: function() {
    let types = [
      ParticipatorType.MAIN_ARTIST,
      ParticipatorType.PAY_PER_PROJECT_ARTIST,
    ], mainArtists = [];
    types.forEach(type => {
      let tempMass = this.get('participators').where({ type: type });
      mainArtists = mainArtists.concat(tempMass);
    });
    return mainArtists;
  },
  getMainArtistName() {
    let artists = this.getMainArtist();
    return artists.length > 0
      ? artists.map((a) => a.get('name')).join(', ')
      : '';
  },
  getGenre: function () {
    return this.has('genre') ? this.get('genre').get('title') : null;
  },
  getGenreId: function () {
    return this.has('genre') ? this.get('genre').get('id') : null;
  },
  getLanguage: function () {
    return this.has('language') ? this.get('language') : null;
  },
  getLanguageId: function () {
    return this.getLanguage() ? this.getLanguage().getLanguageId() : null;
  },
  hasRingtone: function () {
    return this.has('ringtone');
  },
  getRingtone: function () {
    return this.get('ringtone');
  },
  getEditPageUrl: function () {
    return "/dashboard/edit-project/" + this.getProject().get('id') + "/tracks#track-" + this.get('id');
  },
  getFormattedDuration: function () {
    var seconds = this.getDuration();
    return Moment.duration(seconds, 'seconds').format('hh:mm:ss', {trim: false});
  },
  getSlotId: function () {
    return this.get("slotId");
  },
  getProject: function () {
    return this.get('project');
  },
  uploadProgress: function () {
    if (this.get('progress') > 100) {
      this.set('progress', 100);
    }
  },
  setChanged: function (state){
    this.set('changed',state);
  },
  setJustUploadState: function (state) {
    this.justUpload = state;
  },
  isJustUploaded: function () {
    return this.justUpload;
  },
  setInvalidBackend: function (state) {
    this.invalidBackend = state;
  },
  isInvalidBackend: function () {
    return this.invalidBackend;
  },
  setInvalid: function (state) {
    this.invalid = state;
  },
  isInvalid: function () {
    return this.invalid;
  },
  getUrls: function () {
    return this.get('urls');
  },
  hasFile: function () {
    return (this.has("file") && this.get("file").length > 0)
  },
  validate: function (attributes) {
    var errors = {};
    var field;
    var methodName;
    var error;
    var i;

    attributes = attributes || this.attributes;

   /* if (this.isNew()) {
      return;
    }*/

    for (field in attributes) {
      // check required
      if ((Backbone.$.inArray(field, this.required) !== -1) && !attributes[field] && field !== 'isExplicit') {
        errors[field] = 'Field is required';

      } else if (field === 'isExplicit' && attributes[field] === null) {
        errors[field] = 'Field is required';

      } else if (attributes[field]) {

        // call method 'validate{FieldName}'
        methodName = 'validate' + field.charAt(0).toUpperCase() + field.slice(1);

        if (Backbone.$.isFunction(this[methodName])) {
          error = this[methodName](attributes[field], attributes);

          if (typeof error === 'undefined' || error === true) {
            continue;
          }

          // check if it was error
          if (typeof error === 'object') {
            for (i in error) {
              errors[i] = error[i];
            }
          } else {
            errors[field] = error;
          }
        }
      }
    }


    //console.info(errors);
    // return errors if it was any
    if (Object.keys(errors).length > 0) {
      this.setInvalid(true);

      return errors;
    }

    this.setInvalid(false);
  },
  setStop:function(){
    this.set('isPlay',false)
  },
  setPlay:function(){
      this.set('isPlay',true)
  },
  isPlay:function(){
    this.get('isPlay')
  },
  /**
   * Must be standard upper and lower case.
   * The following words must be lower case
   * UNLESS they are the first or last word of the title:
   *     a, an, and, for, from of, or, to, the, in, as,
   *     but, nor, so, yet, at, by, into, off, onto, out, over, up and with.
   * Must NOT contain the word "EP" or "Album".
   * If the project is a one-song Single AND there is a featured artist,
   * add to the title the following: "(feat. Artist Name)"
   * - Note that it must be written by adding a space after the title,
   * followed by left parenthesis "(" then "feat."
   * (must be spelled exactly "feat." followed by a space,
   * the featured artist name, followed by right parenthesis ")."
   * Separate more than one featured artist with a comma.
   * For singles, add " - Single" after the title.
   * Note featured artists names
   * (within the parenthesis CAN be upper case,
   * just make sure that "feat." is always always "feat."
   * and not "Feat." or "Feat" or "Featured" or "featured."
   *
   * Do not allow artist to enter the words "featuring" or "feat." or "FEAT" in this field,
   * as we will insert it per above.
   *
   * For one-song singles,
   * Title in this field must match title song title in row AE exactly
   * (with the exception that the featured artist
   * would only be displayed in cell C-9 at the album level.
   *
   * @param {string} title
   *
   * @return {string|undefined}
   */
  validateTitle: function (title) {
    var notAllowedWords = [
      'ep', 'album', 'featuring', 'featured', 'feat'
    ];

    var notAllowedWordsRegexp = new RegExp('(^|[^a-zа-я])(' + notAllowedWords.join('|') + ')([^a-zа-я]|$)', 'i');

    var linkRegExp = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(linkRegExp.test(title)){
      return 'Don\'t use web links for title'
    }

    if (notAllowedWordsRegexp.test(title)) {
      return 'Words "' + notAllowedWords.join('", "') + '" are not allowed in a title';
    }
  },
  validateMixNote: function (mixNote) {
    var notAllowedWords = [
        'mix', 'clean', 'explicit'
      ],
      regexp = new RegExp('(^|[^a-zа-я])(' + notAllowedWords.join('|') + ')([^a-zа-я]|$)', 'i');

    if (regexp.test(mixNote)) {
      return 'Words "' + notAllowedWords.join('", "') + '" are not allowed in a mix note';
    }
  },
  validateIsrc: function (isrc) {
    if (isrc.length != 12) {
      return 'Field length should be 12';
    }
  },

  validateIsExplicit: function (isExplicit) {
    if (isExplicit == null) {
      return 'Please choose one option. This field is required.';
    }
  },

  validateCopyrightLine: function (copyrightLine) {
    var beginYearRegexp = /^\d{4}/;

    if (beginYearRegexp.test(Backbone.$.trim(copyrightLine))) {
      return 'Field should not begin with four digits.';
    }

    if (copyrightLine.length > 55) {
      return 'Field length should be less or equal to 55';
    }
  },

  validateCopyrightYear: function (copyrightYear) {
    var nextYear = new Date().getFullYear() + 1;
    if (copyrightYear < 1900 || copyrightYear > nextYear) {
      return 'Field should be between 1900 and ' + nextYear;
    }
  },
  validateParticipators: function (participators) {
    var errors = {};

    // prevent duplicate validation after server response
    if (!(participators instanceof Backbone.Collection)) {
      return;
    }

    if (!participators.findWhere({type: ParticipatorType.MAIN_ARTIST})
      && !participators.findWhere({type: ParticipatorType.PAY_PER_PROJECT_ARTIST})
    ) {
      errors.MAIN_ARTIST = 'Main Song Artist is required';
    }

    if (!participators.findWhere({type: ParticipatorType.WRITER})) {
      errors.WRITER = 'Writer is required';
    }

    if (Object.keys(errors).length > 0) {
      return errors;
    }
  },
  // @todo: add filename validation
  toJSON: function () {
    var attributes = Backbone.$.extend(true, {}, this.attributes);

    if (attributes.participators instanceof ParticipatorsCollection) {
      attributes.participators = attributes.participators.map(function (participator) {
        return {
          id: participator.get('id')
        };
      });
    }

    if (attributes.project) {
      delete attributes.project;
    }

    return attributes;
  },

  /**
   * @returns {String}
   */
  getLandrPurchaseClaim: function () {
    return this.get("landrPurchaseClaim");
  },

  /**
   * @returns {bool}
   */
  hasLandrPurchaseClaim: function () {
    return this.has("landrPurchaseClaim");
  },

  /**
   * Uses in js templates
   * @returns {bool}
   */
  isLandrPurchaseClaimValid: function () {
    return (this.hasLandrPurchaseClaim() && this.get("landrPurchaseClaim").isValid());
  }
});


/*

language: "Field is required"
originalFile: "Field is required"
WRITER: "Writer is required"
copyrightLine: "Field length should be less or equal to 55"
isExplicit: "Field is required"*/
