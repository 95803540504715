import Backbone from 'backbone';
import ParticipatorType from './participator/type';
import PlatformArtist from './platform/platform-artist'
import PlatformArtistCollection from '../collections/platform/platform-artists'

import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
    relations: [
        {
            type: Backbone.HasMany,
            key: 'platformArtists',
            relatedModel: PlatformArtist,
            collectionType: PlatformArtistCollection
        },
    ],
    defaults: {
        id: null,
        name: '',
        type: ParticipatorType.MAIN_ARTIST
    },
    urlRoot: '/api/user/participators',
    validate: function (attrs) {
        if (!('name' in attrs) || attrs.name.length === 0) {
            return 'Name is required';
        }
    }
});
