import Subscription from '@/components/models/subscription/music-distribution';

export default {
  state: {
    subscriptionId: false,
    loadingPromise: false,
    hasFreeSingle: false,
    isChange: false,
  },
  getters: {
    getSubscriptionId(state) {
      if (!state.subscriptionId) {
        return null;
      }
      return Backbone.Relational.store.find(Subscription, state.subscriptionId);
    },
    getNewSubscription(state){
      return new Subscription();
    },
    freeSingle(state){
      return state.hasFreeSingle;
    },
    getChange(state){
      return state.isChange;
    }
  },
  mutations: {
    setSubscriptionId(state, data) {
      state.subscriptionId = data;
      state.loadingPromise = false;
    },
    setFreeSingle(state, data){
      state.hasFreeSingle = data;
    },
    startLoading(state, promise) {
      state.loadingPromise = promise;
    },

    setChange(state,data){
      state.isChange = data;
    }
  },
  actions: {
    async loadSubscription(store) {
      if (store.state.subscriptionId) {
        return store.getters.getSubscriptionId;
      }
      if (store.state.loadingPromise) {
        return store.state.loadingPromise;
      }
      let subscription = new Subscription();
      let loadingPromise = subscription.fetch().then(function (e) {
        if (e.id) {
          store.commit("setSubscriptionId", e.id);
          return store.getters.getSubscriptionId;
        }
        if (e.hasFreeSingle) {
          store.commit("setFreeSingle", e.hasFreeSingle);
        }
      }).catch(error => {
        return {error: error.response};
      });
      store.commit("startLoading", loadingPromise);

      return loadingPromise;
    }
  }
}

