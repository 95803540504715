
import Backbone from 'backbone';
import GenreCollection from '../collections/genres';
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
    relations: [{
        type: Backbone.HasMany,
        key: 'children',
        relatedModel: this,
        collectionType: GenreCollection,
        reverseRelation: {
            key: 'parent'
        }
    }],
    defaults: {
        id: 0,
        name: '',
        parent: null,
        children: []
    },
    url: '/api/genres',
    toJSON: function () {
        return {
            id: this.get('id')
        };
    },
    isClassicalGenre: function () {
        return this.get('parent').get('id') === CLASSICAL_GENRE_GROUP_ID;
    }
});
