<template>
  <div>
    <div>
      <div v-show="isConnected">
        <h3 class="title">Hey <strong>{{ user.username }}</strong>!</h3>
        <p>
          <span style="color: green">Your SoundCloud account is already connected.</span><br>
          You project becomes available on <a :href="user.permalink_url" class="url" target="_blank">{{ user.permalink_url }}</a>
        </p>
      </div>

      <div v-show="!isConnected">
        <h3>Ready to Get Started?</h3>
        <p>Click the button below to connect your SoundCloud account and distribute your music like never before.</p>
      </div>

      <br>
      <a href="#" @click.prevent="connect()" v-show="!isConnected">
        <img :src="icon" alt="Connect with SoundCloud">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SoundCloud",
  props: [
    'user'
  ],
  mounted() {
    if (this.user && this.user.username) {
      this.isConnected = true;
    }

    this.toggleIcon();
  },
  data() {
    return {
      connectUrl: '/api/soundcloud/authorize',
      popup: null,
      popupSize: {width: 780, height: 515},
      icon: null,
      isConnected: false,
    }
  },

  methods: {
    toggleIcon() {
      this.icon = this.isConnected
        ? require('@/assets/soundcloud/disconnect.png')
        : require('@/assets/soundcloud/connect-with-soundcloud.png');
    },

    connect(e) {
      this.popup = window.open(
        this.connectUrl,
        'Connect with SoundCloud',
        `width=${this.popupSize.width},height=${this.popupSize.height}`
      );

      window.addEventListener('message', event => {
        switch (event.data) {
          case 'Connected':
            this.connected();
            break;
        }
      });
    },

    /**
     * Soundcloud connection authorized and connected
     */
    connected() {
      this.popup.close();
      this.isConnected = true;
      this.toggleIcon();
      this.$emit('handler', 'success');
    },
  }
}
</script>

<style lang="scss">
  .success {
    color: green;
    margin-top: 1rem;
    font-size: 1.3rem;
  }

  h3.title {
    font-size: 1.5rem;
  }

  p {
    color: #4c4c4c;
    font-size: 1.2rem;
  }

  .url {
    text-decoration: underline;
  }

</style>
