<template>
    <div class="container signform">
        
        
        <div class="row full-width">
            <div class="signform-bgimg d-sm-none d-md-block">
                <a class="signform-logo" v-if="octiiveHomePage" :href="octiiveHomePage">
                    <img class="img-fluid" :src="whiteLogo"
                         alt="Octiive Logo" />
                </a>
            </div>
            <div class="signform-pbody">
                <div class="email-confirmation-pbody">
                    <div class="email-confirmation-body">
                        <div class="email-confirmation-cont">
                            <i class="email-confirmation-icon ficon"
                               :class="['icon-'+icon]"
                            />
                            <p class="email-confirmation-h1" v-html="title" />
                            <p class="email-confirmation-p" v-if="user">
                                Hey {{ user.firstName }}
                            </p>
                            <p class="email-confirmation-p" v-if="text" v-html="text" />
                            
                            <a v-if="text" :href="redirectUrl" class="btn email-confirmation-btn">
                                Close
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <popup ref="popup"></popup>
    </div>
</template>

<script>
    import popup from '@/components/popup';
    import axios from 'axios';

    export default {
        name: "email-confirmation-page",

        data() {
            return {
                success: true,
                text: null,
                redirectUrl: '/home',
                user: null,
            };
        },
        async mounted() {
            axios.put('/api/email-confirmation/confirm', {
                type: this.$route.params.type,
                token: this.$route.params.token,
            }).then((response) => {
                let data = response.data;
                this.success = true;
                this.redirectUrl = data.redirectUrl;
                this.user = data.user;
                this.text = data.message || 'You request was confirmed.';
                this.$store.dispatch('relogin', data.user.id);
            }).catch((error, xhr) => {
                this.success = false;
                this.text = this.$refs.popup.parseMessage(error);
            });

        },
        computed: {
            icon() {
                return !this.success ? 'fail' : (this.user ? 'ok' : 'rocker-1');
            },
            title() {
                return !this.success ? 'Failed' : (this.user ? 'Success' : 'Confirming');
            },
        },
        components: {
            popup,
        },
    }
</script>

<style lang="scss">
    .email-confirmation {
        @include obj;
        @include coner;
        box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
        margin-top: torem(46px);
        height: torem(788px);
        display: flex;
        justify-content: center;
        align-items: center;
        &-cont {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        &-icon {
            font-size: torem(71px);
            color: $blue-18;
            align-items: center;
            margin-bottom: torem(32px);
            &:before {
                margin: 0;
                width: auto;
            }
        }
        &-h1 {
            font-weight: $fw-semibold;
            font-size: torem(32px);
            line-height: torem(39px);
            display: flex;
            align-items: center;
            text-align: center;
            color: $blue-18;
            
        }
        &-p {
            margin-top: torem(8px);
            font-weight: $fw-600;
            font-size: torem(18px);
            line-height: torem(22px);
            display: flex;
            align-items: center;
            text-align: center;
            color: $mainfont;
        }
        &-btn {
            margin-top: torem(72px);
            width: torem(217px);
            height: torem(58px);
        }
        
    }

</style>