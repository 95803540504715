<template>
    <div class="promo-holder" v-if="cart" :class="{'nowrap':!!(cart && cart.attributes.promoCode)}">
        <template v-if="!cart.attributes.promoCode && edittable">
            <p class="promo-title">
                Add promo code
            </p>
            <inputBtn
                ref="input"
                class="promo-input"
                :error="error"
                placeholder="Input code"
                @click="onAdd($event)"
                :disabled="disabled"
                textBtn="Add"
                @keyup="error=null"
            />
        </template>
        <template v-else-if="cart.attributes.promoCode">
            <p class="promo-name">
                Discount Code:&nbsp;<strong>{{cart.getPromocode()}}</strong></p>
            <p class="promo-discount">-${{cart.getDiscountAmount()}}
                <button v-if="edittable" ref="removeButton" @click="askToRemove"
                        class="promo-remove cart-remove ficon icon-subtract"></button>
            </p>
        </template>
    </div>
</template>

<script>
    import inputBtn from '@/components/inputBtn'

    export default {
        name: "promo",
        props: {
            edittable: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                cart: false,
                disabled: false,
                promo: null,
                error: null
            }
        },
        mounted() {
            let self = this;
            this.$store.dispatch('cart/loadCart').then(cart => {
                self.cart = cart;
            });
        },
        methods: {
            onAdd(code) {
                let self = this;
                this.disabled = true;
                this.error = null;
                this.cart.addPromo(code).done(function () {
                    self.cart.fetch();
                }).fail(function (xhr) {
                    self.error = xhr.responseJSON.message
                }).always(() => {
                    self.disabled = false;
                });
            },
            askToRemove() {
                let message = 'Do you really want to delete promo: ' + this.cart.getPromocode() + '?'
                    , self = this;
                this.$root.popup.showMessage(message);
                this.$root.popup.onConfirm = function () {
                    self.removePromo();
                    self.$nextTick(() => {
                        self.$root.popup.clear();
                    });
                };
                this.$root.popup.onCancel = function () {
                    self.$nextTick(() => {
                        self.$root.popup.clear();
                    });
                };
                this.$root.popup.setAddText('Yes');
                this.$root.popup.setCancleText('no');
            },
            removePromo() {
                let self = this,
                    btn = this.$refs.removeButton;
                btn.disabled = true;
                this.cart.removePromo().done(function () {
                    self.cart.fetch();
                });
            }
        },
        components: {
            inputBtn
        }
    }
</script>

<style lang="scss">
    .promo {
        &-title {
            margin-bottom: torem(8px);
            font-size: torem(20px);
            line-height: torem(24px);
            display: flex;
            align-items: center;
            /*color: $mainfont;*/
            color: $blue-18;
            font-weight: $fw-bold;
            flex: 1 1 auto;
            width: 100%;
        }
        &-name {
            flex: 1 1 auto;
        }
        &-holder {
            margin-bottom: torem(48px);
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            &.nowrap {
                flex-wrap: nowrap;
            }
        }
        &-input {
            .inputBtn-input {
                max-width: torem(340px);
            }
        }
        &-remove {
            background-color: transparent;
            color: $red-f3;
            font-size: torem(20px);
            padding: 0;
            border: none;
            border-radius: 50%;
            flex: 0 0 torem(20px);
            margin-left: torem(12px);
            margin-right: 0;
            &:disabled {
                opacity: .3;
            }
            &:before {
                margin: 0;
            }
        }
        &-name {
            font-weight: $fw-600;
            font-size: torem(20px);
            line-height: torem(24px);
            display: flex;
            align-items: center;
            color: $mainfont;
            strong {
                color: $blue-18;
            }
        }
        &-discount {
            font-size: torem(22px);
            color: $mainfont;
            font-weight: $fw-semibold;
        }
        
    }
    
    @include media-breakpoint-down(sm) {
        .promo {
            &-input .inputBtn-input {
                padding-top: 0;
                padding-bottom: 0;
            }
            &-title {
                font-size: torem(14px);
            }
            &-holder {
                margin-bottom: torem(30px);
            }
            &-remove {
                font-size: torem(24px);
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        .promo {
            &-discount {
                align-self: baseline;
                margin: auto;
                flex: 1 0 auto;
                display: flex;
                align-items: center;
            }
            &-remove {
                margin-left: auto;
            }
        }
    }
</style>