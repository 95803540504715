import Backbone from 'backbone';
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
    defaults: {
        initialAmount: 0,
        recurringAmount: 0
    },
    getInitialAmount: function () {
        return this.get('initialAmount');
    },
    getInitialAmountFloat: function () {
        return this.getInitialAmount() / 100;
    },
    getRecurringAmount: function () {
        return this.get('recurringAmount');
    },
    getRecurringAmountFloat: function () {
        return this.getRecurringAmount() / 100;
    },
    hasProratedAmount: function () {
        return Boolean(this.getInitialAmount() != this.getRecurringAmount() && this.getInitialAmount() > 0);
    }
});