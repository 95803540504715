<template>
    <div class="col-12">
      <div class="mondoblastShow">
        <router-link v-if="load" to="/marketing/bio" class="mondoblastShow-close ficon icon-subtract"></router-link>
        <div class="row">
          <div class="col-12">
            <h1 class="mondoblastShow-main-h1 main-h1">
              Artist Custom Bio
            </h1>
          </div>
        </div>
        <template v-if="load">
          <div class="row">
            <div class="col-12">
              <div class="mondoblastShow-h1 mondoblastShow-h1--top">
                Main info
              </div>
            </div>
          </div>
          
          <div class="mondoblastShow-row">
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Stage name'
                :text=" model.attributes.stageName"
              />
            </div>
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Record labels'
                :text=" model.attributes.recordLabel"
              />
            </div>
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Contributors'
                :text=" model.attributes.contributors"
              />
            </div>
          </div>
          <hr class="mondoblastShow-hr">
          <div class="row">
            <div class="col-12">
              <div class="mondoblastShow-h1">
                Artistic influences
              </div>
            </div>
          </div>
          <div class="mondoblastShow-row">
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Music categories'
                :text=" model.attributes.musicCategories"
              />
            </div>
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Music description'
                :text=" model.attributes.musicDescription"
              />
            </div>
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Music topics'
                :text=" model.attributes.musicTopics"
              />
            </div>
          </div>
          <div class="mondoblastShow-row">
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Previous releases'
                :text=" model.attributes.previousReleases"
              />
            </div>
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Start playing music, and live now'
                :text=" model.attributes.location"
              />
            </div>
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Played in'
                :text=" model.attributes.playedIn"
              />
            </div>
          </div>
          <div class="mondoblastShow-row">
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Enjoy about listening to music'
                :text=" model.attributes.enjoyAboutListening"
              />
            </div>
            <div class="mondoblastShow-col col-md-4">
              <itemShow
                strongText='Make music reason'
                :text=" model.attributes.reasonToMakeMusic"
              />
            </div>
            
          </div>
          <hr class="mondoblastShow-hr">
          <div class="row">
            <div class="col-12">
              <div class="mondoblastShow-h1">
                Musical highlights or accmplishments
              </div>
            </div>
          </div>
          <div class="mondoblastShow-row">
            <div class="mondoblastShow-col col-sm-12">
              <itemShow
                strongText='Existing bio'
                :text=" model.attributes.existsBio"
              />
            </div>
            
            <div class="mondoblastShow-col col-sm-12">
              <itemShow
                strongText='Message to audience and fans'
                :text=" model.attributes.message"
              />
            </div>
            <div class="mondoblastShow-col col-sm-12">
              <itemShow
                strongText='Official website'
                :text=" model.attributes.website"
              />
            </div>
          </div>
          
        
        </template>
        <random-loader v-else class="loader--poss" :substrate="false" :randomText="true" />
      </div>
    </div>
</template>
<script>
  import Questionnaires from '@/components/models/marketing/bio-questionnaire'
  import itemShow from './item-show'
  import randomLoader from '@/components/random-loader';

  export default {
    name: "questionnaires-show",
    data() {
      return {
        load: false,
        model: null,
        qaReleaseFormat: null,
      }
    },
    async mounted() {
      let self = this;
      this.model = Backbone.Relational.store.find(Questionnaires, this.$route.params.id);
      if (!this.model) {
        this.model = new Questionnaires({id: this.$route.params.id});
        await this.model.fetch({
          error(collection, error) {
            self.$root.popup.showMessage(error);
          }
        });
      }
      if (this.model.attributes.qaReleaseFormat) {
        this.qaReleaseFormat = this.model.albumTypes[this.model.attributes.qaReleaseFormat]
      }
      this.load = true;
    },
    components: {
      itemShow,
      randomLoader
    }

  }
</script>
