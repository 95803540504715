<template>
  <div class="purchase flex-fill">
    <div class="purchase-cont">
      <i class="purchase-icon ficon "
         :class="[{'icon-ok':!icon},icon]"
      ></i>
      <p class="purchase-h1" v-html="h1Text||'submitted'">
      </p>
      <p class="purchase-p" v-if="text" v-html="text">
      </p>
      <purchase-table :order="order" v-if="order"/>
      <router-link :to="linkTo||'/project'" class="btn purchase-btn">
        {{buttonText || 'Back to list'}}
      </router-link>
    
    </div>
  </div>
</template>

<script>
  import purchaseTable from './components/purchase-table'
  export default {
    name: "purchaseView",
    props: ['cart','linkTo','buttonText','h1Text','text','icon','order'],
    mounted() {
      let self = this;
      this.cart.fetch({
        error(collection,error) {
          self.$root.popup.showMessage(error);
        }
      });
    },
    components:{
      purchaseTable
    }
  }
</script>

<style lang="scss">
  .purchase {
    @include obj;
    @include coner;
    box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
    margin-top: torem(46px);
    /*height: torem(788px);*/
    display: flex;
    justify-content: center;
    align-items: center;
    
    &-cont{
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-icon{
      font-size: torem(71px);
      color: $blue-18;
      align-items: center;
      margin-bottom: torem(32px);
      &:before{
        margin: 0;
        width: auto;
      }
    }
    &-h1{
      font-weight: $fw-semibold;
      font-size: torem(32px);
      line-height: torem(39px);
      display: flex;
      align-items: center;
      text-align: center;
      color: $blue-18;
      
    }
    &-p{
      margin-top:torem(8px);
      font-weight: $fw-600;
      font-size: torem(18px);
      line-height: torem(22px);
      display: flex;
      align-items: center;
      text-align: center;
      color: $mainfont;
    }
    &-btn{
      margin-top: torem(72px);
      width: torem(217px);
      height: torem(58px);
    }
    
  }

  @include media-breakpoint-down(sm) {
    .purchase{
      &-cont{
        max-width: 100%;
        flex: 1 1 auto;
        overflow: hidden;
      }
    }
  }
</style>