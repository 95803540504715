import Backbone from 'backbone';
import RoyaltyReportModel from '@/components/models/user/royalty-report';

export default Backbone.Collection.extend({
    model: RoyaltyReportModel,
    url: '/api/user/royalty-report',

    loadReportsByYear: function (year) {
        var self = this;
        /*
              return new Promise(function (resolve, reject) {
                  self.fetch({url: self.url + '?year=' + year,})
                      .then(e => {
                          console.log(e);
                          resolve(e)
                      })
                      .catch(e => {
                          console.error(e);
                          reject(e)
                      });
              });*/
        return this.fetch({
            url: this.url + '?year=' + year,
            success: function () {
                self.trigger('loaded');
            },
            error: function () {
                self.trigger('loadError');
            }
        })
    }
});