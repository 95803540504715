import Backbone from "backbone"
import OrderItemModel from "./order/item"
import OrderItemCollection from "@/components/collections/user/orders/items"
import moment from "moment/moment"
import 'backbone-relational/backbone-relational'
import '@/components/jquery.json'


let STATUSES = {
  CREATED: 'created',
  PENDING: 'pending',
  WAIT_CONFIRMATION: 'wait_confirmation',
  CANCEL: 'cancel',
  PAID: 'paid',
  FAILED: 'failed',
  DATA: {}
};

STATUSES.DATA[STATUSES.CREATED] = {
  class: 'yellow',
  text: 'in progress'
};
STATUSES.DATA[STATUSES.PENDING] = {
  class: 'yellow',
  text: 'in progress'
};
STATUSES.DATA[STATUSES.WAIT_CONFIRMATION] = {
  class: 'yellow',
  text: 'in progress'
};
STATUSES.DATA[STATUSES.CANCEL] = {
  class: 'rouge',
  text: 'canceled'
};
STATUSES.DATA[STATUSES.PAID] = {
  class: 'green',
  text: 'completed'
};
STATUSES.DATA[STATUSES.FAILED] = {
  class: 'rouge',
  text: 'failed'
};

export default Backbone.RelationalModel.extend({
  relations: [
    {
      type: Backbone.HasMany,
      key: 'orderItems',
      relatedModel: OrderItemModel,
      collectionType: OrderItemCollection
    }
  ],
  defaults: {
    status: STATUSES.CREATED,
    price: 0,
    isFree: false,
    createdAt: 0,
    orderItems: []
  },
  url: function () {
    return "/api/cart/placeorder";
  },
  getId: function () {
    return this.get("id");
  },
  getPrice: function () {
    return this.get("price");
  },
  getDiscountAmount: function () {
    var items = this.getItems();
    var totalDiscountAmount = 0;
    items.forEach(function (item) {
      totalDiscountAmount += item.get('discountAmount');
    });
    return totalDiscountAmount.toFixed(2);
  },
  getCreatedAt: function () {
    return this.get("createdAt");
  },

  getCreatedAtFormatted: function (format) {
    var createdAt = this.getCreatedAt();

    format = format || 'MMMM D, YYYY';

    if (!createdAt) {
      return '';
    }

    return moment.unix(createdAt).format(format);
  },
  getItems: function () {
    return this.get("orderItems");
  },
  getItemsCount: function () {
    return this.getItems().length;
  },
  getPaymentMethod: function () {
    return this.get("paymentMethod");
  },
  isFree: function () {
    return this.get('isFree');
  },
  getFreePayUrl: function () {
    return "/shop/order/" + this.getId() + "/free";
  },
  getStatus: function () {
    return this.get("status");
  },
  getStatusData: function () {
    return STATUSES.DATA[this.getStatus()];
  },
  create: function (creditCard) {
    this.set("paymentMethod", creditCard !== undefined ? 'Card' : 'PayPal');

    return this.save(creditCard);
  },
  payViaCreditCard: function (creditCard) {
    return Backbone.$.ajax("/api/order/" + this.getId() + "/pay", {
      data: Backbone.$.toJSON(creditCard),
      method: "POST",
      dataType: "json",
      contentType: 'application/json'
    })
  },
  requestPayPalPaymentToken: function () {
    return Backbone.$.ajax("/api/order/" + this.getId() + "/paypal-confirmation-token", {
      method: "POST",
      dataType: "json",
      contentType: 'application/json'
    });
  },
  toDTO: function () {
    return {
      id: this.getId(),
      price: this.getPrice(),
      orderItems: this.getItems().toDTO()
    };
  }
});

