<template>
  <div class="cartTable">
    <div class="cartTable-row">
      <div class="cartTable-col cartTable-col-number">
        <p class="cartTable-header">№</p>
      </div>
      <div class="cartTable-col cartTable-col-name">
        <p class="cartTable-header ">Name</p>
      </div>
      <div class="cartTable-col cartTable-col-link"></div>
    </div>
    <template v-for="(orderItem,index) in order.getItems().models">
      <hr>
      <div class="cartTable-row" >
        <div class="cartTable-col cartTable-col-number">
          <p class="cartTable-text">{{index+1}}</p>
        </div>
        <div class="cartTable-col cartTable-col-name">
          <p class="cartTable-text">
            {{orderItem.getTitle()}}
          </p>
        </div>
        <div class="cartTable-col cartTable-col-link">
          <router-link
            v-if="orderItem.getEditLink()" :to="orderItem.getEditLink()"
            class="main-link ficon icon-arrowright">
            {{orderItem.getEditText()}}
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    props: ['order'],
    name: "purchase-table",
    mounted() {
        let self = this;
        this.order.getItems().each(orderItem=>{
            let type = orderItem.getParams().product.type;
            if(type === 1){
                self.subscriptionFetch();
            }
            if(type === 5){
                self.fetchUserInfo();
            }
        });
    },
    methods:{
        subscriptionFetch() {
            let self = this;
            this.$store.dispatch('subscription/loadSubscription').then(subscription => {
                if(!subscription) {
                    subscription = this.$store.getters["subscription/getNewSubscription"];
                }
                subscription.fetch().done(e=>{
                    self.$store.commit('subscription/setSubscriptionId',e.id);
                    self.$store.commit('subscription/setChange',true);
                })
                
                
            });
        },
        fetchUserInfo(){
            let self = this;
            self.$store.dispatch('user/loadUser')
                .catch((error)=>{
                  this.$root.popup.showMessage(error);
            });
        }
    }
  }
</script>

<style lang="scss">
  .cartTable {
    //max-width: torem(710px);
    width: auto;
    margin-top: torem(80px);
    margin-bottom: torem(150px);
    hr {
      border-top: torem(2px) solid $color-c2;
      width: 100%;
    }
    &-text {
      font-weight: $fw-500;
      font-size: torem(18px);
      line-height: torem(22px);
      display: flex;
      align-items: center;
      color: $mainfont;
      white-space: nowrap;
      padding-right: torem(30px);
    }
    &-row {
      padding-left: torem(44px);
      padding-right: torem(44px);
      display: flex;
    }
    &-col {
        padding-left: torem(15px);
        padding-right: torem(15px);
        flex: 0 0 50%;
        display: flex;
        align-items: center;
        &-number {
            margin-right: torem(120px);
            max-width: torem(60px);
            flex: 0 0 torem(60px);
        }
        &-name {
            flex: 1 1 auto;
            width: auto;
            padding: 0;
        }
        &-link{
            flex: 0 0 torem(300px);
            width: torem(300px);
        }
    }
    &-header {
      font-weight: bold;
      font-size: torem(20px);
      line-height: torem(24px);
      display: flex;
      align-items: center;
      color: $blue-1f;
    }
    .main-link{
      white-space: nowrap;
    }
  }

  @include media-breakpoint-down(sm) {
    .cartTable {
        max-width: 100%;
        &-row {
            padding: 0 torem(25px);
        }
        &-col {
            &-number {
                margin: 0;
            }
            &-name {
                max-width: 50%;
                overflow: hidden;
                p.cartTable-text {
                    text-overflow: ellipsis;
                    max-width: 100%;
                    display: block;
                    overflow: hidden;
                }
            }
        }
    }
  }
  @include media-breakpoint-down(xs)
  {
    .cartTable{
      margin-bottom: auto;
      &-row {
        flex-wrap: wrap;
        padding: 0;
      }
      &-col{
        &-link {
          flex: 1 1 auto;
        }
  
        &-name {
          max-width: 77%;
          flex: 1 1 auto;
        }
  
        &-number {
          flex: 1 1 auto;
          max-width: 23%;
        }
  
        &-link {
          text-align: right;
          justify-content: flex-end;
          margin: torem(14px) 0;
        }
      }
      
    }
    
  }
</style>