var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"masteringItem",class:{'masteringItem-disabled':_vm.disabled }},[_c('div',{staticClass:"masteringItem-info"},[_c('p',{staticClass:"masteringItem-title"},[_vm._v("\n      "+_vm._s(_vm.item.attributes.title.replace('Mastering. ',''))+" ("+_vm._s(_vm.item.attributes.paramsData.min_tracks)+"-"+_vm._s(_vm.item.attributes.paramsData.max_tracks)+").\n      "),_c('span',[_vm._v("$"+_vm._s(_vm.item.attributes.price))]),_c('span',{staticClass:"track"},[_vm._v("/track")])])]),_c('div',{staticClass:"masteringItem-spinner"},[_c('ui-spinner',{ref:"spinner",attrs:{"min":Number.parseInt(_vm.item.attributes.paramsData.min_tracks),"max":Number.parseInt(_vm.item.attributes.paramsData.max_tracks),"disabled":_vm.disabled},model:{value:(_vm.count),callback:function ($$v) {_vm.count=$$v},expression:"count"}})],1),_c('div',{staticClass:"masteringItem-total"},[_vm._v("\n    $"+_vm._s(_vm.price.toFixed(2))+"\n  ")]),_c('button',{class:[
  'masteringItem-btn',
  'btn',
  'ficon',
  {'icon-plus':!_vm.disabled},
  {'icon-minus':_vm.disabled},
  {'masteringItem-btn--remove':_vm.disabled}
  ],on:{"click":function($event){return _vm.onClick($event)}}},[_vm._v("\n    "+_vm._s(_vm.disabled?'Remove':'Add')+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }