import Backbone from 'backbone';

export default Backbone.Model.extend({
    url: "/api/user/payment-method",

    defaults: {
        'paymentMethod': 'paypal',
        'paypalEmail':'',
        'payoneerEmail':'',
        'checkName':'',
        'checkAddress':'',
        'checkCity':'',
        'checkState':'',
        'checkZip':''
    },

    required: [
        'paymentMethod'
    ],

    paymentMethods: {
        paypal: {
            fields: [
                'paypalEmail'
            ]
        },

        payoneer: {
            fields: [
                'payoneerEmail'
            ]
        },

        check: {
            fields: [
                'checkName',
                'checkAddress',
                'checkCity',
                'checkState',
                'checkZip'
            ]
        }
    },

    getId: function () {
        return this.get("id");
    },

    getPaymentMethod: function () {
        return this.get('paymentMethod');
    },

    getPayPalEmail: function () {
        return this.get('paypalEmail');
    },

    getPayoneerEmail: function () {
        return this.get('payoneerEmail');
    },

    getCheckName: function () {
        return this.get('checkName');
    },

    getCheckAddress: function () {
        return this.get('checkAddress');
    },

    getCheckCity: function () {
        return this.get('checkCity');
    },

    getCheckState: function () {
        return this.get('checkState');
    },

    getCheckZip: function () {
        return this.get('checkZip');
    },

    isPaymentMethodPayPal: function () {
        return this.getPaymentMethod() === 'paypal';
    },

    isPaymentMethodPayoneer: function () {
        return this.getPaymentMethod() === 'payoneer';
    },

    isPaymentMethodCheck: function () {
        return this.getPaymentMethod() === 'check';
    },

    savePaymentInfo: function (data,options) {
        return this.save(data, options);
    },

    getPaymentMethodFields: function () {
        return this.paymentMethods[this.getPaymentMethod()].fields;
    },

    validate: function (attributes) {
        var errors = {};
        var required = this.required.concat(this.getPaymentMethodFields());
        var methodName;
        var error;
        var field;

        for (field in attributes) {
            if ((Backbone.$.inArray(field, required) !== -1) && !attributes[field]) {
                errors[field] = 'Field is required';
            } else if (attributes[field]) {
                // call method 'validate{FieldName}'
                methodName = 'validate' + field.charAt(0).toUpperCase() + field.slice(1);

                if (Backbone.$.isFunction(this[methodName])) {
                    error = this[methodName](attributes[field], attributes);

                    // check if it was error
                    if (typeof error !== 'undefined' && error !== true) {
                        errors[field] = error;
                    }
                }
            }
        }

        // return errors if it was any
        if (Object.keys(errors).length > 0) {
            return errors;
        }
    },

    validatePaypalEmail: function (email) {
        var emailRegexp = /^(([a-zA-Z]|[0-9])|([-]|[_]|[.]))+[@](([a-zA-Z0-9])|([-])){2,63}([.](([a-zA-Z0-9]){2,63}))+$/gi;

        if (!this.isPaymentMethodPayPal()) {
            return;
        }

        if (!emailRegexp.test(email)) {
            return 'Please enter an email address.';
        }
    },

    validatePayoneerEmail: function (email) {
        var emailRegexp = /^(([a-zA-Z]|[0-9])|([-]|[_]|[.]))+[@](([a-zA-Z0-9])|([-])){2,63}([.](([a-zA-Z0-9]){2,63}))+$/gi;

        if (!this.isPaymentMethodPayoneer()) {
            return;
        }

        if (!emailRegexp.test(email)) {
            return 'Please enter an email address.';
        }
    },

    toJSON: function () {
        var model = this;
        var attributes = {
            'paymentMethod': this.getPaymentMethod()
        };
        var fields = this.getPaymentMethodFields();

        fields.forEach(function (field) {
            attributes[field] = model.get(field);
        });

        return attributes;
    }
});
