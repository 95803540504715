<template>
    <ValidationObserver
        v-if="user"
        ref="observer"
        :class="['sform',{'hide':isShowing}]"
        tag="form"
        @keydown.enter = "onClick()"
        v-slot="{ invalid }">
        <div class="row">
            <div class="col-12">
                <h2 class="sform-title" @click="toggleHider">
                    Password
                </h2>
            </div>
        </div>
        <div class="sform-hider">
            <div class="row">
                <!--firstName-->
                <div class="col-12">
                    <vinput
                        key="password"
                        ref="password"
                        class="sform-input"
                        :value="password"
                        :ttInfoOptions="{contentAsHTML:true}"
                        title="Old password"
                        VPRules="required"
                        inputType="password"
                        inputName="password"
                        @input="onInput($event,$refs.password)"

                    />
                </div>
                <div class="col-12 col-sm-6 col-md-12">
                    <vinput
                        key="newPassword"
                        ref="newPassword"
                        class="sform-input"
                        :value="newPassword"
                        :ttInfoOptions="{contentAsHTML:true}"
                        title="New password"
                        VPRules="required|min:6|max:32|different:@password"
                        inputType="password"
                        inputName="newPassword"
                        inputDisplayName="new password"
                        @input="onInput($event,$refs.newPassword)"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-12">
                    <vinput
                        key="confirmPassword"
                        ref="confirmPassword"
                        class="sform-input"
                        :value="confirmPassword"
                        :ttInfoOptions="{contentAsHTML:true}"
                        title="Confirm new password"
                        VPRules="required|min:6|max:32|confirmed:newPassword"
                        inputType="password"
                        inputName="confirmPassword"
                        inputDisplayName="Confirm Password"
                        @input="onInput($event,$refs.confirmPassword)"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button ref="btn" class="btn sform-btn" :disabled="isOldValues || invalid" @click="onClick()">
                        change password
                    </button>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
    import vinput from '@/components/input-tt';
    import {ValidationProvider, ValidationObserver,extend} from 'vee-validate';
    import {toggle} from '../mixins/toggle';
    import {marge} from '../mixins/marge';
    import {onInput} from '../mixins/onInput';
    extend('different', {
        params: ['target'],
        validate(value, {target}) {
            return target !== value;
        },
        message:'New password must be different from the current one'
    });
    export default {
        name: "password-form",
        mixins: [toggle, marge, onInput],
        props: ['user'],
        data() {
            return {
                password: null,
                newPassword: null,
                confirmPassword: null,
                isOldValues: true,
            }
        },
        mounted() {

            this.password = this.user.get('password');
            this.newPassword = this.user.get('newPassword');
            this.confirmPassword = this.user.get('confirmPassword');
            

        },
        methods: {
            async onClick() {
                this.isOldValues = true;
                var self = this,
                    isValid = await this.$refs.observer.validate();
                if (isValid) {
                    this.marge();
                    this.user.changePassword({
                        error(newUserInfo, response) {
                            if (response.status != 200) {
                                self.$refs.observer.setErrors({
                                    password: ['Invalid Old password.']
                                });
                                let errorMessage = [];
                                if (response.responseJSON) {
                                    for (let type in response.responseJSON.errors) {
                                        let error = response.responseJSON.errors;
                                        errorMessage.push(error[type].field + ':' + error[type].message);
                                    }
                                }
                                self.$root.popup.showMessage(errorMessage);
                                self.isOldValues = false;
                            }
                            else {
                                self.$root.popup.showMessage('Password changed success');
                            }
                        },
                        success() {
                            self.$root.popup.showMessage('Password changed success');
                        },
                    });
                }
            },
        },
        components: {
            vinput,
            ValidationProvider,
            ValidationObserver,
        }
    }
</script>