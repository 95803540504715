<template>
    <div class="container d-flex flex-column align-items-stretch">
        <div class="row">
            <div class="col-12">
                <h1 class="main-h1" v-if="subscription && cart">
                    <template v-if="subscription.attributes.status === 'active' || cart.getItemsByType([1,13]).length">
                        Distribute
                    </template>
                    <template v-else-if="subscription.attributes.status !== 'active'">
                        Plans & Pricing
                    </template>
                </h1>
            </div>
        </div>
        <template v-if="!!(currentProject && subscription !== false && load)">
            <div class="posr">
                <router-link class="distribute-close edit-project-close ficon icon-subtract"
                             :to="'/project/'+currentProject.getId()+'/edit'"></router-link>
                <project-item :item="currentProject" v-if="currentProject.attributes.title" :viewMode="true" />
            </div>
            <template
                v-if="load && subscription !== false && cart && projectCollection && currentProject.attributes.title ">
                <addons-form
                    v-if="isAddons()"
                    :currentProject="currentProject"
                    :subscription="subscription"
                    :cart="cart"
                />
                <plans-loop
                    v-else
                    :products="products"
                    :usedArtistCount="usedArtistCount"
                    :currentProject="currentProject"
                    :currentSubscription="subscription"
                    :cart="cart"
                />
            </template>
        </template>
        <random-loader v-else class="loader--poss" :substrate="false" :randomText="true" />
    </div>
</template>

<script>
    import DistibutiionModel from '@/components/models/distribution-project';
    import Products from '@/components/collections/products'
    import addonsForm from './components/addons-form'
    import projectItem from './components/project-item';
    import plansLoop from '../plans/components/plans-loop';
    import {mapGetters} from 'vuex';
    import randomLoader from '@/components/random-loader';
    import ProjectCollection from '@/components/collections/distribution-projects';

    export default {
        name: "distribute",
        data() {
            return {
                currentProject: false,
                load: false,
                subscription: false,
                cart: false,
                products: false,
                projectCollection: null,
                usedArtistCount: null
            }
        },

        async mounted() {
            this.disableCartCursorPointer();
            this.initCurrentProject();

            let self = this;
            this.$store.dispatch('cart/loadCart').then(cart => {
                this.cart = cart;
            });
            this.$store.dispatch('subscription/loadSubscription').then(subscription => {
                this.subscription = subscription;
            });
            await this.initProducts();
            this.projectCollection = new ProjectCollection();
            await this.projectCollection
                .loadUsedMainArtistCount(this.currentProject)
                .then((count) => {
                    console.log(count);
                    this.usedArtistCount = count;
                });
            this.load = true;
        },

        beforeRouteLeave (to, from , next) {
          this.enableCartCursorPointer();
          next();
        },

        methods: {
            isAddons() {
                return this.currentProject.isPayPerProject() || this.currentProject.isFreeSingle() ||
                    (
                        this.subscription &&
                        this.projectCollection &&
                        this.subscription.get('maxArtistCount') >= this.usedArtistCount &&
                        this.subscription.attributes.status === 'active' && !this.subscription.get('isRenewalAllowed')
                    )
                    || !!this.cart.getItemsByType([1, 13]).length;
            },
            disableCartCursorPointer() {
              let cartButton = document.querySelector(".header .cart-header-btn");
              let cartCounter = document.querySelector(".cart-header-counter");

              if (cartButton) {
                cartButton.style.cursor = 'default';
                cartButton.classList.add('disableCartButton');
              }

              if (cartCounter) {
                cartCounter.classList.add('disableCartCounter');
              }
            },
            enableCartCursorPointer() {
              let cartButton = document.querySelector(".header .cart-header-btn");
              let cartCounter = document.querySelector(".cart-header-counter");

              if (cartButton) {
                cartButton.style.cursor = "pointer";
                cartButton.classList.remove('disableCartButton');
              }

              if (cartCounter) {
                cartCounter.classList.remove('disableCartCounter');
              }
            },
            checkProjectForDistribute() {
                // todo: add check for subscription in cart and main artists in current project
                if (!this.currentProject.readyForDistribute()) {
                    console.warn('this project doesn\'t ready for distribution');
                    let id = this.currentProject.getId();
                    this.$router.push('/project/' + id + '/edit');

                    return;
                }

                if (!this.currentProject.isIncomplete()) {
                    this.$router.push('/project/list');
                }
            },
            initCurrentProject() {
                let self = this;
                if (this.$route.params.id) {
                    this.currentProject = Backbone.Relational.store.find(DistibutiionModel, this.$route.params.id);
                    if (!this.currentProject) {
                        this.currentProject = new DistibutiionModel();
                        this.currentProject.set('id', this.$route.params.id);
                        this.currentProject.fetch({
                            error(collection, error) {
                                self.$root.popup.showMessage(error);
                            },
                            success() {
                                self.checkProjectForDistribute();
                            }
                        });
                    } else {
                        self.checkProjectForDistribute();
                    }
                }
            },
            async initProducts() {
                if (!this.productList) {
                    await this.$store.dispatch('product-list/load');
                }

                this.products = new Products(this.productList);
            },
        },
        computed: {
            ...mapGetters({
                productList: 'product-list/get'
            })
        },
        components: {
            projectItem,
            plansLoop,
            addonsForm,
            randomLoader
        }
    }
</script>
