export default function (cartItem) {
  this.getPrice = function () {
    let attributes = cartItem.getProductAttributes();
    let product = cartItem.getProduct();
    let tracksCount = (attributes.tracks_count ? parseInt(attributes.tracks_count) : 0);
    let price = cartItem.getSalePrice() * tracksCount * product.getRecurringMonthsCount();

    return parseFloat(price.toFixed(2));
  };

  /**
   * Format cart item title for
   * radio product type
   *
   * @returns {string}
   * @example "(10 songs / 1 year)"
   */

  this.getTitle = function () {
    let title = cartItem.getProduct().getTitle(),
      tracksCount = cartItem.getProductAttributes().tracks_count,
      recurring = cartItem.getProduct().getRecurringPeriod(),
      recurringString = recurring.frequency;

    switch (recurring.period.toLowerCase()) {
      case 'month':
        recurringString += (recurring.frequency == 1) ? " month" : " months";
        break;
      case 'year':
        recurringString += " year";
        break;
    }

    title += " (" + tracksCount + (tracksCount == 1 ? " song" : " songs") + " / ";
    title += recurringString + ")";

    return title;
  };
};

