export default {
    state: {
        sizeTable: [
            {model: 'xs', size: 375, BPsize: 768, fontSize: 14},
            {model: 'sm', size: 768, BPsize: 1210, fontSize: 14},
            {model: 'md', size: 1210, BPsize: 1920, fontSize: 8.82292},
            {model: 'lg', size: 1920, BPsize: Infinity, fontSize: 14}
        ],
        deviceSize: {
            width: 0,
            height: 0,
        },
        currentSize: null,
        orientation: null,
        currentState: null,
        fontSize: null,
    },
    mutations: {
        set(state, winSize) {
            state.currentSize = winSize;
            for (let i =0; i<state.sizeTable.length;i++){
                let bp = state.sizeTable[i].BPsize,
                    previosBp = i? state.sizeTable[i-1].BPsize :0;
                if(winSize>=previosBp && winSize < bp){
                    state.currentState = state.sizeTable[i];
                }
            }
        },
        setInnerSize(state, size) {
            state.deviceSize = size;
        },
        setOrientation(state, size) {
            state.orientation = size;
        },
        setFontSize(state, fintSize) {
            state.fontSize = fintSize;
        }
    },
    getters: {
        get: function (state) {
            return state.currentSize;
        },
        getCurrentState(state) {
            return state.currentState;
        },
        getInnerSize: function (state) {
            return state.deviceSize;
        },
        getSizeTable(state) {
            return state.sizeTable;
        },
        getOrientation(state) {
            return state.orientation;
        },
        getFontSize(state) {
            return state.fontSize;
        }
    },
};