export const onInput = {
    data() {
        return {
            dictionary: {
                'GooglePlus': 'socialLinkGooglePlus',
                'Twitter': 'socialLinkTwitter',
                'Facebook': 'socialLinkFacebook',
                'First Name': 'firstName',
                'Last Name': 'lastName',
                'Password': 'password',
                'New Password': 'newPassword',
                'Confirm Password': 'confirmPassword',
                'PayPal Email': 'paypalEmail',
                'Payoneer Email': 'payoneerEmail',
                'Name' : 'checkName',
                'City' : 'checkCity',
                'Address' : 'checkAddress',
                'Zip Code' : 'checkZip',
                'State' : 'checkState',
            }
        }
    },
    methods: {
        async onInput(value, refInput, model) {
                let observer = this.$refs.observer;
                    model = model || this.user;
                this.isOldValues = observer.$children.every(observerInput => {
                    let modelInputName = observerInput.inputName,
                        compareValue = observerInput.inputName === refInput.inputName ? value : this[modelInputName];
                    return model.get(modelInputName) === compareValue;
                });
                this[refInput.inputName] = value;

        },
    }
};