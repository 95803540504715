
import Backbone from 'backbone';
import GenreModel from './genre';
import DistributionCoverArtModel from './distribution-project/cover-art';

import DistributionTrackModel from './distribution-project/track';
import DistributionTrackCollection from '../collections/distribution-project/tracks';
import validationMessages from './distribution-project/validation/messages';
import projectStatuses from './distribution-project/statuses';
import soundcloudStatuses from './distribution-project/soundcloud-statuses';

import Moment from 'moment';
import 'moment-duration-format';
import 'backbone-relational'

let ONE_DAY = 24 * 3600 * 1000;
let TODAY = function () {
    return new Date().getTime();
};
let MIN_EXPEDITE_INTERVAL = ONE_DAY;
let MIN_EXPEDITE_AND_PRE_RELEASE_INTERVAL = 14 * ONE_DAY;
let MIN_PRE_RELEASE_AND_RELEASE_INTERVAL = 14 * ONE_DAY;
let MIN_NO_EXPEDITE_INTERVAL = 30 * ONE_DAY;

let SINGLE_ALBUM = 1;
let EP_ALBUM = 2;
let ALBUM = 3;

export default Backbone.RelationalModel.extend({
    relations: [
        {
            type: Backbone.HasMany,
            key: 'tracks',
            relatedModel: DistributionTrackModel,
            collectionType: DistributionTrackCollection,
            reverseRelation: {
                key: 'project',
                relationalType: Backbone.HasOne
            }
        }, {
            type: Backbone.HasOne,
            key: 'primaryGenre',
            relatedModel: GenreModel
        }, {
            type: Backbone.HasOne,
            key: 'secondaryGenre',
            relatedModel: GenreModel
        }, {
            type: Backbone.HasOne,
            key: 'coverArt',
            relatedModel: DistributionCoverArtModel
        }
    ],
    urlRoot: '/api/projects',
    defaults: {
        albumType: false, //SINGLE_ALBUM
        originalReleaseDate: false,
        trackCount: 0,
        tracks: null,
        title: '',
        upc: null,
        coverArt: null,
        coverArtSlotId: null,
        desiredReleaseDate: null,
        preReleaseDate: null,
        expediteToITunes: false,
        preRelease: false,
        mondoBlast: false,
        mondoBio: false,
        payPerProject: null,
        freeSingle: false,
        isExpediteDateHidden: true,
        copyrightYear: (new Date()).getFullYear(),
        copyrightLine: '',
        primaryGenre: null,
        secondaryGenre: null,
        notes: '',
        status: projectStatuses.INCOMPLETE,
        distributeState: false,
        soundcloudStatus: soundcloudStatuses.NO_DISTRIBUTION,
    },
    desiredReleaseDateMin: null,
    preReleaseDateMin: null,
    autoCorrectDate: false,
    validationOfTracksIsEnabled: false,
    validationUPCRegexp: /^[A-Z0-9]{12,14}$/i,
    validationOfCoverArtIsEnabled: false,
    validationAllIsEnabled: false,
    cartItems: null,
    totalPrice: 0,
    payPerProjectPrice: 0,
    required: [
        'title', 'albumType',
        'copyrightYear', 'copyrightLine',
        'primaryGenre', 'secondaryGenre'
    ],
    initialize: function () {
        this.listenTo(this, 'change:preReleaseDate', function () {
            /**
             * This field use to manage swiper-box on project information page and display AddOn "Pre Order"
             */
            this.set("preRelease", this.hasPreReleaseDate());
        });
        //preparation for free single
        if ($('.js-free-single-enable').length
            && window.location.pathname === '/dashboard/start-project/free-single'
        ) {
            this.set('trackCount', 1);
            this.set('freeSingle', 1);
        }
    },
    enableAutoCorrectDate: function () {
        this.autoCorrectDate = true;
    },
    enableDistribute: function () {
      this.setDistributeState(true);
    },
    getId: function () {
        return this.get('id');
    },
    getTitle: function () {
        return this.get('title');
    },
    getCopyrightLine: function(){
        return this.get('copyrightLine')
    },
    getArtistsNames: function () {
        var artistsNames = [];
        this.get('tracks').getMainArtists().forEach(function (mainArtist) {
            artistsNames.push(mainArtist.get('name'));
        });

        return artistsNames;
    },
    getMainArtists: function () {
      let mainArtists = [];
      this.get('tracks').getMainArtists().forEach(function (mainArtist) {
        mainArtists.push(mainArtist);
      });

      return mainArtists;
    },
    getPaidArtistsNames: function () {
        var artistsNames = [];
        this.get('tracks').getPaidMainArtists().forEach(function (mainArtist) {
            artistsNames.push(mainArtist.get('name'));
        });

        return artistsNames;
    },
    getTracks: function () {
        return this.get('tracks');
    },
    getTrackByIsrc:function(isrc){
        return this.getTracks().findWhere({isrc});
    },

    getGenreId: function (type) {
        return this.get(type) !== null ? this.get(type).get('id') : null;
    },
    getFullAlbumType() {
        let tracksLength = this.getTracks().length;
        if(tracksLength <=3){
            return {id:1, text:'Single'}
        }
        else if(tracksLength <=6){
            return {id:2, text:'EP'}
        }
        return {id:3, text:'Album'};
    },
    updateAlbumType() {
        let count = this.get('tracks').models.length;
        this.set('trackCount', count);
        this.set('albumType', count < 3 ? 1 : (count < 6 ? 2 : 3));
    },
    stopPlayingAllTrack(){
        this.getTracks().where({isPlay:true}).forEach(track=>track.setStop())
    },
    setNewCoverArt: function() {
        this.setCoverArtUrl(null);
    },
    getAlbumType: function () {
        return this.get('albumType')
    },
    getDistributeState: function () {
      return this.get('distributeState')
    },
    setDistributeState: function (value) {
      this.set('distributeState', value);
    },
    getTrackCount: function () {
        return this.get('trackCount')
    },
    hasCoverArt: function () {
        return !!this.getCoverArt();
    },
    getCoverArt: function () {
        return this.get('coverArt');
    },
    getCoverArtUrl: function () {
        var coverArt = this.getCoverArt();

        return coverArt ? coverArt.getImageUrl() : null;
    },
    setCoverArtUrl: function(url) {
        var coverArt = this.getCoverArt();
        if (!coverArt) {
            coverArt = new DistributionCoverArtModel({project: this});
            this.set('coverArt', coverArt);
        }

        coverArt.set('path', url);
    },
    getDesiredReleaseDate: function () {
        return this.get('desiredReleaseDate');
    },
    getPreReleaseDate: function () {
        return this.get('preReleaseDate');
    },
    countTotalDuration: function () {
        if (!this.getTracks()) {
            return 0;
        }
        return this.getTracks().countTotalDuration();
    },
    getFormattedTotalDuration: function () {
        var seconds = this.countTotalDuration();
        return Moment.duration(seconds, 'seconds').format('hh:mm:ss', {trim: false});
    },
    getPrimaryGenreTitle: function () {
        if (this.get('primaryGenre') && this.get('primaryGenre').get('title')) {
            return this.get('primaryGenre').get('title');
        }

        return null;
    },
    getSecondaryGenreTitle: function () {
        if (this.get('secondaryGenre') && this.get('secondaryGenre').get('title')) {
            return this.get('secondaryGenre').get('title');
        }

        return null;
    },
    enableValidationOfTracks: function () {
        this.validationOfTracksIsEnabled = true;
    },
    disableValidationOfTracks: function () {
        this.validationOfTracksIsEnabled = false;
    },
    enableValidationOfCoverArt: function () {
        this.validationOfCoverArtIsEnabled = true;
    },
    disableValidationOfCoverArt: function () {
        this.validationOfCoverArtIsEnabled = false;
    },
    enableAllValidation: function () {
        this.validationAllIsEnabled = true;
    },
    disableAllValidation: function () {
        this.validationAllIsEnabled = false;
    },
    initEarliestDefaultReleaseDates: function () {
        var preReleaseDate;

        this.desiredReleaseDateMin = new Date(TODAY() + MIN_NO_EXPEDITE_INTERVAL);
        this.preReleaseDateMin = null;

        if (this.expediteToITunes()) {
            this.desiredReleaseDateMin = new Date(TODAY() + MIN_EXPEDITE_INTERVAL);
            if (this.hasPreReleaseDate()) {
                this.preReleaseDateMin = new Date(TODAY() + MIN_EXPEDITE_AND_PRE_RELEASE_INTERVAL);
                this.desiredReleaseDateMin = new Date(this.preReleaseDateMin.getTime() + MIN_PRE_RELEASE_AND_RELEASE_INTERVAL);
            }

            return;
        }

        if (this.hasPreReleaseDate()) {
            preReleaseDate = new Date(this.getPreReleaseDate());

            this.preReleaseDateMin = new Date(TODAY() + MIN_NO_EXPEDITE_INTERVAL);

            if (preReleaseDate.getTime() < this.preReleaseDateMin.getTime()) {
                preReleaseDate = this.preReleaseDateMin;
            }

            this.desiredReleaseDateMin = new Date(preReleaseDate.getTime() + MIN_PRE_RELEASE_AND_RELEASE_INTERVAL);
        }
    },
    correctionReleaseDates: function (isError) {
        var releaseDate;
        var preReleaseDate;
        var oldInterval;

        if (!this.autoCorrectDate) {
            return;
        }

        isError = isError === true ? isError : false;
        releaseDate = this.getDesiredReleaseDate() ? new Date(this.getDesiredReleaseDate()) : new Date();
        preReleaseDate = this.getPreReleaseDate() ? new Date(this.getPreReleaseDate()) : new Date();

        if (this.expediteToITunes()) {
            if (this.hasPreRelease()) {
                if (isError) {
                    oldInterval = releaseDate.getTime() - preReleaseDate.getTime();
                    releaseDate = new Date(TODAY() + MIN_EXPEDITE_AND_PRE_RELEASE_INTERVAL + oldInterval);
                }

                preReleaseDate = new Date(TODAY() + MIN_EXPEDITE_AND_PRE_RELEASE_INTERVAL);
                this.set('preReleaseDate', Moment(preReleaseDate).format("MM/DD/YYYY"));

                if (releaseDate.getTime() < TODAY() + MIN_EXPEDITE_AND_PRE_RELEASE_INTERVAL + MIN_PRE_RELEASE_AND_RELEASE_INTERVAL) {
                    releaseDate = new Date(TODAY() + MIN_EXPEDITE_AND_PRE_RELEASE_INTERVAL + MIN_PRE_RELEASE_AND_RELEASE_INTERVAL);
                }

                this.set('desiredReleaseDate', Moment(releaseDate).format("MM/DD/YYYY"));
            } else {
                preReleaseDate = null;
                releaseDate = new Date(TODAY() + MIN_EXPEDITE_INTERVAL);

                this.set('preReleaseDate', preReleaseDate);
                this.set('desiredReleaseDate', Moment(releaseDate).format("MM/DD/YYYY"));
            }
        } else if (this.hasPreRelease()) {
            if (preReleaseDate.getTime() < TODAY() + MIN_NO_EXPEDITE_INTERVAL) {
                preReleaseDate = new Date(TODAY() + MIN_NO_EXPEDITE_INTERVAL);
                releaseDate = new Date(preReleaseDate.getTime() + MIN_PRE_RELEASE_AND_RELEASE_INTERVAL);

                this.set('preReleaseDate', Moment(preReleaseDate).format("MM/DD/YYYY"));
                this.set('desiredReleaseDate', Moment(releaseDate).format("MM/DD/YYYY"));
            } else {
                releaseDate = new Date(releaseDate.getTime());

                this.set('preReleaseDate', Moment(preReleaseDate).format("MM/DD/YYYY"));
                this.set('desiredReleaseDate', Moment(releaseDate).format("MM/DD/YYYY"));
            }
        } else if (releaseDate.getTime() < TODAY() + MIN_NO_EXPEDITE_INTERVAL) {
            releaseDate = new Date(TODAY() + MIN_NO_EXPEDITE_INTERVAL);
            preReleaseDate = null;

            this.set('preReleaseDate', preReleaseDate);
            this.set('desiredReleaseDate', Moment(releaseDate).format("MM/DD/YYYY"));
        } else {
            preReleaseDate = null;

            this.set('preReleaseDate', preReleaseDate);
            this.set('desiredReleaseDate', Moment(releaseDate).format("MM/DD/YYYY"));
        }

        this.initEarliestDefaultReleaseDates();
        /*this.trigger('correct:desiredReleaseDate', this);
        this.trigger('correct:preReleaseDate', this);*/
    },
    distribute: function () {
        return this.requestForChangeStatus(this.url() + '/start-distribution')
    },
    continueDistribution: function () {
        return this.requestForChangeStatus(this.url() + '/resubmit')
    },
    pay: function () {
        if (this.getStatus() === projectStatuses.PENDING_PAYMENT) {
            return Backbone.$.Deferred().resolve(this).promise();
        }

        return this.requestForChangeStatus(this.url() + '/start-pay')
    },
    requestForChangeStatus: function (url) {
        var model = this;
        var deferred = Backbone.$.Deferred();

        return Backbone.$.ajax({
            type: "POST",
            url: url,
            dataType: "json"
        })
            .done(function (project) {
                if (project && project.id != model.get('id')) {
                    deferred.reject({
                        "status": 1000,
                        "statusText": "Server received an invalid project."
                    });
                }
                deferred.resolve(model);
            })
            .fail(function (jqXHR) {
                console.error(jqXHR);

                deferred.reject({
                    "status": jqXHR.status,
                    "statusText": jqXHR.statusText,
                    "responseMessage": (typeof (jqXHR.responseJSON) == "object" && jqXHR.responseJSON.message)
                        ? jqXHR.responseJSON.message
                        : ""
                });
            });
    },
    sendRequestTakeDown: function () {
        var self = this;
        return Backbone.$.ajax({
            url: '/api/projects/' + this.get('id') + '/take-down',
            type: "POST",
            contentType: 'application/json',
            data: '',
            dataType: 'json'
        })
            .done(function () {
                self.setStatus(projectStatuses.TAKE_DOWN);
            });
    },
    getBackendErrors: function () {
        return Backbone.$.ajax({
            type: "GET",
            url: this.url() + "/validation",
            dataType: "json"
        });
    },
    create:function(data){
        return Backbone.$.ajax({
            type: "POST",
            url: '/api/projects',
            data: data,
            dataType: "json"
        });
    },
    hasCartItems: function () {
        return (
            this.expediteToITunes()
            || this.hasPreRelease()
            || this.getTracks().some(function (track) {
                return track.hasRingtone();
            })
            || this.hasLandrMastering()
            || this.hasMondoBlast()
            || this.hasMondoBio()
            || this.isPayPerProject() && !this.isFreeSingle()
        );
    },
    hasLandrMastering: function () {
        return this.getTracks().some(function (track) {
            return track.hasLandrPurchaseClaim();
        });
    },
    isAllLandrPurchasesValid: function () {
        return this.getTracks().every(function (track) {
            return !track.hasLandrPurchaseClaim() || track.hasLandrPurchaseClaim() && track.isLandrPurchaseClaimValid();
        });
    },
    getValidationMessage: function (type) {
        return validationMessages[type];
    },
    readyForDistribute(){
        let trackError = true;
        if(this.hasTracks()){
            let tracks = this.getTracks().models;
            trackError = tracks.some((track) => {
                return !!track.validate(track.attributes);
            })
        }
        let fillProject =!!this.getCopyrightLine() && !!this.getSecondaryGenreTitle() && !!this.getPrimaryGenreTitle() && !!this.getTitle();
        return  fillProject && !this.validate(this.attributes) && this.hasTracks() && this.hasCoverArt() && !trackError;
    },
    validate: function (attributes) {
        var errors = {};
        var requiredFields = this.required.slice();
        var field;
        var methodName;
        var error;

        if (this.validationOfCoverArtIsEnabled || this.validationAllIsEnabled) {
            requiredFields.push('coverArt');
        }

        for (field in attributes) {
            // check required
            if ((Backbone.$.inArray(field, requiredFields) !== -1) && !attributes[field]) {
                if (field == 'coverArt') {
                    errors[field] = this.getValidationMessage('coverArt');
                } else {
                    errors[field] = this.getValidationMessage('required');
                }
            } else if (attributes[field] !== null) {

                // call method 'validate{FieldName}'
                methodName = 'validate' + field.charAt(0).toUpperCase() + field.slice(1);

                if (Backbone.$.isFunction(this[methodName])) {
                    error = this[methodName](attributes[field], attributes);

                    // check if it was error
                    if (typeof error !== 'undefined' && error !== true) {
                        errors[field] = error;
                    }
                }
            }
        }

        // return errors if it was any
        if (Object.keys(errors).length > 0) {
            return errors;
        }
    },
    validateUpc: function (upc) {
        if (this.validationUPCRegexp.test(upc) == false) {
            return this.getValidationMessage('upc');
        }
    },
    validateCopyrightLine: function (copyrightLine) {
        var beginYearRegexp = /^\d{4}/;

        if (beginYearRegexp.test(Backbone.$.trim(copyrightLine))) {
            return this.getValidationMessage('copyrightLine1');
        }

        if (copyrightLine.length > 55) {
            return this.getValidationMessage('copyrightLine2');
        }
    },
    validateCopyrightYear: function (copyrightYear) {
        var nextYear = new Date().getFullYear() + 1;
        if (copyrightYear < 1900 || copyrightYear > nextYear) {
            return this.getValidationMessage('copyrightYear');
        }
    },
    validatePreReleaseDatee: function (preReleaseDate) {
        var error = this.validateDate(preReleaseDate);
        var preReleaseDate;
        var desiredReleaseDate;

        if (typeof error !== 'undefined') {
            return error;
        }
        // temporary disable
        return;

        preReleaseDate = new Date(preReleaseDate);
        desiredReleaseDate = new Date(this.get('desiredReleaseDate'));

        // atleast two weeks
        if (desiredReleaseDate.getTime() - preReleaseDate.getTime() < 14 * 24 * 3600 * 1000) {
            return this.getValidationMessage('preReleaseDate');
        }
    },
    validateDesiredReleaseDate: function (desiredReleaseDate) {
        return this.validateDate(desiredReleaseDate);
    },
    /**
     * Validate date
     *
     * @param {string} date
     *
     * @return {string|undefined}
     *
     * @todo you can do better
     */
    validateDate: function (date) {
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) {
            return this.getValidationMessage('date');
        }
    },
    /**
     * Must be standard upper and lower case.
     * The following words must be lower case
     * UNLESS they are the first or last word of the title:
     *     a, an, and, for, from of, or, to, the, in, as,
     *     but, nor, so, yet, at, by, into, off, onto, out, over, up and with.
     * Must NOT contain the word "EP" or "Album".
     * If the project is a one-song Single AND there is a featured artist,
     * add to the title the following: "(feat. Artist Name)"
     * - Note that it must be written by adding a space after the title,
     * followed by left parenthesis "(" then "feat."
     * (must be spelled exactly "feat." followed by a space,
     * the featured artist name, followed by right parenthesis ")."
     * Separate more than one featured artist with a comma.
     * For singles, add " - Single" after the title.
     * Note featured artists names
     * (within the parenthesis CAN be upper case,
     * just make sure that "feat." is always always "feat."
     * and not "Feat." or "Feat" or "Featured" or "featured."
     *
     * Do not allow artist to enter the words "featuring" or "feat." or "FEAT" in this field,
     * as we will insert it per above.
     *
     * For one-song singles,
     * Title in this field must match title song title in row AE exactly
     * (with the exception that the featured artist
     * would only be displayed in cell C-9 at the album level.
     *
     * @param {string} title
     *
     * @return {string|undefined}
     */
    validateTitle: function (title) {
        var notAllowedWords = [
            'ep', 'album', 'featuring', 'featured', 'feat'
        ];

        var notAllowedWordsRegexp = new RegExp('(^|[^a-zа-я])(' + notAllowedWords.join('|') + ')([^a-zа-я]|$)', 'i');
        var linkRegExp = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        if(linkRegExp.test('title')){
            return 'Don\'t use web links for title'
        }
        if (notAllowedWordsRegexp.test(title)) {
            return this.getValidationMessage('title').replace("%s", notAllowedWords.join('", "'));
        }
    },
    validateTracks: function (tracks) {

        if (!this.validationOfTracksIsEnabled && !this.validationAllIsEnabled) {
            return;
        }

        // @todo: how to not validate tracks on first page?
        if (tracks.length === 0) {
            return;
        }

        // validate all tracks
        if (!this.get('tracks').every(function (track) {
            return track.validate() == null;
        }, true)) {
            return this.getValidationMessage('tracks');
        }
    },
    validateTrackCount: function (trackCount) {
        if (!this.validationOfTracksIsEnabled && !this.validationAllIsEnabled) {
            return;
        }

        if (this.getTracks().length < trackCount) {
            return this.getValidationMessage('trackCount').replace("%s", trackCount);
        }
    },
    validateMaxTrackCount: function () {
        if (!this.validationOfTracksIsEnabled && !this.validationAllIsEnabled) {
            return;
        }

        if (this.getTracks().length < this.getTrackCount()) {
            return true;
        }

        this.trigger('invalid', this, {
            maxTrackCount: this.getValidationMessage('maxTrackCount').replace("%s", this.get('trackCount'))
        });

        return false;
    },
    hasTracks(){
        return this.getTracks() && this.getTracks().length > 0;
    },
    getFullStatus(){
            let projectStatuses = {
            1: {
                class: 'gray',
                text: 'Pending Payment',
                tooltip: 'Waiting for Paypal or your credit card institution to clear payment.'
            },
            2: {
                class: 'red',
                text: 'Payment Failed',
                tooltip: 'Uh Oh! It looks like your payment did not go through. Please<br>contact Paypal or your credit card institution to resolve the issue.'
            },
            3: {
                class: 'blue',
                text: 'In Progress'
            },
            4: {
                class: 'red',
                text: 'Distribution Failed'
            },
            5: {
                class: 'green',
                text: 'Distribution Complete',
                tooltip: 'Nice work! Your project is set to go live on its official release date.'
            },
            6: {
                class: 'gray',
                text: 'Pending Payment',
                tooltip: 'Waiting for Paypal or your credit card institution to clear payment.'
            },
            7: {
                class: 'blue',
                text: 'In Progress',
                tooltip: 'You\'re done! Your music is in the process of being submitted to retailers.'
            },
            8: {
                class: 'gray',
                text: 'Incomplete',
                tooltip: 'There is still information that needs to be inputted before<br>we can deliver your music to retailers. Click edit to continue.'
            },
            9: {
                class: 'red',
                text: 'Take Down',
                tooltip: 'Your project has been removed from retailer sites per your request.'
            },
            10: {
                class: 'blue',
                text: 'Mastering'
            },
            11: {
                class: 'red',
                text: 'Resubmission',
                tooltip: 'Cover art was not accepted.<br>Please be sure to follow guidelines provided on the Upload Cover Art page.'
            }
        };
            return projectStatuses[ this.get('status') ];
        },
    getStatus: function () {
        return this.get('status');
    },
    /**
     * @returns {Boolean}
     */
    hasPreRelease: function () {
        return this.get('preRelease');
    },
    hasPreReleaseDate: function () {
        return !!this.get('preReleaseDate');
    },
    expediteToITunes: function () {
        return this.get('expediteToITunes');
    },
    hasMondoBlast: function () {
        return this.get('mondoBlast') || false;
    },
    hasMondoBio: function () {
        return this.get('mondoBio') || false;
    },
    setMondoBlast: function (value) {
        this.set('mondoBlast', value);
    },
    setMondoBio: function (value) {
        this.set('mondoBio', value);
    },
    isExpediteDateHidden: function () {
        return this.get('isExpediteDateHidden');
    },
    isSingleAlbum: function () {
        return this.get('albumType') === SINGLE_ALBUM;
    },
    isEpAlbum: function () {
        return this.get('albumType') === EP_ALBUM;
    },
    isAlbum: function () {
        return this.get('albumType') === ALBUM;
    },
    isEditable: function () {
        return this.isIncomplete() || this.isDistributionBack();
    },
    isIncomplete: function () {
        return this.getStatus() === projectStatuses.INCOMPLETE;
    },
    isDistributionComplete: function () {
        return this.getStatus() === projectStatuses.DISTRIBUTION_COMPLETE;
    },
    isPaymentPending: function () {
        return this.getStatus() === projectStatuses.PENDING_PAYMENT;
    },
    isPayPerProject: function () {
        return this.get('payPerProject');
    },
    isFreeSingle: function () {
        return this.get('freeSingle');
    },
    setPayPerProject: function (value) {
        this.set('payPerProject', value);
    },
    getPayPerProjectPrice: function () {
        if (this.payPerProjectPrice !== 0) {
            return this.payPerProjectPrice;
        }

        return this.retrievePayPerProjectPrice();
    },
    retrievePayPerProjectPrice: function () {
        var self = this;

        Backbone.$.ajax({
            url: '/api/projects/' + this.get('id') + '/music-distribution-project-price',
            type: "GET",
            data: '',
            success: function (val) {
                self.payPerProjectPrice = val;
                $('.js_project-price').html(self.payPerProjectPrice);
            }
        });
    },
    isDistributionBack: function () {
        return this.getStatus() === projectStatuses.DISTRIBUTION_BACK;
    },
    getEditPageUrl: function () {
        if (this.isNew()) {
            return '';
        }

        return '/dashboard/edit-project/' + this.getId();
    },
    getTracksPageUrl: function () {
        if (this.isNew()) {
            return '';
        }

        return this.getEditPageUrl() + '/tracks';
    },
    getSummaryUrl: function () {
        if (this.isNew()) {
            return '';
        }

        return this.getEditPageUrl() + '/summary';
    },
    getProjectCheckoutUrl: function () {
        if (this.isNew()) {
            return '';
        }

        return this.getEditPageUrl() + '/checkout';
    },
    setStatus: function (status) {
        this.set('status', status);
    },
    toJSON: function () {
        var attributes = Backbone.$.extend(true, {}, this.attributes);

        delete attributes.coverArt;

        return attributes;
    },
    placeTrackOnPosition(track, position) {
        var oldPosition = track.get('position');
        if (oldPosition == position) {
            return;
        }

        track.set('position', position);
        let tracks = this.get('tracks');

        if (oldPosition < position) {
            for (let i = oldPosition; i < position; i++) {
                let track = tracks.models[i];
                track.set('position', track.get('position') - 1);
            }
        } else {
            for (let i = position - 1; i < oldPosition - 1; i++) {
                let track = tracks.models[i];
                track.set('position', track.get('position') + 1);
            }
        }

        tracks.sort();
        this.fixTrackPositions();
    },
    fixTrackPositions() {
        let tracks = this.get('tracks');
        tracks.sort();
        let i = 1;
        for (let track of tracks.models) {
            track.set('position', i++);
        }
    },
    setTitle(title){
        /*title = title.replace(/\s+/g, ' ').trim();*/
        var lowerWords = [
            'a', 'an', 'and', 'for', 'from',
            'of', 'or', 'to', 'the', 'in', 'as',
            'but', 'nor', 'so', 'yet', 'at',
            'by', 'into', 'off', 'onto',
            'over', 'up', 'with'
        ];

        var valueArray = title.split(' ');
        var valueArrayLength = valueArray.length;
        var newValue = '';
        var word;
        var lowerCase;
        var filter;

        for (var i in valueArray) {
            filter = valueArray[i].match(/^([^a-zа-я]*)(.*?)([^a-zа-я]*)$/i);
            word = filter[2];
            lowerCase = word.toLowerCase();

            if (i > 0 && i < valueArrayLength - 1
                && lowerWords.indexOf(lowerCase) != -1
            ) {
                word = lowerCase;
            } else {
                word = word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
            }

            newValue += filter[1] + word + filter[3] + (i < valueArrayLength - 1 ? ' ' : '');
        }
        this.set('title',newValue);
    },
    distributeToSoundCloud() {
      this.set('soundcloudStatus', soundcloudStatuses.PENDING_QC);
    },
});
