define([], function () {
    var nextYear = new Date().getFullYear() + 1;
    return {
        title: 'Words "%s" are not allowed in a title',
        upc: 'Character should be A-Za-z0-9, field length should be between 12 and 14',
        preReleaseDate: 'Pre Order date must be atleast 2 weeks prior to Release Date',
        copyrightYear: 'Field should be between 1900 and ' + nextYear + '.',
        copyrightLine1: 'Field should not begin with four digits.',
        copyrightLine2: 'Field length should be less or equal to 55.',
        coverArt: 'Please upload cover art.',
        tracks: 'Please check your tracks information.',
        trackCount: 'Please upload atleast %s tracks to you project.',
        maxTrackCount: 'Your project type is limited to %s tracks. You will need to change it on Project Information page.',
        required: 'Field is required',
        date: 'Invalid date format'
    };
});
