<template>
    <div class="container signform" v-if="winRes">
        <div class="row full-width flex-column flex-sm-row">
            <div class="signform-bgimg d-sm-none d-md-block">
                <a class="signform-logo" v-if="octiiveHomePage" :href="octiiveHomePage.octiive_home_page">
                    <img class="img-fluid" :src="whiteLogo"
                         alt="Octiive Logo" />
                </a>
                <h1 class="signform-h1" v-if="isMobile ">
                    <template v-if="actual==='reset'">
                        Forgot your password?
                    </template>
                    <template v-else>
                        Welcome <br class="d-sm-block d-md-none"> to Octiive!
                    </template>
                </h1>
            </div>
            <div class="signform-pbody">
                <a class="signform-tablelogo" v-if="octiiveHomePage && (isTablet && !isMobile)"
                   :href="octiiveHomePage.octiive_home_page">
                    <img :src="blackLogo"
                         alt="Octiive Logo" />
                </a>
                <div class="signform-body">
                    
                    <h1 class="signform-h1 d-none d-sm-block">
                        <template v-if="actual==='reset'">
                            Forgot your password?
                        </template>
                        <template v-else>
                            Welcome <br class="d-sm-block d-md-none"> to Octiive!
                        </template>
                    </h1>
                    <div v-if="this.$route.meta.name==='sign-up'">
                        <!-- SIGN-UP form -->
                        <vform ref="vueForm" :formdata="signUp" />
                        <!-- END SIGN-UP form -->
                        <div class="signform-p">
                            Already have an Octiive account?
                            <vlink v-model="actual"
                                   text="Sign In"
                                   to="/sign-in" />
                        </div>
                    </div>
                    <div v-else-if="this.$route.meta.name==='sign-in'">
                        <!-- SIGN-IN form -->
                        <br>
                        <vform ref="vueForm" :formdata="signIn" :rememberme="''+(+rememberme)" />
                        <!-- END SIGN-in form -->
                        <p v-if="facebookAppId" class="text-center"> or </p>
                        <vfaceBook v-if="facebookAppId" :facebookAppId="facebookAppId"></vfaceBook>
                        
                        <div class="signform-remember">
                            <vcheckbox v-model="rememberme" name="is_agree" adclass="active-blue-text"
                                       text="Remember Me" />
                            <p v-if="!isMobile" class="signform-p">
                                <vlink v-model="actual"
                                       text="Forgot Password?"
                                       to="/forgot-password" />
                            </p>
                        </div>
                        <p v-if="!isMobile"
                           class="signform-p signform-bottom ta-center full-width d-none d-sm-inline-block">
                            Don't have a Octiive account?
                            <vlink v-model="actual"
                                   text="Create one today!"
                                   to="/sign-up" />
                        </p>
                        <template v-else>
                            <div class="signform-bottom">
                                <vlink v-model="actual"
                                       text="Create account"
                                       to="/sign-up" />
                                <vlink v-model="actual"
                                       text="Forgot Password"
                                       to="/forgot-password" />
                            </div>
                        </template>
                    </div>
                    <div v-else-if="this.$route.meta.name==='forgot-password'">
                        <!-- FORGOT PASSWORD form -->
                        <vform ref="vueForm" :formdata="reset" />
                        <!-- END FORGOT PASSWORD -->
                        <div class="signform-p">
                            Find your password?
                            <vlink v-model="actual"
                                   text="Sign In"
                                   to="/sign-in" />
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <popup ref="popup"></popup>
    </div>
</template>

<script>
    import vform from './signform/components/form';
    import vfaceBook from './signform/vfacebook'
    import vcheckbox from './signform/components/vcheckbox'
    import vlink from './signform/components/vlink'
    import popup from '@/components/popup';
    import {mapGetters} from 'vuex';
    import {isMobile} from '../components/mixins/isMobile';

    export default {
        name: "signform-page",
        mixins:[isMobile],

        data() {
            return {
                octiiveHomePage: window.phpSignFormLink || false,
                actual: false,
                facebookAppId: false,
                rememberme: true,

                signUp: {
                    request: '/api/user/register',
                    redirectUrl: false,
                    isFree: false,
                    inputs: [
                        {
                            name: "firstName",
                            displayName: 'first_name',
                            rules: "required|max:255",
                            type: "text",
                            placeholder: 'Enter Your First Name',
                            model: '',
                        },
                        {
                            name: "lastName",
                            displayName: 'last_name',
                            rules: "required|max:255",
                            type: "text",
                            placeholder: "Enter Your Last Name",
                            model: '',
                        },
                        {
                            name: "email",
                            rules: "required|max:255|email",
                            type: "text",
                            placeholder: "Enter Your Email",
                            model: '',
                        },
                        {
                            name: "phone",
                            rules: "max:20|phone",
                            type: "text",
                            placeholder: "Phone Number",
                            model: '',
                        },
                        {
                            name: "password",
                            rules: "required|min:6|max:32",
                            type: "password",
                            placeholder: "Create Password",
                            model: '',
                        },
                        {
                            name: "confirm_password",
                            displayName: "confirm password",
                            rules: "required|min:6|max:32|confirmed:password",
                            type: "password",
                            placeholder: "Create Password",
                            model: '',
                        },
                        {
                            name: 'is_agree',
                            rules: 'required',
                            type: 'checkbox',
                            model: '',
                            cbhtml: ' I agree to the Octiive <a target="_blank" class="vlink" href="//sandbox.mondotunes.com/terms-conditions">Terms &amp; Conditions</a> <span> and </span> <a target="_blank" class="vlink" href="//sandbox.mondotunes.com/privacy-policy">Privacy Policy</a>',
                            errormes: 'You must agree to \'Terms & Conditions and Privacy Policy\' before register.'
                        },
                        {type: 'captcha'}
                    ],
                    submittext: 'Create account',
                    submitClass: ['btn-blue'],
                },
                signIn: {
                    request: 'api/login/process',
                    submittext: 'Sign In',
                    inputs: [
                        {
                            name: "email",
                            rules: "required|max:255|email",
                            type: "text",
                            placeholder: "Enter Your Email",
                            model: '',
                        },
                        {
                            name: "password",
                            rules: "required|min:1|max:32",
                            type: "password",
                            placeholder: "Enter Your Password",
                            model: '',
                        },
                        {
                            type: 'captcha'
                        }
                    ],
                    btn: false,
                    else: false
                },
                reset: {
                    request: '/api/user/new-password',
                    submittext: 'Send new password',
                    submitClass: ['btn-blue'],
                    inputs: [{
                        name: "email",
                        rules: "required|max:255|email",
                        type: "text",
                        placeholder: "Enter Your Email",
                        model: '',
                    }],
                    btn: false,
                    else: false
                },
            
                
                whiteLogo: require('@/assets/img/logo_white.svg'),
                blackLogo: require('@/assets/img/logo_black.svg'),
            }
        },
        mounted() {
            this.$root.popup = this.$refs.popup;
            this.facebookAppId = this.octiiveHomePage.facebookAppId;
            this.actual = this.$route.meta.name;
            this.setFreeSingle();

        },
        updated() {
            this.$refs.vueForm.$refs.observer.reset();
            this.$refs.vueForm.$forceUpdate('force');

        },
        methods: {
            setFreeSingle() {
                if (!this.$route.meta.isFree) {
                    return false;
                }
                this.signUp.isFree = true;
                let routeQuery = this.$route.query;
                if (Object.keys(routeQuery).length) {
                    for (let r in routeQuery) {
                        let input = this.signUp.inputs.find(e => {
                            return e.name === r || e.displayName === r
                        });
                        input.model = routeQuery[r];
                    }
                    this.$router.push('/sign-up/free-single')
                }
            }
        },
        components: {
            vform,
            vfaceBook,
            vcheckbox,
            vlink,
            popup
        },
        computed: {
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
                
            }),
        },
    }
</script>

<style lang="scss">
    .vee-error {
        .tooltipster-content {
            color: $red-f3;
        }
    }
    
    .signform {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        flex-direction: row;
        display: flex;
        min-height: 100vh;
        &-bottom {
            margin-top: torem(130px);
        }
        &-pbody {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1 1 auto;
            padding: 0 torem(15px);
        }
        &-body {
            display: flex;
            width: torem(562px);
            max-width: 100%;
            flex-direction: column;
            margin-bottom: torem(23px);
            form{
                margin-bottom: 0;
            }
            .fb-btn{
                margin-top: 0;
            }
            .text-center{
                margin: torem(15px) 0;
                line-height: 1;
            }
        }
        &-form {
            display: flex;
            flex-direction: column;
        }
        &-h1 {
            font-weight: $fw-extrabold;
            font-size: torem(32px);
            line-height: torem(39px);
            color: $color-13;
            margin-bottom: torem(23px);
        }
        &-holder {
            position: relative;
            margin-bottom: torem(27px);
            display: block;
        }
        &-input {
            input {
                width: 100%;
                border: torem(1px) solid $color-62;
                box-sizing: border-box;
                border-radius: torem(4px);
                padding: 0 torem(15px);
                font-weight: $fw-medium;
                font-size: torem(17px);
                line-height: torem(21px);
                display: flex;
                align-items: center;
                color: $blue-1f;
                height: torem(46px);
                vertical-align: center;
                outline: none !important;
                &::placeholder {
                    color: $grey-ba;
                    opacity: 1;
                }
                &:focus {
                    border: torem(1px) solid $blue-18;
                }
                &.error {
                    border-color: $red-f3;
                }
            }
        }
        &-iconholder {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            padding-right: torem(27px);
            border: none;
            display: flex;
            height: torem(18px);
            margin: auto;
            .tooltipster {
                color: $errofontColor;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: torem(12px);
                .ficon {
                    color: $errofontColor;
                    &:before {
                        margin: 0;
                    }
                }
            }
        }
        &-error--input {
            width: torem(23px) !important;
            
        }
        &-showpassword {
            margin: auto;
            background-color: transparent;
            font-size: torem(16px);
            border: none;;
            outline: none !important;
            &.active {
                opacity: .3;
            }
        }
        &-checkbox {
            display: block;
            margin-bottom: torem(24px);
            line-height: torem(24px);
            vertical-align: middle;
        }
        &-p {
            
            vertical-align: middle;
            display: inline-block;
            color: $color-62;
            
            font-style: normal;
            font-weight: $fw-medium;
            font-size: torem(17px);
            line-height: torem(25px);
            
        }
        &-btn {
            margin-top: torem(5px);
            width: 100%;
            padding: torem(10px) 0;
            &:disabled {
                opacity: .7;
            }
        }
        &-error {
            display: inline-block;
            background: rgba(231, 76, 60, 0.9);
            padding: torem(5px) torem(15px);
            width: 100%;
            border-radius: torem(15px);
            color: #fff;
            &--input {
                display: block;
                max-width: 89%;
                width: auto;
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
                margin-left: torem(24px);
            }
        }
        &-bgimg {
            position: relative;
            flex: 1 1 45.2%;
            max-width: 45.2%;
            background: url(../assets/img/SiginImg.png) 0 0/cover no-repeat;
        }
        &-logo {
            display: inline-block;
            margin: torem(50px);
            .img-fluid {
                width: torem(92px);
            }
        }
        &-remember {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: torem(20px) 0;
            input {
                margin-right: 5px;
                
            }
            p {
                margin-left: auto;
            }
        }
        .label {
            font-style: normal;
            font-weight: $fw-semibold;
            font-size: torem(20px);
            line-height: torem(24px);
            display: flex;
            align-items: center;
            color: $color-13;
            margin-bottom: torem(10px);
        }
        .text-center {
            font-style: normal;
            font-weight: $fw-medium;
            font-size: torem(18px);
            color: $color-62;
        }
        
        
    }
    
    div#confirm_password.signform-holder {
        &, .signform-holder {
            margin-bottom: torem(14px);
        }
    }
    @include media-breakpoint-down(sm) {
        .signform {
            padding-left: torem(15px);
            padding-right: torem(15px);
            .full-width {
                width: auto;
                flex: 1 1 auto;
            }
            &-pbody{
                padding-top: torem(50px);
                padding-bottom: torem(50px);
            }
            &-body {
                max-width: torem(390px);
                margin-bottom: auto;
                .checkbox{
                    align-items: flex-start;
                    &:before{
                        margin-top: torem(2px);
                    }
                }
            }
            &-h1 {
                font-size: torem(50px);
                line-height: torem(61px);
            }
            &-tablelogo {
                display: block;
                margin: auto;
                margin-bottom: torem(20px);
                margin-left: auto;
                width: torem(390px);
                img {
                    max-width: torem(143px)
                }
            }
            &-bottom {
                margin: auto;
                margin-top: torem(32px);
                a {
                    margin-right: torem(54px);
                }
            }
            &-remember {
                justify-content: flex-start;
            }
            &-holder {
                margin-bottom: torem(22px);
            }
            &-p{
                margin-top: torem(14px);
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .signform {
            &.container {
                padding-left: 0;
                padding-right: 0;
                max-width: 100%;
            }
            &-body {
                max-width: 100%;
                width: torem(335px);
                
            }
            &-pbody {
                max-width: 100%;
                overflow: hidden;
                padding-top: torem(41px);
                border-top-left-radius: torem(30px);
                border-top-right-radius: torem(30px);
                background-color: $white;
                z-index: 1;
    
                margin: torem(-50px) auto 0;
                height: auto;
                flex: 0 0 auto;
                width: 100%;
            }
            &-logo {
                width: torem(300px);
                margin-left: torem(20px);
                margin-top: torem(20px);
            }
            &-remember {
                flex-direction: column;
                p {
                    margin: auto;
                }
            }
            &-remember &-p {
                margin-top: torem(20px);
            }
            &-bgimg {
                flex: 1 1 auto;
                max-width: 100%;
                display: flex;
                flex-direction: column;
                padding-bottom: torem(64px);
                width: 100%;
            }
            
            &-h1 {
                margin-top: auto;
                margin-left: torem(20px);
                margin-bottom: auto;
                color: $white;
                width: torem(335px);
                max-width: 100%;
                font-size: torem(40px);
                line-height: torem(49px);
            }
            .label, &-input input {
                font-size: torem(18px);
            }
            &-input input {
                height: torem(38px);
            }
            &-showpassword,
            &-iconholder .tooltipster .ficon {
                font-size: torem(17px);
            }
            &-iconholder {
                padding-right: torem(17px);
            }
            .text-center {
                line-height: torem(17px);
            }
            &-btn.btn, .fb-btn {
                font-size: torem(24px);
                padding-left: 0;
                padding-right: 0;
                height: torem(56px);
            }
            &-remember {
                .checkbox {
                    margin-right: auto;
                }
            }
            &-bottom{
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                a{
                    margin: 0;
                }
            }
            .full-width{
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                max-width: 100%;
                margin: 0;
                width: 100%;
                flex-wrap: nowrap;
            }
        }
    }

</style>