<template>
  <div class="audio">
    <p class="audio-title">
      {{title}}
    </p>
    <audio ref="audio" style="display: none" controls preload='none'>
      <source :src="src" type='audio/mp3' />
    </audio>
    <div class="audio-player">
      <button class="audio-btn" @click="playPause()">
        <i class="ficon icon-play" v-if="!play"></i>
        <i class="ficon icon-pause" v-else></i>
      </button>
      <div class="audio-progress">
        <div class="audio-progressLine">
          <div class="audio-trackLine">
            <div ref="thumbLine" class="audio-thumbLine"></div>
          </div>
          <div ref="thumb" class="audio-thumb"></div>
        </div>
        <input
          type="range"
          class="audio-trackBar"
          ref="trackBar"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['src', 'title'],
    name: "mastering-audio",
    data() {
      return {
        play: false
      }
    },
    mounted() {
      let trackBar = this.$refs.trackBar;
      this.$refs.audio.ontimeupdate = this.timeUpdate;
      this.$refs.audio.onloadedmetadata = function (e) {
        trackBar.disabled = false;
      };
      this.$refs.audio.onended = this.playPause;
      trackBar.oninput = this.onChangeTrackBar;
      trackBar.value = 0;
      trackBar.disabled = true;
    },
    methods: {
      timeUpdate() {
        this.thumTransfer();
      },
      getTimeToCoordinates() {
        return +((100 / this.$refs.audio.duration) * this.$refs.audio.currentTime).toFixed(0)
      },
      thumTransfer() {
        let coord = this.getTimeToCoordinates();
        this.$refs.trackBar.value = coord;
        this.$refs.thumb.style.left = coord + '%';
        this.$refs.thumbLine.style.width = coord + '%';
      },
      onChangeTrackBar() {
        this.$refs.audio.currentTime = (+this.$refs.trackBar.value / 100) * this.$refs.audio.duration
      },
      playPause() {
        if (this.play) {
          this.onPause();
          return
        }
        this.onPlay();
      },
      onPause(){
          this.play = false;
          this.$refs.audio.pause();
          this.$emit('pause',this);
      },
      onPlay(){
          this.$refs.audio.play();
          this.play = true;
          this.$emit('play',this);
      }
    }
  }
</script>

<style lang="scss">
  .audio {
    display: flex;
    flex-direction: column;
    margin-top: torem(28px);
    margin-bottom: torem(28px);
    &-player {
      display: flex;
      
      align-items: center;
      width: 100%;
    }
    &-title {
      font-weight: $fw-bold;
      font-size: torem(18px);
      line-height: torem(22px);
      display: flex;
      align-items: center;
      color: $mainfont;
      padding-left: torem(50px);
    }
    &-progress {
      position: relative;
      box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
      border-radius: torem(4px);
      height: torem(8px);
      flex: 1 1 auto;
      padding: 0 torem(9px);
      background: rgba(172, 189, 203, .6);
    }
    &-trackLine {
      position: relative;
      overflow: hidden;
      margin-left: torem(-9px);
      margin-right: torem(-9px);
      /*width: 100%;*/
      height: 100%;
      border-radius: torem(4px);
    }
    &-progressLine{
      position: relative;
      width: 100%;
      height: 100%;
    }
    &-thumb {
      border: torem(1.5px) solid $blue-18;
      box-sizing: border-box;
      box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
      border-radius: 50%;
      width: torem(18px);
      height: torem(18px);
      margin-top: torem(-5px);
      margin-bottom: torem(-5px);
      background: $white;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin-left: torem(-9px);
    }
    &-trackBar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      background: transparent;
      -webkit-appearance: none;
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: torem(18px);
        height: torem(12px);
        
       
      }
      &::-webkit-slider-runnable-track {
        background: transparent;
        height: torem(8px);
      }
      &::-ms-thumb, &::-moz-range-thumb {
        width: torem(18px);
        height: torem(12px);
      
      }
    }
    &-thumbLine {
      background-color: $blue-18;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 100%;
      transition: right .3s;
    }
    &-btn {
      padding: 0;
      outline: none !important;
      width: torem(36px);
      height: torem(36px);
      border-radius: 50%;
      overflow: hidden;
      box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
      flex: 0 0 torem(36px);
      margin-right: torem(14px);
      border: none;
      background: $white;
      i {
        margin: 0;
        display: block;
        height: torem(20px);
        &:before {
          color: $blue-18;
          font-size: torem(20px);
          margin: 0;
        }
        &.icon-play {
          margin-left: torem(3px);
        }
      }
    }
  }
</style>