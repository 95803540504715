import Backbone from 'backbone';
import ParticipatorModel from '../models/participator';
import ParticipatorType from '../models/participator/type';

export default Backbone.Collection.extend({
    model: ParticipatorModel,
    url: '/api/user/participators',
    initialize: function () {
        //this.listenTo(this, 'remove', this.onRemove);
    },
    onRemove: function (model, collection, options) {
        var self = this;
        if (options.xhr) {
            options.xhr.success(function (response) {
                // check if model was actually removed
                if (response.dry) {
                    self.add(model); // get it back
                }
            });
        }
    },
    getParticipatorsByType: function (type) {
      if (Array.isArray(type)) {
        // Filter models where 'type' is included in the array
        return this.filter(function (model) {
          return type.includes(model.get('type'));
        });
      } else {
        // If type is a single value, use where
        return this.where({ type: type });
      }
    },
    /*
    * Return new participators List by types*/
    getParticipatorsByTypes: function (types) {
        let res = [];
        for (var i=0; i<types.length; i++ ){
            res = res.concat(this.getParticipatorsByType(types[i]));
        }
        return res
    },
    getMainParticipators: function () {
        var mainArtists = this.getParticipatorsByType(ParticipatorType.MAIN_ARTIST);

        return mainArtists.concat(this.getParticipatorsByType(ParticipatorType.LEGACY_MAIN_ARTIST));
    },
    getFeaturingParticipators: function () {
        return this.getParticipatorsByType(ParticipatorType.FEATURING);
    },
    getRemixerParticipators: function () {
        return this.getParticipatorsByType(ParticipatorType.REMIXER);
    },
    getWriterParticipators: function () {
        return this.getParticipatorsByType(ParticipatorType.WRITER);
    },
    getComposerParticipators: function () {
        return this.getParticipatorsByType(ParticipatorType.COMPOSER);
    },
    getPublisherParticipators: function () {
        return this.getParticipatorsByType(ParticipatorType.PUBLISHER);
    },
    getLegacyParticipators: function () {
        return this.getParticipatorsByType(ParticipatorType.LEGACY_MAIN_ARTIST);
    },
    getPayPerProjectParticipators: function () {
        return this.getParticipatorsByType(ParticipatorType.PAY_PER_PROJECT_ARTIST);
    },
    getEngineerParticipators: function () {
      return this.getParticipatorsByType([
        ParticipatorType.ENGINEER_PRODUCER,
        ParticipatorType.ENGINEER_CO_PRODUCER,
        ParticipatorType.ENGINEER_ENGINEER,
        ParticipatorType.ENGINEER_EXECUTIVE_PRODUCER,
        ParticipatorType.ENGINEER_MASTERING_ENGINEER,
        ParticipatorType.ENGINEER_MIXING_ENGINEER,
      ]);
    },
    getPerformerParticipators: function () {
      return this.getParticipatorsByType([
        ParticipatorType.PERFORMER_BAND,
        ParticipatorType.PERFORMER_CHOIR,
        ParticipatorType.PERFORMER_DJ,
        ParticipatorType.PERFORMER_MC,
        ParticipatorType.PERFORMER_ORCHESTRA,
      ]);
    },
    getLyricistParticipators: function () {
      return this.getParticipatorsByType(ParticipatorType.LYRICIST);
    },
    comparator: 'type'
});

