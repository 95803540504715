<template>
  <div class="switcher" >
    <template v-for="(item,index) in itemList">
      <button
          :key="index"
          :class="['switcher-item',item.class,{'active':value === index}]"
          @click="onClick(item,index)"
          :disabled="value === index"
      >
        {{item.text}}
      </button>
      <div v-if="index == 0" class="switcher-decor d-sm-none"
           :class="{'switcher-decor--switch': value != index}"
           @click="decorClick"
      >
      
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    name: "switcher",
    props:['itemList','value'],
    methods:{
        decorClick(){
            const index = this.value? 0 : 1;
            this.onClick(this.itemList[index],index);
        },
      onClick(item,index){
        this.$emit('click',item);
        this.$emit('input',index);
        this.$router.push(item.link);
      }
    }
  }
</script>

<style lang="scss">
  .switcher {
    display: flex;
    margin-bottom: torem(55px);
    &-item {
      background: none;
      border: none;
      box-shadow: none;
    
      padding: 0;
      font-weight: $fw-extrabold;
      font-size: torem(28px);
      line-height: torem(34px);
    
      outline: none !important;
      display: flex;
      align-items: center;
      color: $color-ac;
    
      position: relative;
      margin: 0 torem(12px);
    
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:after {
        position: absolute;
        content: '';
        top: 100%;
        left: 0;
        right: 0;
        margin-top: torem(4px);
        display: none;
        border: torem(1px) solid $blue-18;
        border-radius: torem(4px);
        height: torem(4px);
        background-color: $blue-18;
      }
      &.active {
      
        color: $blue-18;
        &:after {
          display: block;
        }
      }
    }
    &-empty {
      width: 100%;
      @include obj;
      @include coner;
      display: flex;
      /*height: 100vh;*/
      max-height: torem(300px);
      justify-content: center;
      align-items: center;
      font-size: torem(32px);
      color: $color-ac;
      font-weight: $fw-700;
      box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
      background: $white;
    }
    &-decor{
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    .switcher {
      margin-bottom: torem(34px);
      &-item{
        font-size: torem(22px);
        line-height: torem(27px);
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .switcher {
      &-item{
        font-size: torem(16px);
        line-height: torem(20px);
        white-space: nowrap;
        margin: auto;
        font-weight: $fw-600;
        &:after,&.active:after{
          display: none;
        }
      }
      &-decor{
        display: block;
        width: torem(61px);
        border: torem(1px) solid $color-ac;
        box-sizing: border-box;
        border-radius: torem(30px);
        margin: 0 auto;
        padding: torem(2px) torem(3px);
        &:before{
          content: '';
          display: block;
          $size:torem(23px);
          position: relative;
          width: $size;
          height: $size;
          background-color: $mainfont;
          border-radius: 50%;
          overflow: hidden;
          margin-left: 0;
          transition: margin-left .3s;
        }
        &--switch{
          &:before{
            margin-left: torem(30px);
          }
        }
      }
    }
  }
</style>