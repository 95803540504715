import Backbone from 'backbone'

export default Backbone.Model.extend({
  getItemsPerPage: function () {
    return this.get('itemsPerPage');
  },
  getItemsTotal: function () {
    return this.get('itemsTotal');
  },
  getPage: function () {
    return this.get('page');
  },
  getTotalPages: function () {
    return Math.ceil(this.getItemsTotal() / this.getItemsPerPage());
  }
});
