import User from '@/components/models/user.js';

export default {
    state: {
        userId: null,
        loadingPromise: false,
    },
    getters: {
        user(state) {
            if (!state.userId) {
                return null;
            }
            return Backbone.Relational.store.find(User, state.userId);
        },
    },
    mutations: {
        setUserId(state, userId) {
            state.userId = userId;
            state.loadingPromise = false;
        },
        startLoading(state, promise) {
            state.loadingPromise = promise;
        },
    },
    actions: {
        async loadUser(store) {
            if (store.state.userId) {
                return store.getters.user;
            }

            if (store.state.loadingPromise) {
                return store.state.loadingPromise;
            }

            let user = new User();
            let loadingPromise = user.loadCurrent().then(function (data) {
                store.commit('setUserId', data.id);

                return store.getters.user;
            });
            store.commit('startLoading', loadingPromise);

            return loadingPromise;
        },
        async relogin(store, userId) {
            if (store.state.userId != userId) {
                console.log('same user');

                return store.getters.user;
            }
            if (store.state.userId) {
                store.commit('setUserId', null);
            }

            return store.dispatch('loadUser');
        }
    }
}

