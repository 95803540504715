import Backbone from 'backbone'
import BioQuestionnaireModel from '../../models/marketing/bio-questionnaire'
import PaginationMetaModel from '../../models/pagination/meta'

export default Backbone.Collection.extend({
  meta: new PaginationMetaModel(),
  model: BioQuestionnaireModel,
  url: '/api/marketing/bio/questionnaires',
  parse: function (response) {
    this.meta.set(response.meta);

    return response.data;
  },
  getMeta: function () {
    return this.meta;
  }
});
