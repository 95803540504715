import cart from '../cart/cart'
import checkout from '../cart/checkout-page'
import emailConfirmation from '../email-confirmation-page'
import payPalPayment from '../cart/cart-paypal-success'
import distribute from '../projects/distribute'
import editProjects from '../projects/project-edit'
import selectDsp from '../projects/select-dsp'
import home from '../dashboard/home';
import plans from '../plans/plans-and-pricing-page'
import projects from '../projects/project-table'
import projectsTable from '../projects/components/table'
import reports from '../reports/reports'
import regLabel from '../reglabel/register-label'
import services from '../services/services';
import settings from '../settings/settings'
import sigInPage from '../signform_page'
import marketing from '../marketing/marketing-page'
import page from '../marketing/components/mondoblast-page'
import mondoBlastLoop from '../marketing/components/mondoblast-loop'
import bioLoop from '../marketing/components/questionnaires-loop'
import mondoBlastShow from '../marketing/components/mondoblast-show'
import mondoBlastEdit from '../marketing/components/mondoblast-edit'
import bioShow from '../marketing/components/questionnaires-show'
import bioEdit from '../marketing/components/questionnaires-edit'
import userOrders from '../userOrders/userorders-page'
import coverBuilder from '../cover-builder/coverbuilder-page'
import moneyReports from '../money-reports/money-reports-page'
import moneyRequest from '../money-reports/components/money-requests'
import royaltyReports from '../money-reports/components/royalty-reports'
import NotFound from '../404/404'

export const routes = [
    {
        path: '/404',
        name: '404',
        component: NotFound,
    }, {
        path: '*',
        component: NotFound,
    },
    {
        path: '/', component: home,
    },
    {
        path: '/home', component: home,
    },
    {
        path: '/home/label', component: regLabel
    },
    {
        path: '/marketing', component: marketing,
        children: [
            {
                path: 'prblast', component: page,
                children: [
                    {
                        path: '', component: mondoBlastLoop, meta: {name: 'prblast'},
                    },
                    {
                        path: ':id/show', component: mondoBlastShow, meta: {name: 'prblast', action: 'show'},
                    },
                    {
                        path: ':id/edit', component: mondoBlastEdit, meta: {name: 'prblast', action: 'edit'},
                    },
                ]
            },
            {
                path: 'bio', component: page,
                children: [
                    {
                        path: '', component: bioLoop, meta: {name: 'bio'},
                    },
                    {
                        path: ':id/show', component: bioShow, meta: {name: 'bio', action: 'show'},
                    },
                    {
                        path: ':id/edit', component: bioEdit, meta: {name: 'bio', action: 'edit'},
                    },
                ]
            },
        ]
    },
    {
        path: '/dashboard', component: home,
    },
    {
        path: '/project', component: projects,
        children: [
            {
                path: 'list', component: projectsTable, name: 'etable'
            },
            {
                path: 'create', component: editProjects, name: 'create-project'
            },
            {
                path: 'freesingle', component: editProjects, name: 'free'
            },
            {
                path: ':id/edit', component: editProjects, name: 'edit-project', props: true,
            },
            {
                path: ':id/distribute', component: distribute, props: true,
            },
            {
                path: ':id/select-dsp', component: selectDsp, props: true,
            },
            {
                path: ':id/cover-builder', component: coverBuilder
            },
            {
                path: 'cover-builder', component: coverBuilder
            },
        ]
    },

    {
        path: '/user/settings', component: settings
    },
    {
        path: '/user/ordersList', component: userOrders
    },
    {
        path: '/user/money-reports', component: moneyReports,
        children: [
            {
                path: 'royalty-reports', component: royaltyReports, name: 'royaltyReports'
            },
            {
                path: 'money-requests', component: moneyRequest, name: 'moneyRequest'
            }
        ]
    },
    {
        path: '/cart/payment/success', component: payPalPayment
    },
    {
        path: '/cart/payment/fail', component: payPalPayment
    },
    {
        path: '/services', component: services,
    },
    {
        path: '/services/bio', component: services, name: 'bio'
    },
    {
        path: '/services/prblast', component: services, name: 'prblast'
    },
    {
        path: '/services/mastering', component: services, name: 'mastering'
    },
    {
        path: '/analytics', component: reports,
    },
    {
        path: '/sign-in', component: sigInPage, meta: {name: 'sign-in'},
    },
    {
        path: '/sign-up', component: sigInPage, meta: {name: 'sign-up'},
        children: [
            {
                path: 'free-single/:first_name/:last_name/:email',
                component: sigInPage,
                meta: {isFree: true, name: 'sign-up'}
            },
            {
                path: 'free-single', component: sigInPage, meta: {isFree: true, name: 'sign-up'}
            }
        ]
    },
    {
        path: '/forgot-password', component: sigInPage, meta: {name: 'forgot-password'},
    },

    {
        path: '/cart', component: cart
    },
    {
        path: '/plans', component: plans
    },
    {
        path: '/cart/checkout', component: checkout
    },
    {
        path: '/confirmation/:type/:token',
        component: emailConfirmation,
    }

];
