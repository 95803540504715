import Backbone from 'backbone';
import _ from 'underscore';
import ProductModel from '../product';
import ProductsCollection from '../../collections/products';
import helperFactory from './item/helper.factory';
import SubscriptionAgreementModel from './../subscription/subscription-agreement';
import 'backbone-relational/backbone-relational';

export default Backbone.RelationalModel.extend({
    relations: [
        {
            type: Backbone.HasOne,
            key: 'product',
            relatedModel: ProductModel,
            collectionType: ProductsCollection
        },
        {
            type: Backbone.HasOne,
            key: 'subscriptionAgreement',
            relatedModel: SubscriptionAgreementModel
        }

    ],
    urlRoot: '/api/cart/item',
    productId: null,
    defaults: {
        subscriptionAgreement: new SubscriptionAgreementModel(),
        price: 0.00,
        discountAmount: 0.00,
        product: null,
        productAttributes: []
    },
    initialize: function () {
        /**
         * Hack:
         * We use event `remove` and need `productId`
         * `backbone-relational` remove related model `product`
         * and on event `remove` we cannot get `productId`
         */
        this.productId = this.getProduct().getId();
    },
    getId: function () {
        return this.get('id');
    },
    getProduct: function () {
        return this.get('product');
    },
    getProductId: function () {
        return this.productId;
    },
    getProductAttributes: function () {
        return this.get('productAttributes');
    },
    setProductAttributes: function (attributes) {
        return this.set('productAttributes', attributes);
    },
    getTitle: function () {
        return this.getHelper().getTitle();
    },
    getPrice: function () {
        return this.getHelper().getPrice();
    },
    getDiscountAmount: function () {
        return this.getHelper().getDiscountAmount();
    },
    getSalePrice: function () {
        return this.get('price');
    },
    getSaleDiscountAmount: function () {
        return this.get('discountAmount');
    },
    getInitialAmount() {
        return this.get('subscriptionAgreement').get('initialAmount');
    },
    getRecurringAmount() {
        return this.get('subscriptionAgreement').get('recurringAmount');
    },

    getRecurring: function () {
        return this.getProduct().getRecurringString();
    },
    getSubscriptionAgreement: function () {
        return this.get('subscriptionAgreement');
    },
    hasProratedAmount: function () {
        return this.getSubscriptionAgreement().hasProratedAmount();
    },
    isSubscription: function () {
        return this.getProduct().isRecurring();
    },
    isEqualAttributes: function (attributes) {
        var currentAttrs = this.getProductAttributes();

        return _.isEqual(attributes, currentAttrs);
    },
    getHelper: function () {
        var CartItemHelper;

        if (typeof this.helper == 'undefined') {
            this.helper = null;
            CartItemHelper = helperFactory.get(this.getProduct().getType());
            if (CartItemHelper) {
                this.helper = new CartItemHelper(this);
            }
        }

        return this.helper;
    },
    getSubPrice: function () {
        if (this.isSubscription()) {
            return this.getSubscriptionAgreement().getInitialAmountFloat();
        }

        return this.getPrice();
    },
    toJSON: function () {
        return {
            productId: this.getProductId(),
            productAttributes: this.getProductAttributes()
        };
    },
    isAddonFor(project, type) {
        let cartItem = this,
            productAttributesId = cartItem.get('productAttributes').project_id;

        return (type == null || (cartItem.getProduct() && cartItem.getProduct().getType() == type))
            && productAttributesId == project.get('id');
    }
});
