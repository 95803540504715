<template>
  <div class="toggleItem" :class="{'active':value}" slot="title">
    <p @click="$emit('input',!value)">{{buttonText}}<i class="ficon icon-plus"></i></p>
  </div>
</template>

<script>
  export default {
    props: {
      buttonText: '',
      value: {
        type: Boolean,
        default: '',
      },
    },
    name: "togglerItem"
  }
</script>

<style lang="scss">
  .toggleItem {
    font-weight: $fw-semibold;
    font-size: torem(20px);
    line-height: torem(24px);
    display: flex;
    align-items: center;
    color: $color-ac;
    p {
      display: flex;
      align-items: center;
    }
    &.active {
      color: $mainfont;
    }
    .ficon {
      color: $black;
      font-size: torem(14px);
      cursor: pointer;
      display: flex;
      align-content: center;
    }
    &.active .ficon:before {
      content: '\e814';
      font-size: torem(4px);
      width: torem(19px);
    }
  }
  @include media-breakpoint-down(sm) {
    .toggleItem p{
      font-size: torem(18px);
    }
  }
</style>