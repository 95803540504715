<template>
    <ValidationObserver
        tag="form" class="dnd dnd--resubmission"
        ref="observer"
        v-slot="{ invalid }"
        @submit.prevent=""
    >
        <div class="coverArt" v-if="coverArtPreview">
            <img :src="coverArtPreview" alt="cover art">
            <i @click="$emit('deleteCover')"
               class="coverArt-remove ficon icon-plus router-link-active" v-if="!coverArtProgress"></i>
        </div>
        <ValidationProvider
            name="CoverArt"
            class="edit-project-dndHolder"
            tag="div"
            v-slot="{errors, classes}"
            rules="required">
            <DragAndDrop
                :disabled="!!coverArtProgress"
                :text="isMobile?'Cover':'Drag & Drop cover art here, or&nbsp;'"
                @upload="$emit('onFileAdded',$event)"
                @imageOnloadEnd="$emit('placeCoverArt',$event)"
                :shape="true"
                accept="image/*"
                :customClass="{'error':classes.invalid}"
            />
            <div ref="progress" class="dnd-resubmition-progress" v-if="!!isinprogress"></div>
        </ValidationProvider>
    </ValidationObserver>
</template>

<script>
    import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
    import DragAndDrop from './drag-and-drop';
    import {mapGetters} from 'vuex';
    import {isMobile} from '../../../components/mixins/isMobile'

    export default {
        name: "resubmission-form",
        props: ['coverArtPreview', 'coverArtProgress'],
        mixins: [isMobile],
        components: {
            ValidationProvider,
            ValidationObserver,
            DragAndDrop,
        },
        computed: {
            ...mapGetters({
                winRes: 'resolution/get',
                sizeTab: 'resolution/getSizeTable',
            }),
            isinprogress() {
                if (this.$refs.progress) {
                    this.$refs.progress.style.width = this.coverArtProgress ? this.coverArtProgress + '%' : this.coverArtProgress;
                }
                return this.coverArtProgress
            }
        }
    }
</script>

<style lang="scss">
    .dnd {
        &--resubmission {
            display: flex;
            .coverArt {
                margin-top: torem(10px);
                margin-bottom: torem(10px);
            }
            .edit-project-dndHolder {
                flex: 1 1 auto;
                max-width: 100%;
            }
            .dnd-area {
                flex: 1 1 auto;
                max-width: 100%;
            }
            .dnd-resubmition-progress {
                position: absolute;
                width: 100%;
                transition: width .3s;
                left: 0;
                bottom: 0;
                height: torem(6px);
                background-color: $orange-ff;
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        button.btn.edit-project-resubmission {
            margin-left: auto;
            margin-right: auto;
        }
        .dnd--resubmission {
            .coverArt {
                margin-top: 0;
                margin-bottom: 0;
            }
            
        }
        .resubmission {
            &-info {
                flex-wrap: wrap;
                &--p {
                    flex: 1 1 100%;
                    margin: torem(20px) 0;
                    span{
                        margin-top: torem(10px)!important;
                    }
                }
            }
        }
    }
</style>