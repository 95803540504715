<template>
    <ValidationObserver
        ref="observer"
        class="sform"
        tag="form"
        @submit.prevent=""
        @keydown.enter = "onClick()"
        v-slot="{ invalid }">
        <div class="row">
            <!--firstName-->
            <div class="col-12">
                <vinput
                    ref="payoneerEmail"
                    class="sform-input"
                    key="Email"
                    :value="payoneerEmail"
                    :ttInfoOptions="{contentAsHTML:true}"
                    title="Email"
                    VPRules="required|email"
                    inputType="text"
                    inputDisplayName="Payoneer Email"
                    inputName="payoneerEmail"
                    @input="onInput($event, $refs.payoneerEmail, payment)"
                />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button ref="btn" class="btn sform-btn" :disabled="(isOldValues && isOldMethod) || invalid" @click="savePaymentInfo">
                    Save payment Info
                </button>
            </div>
        </div>
    </ValidationObserver>
</template>


<script>
    import vinput from '@/components/input-tt';
    import {ValidationProvider, ValidationObserver} from 'vee-validate';
    import {onInput} from '../mixins/onInput';
    import {pay} from '../mixins/paymentMixins';
    export default {
        name: "payoneer-form",
        props: ['payment', 'paymentMethod'],
        mixins: [onInput, pay],
        data() {
            return {
                payoneerEmail:null,
                isOldValues:true,
            }
        },
        mounted(){
            this.payoneerEmail = this.payment.get('payoneerEmail');
        },
     
        components:{
            ValidationProvider,
            ValidationObserver,
            vinput
        }
    }
</script>

