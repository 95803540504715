import ProductType from '@/components/models/product/type';
import BaseHelper from './helper/base';
import RadioHelper from './helper/radio';
import MasteringHelper from './helper/mastering';

var ProductHelperFactory = function () {
};

ProductHelperFactory.prototype.get = function (productType) {
    switch (productType) {
        case ProductType.RADIO:
            return RadioHelper;
        case ProductType.MASTERING:
            return MasteringHelper;
    }

    return BaseHelper;
};
export default new ProductHelperFactory();

