export default function (cartItem) {
  this.getPrice = function () {
    let attributes = cartItem.getProductAttributes() || {};
    let tracksCount = (attributes.tracks_count ? parseInt(attributes.tracks_count) : 0);
    let price = cartItem.getSalePrice() * tracksCount;

    return parseFloat(price.toFixed(2));
  };

  this.getDiscountAmount = function () {
    let attributes = cartItem.getProductAttributes() || {};
    let tracksCount = (attributes.tracks_count ? parseInt(attributes.tracks_count) : 0);
    let discountAmount = cartItem.getSaleDiscountAmount() * tracksCount;
    return parseFloat(discountAmount.toFixed(2));
  };

  /**
   * Format cart item title for
   * mastering product type
   *
   * @returns {string}
   * @example "(1 track)"
   */
  this.getTitle = function () {
    let title = cartItem.getProduct().getTitle(),
      tracksCount = cartItem.getProductAttributes().tracks_count;

    return title + " (" + tracksCount + (tracksCount == 1 ? " track" : " tracks") + ")";
  };
};
