import axios from 'axios';

export default {
    state: {
        langUrl: '/api/languages',
        language: false,
    },
    mutations: {
        set(state, data) {
            state.item = data;
        },
        setLanguage(state, data){
            state.language = data;
        },
    },
    getters: {
        get: function (state) {
            return state.list;
        },

        getLanguage: function (state) {
            return state.language;
        },
    },
    actions: {
        async load(store) {
            /*get all projects*/
            if (store.state.item) {
                return true;
            }
           /* await axios.get(store.state.url).then(function (e) {
                store.commit("set", e.data);
                return true;
            }).catch(error => {
                return {error: error.response};
            });*/
        },
        async reqLang(store) {
            if (store.state.language) {
                return store.state.language;
            }
            return axios.get(store.state.langUrl).then(e => {
                store.commit('setLanguage', e.data);

                return e.data;
            });
        },
    },
};