<template>
    <div class="container d-flex flex-column  align-items-stretch">
        <div class="row">
            <div class="col-12 ">
                <h1 class="main-h1">
                    My Cart
                </h1>
            </div>
        </div>
        <div class="row flex-fill ">
            <div class="col-12 flex-fill d-flex flex-column  align-items-stretch ">
                <div class="cart flex-fill">
                    <div class="cart-bg">
                        <img src="../../assets/img/gitars.png" alt="">
                    </div>
                    <div class="cart-cont" v-if="cart && cart.attributes.cartItems.length ">
                        <h1 class="cart-h1 main-h1">
                            Order
                        </h1>
                        <div class="cart-list">
                            <cartList
                                @click="$router.push('cart/checkout')"
                                :buttonText="'Checkout'"
                                :remove="true"
                                :cart="cart" />
                        </div>
                    </div>
                    <div class="cart-cont cart-cont--empty posr" v-else-if="!cart">
                        <random-loader class="loader--poss" :substrate="false" :randomText="true" />
                    </div>
                    
                    <div class="cart-cont cart-cont--empty" v-else-if="!cart.attributes.cartItems.length">
                        <p class="cart-empty-h1">Your cart is empty</p>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    import inputBtn from '@/components/inputBtn';
    import Products from '@/components/collections/products'
    import cartList from './components/cart-item-list'
    import randomLoader from '@/components/random-loader';
    /*import Product from '@/components/models/product'*/

    export default {
        name: "cart",
        data() {
            return {
                cart: false,
                products: false,
            }
        },
        async mounted() {
            // todo: import productList and sort them by group
            let self = this;
            this.$store.dispatch('cart/loadCart').then(cart => {
                this.cart = cart;
            });
        },
        methods: {
            addPromo(value) {
                this.cart.addPromo(value);
            }
        },
        components: {
            inputBtn,
            cartList,
            randomLoader,
        }
    }
</script>

<style lang="scss">
    .cart {
        @include obj;
        @include coner;
        box-shadow: torem(4px) torem(4px) torem(21px) $objshadow;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        padding: 0;
        overflow: hidden;
        &-hr {
            opacity: 0.4;
            border-top: torem(2px) solid $blue-18;
            margin: torem(58px) 0 torem(33px) 0;
        }
        &-bg {
            max-width: 43%;
            width: 100%;
            flex: 1 1 43%;
            min-height: torem(614px);
            position: relative;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                min-width: 100%;
                min-height: 100%;
            }
        }
        &-cont {
            flex: 1 1 auto;
            padding: torem(50px) torem(115px);
            
        }
        &-item {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            margin: torem(28px) 0;
            .title, .price, .remove {
                flex: 1 1 auto;
                padding: 0 torem(10px);
                align-items: center;
                font-size: torem(27px);
                color: $mainfont;
                font-weight: $fw-semibold;
                p {
                    margin: 0;
                }
            }
            .title {
                padding-left: 0;
            }
            .price {
                margin-left: auto;
                text-align: right;
            }
            .remove {
                $size: torem(18px);
                font-size: $size;
                color: $red-f3;
                width: $size;
                height: $size;
                flex: 0 0 torem(28px);
                margin: 0;
                background-color: transparent;
                border: none;
                box-shadow: none;
                display: flex;
                padding: 0 0 0 torem(12px);
                &:before {
                    margin: 0;
                }
                &:disabled {
                    cursor: pointer;
                    opacity: .5;
                }
            }
            &--subs {
                margin: torem(47px) 0;
                .title, .price {
                    p {
                        
                        color: $blue-18;
                    }
                    
                }
            }
        }
        &-remove {
            outline: none !important;
        }
        &-total {
            display: flex;
            &--text {
                flex: 1 1 auto;
                p {
                    font-weight: $fw-extrabold;
                    font-size: torem(32px);
                    line-height: torem(39px);
                    display: flex;
                    align-items: center;
                    color: $mainfont;
                }
            }
            &--price {
                flex: 0 0 auto;
                margin-left: auto;
                p {
                    font-weight: $fw-semibold;
                    font-size: torem(28px);
                    line-height: torem(34px);
                    display: flex;
                    align-items: center;
                    text-align: right;
                    color: $mainfont;
                    padding-right: torem(38px);
                }
            }
            margin-bottom: torem(50px);
        }
        &-bottom {
            display: flex;
            align-items: flex-end;
            margin-right: auto;
            top: auto;
        }
        &-ibholder {
            flex: 1 1 auto;
            max-width: torem(306px);
            margin-right: auto;
        }
        &-checkoutholder {
            max-width: torem(253px);
            margin-left: auto;
            flex: 1 1 auto;
            .btn {
                outline: none;
                width: 100%;
                line-height: torem(29px);
                font-size: torem(24px);
                height: torem(58px);
            }
        }
        &-cont--empty {
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            p {
                color: $color-ac;
            }
        }
        &-h1 {
            margin-bottom: 0;
            line-height: 1;
        }
        &-empty {
            &-h1 {
                font-weight: $fw-bold;
                font-size: torem(32px);
                line-height: torem(39px);
                margin: 0 0 torem(16px) 0;
            }
            &-p {
                font-weight: $fw-medium;
                font-size: torem(24px);
                line-height: torem(29px);
                margin: 0 0 torem(36px) 0;
            }
        }
    }
    
    @include media-breakpoint-down(sm) {
        .cart {
            &.flex-fill {
                flex-direction: column;
            }
            &-bg {
                flex: 0 0 auto;
                max-width: 100%;
                min-height: 1px;
                height: torem(200px);
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            &-cont {
                padding: torem(40px);
            }
            &-h1 {
                margin: 0;
            }
            .loader {
                left: 0;
            }
            .cartList {
                &-item {
                    align-items: center;
                    .top {
                        align-items: center;
                    }
                    .title, .price {
                        font-size: torem(18px);
                        /*padding: torem(18px) 0;*/
                        margin: 0;
                    }
                    .price {
                        font-size: torem(22px);
                        padding-right: 0;
                    }
                    .remove {
                        font-size: torem(24px);
                        width: auto;
                        height: auto;
                    }
                }
                &-total {
                    margin-bottom: torem(28px);
                    &--price, &--text {
                        p {
                            font-size: torem(22px)
                        }
                    ;
                    }
                }
                &-btn {
                    margin-top: 0;
                }
                .promo {
                    &-title {
                        font-size: torem(17px);
                    }
                    &-holder {
                        margin-bottom: torem(28px);
                    }
                }
                
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
    
        .cart {
            background: transparent;
            box-shadow: none;
            border: none;
            &-bg {
                display: none;
            }
            &-cont {
                padding: torem(0px);
            }
            &-h1.main-h1 {
                display: none;
            }
            .cartList .title{
                margin-right: auto;
            }
            
        }
    }
</style>