import Backbone from "backbone";
import 'backbone-relational/backbone-relational'

export default Backbone.Model.extend({
  defaults: {
    active: false,
    params: {},
    productId: null,
  },
  url: function () {
    return "/api/user/products";
  },
});

