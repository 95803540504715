<template>
    <div class="icountup obj">
        <ICountUp
            class="icountup-digits"
            :delay="delay"
            :endVal="value"
            :options="options"
            @ready="onReady"
        />
        <p class="icountup-text">{{text}}</p>
    </div>
</template>

<script type="text/babel">
    import ICountUp from 'vue-countup-v2';

    export default {
        name: 'countUp',
        components: {
            ICountUp
        },
        props:['text','value'],
        data() {
            return {
                delay: 1000,
                endVal: 120500,
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: ''
                }
            };
        },
        methods: {
            onReady: function (instance, CountUp) {
                instance.update(this.value);
            }
        }
    };
</script>

<style lang="scss">
    .icountup{
        &.obj {
            @include coner;
            margin: torem(23px) torem(0px);
            padding-top: 50px;
            padding-bottom: 50px;
        }
        
        &-digits {
            font-weight: $fw-800;
            font-size: torem(52px);
            line-height: torem(63px);
            display: flex;
            justify-content: center;
            color: $blue-18;
        }
        &-text{
            font-weight: $fw-500;
            font-size: torem(18px);
            line-height: torem(22px);
            display: flex;
            justify-content: center;
            color: $color-62;
        }
    }
</style>