<template>
  <p :class="{'strongText-empty':!text}" class="strongText">
    <strong>{{strongText}}:</strong> {{text||strongText}}
  </p>
</template>

<script>
  export default {
    name: "item-show",
    props: ['strongText', 'text']
  }
</script>

<style lang="scss">
  .strongText {
    
    font-weight: $fw-500;
    font-size: torem(22px);
    line-height: torem(27px);
    color: $mainfont;
    margin: torem(20px) 0;
    &-empty{
      color: $color-94;
    }
  }
</style>