import Backbone from 'backbone';
import 'backbone-relational/backbone-relational'

export default Backbone.RelationalModel.extend({
    url: "/api/paypal/paypal-profile",
    defaults: {
        'status': '',
        'account': 'octiive'
    },

    isCanBeCancelled: function () {
        var status = this.get('status');

        if (status === 'active') {
            return true;
        }
        return false;
    }
});
