<template>
    <div class="select">
        <div class="select-input">
            <i class="select-icon ficon icon-checksvg"></i>
            <p class="text">
                {{text.replace('✓','')}}
            </p>
            <span class="select-icons">
                <i class="ficon icon-select-up"></i>
                <i class="ficon icon-select-down"></i>
            </span>
            <select ref="select" @change="onChangeSelect">
                <slot></slot>
            </select>
        </div>
    
    
    </div>
</template>

<script>
    export default {
        name: "v-select",
        data() {
            return {
                selected: null,
                text: ''
            }
        },
        mounted() {
            this.onChangeSelect();
        },
        methods: {
            onChangeSelect() {
                this.selected = this.$refs.select.selectedIndex;
                this.text = this.$refs.select.options[this.selected].text;
                this.$emit('change', this.selected)
            }
        }
    }
</script>

<style lang="scss">
    .select {
        position: relative;
        &-input {
            display: flex;
            p {
                font-weight: $fw-600;
                font-size: torem(18px);
                line-height: torem(22px);
                display: flex;
                align-items: center;
                text-align: center;
                color: $mainfont;
            }
        }
        &-icon{
            font-size: torem(13px);
            color:$color-44;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 torem(15px) 0 0;
        }
        &-icons{
            display: flex;
            flex-direction: column;
            font-size: torem(6px);
            text-align: center;
            align-items: center;
            justify-content: center;
            width: torem(17px);
            margin-left: auto;
            .ficon{
                width: 100%;
                text-align: center;
                margin: torem(5px) 0;
                &:first-child{
                    margin-top: 0;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                &:before{
                    width: 100%;
                    margin: 0;
                }
            }
        }
        select {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 100%;
            height: 100%;
        }
    }
</style>