<template>
    <div :class="['sform',{'hide':isShowing}]">
        <ttip-Slot class="settings-top--notif"
                   ttipText="You can select to receive royalties via PayPal or<br>by physical check (physical checks are only sent<br>to artists in the United States).  Artists keep 100%<br>net royalties for every sale along with detailed<br>quarterly reports!">
            <h2 class="sform-title" @click="toggleHider">How would you like to get paid?</h2>
        </ttip-Slot>
        <div class="sform-hider">
            <notif class="settings-notif"
                   html="<p>Physical checks are only available in the US with a $10.00 <br> bank administrative fee</p>" />
            <multiselect
                ref="paymentMethod"
                v-model="selectedPaymentMethod"
                :options="options"
                :allowEmpty="false"
                :show-labels="false"
                :searchable="false"
                track-by="name"
                label="text"
                class="settings-select"
                @input="changePaymentMethod($event)"
            />

            <template v-if="doesPaymentMethodMatch('paypal')">
                <paypalForm :payment="userPaymentInfoModel" :paymentMethod="userPaymentInfoModel"/>
            </template>
            <template v-else-if="doesPaymentMethodMatch('payoneer')">
                <payoneerForm :payment="userPaymentInfoModel" :paymentMethod="userPaymentInfoModel"/>
            </template>
            <template v-else-if="load">
                <checkForm :payment="userPaymentInfoModel" :paymentMethod="userPaymentInfoModel"/>
            </template>
        </div>
    </div>
</template>

<script>
    import notif from '@/components/notiffication';
    import ttipSlot from '@/components/ttip-slot';
    import multiselect from 'vue-multiselect';

    import paypalForm from './paypal-form'
    import payoneerForm from './payoneer-form'
    import checkForm from './check-form'
    import UserPaymentInfoModel from "@/components/models/user/payment-info.js"

    import {toggle} from '../mixins/toggle'

    export default {
        name: "payment-method-form",
        props: ['user'],
        mixins: [toggle],
        data() {
            return {
                options: [
                    // Valid options for payment method
                    // TODO Better if we receive this list from the backend or a config file
                    {name:'paypal', text:'PayPal'},
                    // {name:'payoneer', text: 'Payoneer'},
                    {name:'check', text:'Physical check'},
                ],
                userPaymentInfoModel: null,
                defaultPaymentMethod: 'paypal', // By default we select paypal as it is the primary payment method
                selectedPaymentMethod: null,
                buttonText: 'Save payment Info',
                load: false,
            }
        },
        mounted() {
            this.userPaymentInfoModel = new UserPaymentInfoModel();
            this.userPaymentInfoModel
                .fetch()
                .then(userPaymentMethod => {
                    const activePaymentMethod = this.userPaymentInfoModel.getPaymentMethod() || this.defaultPaymentMethod;

                    // front end should be adjusted. this not gonna work anyway
                    // if (this.userPaymentInfoModel.isNew()) {
                    //     this.buttonText = 'Create payment info'
                    // }

                    this.selectedPaymentMethod = this.resolvePaymentMethodByName(activePaymentMethod);
                    this.load = true;
                }).catch(error => {
                    // We have a bug on the backend side. If user registered recently
                    // they have no payment method record in the database. and instead
                    // of null, backend throws error 500 on calling /api/user/payment-method.
                    // in this case if any error happens, we set the selected payment method
                    // to the default one.
                    this.selectedPaymentMethod = this.resolvePaymentMethodByName(this.defaultPaymentMethod);
                    this.changePaymentMethod()
                    this.load = true;
                });
        },
        methods: {
            /**
             * Change user's payment method
             */
            changePaymentMethod() {
                this.userPaymentInfoModel.set('paymentMethod', this.selectedPaymentMethod.name);
            },

            /**
             * Resolves proper payment method option by given name
             * We use it for multi-select component
             */
            resolvePaymentMethodByName(name) {
                return this.options.find(
                    paymentMethod => paymentMethod.name === name
                ) || this.resolvePaymentMethodByName(this.defaultPaymentMethod)
            },

            /**
             * It check if the selected payment method match the given key
             * @returns {boolean}
             */
            doesPaymentMethodMatch(name) {
                return this.selectedPaymentMethod
                    && this.selectedPaymentMethod.name === name
                    && this.load;
            },
        },
        components: {
            notif,
            ttipSlot,
            multiselect,
            paypalForm,
            payoneerForm,
            checkForm
        }
    }
</script>
