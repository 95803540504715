import {mapGetters} from 'vuex';
export const isMobile = {
    computed: {
        isMobile() {
            return this.deviceState.model === 'xs';
        },
        isTablet(){
            return this.deviceState.model === 'sm';
        },
        isMiddle(){
            return this.deviceState.model === 'md';
        },
        isLarge(){
            return this.deviceState.model === 'lg';
        },
        ...mapGetters({
            winRes: 'resolution/get',
            sizeTab: 'resolution/getSizeTable',
            deviceState: 'resolution/getCurrentState'
        })
    },
};