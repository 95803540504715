<template>
    <router-link to="/home" class="logo" v-if="logo">
        <img :src="logo" alt="Octiive">
    </router-link>
</template>

<script>
    export default {
        name: "logo",
        props: ['logoName'],
        data() {
            return {
                logo: false,
                locLink: {
                    whiteLogo: require('@/assets/img/logo_white.svg'),
                    whiteLogoSM: require('@/assets/img/logo_white-sm.svg'),
                    blackLogo: require('@/assets/img/logo_black.svg'),
                    blueLogo: require('@/assets/img/logo_blue.svg'),
                },
            }
        },

        mounted: function () {
            var self = this;
            self.logo = this.locLink[this.logoName];
        },

    }
</script>

<style lang="scss">
    .logo {
        flex: 0 0 auto;
        line-height: 1;
        display: block;
        img {
            max-width: torem(92px);
            width: 100%;
        }
    }
    @include media-breakpoint-down(sm) {
        .logo{
            img {
                max-width: torem(37px);
                width: torem(37px);
            }
        }
    }
</style>